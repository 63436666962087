import { Alert, Button, Snackbar, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import { IdentityClient } from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TimeoutAlert = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    button: {
      color: "inherit",
      fontWeight: "bold",
    },
    alert: {
      width: "100%",
      fontWeight: "bold",
    },
  }));
  const classes = useStyle(theme);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway" || reason === "escapeKeyDown") {
      return;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.setOpen(false);
    new IdentityClient(Configuration.SERVER_ROOT, FetchOverride).keepAlive();
  };

  return (
    <Snackbar
      open={props.open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        className={classes.alert}
        severity="warning"
        variant="filled"
        action={
          <Button className={classes.button} size="small" onClick={handleClick}>
            KEEP ME LOGGED IN
          </Button>
        }
      >
        You are about to be logged out due to inactivity.
      </Alert>
    </Snackbar>
  );
};

export default TimeoutAlert;
