import {
  Timeline,
  TimelineConnector,
  TimelineItem,
  TimelineDot,
  TimelineSeparator,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";
import { Theme } from "@mui/material";
import {
  PatientViewModel,
  PatientTimelineDataViewModel,
  UserViewModel,
} from "../../../../types/auto/types";
import { TimelineGrid } from "../table/TimelineGrid";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import React from "react";

interface Props {
  items: { [x: string]: PatientTimelineDataViewModel[] };
  patient: PatientViewModel;
  user: UserViewModel;
  canEditResponses: boolean;
  refreshTimeline: () => void;
  currentTimelineLength: number;
  totalTimelineLength: number;
  totalTimelineLengthFiltered: number;
  setCurrentTimelineLength: React.Dispatch<React.SetStateAction<number>>;
  setTotalTimelineLengthFiltered: React.Dispatch<React.SetStateAction<number>>;
  setTotalTimelineLength: React.Dispatch<React.SetStateAction<number>>;
  questionnairesWithPrintingTemplateEnabled: number[];
}

const TimelineView = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles((th: Theme) => ({
    timelineContent: {
      flex: 1,
      width: "100%",
      padding: 0,
      paddingLeft: "5px",
    },
    timeline: {
      padding: "0px",
      marginBottom: 0,
    },
    timelineItem: {
      ":before": { display: "none" },
    },
    timelineOppositeContent: {
      flex: 0.1,
      paddingLeft: 0,
    },
  }));
  const classes = useStyle(theme);
  const [hiddenArray, setHiddenArray] = React.useState<Array<boolean>>(
    new Array(Object.keys(props.items).length).fill(false)
  );
  const [, setRerender] = React.useState(false);
  var timelineCards = Object.entries(props.items).map(
    ([date, responsesAgainstDate], index) => {
      return (
        <TimelineItem
          className={classes.timelineItem}
          key={index}
          hidden={hiddenArray[index]}
        >
          <TimelineOppositeContent
            hidden={isMobile}
            className={classes.timelineOppositeContent}
          >
            {date}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector
              hidden={index + 1 === Object.keys(props.items).length}
            />
          </TimelineSeparator>
          <TimelineContent className={classes.timelineContent}>
            {isMobile ? date : ""}
            <TimelineGrid
              timelineEntrees={responsesAgainstDate}
              patient={props.patient}
              user={props.user}
              canEditResponses={props.canEditResponses}
              refreshTimeline={props.refreshTimeline}
              currentTimelineLength={props.currentTimelineLength}
              setCurrentTimelineLength={props.setCurrentTimelineLength}
              totalTimelineLength={props.totalTimelineLength}
              setTotalTimelineLength={props.setTotalTimelineLength}
              totalTimelineLengthFiltered={props.totalTimelineLengthFiltered}
              setTotalTimelineLengthFiltered={
                props.setTotalTimelineLengthFiltered
              }
              setHiddenArrayIndex={() => {
                setHiddenArray((y) => {
                  y[index] = true;
                  return y;
                });
                setRerender((x) => !x);
              }}
              questionnairesWithPrintingTemplateEnabled={
                props.questionnairesWithPrintingTemplateEnabled
              }
            />
          </TimelineContent>
        </TimelineItem>
      );
    }
  );
  return <Timeline className={classes.timeline}>{timelineCards}</Timeline>;
};

export default TimelineView;
