export const getCookie = (cname: string) => {
  const name = cname + "=";
  const ca = decodeURIComponent(document.cookie).split(";");
  for (let c of ca) {
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
};

type CookieCallback = (cookieVal: string) => any;

export const onChangeCookie = (cname: string, handler: CookieCallback) => {
  let prevCookie = getCookie(cname);

  setInterval(() => {
    const currCookie = getCookie(cname);
    if (currCookie !== prevCookie) {
      handler(currCookie);
    }
    prevCookie = currCookie;
  }, 250);
};
