import { CircularProgress, Container, Typography } from "@mui/material";
import { useUnmountPromise } from "react-use";
import { makeStyles } from "@mui/styles";
import { useContext, useEffect, useState } from "react";
import {
  CaseloadClient,
  CaseloadViewModel,
  TenantClient,
  CustomIdViewModel,
} from "../../types/auto/types";
import { UserContext } from "../components/elements/stores/UserStore";
import { Configuration, HelpText } from "../Constants";
import { FetchOverride } from "../utils/Request";
import CaseloadCard from "../components/caseload/CaseloadCard";
import CreateEditCaseload from "../components/caseload/CreateEditCaseload";
import PaginatedView from "../components/search/PaginatedView";
import HandleError from "../components/elements/errors/HandleError";

const caseloadClient = new CaseloadClient(
  Configuration.SERVER_ROOT,
  FetchOverride
);

const tenantClient = new TenantClient(Configuration.SERVER_ROOT, FetchOverride);

const SharedCaseloadSearchPage = () => {
  const user = useContext(UserContext);

  const [integration, setIntegration] = useState(false);

  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState<number>();

  const [caseloads, setCaseloads] = useState<CaseloadViewModel[]>([]);
  const [refreshCaseload, setRefreshCaseload] = useState(false);

  const [termFilter, setTermFilter] = useState<string>("");
  const [archivedFilter, setArchivedFilter] = useState<boolean>(false);

  const [customCaseloadIdOptions, setCustomCaseloadIdOptions] = useState<
    CustomIdViewModel | undefined
  >(undefined);

  const useStyle = makeStyles({
    titles: {
      marginTop: "10px",
    },
    caseloads: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    mainContainer: {
      marginTop: "10px",
    },
    createEditButton: {
      marginTop: "15px",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
      marginTop: 10,
    },
  });
  const classes = useStyle();

  const resolveWhileMounted = useUnmountPromise();

  useEffect(() => {
    const effect = async () => {
      setStatusCode(undefined);
      await resolveWhileMounted(tenantClient.getIsTenantIntegrated())
        .then((integration) => setIntegration(integration))
        .catch((e) => setStatusCode(e.statusCode));
    };
    effect();
  }, [resolveWhileMounted, user]);

  useEffect(() => {
    const effect = async () => {
      if (user.id === undefined) {
        return;
      }
      setLoading(true);
      setStatusCode(undefined);
      const customCaseloadId = await resolveWhileMounted(
        tenantClient.getCustomCaseloadIdEnabled()
      ).catch((e) => setStatusCode(e.statusCode));
      if (customCaseloadId) {
        const tenant = await resolveWhileMounted(
          tenantClient.getTenant(undefined)
        );
        setCustomCaseloadIdOptions(tenant.tenantOptions?.customCaseloadId);
      }
      await resolveWhileMounted(
        caseloadClient.getCaseloadsForAdministrator(true)
      )
        .then((caseloads) => {
          setLoading(false);
          setCaseloads(caseloads);
        })
        .catch((e) => setStatusCode(e.statusCode));
    };
    effect();
  }, [user, refreshCaseload, resolveWhileMounted]);

  return (
    <Container className={classes.mainContainer} disableGutters>
      <Typography variant="h5" component="h2" className={classes.titles}>
        Shared Caseloads
      </Typography>
      <PaginatedView
        controlElements={
          !integration && (
            <Container className={classes.createEditButton} disableGutters>
              <CreateEditCaseload
                setRefresh={() => setRefreshCaseload((x) => !x)}
                customCaseloadIdOptions={customCaseloadIdOptions}
              />
              <HandleError statusCode={statusCode} />
              {caseloads.length === 0 && !loading && (
                <Typography>
                  There are no active caseloads. Click the above button to add a
                  new add a new caseload.
                </Typography>
              )}
            </Container>
          )
        }
        elements={caseloads
          .filter(
            (x) =>
              x.active === !archivedFilter &&
              (x.name
                ?.toLowerCase()
                ?.startsWith(termFilter?.toLowerCase() ?? "") ||
                termFilter?.trimEnd() === "")
          )
          .map((caseload, key) => (
            <CaseloadCard
              caseload={caseload}
              key={key}
              setRefresh={() => setRefreshCaseload((x) => !x)}
              integration={integration}
              customCaseloadIdOptions={customCaseloadIdOptions}
            />
          ))}
        filterCallback={(term, archived) => {
          setTermFilter(term);
          setArchivedFilter(archived);
        }}
        searchTerm={termFilter}
        enableArchived={archivedFilter}
        helpText={HelpText.CASELOAD_TABLE.SEARCH_CASELOAD}
      />

      <Container>
        {loading && (
          <CircularProgress
            className={classes.loadingSpinner}
            aria-label="Loading"
          />
        )}
      </Container>
    </Container>
  );
};

export default SharedCaseloadSearchPage;
