import {
  Theme,
  Card,
  CardContent,
  Typography,
  Container,
  CardActions,
  Collapse,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useEffect } from "react";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import {
  QuestionnaireViewModel,
  QuestionnaireVersionViewModel,
  QuestionnaireClient,
  TenantOptionsViewModel,
} from "../../../types/auto/types";
import { theme } from "../../../Theme";
import { CheckIsMobile, CheckIsSmallDevice } from "../../utils/MobileStatus";
import QuestionnaireActions from "./QuestionnaireActions";
import QuestionnaireVersionCard from "./QuestionnaireVersionCard";

interface Props {
  questionnaire: QuestionnaireViewModel;
  setRefresh: () => void;
  editQuestionnaire: (qId: number, v: number) => void;
  newVersion: (qId: number) => void;
  tenancyOptions: TenantOptionsViewModel;
  uploadVersion: (qId: number, qName: string) => void;
  refreshVersions: boolean;
  setRefreshVersions: (x: boolean) => void;
}

const QuestionnaireCard = (props: Props) => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const useStyle = makeStyles((th: Theme) => ({
    card: {
      marginTop: "10px",
      width: isMobile ? "100%" : "49.5%",
      marginRight: isMobile ? "auto" : "0.5%",
      minWidth: isSmallDevice ? "180px" : "250px",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      height: "fit-content",
      "&:nth-child(even)": {
        marginRight: isMobile ? "auto" : "0",
        marginLeft: isMobile ? "auto" : "0.5%",
      },
    },
    nameContainer: {
      display: "flex",
      float: "left",
      paddingLeft: "0",
      paddingRight: "0",
    },
    nameText: {
      fontSize: isMobile ? "1.3em" : "1.5em",
      width: "90%",
    },
    buttonContainer: {
      marginLeft: "auto",
      display: "flex",
      width: "10%",
    },
    expandButton: {
      marginLeft: "auto",
    },
    subTitle: {
      fontWeight: "bold",
    },
    cardContent: {
      display: "flex",
      paddingLeft: "10px",
    },
    cardActions: {
      paddingLeft: "10px",
      cursor: "pointer",
    },
    collapse: {
      width: "100%",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  }));
  const classes = useStyle({ theme });

  const [questionnaireVersions, setQuestionnaireVersions] = React.useState<
    QuestionnaireVersionViewModel[]
  >([]);
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [resetVersions, setResetVersions] = React.useState<boolean>(false);
  const [hasPublishedVersion, setHasPublishedVersion] =
    React.useState<boolean>(false);

  function resetQuestionnaireVersions() {
    setLoading(true);
    new QuestionnaireClient(Configuration.SERVER_ROOT, FetchOverride)
      .getQuestionnaireVersions(props.questionnaire.id)
      .then((questionnaireVersions) => {
        setLoading(false);
        setHasPublishedVersion(
          questionnaireVersions.some((v) => v.published != null)
        );
        setQuestionnaireVersions(questionnaireVersions);
        setResetVersions(false);
      });
  }

  useEffect(() => {
    if (resetVersions) {
      resetQuestionnaireVersions();
    }
    if (props.refreshVersions) {
      resetQuestionnaireVersions();
      props.setRefreshVersions(false);
    }
    // eslint-disable-next-line
  }, [resetVersions, props.refreshVersions]);

  return (
    <Card className={classes.card} elevation={0}>
      <CardContent className={classes.cardContent}>
        <Container className={classes.nameContainer}>
          <Typography className={classes.nameText}>
            {props.questionnaire.name}
          </Typography>
          <Container className={classes.buttonContainer} disableGutters>
            <QuestionnaireActions
              questionnaire={props.questionnaire}
              questionnaireVersions={questionnaireVersions}
              refresh={props.setRefresh}
              newVersion={(qId) => props.newVersion(qId)}
              uploadVersion={(qId, qName) => props.uploadVersion(qId, qName)}
            />
          </Container>
        </Container>
      </CardContent>
      <CardActions
        className={classes.cardActions}
        onClick={() => {
          setExpanded((x) => !x);
          if (!expanded && questionnaireVersions.length === 0) {
            resetQuestionnaireVersions();
          }
        }}
      >
        <Typography className={classes.subTitle}>Versions</Typography>
        <div className={classes.expandButton}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </div>
      </CardActions>
      <Collapse
        className={classes.collapse}
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        {questionnaireVersions.map((questionnaireVersion, key) => (
          <QuestionnaireVersionCard
            questionnaireVersion={questionnaireVersion}
            resetQuestionnaireVersions={() => setResetVersions(true)}
            key={key}
            editQuestionnaire={(qId, v) => props.editQuestionnaire(qId, v)}
            printingTemplateEnabled={
              props.tenancyOptions.questionnairesWithPrintingTemplateEnabled?.includes(
                questionnaireVersion.questionnaireId
              ) == null
                ? false
                : props.tenancyOptions.questionnairesWithPrintingTemplateEnabled?.includes(
                    questionnaireVersion.questionnaireId
                  )
            }
            hasPublishedVersion={hasPublishedVersion}
            questionnaireName={props.questionnaire.name ?? ""}
          />
        ))}
        {loading && (
          <CircularProgress
            className={classes.loadingSpinner}
            aria-label="Loading"
          />
        )}
      </Collapse>
    </Card>
  );
};
export default QuestionnaireCard;
