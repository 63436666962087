import { TextField } from "@mui/material";
import {
  MeasurementViewModel,
  PatientDatasetViewModel,
  PatientMeasurementViewModel,
} from "../../../../types/auto/types";
import {
  IsMeasurementValueValid,
  MeasurementError,
} from "./PatientDatasetHelper";
import { useEffect, useState } from "react";

interface Props {
  measurement: MeasurementViewModel;
  patientDataset: PatientDatasetViewModel;
  setPatientDataset: React.Dispatch<
    React.SetStateAction<PatientDatasetViewModel>
  >;
  setReRender: React.Dispatch<React.SetStateAction<boolean>>;
  setMeasurementError: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeCheck: (patientDataset: PatientDatasetViewModel) => boolean;
  measurementError: boolean;
}
const MeasurementTextField = (props: Props): JSX.Element => {
  const [measurementError, setMeasurementError] = useState<MeasurementError>(
    MeasurementError.NoError
  );

  function measurementChange(value: string, measurement: MeasurementViewModel) {
    props.setPatientDataset((dataset) => {
      var patientMeasurement = dataset.measurements?.find(
        (patMeasurement) => patMeasurement.measurementId === measurement.id
      );
      if (
        patientMeasurement === undefined ||
        dataset.measurements === undefined
      ) {
        var newMeasurement = {
          measurementId: measurement.id,
          value: value,
          measurementName: measurement.name,
        } as PatientMeasurementViewModel;
        if (dataset.measurements === undefined) {
          dataset.measurements = [newMeasurement];
        } else {
          var updatedMeasurements = dataset.measurements;
          updatedMeasurements.push(newMeasurement);
          dataset.measurements = updatedMeasurements;
        }
      } else {
        dataset.measurements = dataset.measurements.map((patientMeasurement) =>
          patientMeasurement?.measurementId === measurement.id
            ? ({
                ...patientMeasurement,
                value: value,
              } as PatientMeasurementViewModel)
            : patientMeasurement
        );
      }
      props.setReRender((x) => !x);
      props.onChangeCheck(dataset);
      return dataset;
    });
    setMeasurementError(IsMeasurementValueValid(measurement, value));
  }

  useEffect(() => {
    if (!props.measurementError) {
      setMeasurementError(MeasurementError.NoError);
    }
  }, [setMeasurementError, props.measurementError]);
  return (
    <TextField
      label={props.measurement.name}
      value={
        props.patientDataset.measurements?.find(
          (x) => x.measurementId === props.measurement.id
        ) === undefined ||
        props.patientDataset.measurements?.find(
          (x) => x.measurementId === props.measurement.id
        ) === null
          ? ""
          : props.patientDataset.measurements?.find(
              (x) => x.measurementId === props.measurement.id
            )?.value
      }
      onChange={(e) => {
        var measurementValue = e.target.value;
        measurementChange(measurementValue, props.measurement);
      }}
      error={measurementError !== MeasurementError.NoError}
      helperText={
        (measurementError === MeasurementError.NotANumber &&
          "Max measurement defined as numerical value but text value supplied.") ||
        (measurementError === MeasurementError.InvalidValue &&
          `Value must be between ${props.measurement.minMeasurement} and ${props.measurement.maxMeasurement}.`) ||
        (measurementError === MeasurementError.InvalidCharacter &&
          "Invalid character used for text measurement.")
      }
    />
  );
};

export default MeasurementTextField;
