import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  QuestionDataModel,
  QuestionnaireBuilderDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import {
  DefaultQuestionValuesViewModel,
  QuestionType,
} from "../../../../types/auto/types";
import { useState } from "react";
import nameof from "../../../utils/NameOf";
import DefaultValuesAccordionComponent from "./DefaultValuesAccordionComponent";

interface Props {
  open: boolean;
  questionnaire: QuestionnaireBuilderDataModel;
  question: QuestionDataModel;
  handleClose: () => void;
  handleSubmit: (
    field: string,
    defaultValueOverride?: DefaultQuestionValuesViewModel
  ) => void;
}

const DefaultValuesOverrideModal = (props: Props): JSX.Element => {
  const [defaultValuesOverrideWIP, setDefaultValuesOverrideWIP] = useState<
    DefaultQuestionValuesViewModel | undefined
  >(props.question.defaultValueOverride);
  const [noResponseError, setNoResponseError] = useState<boolean>(false);

  function handleLocalChange(field: string, event: any) {
    var localCopy =
      defaultValuesOverrideWIP ??
      new DefaultQuestionValuesViewModel({
        questionType: props.question.questionType,
      });
    if (field === nameof<DefaultQuestionValuesViewModel>("answer")) {
      switch (props.question.questionType) {
        case QuestionType.Date:
        case QuestionType.Day: {
          if (event != null) {
            event = new Date(event);
            if (!isNaN(event.getDate())) {
              var newDate = event.toLocaleDateString("en-GB", {
                day: "numeric",
                month: "long",
                year: "numeric",
              });
              localCopy = new DefaultQuestionValuesViewModel({
                ...localCopy,
                [field]: newDate,
              });
            } else {
              localCopy = new DefaultQuestionValuesViewModel({
                ...localCopy,
                [field]: "",
              });
            }
          }
          break;
        }
        default: {
          localCopy = new DefaultQuestionValuesViewModel({
            ...localCopy,
            [field]:
              props.question.questionType === QuestionType.CheckBoxSingle
                ? event.toString()
                : event.target.value,
          });
          break;
        }
      }
    } else {
      localCopy = new DefaultQuestionValuesViewModel({
        ...localCopy,
        [field]: event.target.value,
      });
    }
    setDefaultValuesOverrideWIP(localCopy);
  }

  function validateInputs() {
    var valuesToSubmit =
      defaultValuesOverrideWIP != null
        ? new DefaultQuestionValuesViewModel({ ...defaultValuesOverrideWIP })
        : undefined;
    if (valuesToSubmit === undefined) {
      setNoResponseError(true);
      return { valid: false, value: valuesToSubmit };
    }
    if (
      valuesToSubmit?.answer == null ||
      valuesToSubmit?.answer === "" ||
      valuesToSubmit.score == null ||
      valuesToSubmit.score.toString() === ""
    ) {
      setNoResponseError(true);
      return { valid: false, value: valuesToSubmit };
    } else {
      if (
        valuesToSubmit.displayValue == null ||
        valuesToSubmit.displayValue.toString() === ""
      ) {
        valuesToSubmit.displayValue = undefined;
      }
      setNoResponseError(false);
      return { valid: true, value: valuesToSubmit };
    }
  }

  return (
    <Dialog
      open={props.open}
      key={"default-values-override-modal"}
      scroll="paper"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle key={"default-values-override-modal-title"}>
        Default Value Override
      </DialogTitle>
      <DialogContent key={"default-values-override-modal-content"}>
        <Box
          display="flex"
          flexWrap={"wrap"}
          width={"100%"}
          key={"default-values-override-modal-content-container"}
        >
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={props.question.questionType}
            handleChange={(qT, f, e) => handleLocalChange(f, e)}
            defaultValuesOverride={defaultValuesOverrideWIP}
            question={props.question}
            handleUpdate={() => {
              var validatedModel = validateInputs();
              if (
                validatedModel != null &&
                validatedModel.valid &&
                validatedModel.value != null
              ) {
                props.handleSubmit(
                  nameof<QuestionDataModel>("defaultValueOverride"),
                  validatedModel.value
                );
              }
            }}
            handleDelete={(qType) => {
              props.handleSubmit(
                nameof<QuestionDataModel>("defaultValueOverride"),
                undefined
              );
              setNoResponseError(false);
            }}
            noResponseError={noResponseError}
          />
        </Box>
      </DialogContent>
      <DialogActions key={"default-values-override-modal-actions"}>
        <Button
          variant="contained"
          onClick={() => props.handleClose()}
          key={"default-values-override-modal-cancel-button"}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DefaultValuesOverrideModal;
