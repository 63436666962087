import React, { useEffect, useState } from "react";
import {
  GraphClient,
  PatientViewModel,
  Role,
  TenantOptionsViewModel,
  UserViewModel,
} from "../../../../../types/auto/types";
import { graphColours } from "../../../../../Theme";
import { ExtendedLineViewModel } from "../../../elements/graph/GraphLegend";
import { Configuration } from "../../../../Constants";
import { FetchOverride } from "../../../../utils/Request";
import LineGraphCore from "../summary/LineGraphCore";
import { Container, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GraphHeader from "../header/GraphHeader";
import { Flag, FlagOutlined } from "@mui/icons-material";
import { useUnmountPromise } from "react-use";
import CompletedGoalIcon from "../../../elements/completed-goal-icon/CompletedGoalIcon";
import AccordionContainer from "../AccordionContainer";

interface Props {
  patient: PatientViewModel;
  user: UserViewModel;
  fromDate: Date | undefined;
  toDate: Date | undefined;
  showCompletedGoals: boolean;
  setNoData: (noData: boolean) => void;
  hideGraph?: boolean;
  tenancyOptions: TenantOptionsViewModel | undefined;
  firstGoalResponsesLoad: boolean;
  setFirstGoalResponsesLoad: React.Dispatch<React.SetStateAction<boolean>>;
  collapsedGraph?: boolean;
}

const graphWidth = 1000;
const graphHeight = 500;

const GoalResponsesChart = (props: Props): JSX.Element => {
  const [data, setData] = useState<ExtendedLineViewModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const resolveWhileMounted = useUnmountPromise();

  const useStyle = makeStyles(() => ({
    splitSummaryContainer: { marginTop: 30, "@media print": { marginTop: 0 } },
  }));
  const classes = useStyle();

  var isStaff =
    props.user.role === Role.ReadOnlyPIRedacted ||
    props.user.role === Role.ReadOnly ||
    props.user.role === Role.Clinician ||
    props.user.role === Role.Admin ||
    props.user.role === Role.SuperAdmin;

  useEffect(() => {
    const effect = async () => {
      if (props.firstGoalResponsesLoad) {
        props.setFirstGoalResponsesLoad(false);
        setLoading(true);
        if (isStaff) {
          await resolveWhileMounted(
            new GraphClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getGoalResponsesGraph(
              props.showCompletedGoals,
              props.fromDate,
              props.toDate,
              props.patient.id
            )
          )
            .then((x) => {
              if (x.lines === undefined || x.lines.length === 0) {
                props.setNoData(true);
                setLoading(false);
                setError(false);
                return;
              }

              setData(
                x.lines?.map(
                  (x, i) =>
                    ({
                      ...x,
                      hidden: false,
                      colour: graphColours[i % graphColours.length],
                    } as ExtendedLineViewModel)
                ) || []
              );
              props.setNoData(false);
              setError(false);
              setLoading(false);
            })
            .catch(() => {
              setError(true);
              setLoading(false);
              props.setNoData(false);
            });
        } else {
          await resolveWhileMounted(
            new GraphClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getCurrentGoalResponsesGraph(
              props.showCompletedGoals,
              props.fromDate,
              props.toDate
            )
          )
            .then((x) => {
              if (x.lines === undefined || x.lines.length === 0) {
                props.setNoData(true);
                setLoading(false);
                setError(false);
                return;
              }

              setData(
                x.lines?.map(
                  (x, i) =>
                    ({
                      ...x,
                      hidden: false,
                      colour: graphColours[i % graphColours.length],
                    } as ExtendedLineViewModel)
                ) || []
              );
              props.setNoData(false);
              setError(false);
              setLoading(false);
            })
            .catch(() => {
              setError(true);
              setLoading(false);
              props.setNoData(false);
            });
        }
      }
    };
    effect();
  }, [
    setData,
    props,
    resolveWhileMounted,
    isStaff,
    props.firstGoalResponsesLoad,
  ]);

  if (
    data === undefined ||
    data === null ||
    data.length === 0 ||
    props.hideGraph
  ) {
    return <></>;
  }

  return (
    <AccordionContainer
      accordionIcon={<FlagOutlined />}
      accordionTitle={"Personalised Goals"}
      defaultExpanded={!props.collapsedGraph}
    >
      {data.map((x) => (
        <Container
          id={x.lineName}
          key={x.lineName + x.colour}
          className={classes.splitSummaryContainer}
          sx={{
            paddingBottom: 2,
          }}
          disableGutters
        >
          <GraphHeader
            title={x.lineName ?? ""}
            icon={
              <Tooltip title={(x.completed ? "Completed " : "") + "Goal"}>
                <>{x.completed ? <CompletedGoalIcon /> : <Flag />}</>
              </Tooltip>
            }
            hidePrint={error || loading}
          >
            <LineGraphCore
              data={[x]}
              loading={loading}
              error={error}
              width={graphWidth}
              height={graphHeight}
              fromDate={props.fromDate}
              toDate={props.toDate}
              yDomain={[0, 10]}
              fromGoal={true}
              tenancyOptions={props.tenancyOptions}
            />
          </GraphHeader>
        </Container>
      ))}
    </AccordionContainer>
  );
};

export default GoalResponsesChart;
