import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../Theme";
import { Add, PlayArrow, Pause, Archive, DoneAll } from "@mui/icons-material";
import { CheckIsMobile } from "../../utils/MobileStatus";

interface Props {
  createTitle: string;
  onCreateClick: () => void;
  showSuspendAll?: boolean;
  onSuspendClick: () => void;
  showResumeAll?: boolean;
  onResumeClick: () => void;
  showArchiveAll?: boolean;
  onArchiveClick: () => void;
  showCompleteAll?: boolean;
  onCompleteClick?: () => void;
}

const ActivePromptableActionButtons = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    button: {
      margin: 3,
      width: isMobile ? "100%" : undefined,
      backgroundColour: "blue",
    },
    container: { paddingTop: "10px" },
  });
  const classes = useStyle(customTheme);

  return (
    <div className={classes.container}>
      <Button
        className={classes.button}
        variant="contained"
        startIcon={<Add />}
        onClick={props.onCreateClick}
      >
        {props.createTitle}
      </Button>
      {(props.showResumeAll === true || props.showResumeAll === undefined) && (
        <Button
          className={classes.button}
          variant="outlined"
          startIcon={<PlayArrow />}
          onClick={props.onResumeClick}
          sx={{ backgroundColor: "white" }}
        >
          Resume All
        </Button>
      )}
      {(props.showSuspendAll === true ||
        props.showSuspendAll === undefined) && (
        <Button
          className={classes.button}
          variant="outlined"
          startIcon={<Pause />}
          onClick={props.onSuspendClick}
          sx={{ backgroundColor: "white" }}
        >
          Suspend All
        </Button>
      )}
      {(props.showCompleteAll === true ||
        props.showCompleteAll === undefined) &&
        props.onCompleteClick && (
          <Button
            className={classes.button}
            variant="outlined"
            startIcon={<DoneAll />}
            onClick={props.onCompleteClick}
            sx={{ backgroundColor: "white" }}
          >
            Complete All
          </Button>
        )}
      {(props.showArchiveAll === true ||
        props.showArchiveAll === undefined) && (
        <Button
          className={classes.button}
          variant="outlined"
          startIcon={<Archive />}
          onClick={props.onArchiveClick}
          sx={{ backgroundColor: "white" }}
        >
          Archive All
        </Button>
      )}
    </div>
  );
};

export default ActivePromptableActionButtons;
