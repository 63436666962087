import { Button, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Add, Delete, Edit } from "@mui/icons-material";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import WarningDialog from "../../elements/dialog/WarningDialog";
import CreateEditMeasurementModal from "./CreateEditMeasurementModal";
import {
  DatasetClient,
  MeasurementViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";

interface Props {
  measurement?: MeasurementViewModel;
  datasetId: number;
  resetMeasurements: () => void;
}
const CreateEditMeasurement = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles(() => ({
    createButton: {
      marginLeft: isMobile ? "" : "auto",
      width: isMobile ? "50%" : "",
    },
    buttonContainer: {
      display: "flex",
      padding: 0,
      width: isMobile ? "" : "fit-content",
      marginRight: isMobile ? "" : 0,
    },
    deleteButton: {
      backgroundColor: "red",
      "&:hover": {
        backgroundColor: "crimson",
      },
      marginLeft: "10px",
      width: isMobile ? "50%" : "",
    },
    vert: {
      marginLeft: "auto",
      padding: "0",
      minWidth: "24px",
    },
  }));
  const classes = useStyle();
  const [openEditCreateMeasurementModal, setOpenEditCreateMeasurementModal] =
    React.useState<boolean>(false);
  const [openDeleteMeasurementModal, setOpenDeleteMeasurementModal] =
    React.useState<boolean>(false);

  return (
    <>
      {props.measurement === undefined && (
        <Button
          variant="contained"
          className={classes.createButton}
          onClick={() => setOpenEditCreateMeasurementModal(true)}
        >
          <Add /> measurement
        </Button>
      )}
      {props.measurement !== undefined && (
        <Container className={classes.buttonContainer} disableGutters>
          <Button
            onClick={() => setOpenEditCreateMeasurementModal(true)}
            className={classes.createButton}
            aria-label="Edit Measurement"
            variant="contained"
          >
            <Edit />
          </Button>
          <Button
            onClick={() => setOpenDeleteMeasurementModal(true)}
            className={classes.deleteButton}
            aria-label="Delete Measurement"
            variant="contained"
          >
            <Delete />
          </Button>
        </Container>
      )}
      <CreateEditMeasurementModal
        open={openEditCreateMeasurementModal}
        closeModal={() => setOpenEditCreateMeasurementModal(false)}
        measurement={props.measurement}
        resetMeasurements={props.resetMeasurements}
        datasetId={props.datasetId}
      />
      <WarningDialog
        title="Delete measurement"
        errorText="Error deleting measurement."
        body={`This will delete the selected measurement: ${
          props.measurement!.name ? props.measurement!.name : ""
        }. Are you sure this is intended?`}
        continueButtonText="Delete measurement"
        open={openDeleteMeasurementModal}
        onCancel={() => setOpenDeleteMeasurementModal(false)}
        setOpen={() => setOpenDeleteMeasurementModal(true)}
        onContinue={() =>
          new DatasetClient(Configuration.SERVER_ROOT, FetchOverride)
            .deleteMeasurement(props.measurement!)
            .then(() => {
              setOpenDeleteMeasurementModal(false);
              props.resetMeasurements();
            })
        }
      />
    </>
  );
};

export default CreateEditMeasurement;
