import { FormControlLabel, Switch, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ArrowForward } from "@mui/icons-material";
import { theme } from "../../../../Theme";
import { useEffect } from "react";
import { CheckIsMobile } from "../../../utils/MobileStatus";

interface Props {
  editing: boolean;
  startDate: Date | undefined;
  setStartDate: (startDate: Date) => void;
  endDate: Date | undefined;
  setEndDate: (endDate: Date) => void;
  indefiniteEndDate: boolean;
  setIndefiniteEndDate: (indefiniteEndDate: boolean) => void;
}

const DateSelect = (props: Props) => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    datePicker: {
      width: "100%",
      zIndex: 100,
    },
    container: {
      width: "100%",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
    },
    arrow: {
      fontSize: isMobile ? 20 : 30,
      margin: 10,
      color: "gray",
      transform: isMobile ? "rotate(90deg)" : "0",
    },
    continueIndefinitely: {
      marginLeft: isMobile ? 0 : 10,
      marginTop:
        (props.startDate !== undefined &&
          props.endDate !== undefined &&
          props.startDate > props.endDate) ||
        props.startDate === undefined ||
        props.endDate === undefined
          ? "5px"
          : 0,
      alignSelf:
        (props.startDate !== undefined &&
          props.endDate !== undefined &&
          props.startDate > props.endDate) ||
        props.startDate === undefined ||
        props.endDate === undefined
          ? "flex-start"
          : "baseline",
    },
  });
  const classes = useStyle(theme);

  useEffect(() => {
    if (props.startDate === undefined || props.startDate === null) {
      props.setStartDate(new Date());
    }
  }, [props]);

  return (
    <div className={classes.container}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div>
          <DateTimePicker
            label="Start Date"
            value={props.startDate}
            onChange={(newValue: Date | null) =>
              newValue != null && props.setStartDate(newValue)
            }
            renderInput={(params) => <TextField {...params} />}
            className={classes.datePicker}
            disablePast={!props.editing} // Dates can be in the past if we're editing
            disableMaskedInput
            ampm={false}
            inputFormat="dd/MM/yyyy HH:mm"
            PopperProps={{
              placement: "bottom-start",
              modifiers: [
                {
                  name: "flip",
                  options: {
                    fallbackPlacements: ["top-start", "right"],
                  },
                },
              ],
              popperOptions: {
                strategy: "fixed",
              },
            }}
          />
        </div>
        {!props.indefiniteEndDate && (
          <>
            <ArrowForward className={classes.arrow} />

            <div>
              <DatePicker
                label="End Date"
                value={props.endDate ?? ""}
                onChange={(newValue: Date | null) =>
                  newValue != null && props.setEndDate(newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      props.startDate !== undefined &&
                      props.endDate !== undefined &&
                      props.startDate > props.endDate
                        ? "End date must be after start date"
                        : props.startDate === undefined ||
                          props.endDate === undefined
                        ? "Please enter a date"
                        : undefined
                    }
                    error={
                      (props.startDate !== undefined &&
                        props.endDate !== undefined &&
                        props.startDate > props.endDate) ||
                      props.startDate === undefined ||
                      props.endDate === undefined
                    }
                  />
                )}
                className={classes.datePicker}
                minDate={props.startDate}
                inputFormat="dd/MM/yyyy"
                PopperProps={{
                  placement: "bottom-start",
                  modifiers: [
                    {
                      name: "flip",
                      options: {
                        fallbackPlacements: ["top-start", "right"],
                      },
                    },
                  ],
                  popperOptions: {
                    strategy: "fixed",
                  },
                }}
              />
            </div>
          </>
        )}
        <FormControlLabel
          control={
            <Switch
              checked={props.indefiniteEndDate}
              onChange={() =>
                props.setIndefiniteEndDate(!props.indefiniteEndDate)
              }
              color="secondary"
            />
          }
          label="Continue Indefinitely"
          className={classes.continueIndefinitely}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DateSelect;
