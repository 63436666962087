import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import TableHeader from "./TableHeader";

interface Props {
  id: string;
  head: string[];
  rows: (string | number | JSX.Element)[][];
  center?: boolean;
}

const tableCellDivision = "1px solid rgba(0,0,0,0.1)";

const GenericTable = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    row: {
      borderRight: tableCellDivision,
    },
    secondaryRow: {
      backgroundColor: "#f9f9f9",
      borderRight: tableCellDivision,
    },
    tableContainer: {
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      borderRadius: "4px",
    },
  }));
  const classes = useStyle(theme);

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table>
        <TableHeader
          headCells={props.head.map((x) => ({
            label: x,
            id: x,
            disablePadding: false,
          }))}
          centred={props.center}
        />
        <TableBody>
          {props.rows.map((row, index) => (
            <TableRow key={props.id + "row" + index}>
              {row.map((cell, cellIndex) => (
                <TableCell
                  key={props.id + "cell" + cellIndex}
                  className={index % 2 ? classes.row : classes.secondaryRow}
                  align="center"
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GenericTable;
