import React, { useRef, useState } from "react";
import { Print } from "@mui/icons-material";
import { Container, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { CheckIsMobile } from "../../../../utils/MobileStatus";
import { useMountedState } from "react-use";
import { PrintChart } from "./GraphHeaderHelper";

interface Props {
  icon?: JSX.Element;
  title?: string;
  hidePrint?: boolean;
  hideHeader?: boolean;
  graphContainerId?: string;
  children: React.ReactNode;
}

const GraphHeader = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles(() => ({
    topContainer: {
      "@media print": { display: "grid" },
    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      width: "100%",
    },
    titleContainer: {
      flex: 8,
      display: "flex",
      flexDirection: "row",
    },
    buttonContainer: {
      textAlign: "right",
      zIndex: 100,
      flex: 1,
      "@media print": { display: "none" },
    },
    graphContainer: {
      marginTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    graphTitle: {
      fontSize: isMobile ? 15 : 25,
    },
    iconContainer: {
      margin: 0,
      padding: 0,
      paddingRight: 10,
      width: "auto",
    },
  }));
  const classes = useStyle();
  const graphContainerId =
    props.graphContainerId ?? "graphContainer" + Math.random();
  const [mouseGrabbing, setMouseGrabbing] = useState(false);
  const graphContainerRef = useRef<HTMLDivElement | null>(null);
  const isMountedState = useMountedState();

  useEffect(() => {
    var graphContainer = graphContainerRef.current;
    graphContainer?.addEventListener(
      "mousedown",
      () => isMountedState() && setMouseGrabbing(true)
    );
    graphContainer?.addEventListener(
      "mouseup",
      () => isMountedState() && setMouseGrabbing(false)
    );

    return () => {
      graphContainer?.removeEventListener(
        "mousedown",
        () => isMountedState() && setMouseGrabbing(true)
      );
      graphContainer?.removeEventListener(
        "mouseup",
        () => isMountedState() && setMouseGrabbing(false)
      );
    };
  }, [graphContainerRef, isMountedState]);

  return (
    <Container
      id={graphContainerId}
      disableGutters
      sx={{ paddingLeft: isMobile ? 0 : 1, paddingRight: isMobile ? 0 : 1 }}
    >
      {!props.hideHeader && (
        <Container className={classes.headerContainer} disableGutters>
          <Container className={classes.titleContainer}>
            {props.icon && (
              <Container className={classes.iconContainer}>
                {props.icon}
              </Container>
            )}
            {props.title && (
              <Typography
                variant="h5"
                component="h2"
                className={classes.graphTitle}
              >
                {props.title}
              </Typography>
            )}
          </Container>
          <Container
            className={classes.buttonContainer}
            style={{
              display: props.hidePrint ? "none" : undefined,
            }}
            disableGutters
          >
            <IconButton
              color="primary"
              aria-label="print graphs"
              onClick={() => PrintChart(graphContainerId)}
            >
              <Print />
            </IconButton>
          </Container>
        </Container>
      )}
      <Container
        ref={graphContainerRef}
        className={classes.graphContainer}
        disableGutters
        style={{
          cursor: mouseGrabbing ? "grabbing" : "grab",
        }}
      >
        {props.children}
      </Container>
    </Container>
  );
};

export default GraphHeader;
