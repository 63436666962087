import React from "react";
import { Report } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { theme } from "../../../../Theme";

interface Props {
  errors?: { [line: string]: string[] };
}

const BatchUploadErrors = (props: Props): JSX.Element => {
  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        overflow: props.errors ? "auto" : "hidden",
        maxHeight: props.errors ? 300 : 0,
        marginTop: 3,
        transition: "max-height 2s",
      }}
      subheader={
        <ListSubheader
          style={{
            color: "red",
            paddingBottom: 0,
            marginBottom: 0,
            marginTop: 7,
            lineHeight: 1,
          }}
        >
          Please fix the following errors with the uploaded csv and try again
        </ListSubheader>
      }
    >
      {props.errors &&
        Object.entries(props.errors).map((error) =>
          error[1].map((errorText) => (
            <ListItem
              key={"errorList" + error[0] + errorText}
              sx={{ pl: 0, pt: 0, pb: 0 }}
            >
              <ListItemIcon>
                <Report color={"error"} />
              </ListItemIcon>
              <ListItemText
                primary={error[0] + ": " + errorText}
                style={{ color: theme.palette.text.secondary, marginLeft: -20 }}
              />
            </ListItem>
          ))
        )}
    </List>
  );
};

export default BatchUploadErrors;
