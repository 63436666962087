import { useState } from "react";
import { Button, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import AddCliniciansToCaseloadModal from "./AddCliniciansToCaseloadModal";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { PersonAdd } from "@mui/icons-material";

interface Props {
  caseloadId: number;
  refresh: () => void;
}
const AddCliniciansToCaseload = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles(() => ({
    button: {
      textAlign: "center",
      width: isMobile ? "100%" : undefined,
      marginTop: "10px",
      whiteSpace: "nowrap",
    },
  }));
  const classes = useStyle(theme);
  const [
    addCliniciansToCaseloadModalOpen,
    setAddCliniciansToCaseloadModalOpen,
  ] = useState(false);

  return (
    <Container disableGutters>
      <AddCliniciansToCaseloadModal
        open={addCliniciansToCaseloadModalOpen}
        closeModal={() => {
          setAddCliniciansToCaseloadModalOpen(false);
        }}
        caseloadId={props.caseloadId}
        refresh={props.refresh}
      />
      <Button
        variant="contained"
        onClick={() => setAddCliniciansToCaseloadModalOpen(true)}
        className={classes.button}
        startIcon={<PersonAdd />}
      >
        Add caseload clinicians
      </Button>
    </Container>
  );
};

export default AddCliniciansToCaseload;
