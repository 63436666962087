import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  QuestionnaireViewModel,
  ResponseState,
  Role,
} from "../../../types/auto/types";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { theme } from "../../../Theme";
import { makeStyles } from "@mui/styles";
import parse from "html-react-parser";
import { LoadingButton } from "@mui/lab";

interface Props {
  questionnaire: QuestionnaireViewModel | undefined;
  editingDate: boolean;
  setEditingDate: React.Dispatch<React.SetStateAction<boolean>> | null;
  responseDate: Date;
  setResponseDate: React.Dispatch<React.SetStateAction<Date>>;
  role: Role | undefined;
  saveDraft: () => void;
  saveDraftButtonLoading: boolean;
  goal: boolean;
  fromNotification: boolean;
  responseState: ResponseState | undefined;
  preview?: boolean;
}

export const QuestionnaireHeader = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    textField: {
      backgroundColor: "#fafafa",
    },
    saveDraftButton: {
      height: "36px",
    },
  });

  const classes = useStyle(theme);
  const isStaff =
    props.role === Role.Clinician ||
    props.role === Role.Admin ||
    props.role === Role.SuperAdmin;

  return (
    <Box key="header-box" sx={{ width: 1, m: isMobile ? 0 : 2, marginTop: 2 }}>
      <Box key="title-box" sx={{ marginBottom: "11px" }}>
        {props.questionnaire?.showTitle !== false && (
          <Typography variant={isMobile ? "h5" : "h4"} component={"span"}>
            {props.questionnaire?.title}
          </Typography>
        )}
      </Box>

      <Typography
        variant={isMobile ? "body1" : "body2"}
        component={"span"}
        gutterBottom
      >
        {parse(props.questionnaire?.description ?? "")}
      </Typography>
      {isStaff ? (
        <Box
          sx={{ width: 1, m: 2, mx: "auto" }}
          display={"inline-flex"}
          flexWrap={"wrap"}
          justifyContent={"flex-start"}
          gap={2}
          alignItems={"center"}
        >
          <Box
            flex={2}
            minWidth={"230px"}
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"flex-start"}
            gap={2}
            alignItems={"center"}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={props.editingDate}
                  color="secondary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.setEditingDate!(event.target.checked)
                  }
                  disabled={props.preview}
                />
              }
              label="Use edited response date"
            />
            {props.editingDate ? (
              <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Response Date"
                    value={props.responseDate}
                    onChange={(newValue: Date | null) => {
                      if (newValue !== null) {
                        props.setResponseDate(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} className={classes.textField} />
                    )}
                    disableFuture
                    inputFormat="dd/MM/yyyy h:mm a"
                    disableMaskedInput
                    maxDateTime={new Date()}
                  />
                </LocalizationProvider>
              </>
            ) : null}
          </Box>
          <Box
            flex={1}
            minWidth={"110px"}
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            gap={2}
            alignItems={"center"}
          >
            {!props.goal && props.responseState !== ResponseState.Submitted && (
              <LoadingButton
                variant="contained"
                onClick={(e) => {
                  props.saveDraft();
                }}
                loading={props.saveDraftButtonLoading}
                className={classes.saveDraftButton}
                disabled={props.preview}
              >
                Save Draft
              </LoadingButton>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{ width: 1, m: 2, mx: "auto" }}
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          gap={2}
          alignItems={"center"}
        >
          {!props.goal && props.responseState !== ResponseState.Submitted && (
            <LoadingButton
              variant="contained"
              onClick={(e) => {
                props.saveDraft();
              }}
              loading={props.saveDraftButtonLoading}
              className={classes.saveDraftButton}
            >
              Save Draft
            </LoadingButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export default QuestionnaireHeader;
