import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Container,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import {
  DatasetClient,
  DatasetViewModel,
  TenancyOptionErrors,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { FetchOverride } from "../../../utils/Request";
import { IsNameValid } from "../measurements/CreateEditMeasurementModalHelper";

interface Props {
  dataset?: DatasetViewModel;
  open: boolean;
  closeModal: () => void;
  refresh: () => void;
}
const CreateEditDataSetModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "100%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      display: "flex",
      justifyContent: "end",
      marginTop: 20,
      gap: "8px",
    },
    textField: {
      width: "100%",
    },
  });
  const classes = useStyle();
  const [dataset, setDataset] = React.useState<DatasetViewModel>(
    props.dataset ?? ({} as DatasetViewModel)
  );
  const [, setReRender] = React.useState<boolean>(false);
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<TenancyOptionErrors | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    if (props.dataset) {
      // Deep copy patient prop to fix state update problems
      setDataset(
        JSON.parse(JSON.stringify(props.dataset), function (key, value) {
          return value;
        })
      );
    } else {
      setDataset({} as DatasetViewModel);
    }
    setReRender((x) => !x);
  }, [props.open, props.dataset]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "80%" : "40%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          {props.dataset ? "Edit Dataset" : "Create Dataset"}
        </Typography>
        <Container>
          <TextField
            label="Dataset Name"
            className={classes.textField}
            required
            value={dataset.name ? dataset.name : ""}
            onChange={(e) => {
              setDataset((x) => {
                x.name = e.target.value;
                setReRender((x) => !x);
                return x;
              });
              setNameError(!IsNameValid(e.target.value));
            }}
            error={nameError}
            helperText={
              nameError && "Name must be between 1 and 20 characters long"
            }
          />
        </Container>
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              props.closeModal();
              setNameError(false);
              setReRender((x) => !x);
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            disabled={nameError}
            loading={loading}
            onClick={async () => {
              setLoading(true);
              if (dataset.name === undefined) {
                setLoading(false);
                setNameError(true);
                return;
              }
              if (props.dataset === undefined || props.dataset === null) {
                new DatasetClient(Configuration.SERVER_ROOT, FetchOverride)
                  .createDataset(dataset)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                    props.refresh();
                  })
                  .catch((e) => {
                    setError(e);
                  });
              } else {
                new DatasetClient(Configuration.SERVER_ROOT, FetchOverride)
                  .updateDataset(dataset)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                    props.refresh();
                  })
                  .catch((e) => {
                    setError(e);
                  });
              }
              setNameError(false);
            }}
          >
            {props.dataset ? "Save Changes" : "Create Dataset"}
          </LoadingButton>
        </div>
        {error !== undefined && (
          <Alert severity="error">
            {error === TenancyOptionErrors.InvalidModel
              ? "Details for dataset are invalid"
              : "Tenancy does not support external datasets."}
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default CreateEditDataSetModal;
