import { Button, Menu, MenuItem, Typography, Container } from "@mui/material";
import React from "react";
import { Add, Edit, Delete, Publish } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";
import {
  QuestionnaireViewModel,
  QuestionnaireClient,
  QuestionnaireErrors,
  QuestionnaireVersionViewModel,
} from "../../../types/auto/types";
import WarningDialog from "../elements/dialog/WarningDialog";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import RenameQuestionnaireModal from "./RenameQuestionnaireModal";
import CustomDialog from "../elements/dialog/CustomDialog";

interface Props {
  questionnaire: QuestionnaireViewModel;
  questionnaireVersions: QuestionnaireVersionViewModel[];
  refresh: () => void;
  newVersion: (qId: number) => void;
  uploadVersion: (qId: number, questionnaireName: string) => void;
}
const QuestionnaireActions = (props: Props): JSX.Element => {
  const [anchorActionList, setAnchorActionList] =
    React.useState<null | HTMLElement>(null);
  const [openRenameQuestionnaire, setOpenRenameQuestionnaire] =
    React.useState(false);
  const [openDeleteQuestionnaire, setOpenDeleteQuestionnaire] =
    React.useState(false);
  const [openUnableToDeleteQuestionnaire, setOpenUnableToDeleteQuestionnaire] =
    React.useState(false);
  const [deleteErrorText, setDeleteErrorText] = React.useState(
    "Error deleting questionnaire."
  );

  const useStyle = makeStyles(() => ({
    vert: {
      marginLeft: "auto",
      padding: "0",
      minWidth: "24px",
    },
  }));
  const classes = useStyle();
  const handleClose = () => {
    setAnchorActionList(null);
  };

  const checkDelete = () => {
    if (
      props.questionnaireVersions.length > 0 &&
      props.questionnaireVersions.findIndex((x) => x.published) !== -1
    ) {
      setOpenUnableToDeleteQuestionnaire(true);
    } else {
      setOpenDeleteQuestionnaire(true);
    }
  };

  return (
    <>
      <Button
        onClick={(event) =>
          setAnchorActionList((val) =>
            val === null ? event.currentTarget : null
          )
        }
        aria-label="Open Questionnaire Action List"
        className={classes.vert}
      >
        <MoreVertIcon />
        <Menu
          open={anchorActionList !== null}
          anchorEl={anchorActionList}
          aria-label="Questionnaire Action List"
          onClose={handleClose}
        >
          <MenuItem
            aria-label="Add version"
            onClick={() => props.newVersion(props.questionnaire.id)}
          >
            <Add />
            Add version
          </MenuItem>
          <MenuItem
            aria-label="Upload version"
            onClick={() =>
              props.uploadVersion(
                props.questionnaire.id,
                props.questionnaire.name ?? ""
              )
            }
          >
            <Publish />
            Upload version
          </MenuItem>
          <MenuItem
            aria-label="Rename questionnaire"
            onClick={() => setOpenRenameQuestionnaire(true)}
          >
            <Edit />
            Rename questionnaire
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              checkDelete();
            }}
            aria-label="Delete questionnaire"
          >
            <Delete />
            Delete questionnaire
          </MenuItem>
        </Menu>
      </Button>
      <WarningDialog
        title="Delete questionnaire"
        errorText={deleteErrorText}
        body={`This will delete the selected questionnaire: ${props.questionnaire.name}. Are you sure this is intended?`}
        continueButtonText="Delete questionnaire"
        open={openDeleteQuestionnaire}
        onCancel={() => setOpenDeleteQuestionnaire(false)}
        setOpen={() => setOpenDeleteQuestionnaire(true)}
        onContinue={() =>
          new QuestionnaireClient(Configuration.SERVER_ROOT, FetchOverride)
            .deleteQuestionnaire(props.questionnaire.id)
            .then(() => {
              setOpenDeleteQuestionnaire(false);
              props.refresh();
            })
            .catch((e) => {
              if (
                e === QuestionnaireErrors.UnableToDeletePublishedQuestionnaire
              ) {
                setDeleteErrorText(
                  "Unable to delete the selected questionnaire because it has a published version."
                );
              }
              throw new Error(`Unable to delete questionnaire: ${e}`);
            })
        }
      />
      <CustomDialog
        open={openUnableToDeleteQuestionnaire}
        onClose={() => setOpenUnableToDeleteQuestionnaire(false)}
        title="Delete questionnaire"
        aria-label="Delete questionnaire"
      >
        <Container>
          <Typography>
            Unable to delete the selected questionnaire (
            {props.questionnaire.name}) because it has a published version.
          </Typography>
        </Container>
      </CustomDialog>
      <RenameQuestionnaireModal
        open={openRenameQuestionnaire}
        closeModal={() => setOpenRenameQuestionnaire(false)}
        questionnaire={props.questionnaire}
        refresh={props.refresh}
      />
    </>
  );
};

export default QuestionnaireActions;
