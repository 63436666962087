import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { Add, Delete, Edit } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CreateEditPatientMedicationModal from "./CreateEditPatientMedicationModal";
import {
  MedicationViewModel,
  PatientMedicationClient,
  PatientMedicationViewModel,
} from "../../../../types/auto/types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WarningDialog from "../../elements/dialog/WarningDialog";
import { FetchOverride } from "../../../utils/Request";
import { Configuration } from "../../../Constants";

interface Props {
  patientMedication?: PatientMedicationViewModel;
  patientId: number;
  refresh: () => void;
  medications: MedicationViewModel[];
}
const CreateEditPatientMedication = (props: Props): JSX.Element => {
  const [anchorActionList, setAnchorActionList] =
    React.useState<null | HTMLElement>(null);
  const [openAddEditPatientMedication, setOpenAddEditPatientMedication] =
    React.useState(false);
  const [openDeletePatientMedication, setOpenDeletePatientMedication] =
    React.useState(false);
  const useStyle = makeStyles({
    button: {
      marginTop: 15,
    },
    vert: {
      marginLeft: "auto",
      padding: "0",
      minWidth: "24px",
    },
  });
  const classes = useStyle();
  const handleClose = () => {
    setAnchorActionList(null);
  };

  return (
    <>
      {props.patientMedication === undefined && (
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => setOpenAddEditPatientMedication(true)}
          startIcon={props.patientMedication ? "" : <Add />}
        >
          {props.patientMedication ? <Edit /> : "Add patient medication"}
        </Button>
      )}
      {props.patientMedication !== undefined && (
        <Button
          onClick={(event) =>
            setAnchorActionList((val) =>
              val === null ? event.currentTarget : null
            )
          }
          aria-label="Open Patient Medication Action List"
          className={classes.vert}
        >
          <MoreVertIcon />
          <Menu
            open={anchorActionList !== null}
            anchorEl={anchorActionList}
            aria-label="Medication Category Action List"
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => setOpenAddEditPatientMedication(true)}
              aria-label="Edit medication"
            >
              <Edit />
              Edit medication
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenDeletePatientMedication(true);
              }}
              aria-label="Delete medication"
            >
              <Delete />
              Delete medication
            </MenuItem>
          </Menu>
        </Button>
      )}
      <CreateEditPatientMedicationModal
        medications={props.medications}
        open={openAddEditPatientMedication}
        refresh={props.refresh}
        closeModal={() => setOpenAddEditPatientMedication(false)}
        patientMedication={props.patientMedication}
        patientId={props.patientId}
      />
      {props.patientMedication !== undefined && (
        <WarningDialog
          title="Delete patient medication"
          errorText="Error deleting patient medication."
          body={`This will delete the selected patient medication: ${
            props.patientMedication!.medication?.name
              ? props.patientMedication!.medication?.name
              : ""
          }. Are you sure this is intended?`}
          continueButtonText="Delete patient medication"
          open={openDeletePatientMedication}
          onCancel={() => setOpenDeletePatientMedication(false)}
          setOpen={() => setOpenDeletePatientMedication(true)}
          onContinue={() =>
            new PatientMedicationClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            )
              .deletePatientMedication(props.patientMedication!)
              .then(() => {
                setOpenDeletePatientMedication(false);
                props.refresh();
              })
          }
        />
      )}
    </>
  );
};
export default CreateEditPatientMedication;
