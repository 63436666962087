import Menu from "@mui/material/Menu";
import { Role } from "../../../../types/auto/types";
import NavigationOptions from "./NavigationOptions";

interface IProp {
  open: boolean;
  handleCloseNavDrawer: () => void;
  userRole?: Role;
  anchorElNavDrawer: HTMLElement | null;
  usingMedication: boolean;
  usingExternalData: boolean;
  allowEditQuestionnaires: boolean;
}

const NavigationDrawer = (props: IProp): JSX.Element => (
  <Menu
    open={props.open}
    onClose={props.handleCloseNavDrawer}
    anchorEl={props.anchorElNavDrawer}
    role="menu"
  >
    <NavigationOptions
      userRole={props.userRole}
      usingMedication={props.usingMedication}
      usingExternalData={props.usingExternalData}
      allowEditQuestionnaires={props.allowEditQuestionnaires}
    />
  </Menu>
);

export default NavigationDrawer;
