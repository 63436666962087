import { LoadingButton } from "@mui/lab";
import { Modal, Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import {
  MedicationViewModel,
  PatientMedicationClient,
  PatientMedicationViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { FetchOverride } from "../../../utils/Request";
import PatientMedicationForm from "./PatientMedicationForm";
import {
  CurrentDate,
  IsDosageValid,
  IsValidDateRange,
  IsValidFrequencyAmount,
  IsValidFrequencySelect,
} from "./PatientMedicationHelper";

interface Props {
  open: boolean;
  closeModal: () => void;
  patientMedication?: PatientMedicationViewModel;
  refresh: () => void;
  patientId: number;
  medications: MedicationViewModel[];
}
const CreateEditPatientMedicationModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "80%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      display: "flex",
      justifyContent: "end",
      marginTop: 20,
      gap: "8px",
    },
  });
  const classes = useStyle();
  const [, setReRender] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [patientMedication, setPatientMedication] =
    React.useState<PatientMedicationViewModel>(
      props.patientMedication ?? ({} as PatientMedicationViewModel)
    );
  const [dosageError, setDosageError] = React.useState<boolean>(false);
  const [frequencyAmountError, setFrequencyAmountError] =
    React.useState<boolean>(false);
  const [invalidDateRange, setInvalidDateRange] =
    React.useState<boolean>(false);
  const [unselectedMedicationError, setUnselectedMedicationError] =
    React.useState<boolean>(false);
  const [selectedMedication, setSelectedMedication] = React.useState<
    MedicationViewModel | undefined
  >(props.patientMedication?.medication ?? undefined);
  const [invalidFrequency, setInvalidFrequency] =
    React.useState<boolean>(false);

  function resetErrors() {
    setDosageError(false);
    setUnselectedMedicationError(false);
    setSelectedMedication(undefined);
    setFrequencyAmountError(false);
  }
  function confirmInput(): boolean {
    var validInput = true;
    if (selectedMedication === undefined) {
      setUnselectedMedicationError(true);
      return false;
    }
    if (
      patientMedication.frequency !== undefined &&
      patientMedication.dose === undefined
    ) {
      setDosageError(true);
      validInput = false;
    }
    if (
      !IsDosageValid(
        selectedMedication,
        patientMedication.dose,
        patientMedication.doseNotApplicable
      )
    ) {
      setDosageError(true);
      validInput = false;
    }
    if (
      !IsValidFrequencyAmount(
        patientMedication.frequencyAmount,
        patientMedication.doseNotApplicable
      )
    ) {
      setFrequencyAmountError(true);
      validInput = false;
    }
    if (
      !IsValidFrequencySelect(
        patientMedication.frequency,
        patientMedication.doseNotApplicable
      )
    ) {
      setInvalidFrequency(true);
      validInput = false;
    }
    if (
      !IsValidDateRange(
        patientMedication.startDate,
        patientMedication.endDate,
        patientMedication.ongoing
      )
    ) {
      setInvalidDateRange(true);
      validInput = false;
    }
    return validInput;
  }

  useEffect(() => {
    if (props.patientMedication) {
      // Deep copy patient prop to fix state update problems
      setPatientMedication(
        JSON.parse(
          JSON.stringify(props.patientMedication),
          function (key, value) {
            return value;
          }
        )
      );
      setSelectedMedication(props.patientMedication?.medication ?? undefined);
    } else {
      setPatientMedication({
        patientId: props.patientId,
      } as PatientMedicationViewModel);
      setSelectedMedication(undefined);
    }
    setReRender((x) => !x);
  }, [props]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          {props.patientMedication
            ? "Edit Patient Medication"
            : "Create Patient Medication"}
        </Typography>
        <PatientMedicationForm
          medications={props.medications}
          patientMedication={patientMedication}
          setPatientMedication={setPatientMedication}
          setReRender={setReRender}
          selectedMedication={selectedMedication}
          setSelectedMedication={setSelectedMedication}
          unselectedMedicationError={unselectedMedicationError}
          setUnselectedMedicationError={setUnselectedMedicationError}
          dosageError={dosageError}
          setDosageError={setDosageError}
          invalidDateRange={invalidDateRange}
          setInvalidDateRange={setInvalidDateRange}
          frequencyAmountError={frequencyAmountError}
          setFrequencyAmountError={setFrequencyAmountError}
          invalidFrequency={invalidFrequency}
          setInvalidFrequency={setInvalidFrequency}
        />
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              props.closeModal();
              setReRender((x) => !x);
              resetErrors();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={
              dosageError ||
              unselectedMedicationError ||
              invalidDateRange ||
              frequencyAmountError
            }
            onClick={async () => {
              setLoading(true);
              if (patientMedication.startDate === undefined) {
                patientMedication.startDate = new Date(
                  CurrentDate.getFullYear(),
                  CurrentDate.getMonth(),
                  CurrentDate.getDate()
                );
              }
              if (!confirmInput()) {
                setLoading(false);
                return;
              }
              if (props.patientMedication === undefined) {
                new PatientMedicationClient(
                  Configuration.SERVER_ROOT,
                  FetchOverride
                )
                  .createPatientMedication(patientMedication)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                    props.refresh();
                  })
                  .catch(() => setLoading(false));
              } else {
                new PatientMedicationClient(
                  Configuration.SERVER_ROOT,
                  FetchOverride
                )
                  .updatePatientMedication(patientMedication)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                    props.refresh();
                  })
                  .catch(() => setLoading(false));
              }
            }}
          >
            {props.patientMedication
              ? "Save Changes"
              : "Create Patient Medication"}
          </LoadingButton>
        </div>
      </Box>
    </Modal>
  );
};

export default CreateEditPatientMedicationModal;
