import { useContext, useEffect, useState } from "react";
import { Configuration } from "../../Constants";
import parse from "html-react-parser";
import { PatientTab, QuestionnaireViewModel } from "../../../types/auto/types";
import { Box, Button, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { UserContext } from "../elements/stores/UserStore";
import { getCookie } from "../../utils/cookie";

interface Props {
  questionnaire: QuestionnaireViewModel;
  patientId: string | null;
}
const PAXDTool = (props: Props) => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles(() => ({
    gameDiv: {
      width: "100%",
      alignContent: "center",
      justifyContent: "center",
      display: "flex",
    },
    footerDivider: {
      marginTop: "20px",
      marginBottom: "5px",
    },
  }));

  const classes = useStyle();
  const [descriptionPage, setDescriptionPage] = useState(true);
  const user = useContext(UserContext);
  const userIsPatient =
    user?.id === undefined || user.id === null || user.id <= 0;

  useEffect(() => {
    if (descriptionPage) {
      const jqueryScript = document.createElement("script");
      jqueryScript.src = Configuration.SITEBASE + "/PAXDTask/jquery.min.js";
      jqueryScript.async = true;
      document.body.appendChild(jqueryScript);
      const momentScript = document.createElement("script");
      momentScript.src = Configuration.SITEBASE + "/PAXDTask/moment.min.js";
      momentScript.async = true;
      document.body.appendChild(momentScript);
      return () => {
        document.body.removeChild(jqueryScript);
        document.body.removeChild(momentScript);
      };
    } else {
      const script = document.createElement("script");
      script.src =
        Configuration.SITEBASE +
        `/PAXDTask/PAXDTask.js?tn=${props.questionnaire.paxTaskNumber}`;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [descriptionPage, props.questionnaire]);
  return (
    <>
      {descriptionPage && (
        <>
          {parse(props.questionnaire.description ?? "")}
          <Button variant="contained" onClick={() => setDescriptionPage(false)}>
            {" "}
            Start Game
          </Button>
        </>
      )}
      {!descriptionPage && (
        <div className={classes.gameDiv}>
          <canvas
            width={isMobile ? "400px" : window.innerWidth}
            height={isMobile ? "400px" : window.innerHeight}
            id="thirdpartyCanvas"
          />
        </div>
      )}
      <Divider key="footer-divider" className={classes.footerDivider} />
      <Box key="footer-box">
        <Button
          variant="contained"
          onClick={() => {
            const viewPatientLink =
              userIsPatient ||
              getCookie(Configuration.EXPIRATION_COOKIE_NAME).length === 0
                ? "/login/viewPatient,"
                : "/viewPatient?p=";

            window.location.href =
              Configuration.SITEBASE +
              viewPatientLink +
              props.patientId +
              (userIsPatient ? "_t=" : "&t=") +
              PatientTab.Questionnaires.toString();
          }}
        >
          Return to patient
        </Button>
      </Box>
    </>
  );
};

export default PAXDTool;
