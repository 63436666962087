/** General configuration for the application to function */
export const Configuration = {
  SERVER_ROOT: process.env.REACT_APP_SERVER_ROOT,
  SITEBASE: process.env.REACT_APP_SITEBASE,
  COOKIE: "WebAPICookie",
  CURRENTUSERKEY: "currentUser",
  TIMEOUTCHECK: 5000,
  EXPIRATION_COOKIE_NAME: "TrueColours_Expiration",
};

/** HTTP Status Codes */
export const HttpStatusCodes = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SUCCESSFUL: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  PARTIAL_CONTENT: 206,
  NONE: 0,
};

export const AllowListPatientSearchRegex = /[^a-zA-Z'0-9 ,.-]+$/i;

export const AllowListPatientPIRedactedSearchRegex = /[^'0-9]+$/i;

export const AllowListViewPatientReasonRegex = /[^\n\ra-zA-Z'0-9!.?() ,.-/@"]+/;

export const HelpText = {
  PATIENT_TABLE: {
    SEARCH_PATIENT:
      "Searches on patient name, surname, true colours ID or NHS number. If searching on true colours ID the full id is required, otherwise search with at least 1 character.",
    SEARCH_PATIENT_PI_REDACTED:
      "Searches on custom ID or true colours ID. If searching on true colours ID the full id is required, otherwise search with at least 1 character.",
    DATE_OF_BIRTH: "Optional field for searching on date of birth.",
    CASELOAD: "Searches for patients belonging to the selected caseload",
    ROLE: "Searches for users belonging to the selected role",
  },
  CASELOAD_TABLE: {
    SEARCH_CASELOAD: "Searches on shared caseload name.",
  },
  MEDICATION_TABLE: {
    SEARCH_MEDICATION: "Searches on medication category name.",
  },
  DATASET_TABLE: {
    SEARCH_DATASET: "Searches on dataset name.",
  },
  PATIENT_ACCESS: {
    PATIENT_ACCESS_WITHOUT_RELATIONSHIP_WARNING:
      "You are about to access the record for a Patient with whom you do not have a Legitimate Relationship. To continue, a reason must be provided.",
  },
  PATIENT_TIMELINE: {
    SEARCH_TIMELINE: "Filters by questionnaire name or goal question.",
    TIMELINE_TYPE: "Filters on either questionnaire or goal or both.",
    DATE_RANGE: "Filters on specified date range.",
  },
  QUESTIONNAIRE_TABLE: {
    SEARCH_QUESTIONNAIRE: "Searches on questionnaire name.",
  },
};

export const LoadMoreAmount = 10;

export const MaxDate = new Date(8640000000000000);

export const MinDate = new Date(-8640000000000000);

export const DefaultCustomIdDisplayName = "Custom Id";
