import { useEffect, useState } from "react";
import { PatientViewModel } from "../../../../types/auto/types";
import WarningDialog from "../../elements/dialog/WarningDialog";

export enum PromptActionWarningState {
  ResumeSingle,
  ResumeAll,
  SuspendSingle,
  SuspendAll,
  ArchiveSingle,
  ArchiveAll,
  CompleteSingle,
  CompleteAll,
  Unarchive,
}

interface Props {
  open: boolean;
  onClose: () => void;
  name: string;
  promptName: string;
  patient: PatientViewModel | undefined;
  state: PromptActionWarningState | undefined;
  refreshList: () => void;
  onResumeSingle: () => Promise<any>;
  onResumeAll: () => Promise<any>;
  onSuspendSingle: () => Promise<any>;
  onSuspendAll: () => Promise<any>;
  onArchiveSingle: () => Promise<any>;
  onArchiveAll: () => Promise<any>;
  onCompleteSingle?: () => Promise<any>;
  onCompleteAll?: () => Promise<any>;
  onUnarchive?: () => Promise<any>;
}

const PromptWarningDialog = (props: Props): JSX.Element => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [continueText, setContinueText] = useState("");
  const [onContinue, setOnContinue] = useState<() => Promise<void> | void>();

  const toUpperFirstLetter = (val: string): string =>
    val[0].toUpperCase() + val.substring(1);

  // Change the warning components based on the current state
  useEffect(() => {
    switch (props.state) {
      case PromptActionWarningState.ResumeSingle:
        setTitle(`Resume ${toUpperFirstLetter(props.promptName)}`);
        setBody(
          `This will resume this ${props.promptName}. Any suspended prompts will be resumed. Are you sure you want to resume "${props.name}"`
        );
        setContinueText("Resume");
        setOnContinue(
          () => () =>
            props.onResumeSingle().then(() => {
              props.onClose();
              props.refreshList();
            })
        );
        break;

      case PromptActionWarningState.ResumeAll:
        setTitle(`Resume All ${toUpperFirstLetter(props.promptName)}s`);
        setBody(
          `This will resume all ${props.promptName}s for ${props.patient?.firstName} ${props.patient?.lastName}. Any suspended prompts will be resumed. Are you sure you wish to proceed?`
        );
        setContinueText("Resume All");
        setOnContinue(
          () => () =>
            props.onResumeAll().then(() => {
              props.onClose();
              props.refreshList();
            })
        );
        break;

      case PromptActionWarningState.SuspendSingle:
        setTitle(`Suspend ${toUpperFirstLetter(props.promptName)}`);
        setBody(
          `This will suspend this ${props.promptName}. No prompts will be sent until resumed. Are you sure you want to suspend "${props.name}"?.`
        );
        setContinueText("Suspend");
        setOnContinue(
          () => () =>
            props.onSuspendSingle().then(() => {
              props.onClose();
              props.refreshList();
            })
        );
        break;

      case PromptActionWarningState.SuspendAll:
        setTitle(`Suspend All ${toUpperFirstLetter(props.promptName)}s`);
        setBody(
          `This will suspend all ${props.promptName} prompts for ${props.patient?.firstName} ${props.patient?.lastName}. No prompts for this patient will be sent until resumed. Are you sure you wish to proceed?.`
        );
        setContinueText("Suspend All");
        setOnContinue(
          () => () =>
            props.onSuspendAll().then(() => {
              props.onClose();
              props.refreshList();
            })
        );
        break;

      case PromptActionWarningState.ArchiveSingle:
        setTitle(`Archive ${toUpperFirstLetter(props.promptName)}`);
        setBody(
          `Archiving a ${props.promptName} will: \n  \u2022 Remove it from the active ${props.promptName} list` +
            (props.promptName === "schedule"
              ? ""
              : ` \n  \u2022 Remove it from the ${props.promptName} graph.`) +
            ` \n  \u2022 Archive any prompts that have been set against it. \n \nAre you sure you want to archive "${props.name}"?`
        );
        setContinueText("Archive");
        setOnContinue(
          () => () =>
            props.onArchiveSingle().then(() => {
              props.onClose();
              props.refreshList();
            })
        );
        break;

      case PromptActionWarningState.ArchiveAll:
        setTitle(`Archive All ${toUpperFirstLetter(props.promptName)}s`);
        setBody(
          `This will archive all ${props.promptName} prompts for ${props.patient?.firstName} ${props.patient?.lastName}. Any prompts that have been scheduled will be archived. Are you sure you want to proceed?`
        );
        setContinueText("Archive All");
        setOnContinue(
          () => () =>
            props.onArchiveAll().then(() => {
              props.onClose();
              props.refreshList();
            })
        );
        break;
      case PromptActionWarningState.CompleteSingle:
        setTitle(`Complete ${toUpperFirstLetter(props.promptName)}`);
        setBody(
          `Completing a ${props.promptName} will: \n  \u2022 Remove it from the active ${props.promptName} list \n  \u2022 Archive any prompts that have been set against it. \n \nAre you sure you want complete "${props.name}" to proceed?`
        );
        setContinueText("Complete");
        setOnContinue(
          () => () =>
            props.onCompleteSingle
              ? props.onCompleteSingle().then(() => {
                  props.onClose();
                  props.refreshList();
                })
              : console.error(
                  "Complete button was pressed with no logic provided to handle it"
                )
        );
        break;

      case PromptActionWarningState.CompleteAll:
        setTitle(`Complete All ${toUpperFirstLetter(props.promptName)}s`);
        setBody(
          `This will complete all ${props.promptName} prompts for ${props.patient?.firstName} ${props.patient?.lastName}. Any prompts that have been scheduled will be archived. Are you sure you want to proceed?`
        );
        setContinueText("Complete All");
        setOnContinue(
          () => () =>
            props.onCompleteAll
              ? props.onCompleteAll().then(() => {
                  props.onClose();
                  props.refreshList();
                })
              : console.error(
                  "Complete button was pressed with no logic provided to handle it"
                )
        );
        break;

      case PromptActionWarningState.Unarchive:
        setTitle(`Unarchive ${toUpperFirstLetter(props.promptName)}`);
        setBody(
          `Unarchiving a ${props.promptName} will: \n  \u2022 Add it to the active ${props.promptName} list. \n  \u2022 Reinstate any prompts set against it. \n \nAre you sure you want to unarchive "${props.name}"?`
        );
        setContinueText("Unarchive");
        setOnContinue(
          () => () =>
            props.onUnarchive
              ? props.onUnarchive().then(() => {
                  props.onClose();
                  props.refreshList();
                })
              : console.error(
                  "Unarchive button was pressed with no logic provided to handle it"
                )
        );
        break;
    }
  }, [props]);

  return (
    <WarningDialog
      open={props.open}
      setOpen={props.onClose}
      title={title}
      body={body}
      onCancel={props.onClose}
      onContinue={onContinue as () => Promise<void>}
      continueButtonText={continueText}
      errorText={"Error deleting prompt."}
    />
  );
};

export default PromptWarningDialog;
