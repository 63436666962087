import { Avatar, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";

interface Props {
  username: string | undefined;
  backgroundColour: string;
  colour: string;
  size?: number;
}

const AvatarLogo = (props: Props) => {
  const useStyle = makeStyles((th: Theme) => ({
    usernameAvatar: {
      color: props.colour,
      backgroundColor: props.backgroundColour,
      fontWeight: "bolder",
    },
    usernameAvatarResized: {
      color: props.colour,
      backgroundColor: props.backgroundColour,
      fontWeight: "bolder",
      height: props.size,
      width: props.size,
    },
  }));
  const classes = useStyle(theme);
  return (
    <Avatar
      className={
        props.size !== undefined
          ? classes.usernameAvatarResized
          : classes.usernameAvatar
      }
      title="Avatar"
    >
      {props.username &&
        props.username.split(" ")[0].charAt(0) +
          props.username.split(" ")[1].charAt(0)}
    </Avatar>
  );
};

export default AvatarLogo;
