import { Button } from "@mui/material";
import React from "react";
import { DatasetViewModel } from "../../../../types/auto/types";
import { Add, Edit } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CreateEditDataSetModal from "./CreateEditDatasetModal";
import { CheckIsMobile } from "../../../utils/MobileStatus";

interface Props {
  dataset?: DatasetViewModel;
  refresh: () => void;
}
const CreateEditDataSet = (props: Props): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    button: {
      marginTop: 15,
      width: isMobile ? "100%" : "default",
    },
  });
  const classes = useStyle();

  return (
    <>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => setOpen(true)}
        startIcon={props.dataset ? "" : <Add />}
      >
        {props.dataset ? <Edit /> : "Add dataset"}
      </Button>
      <CreateEditDataSetModal
        open={open}
        closeModal={() => setOpen(false)}
        dataset={props.dataset}
        refresh={props.refresh}
      />
    </>
  );
};
export default CreateEditDataSet;
