import {
  QuestionnaireBuilderDataModel,
  ScoringDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import ScoringAddModal from "./ScoringAddModal";
import {
  CategoryType,
  QuestionnaireViewModel,
} from "../../../../types/auto/types";

interface Props {
  questionnaire: QuestionnaireBuilderDataModel;
  allQuestionnaires: QuestionnaireViewModel[];
  scoring: ScoringDataModel[];
  handleChange: (
    index: number,
    scoreCategory: ScoringDataModel,
    arrayString: string
  ) => void;
  handleDelete: (index: number) => void;
  handleCancel: () => void;
  allQuestionsArr: number[];
  questionsDropdown: JSX.Element[];
}

const ScoringSelector = (props: Props): JSX.Element => {
  const headers: string[] = ["Category Name", "Questions", "Edit", "Delete"];

  const [rows, setRows] = useState<JSX.Element[]>([]);
  const [totalRows] = useState<number>(rows.length);
  const [scoringAddModalOpen, setScoringAddModalOpen] =
    useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [scoreCategory, setScoreCategory] = useState<ScoringDataModel>({
    categoryType: CategoryType.Subscale,
  });

  function loadRows() {
    var localRows: JSX.Element[] = [];
    if (props.scoring.length === 0) {
      setRows([]);
    } else {
      for (let i = 0; i < props.scoring.length; i++) {
        localRows.push(
          <TableRow key={"scoring-row-" + i}>
            <TableCell key={"question" + i} width={250}>
              <TextField
                value={props.scoring[i].name ?? ""}
                key={"scoring-name-" + i}
                variant="outlined"
                multiline
                maxRows={4}
                fullWidth
              />
            </TableCell>
            <TableCell key={"answers" + i}>
              <TextField
                value={
                  props.scoring[i].questions != null
                    ? props.scoring[i].questions!.join(", ")
                    : ""
                }
                key={"scoring-answers-" + i}
                variant="outlined"
                multiline
                maxRows={4}
                fullWidth
              />
            </TableCell>
            <TableCell key={"scoring-EditButton-cell-" + i} width={150}>
              <Button
                id={"edit-row-" + i}
                variant="contained"
                key={"scoring-EditButton-" + i}
                onClick={() => {
                  editResponseFromKey(i);
                }}
                fullWidth
              >
                Edit
              </Button>
            </TableCell>
            <TableCell key={"scoring-DeleteButton-cell-" + i} width={150}>
              <Button
                id={"delete-row-" + i}
                variant="contained"
                key={"scoring-DeleteButton-" + i}
                onClick={() => {
                  props.handleDelete(i);
                }}
                fullWidth
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    }
    setRows(localRows);
  }

  function addScoreCategory() {
    setScoreCategory({ categoryType: CategoryType.Subscale });
    setIndex(props.scoring.length);
    setScoringAddModalOpen(true);
  }

  function editResponseFromKey(index: number) {
    for (let i = 0; i < props.scoring.length; i++) {
      if (i === index) {
        const newCategory = structuredClone(props.scoring[i]);
        setScoreCategory(newCategory);
        continue;
      }
    }
    setIndex(index);
    setScoringAddModalOpen(true);
  }

  useEffect(
    () => {
      loadRows();
    },
    // eslint-disable-next-line
    [props.scoring]
  );

  return (
    <>
      <Box key={"scoring-selector-table-box-"}>
        <TableContainer
          component={Paper}
          key={"scoring-selector-table-container-"}
        >
          <Table
            sx={{ minWidth: 1000 }}
            aria-label="simple table"
            key={"scoring-selector-table-"}
          >
            <TableHead key={"scoring-selector-table-head-"}>
              <TableRow key={"scoring-selector-table-head-row-"}>
                {headers?.map((header) => (
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    key={"header-" + header}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody key={"scoring-selector-table-body-"}>{rows}</TableBody>
          </Table>
        </TableContainer>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          key={"scoring-selector-buttons-container"}
          sx={{ marginTop: 2, marginBottom: 2 }}
        >
          {totalRows < 20 && (
            <Button
              onClick={async () => {
                addScoreCategory();
              }}
              variant="contained"
              key={"scoring-selector-table-add-button-"}
            >
              Add Score Category
            </Button>
          )}
          <Button
            onClick={() => {
              props.handleCancel();
            }}
            key={"scoring-selector-back-button"}
            variant="contained"
          >
            Close
          </Button>
        </Box>
      </Box>
      <ScoringAddModal
        open={scoringAddModalOpen}
        handleCancel={() => {
          setScoringAddModalOpen(false);
        }}
        questionnaire={props.questionnaire}
        allQuestionnaires={props.allQuestionnaires}
        handleUpdate={(scoreCategory, arrayString) => {
          props.handleChange(index, scoreCategory, arrayString);
          setScoringAddModalOpen(false);
        }}
        allQuestionsArr={props.allQuestionsArr}
        questionsDropdown={props.questionsDropdown}
        scoreCategory={scoreCategory}
        key={"scoring-selector-table-modal-"}
      />
    </>
  );
};

export default ScoringSelector;
