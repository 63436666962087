import { Box, Button, Modal, Typography, Theme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { theme } from "../../../../Theme";
import { CaseloadViewModel, PatientClient } from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import MultiSelectChip from "../../elements/form-elements/MultiSelectChip";
import PatientUploadDropzone from "./PatientUploadDropzone";
import BatchUploadErrors from "./BatchUploadErrors";
import { useMountedState, useUnmountPromise } from "react-use";

interface Props {
  open: boolean;
  closeModal: () => void;
  onSuccess?: (response: string) => void;
  allCaseloads: CaseloadViewModel[];
  customPatientId: boolean;
  customPatientIdMandatory: boolean;
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  border: "none",
};

const BatchUploadPatientModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles((th: Theme) => ({
    header: {
      marginBottom: 5,
    },
    subHeader: {
      fontSize: 20,
      marginBottom: 0,
      marginTop: 20,
    },
    text: {
      margin: 0,
      padding: 0,
      color: th.palette.grey[600],
    },
    textLine2: {
      marginBottom: 20,
      color: th.palette.grey[600],
    },
    actionButtonGroup: {
      display: "flex",
      justifyContent: "end",
      marginTop: 20,
      gap: "8px",
    },
    caseloadSelector: {
      width: "100%",
      marginTop: 20,
    },
  }));
  const classes = useStyle(theme);
  const [loading, setLoading] = useState(false);
  const [selectedCaseloads, setSelectedCaseloads] = useState<
    CaseloadViewModel[]
  >([]);
  const [uploadFile, setUploadFile] = useState<Blob>();
  const [uploadFileError, setUploadFileError] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<{
    [line: string]: string[];
  }>();
  const isMountedState = useMountedState();
  const resolveWhileMounted = useUnmountPromise();

  // Clear the caseloads and uploaded file on open
  useEffect(() => {
    if (isMountedState()) {
      setSelectedCaseloads([]);
      setUploadFile(undefined);
      setUploadErrors(undefined);
    }
  }, [props.open, setSelectedCaseloads, isMountedState]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          Batch Upload Patients
        </Typography>
        <Typography className={classes.text}>
          Please upload a csv containing the patients that you wish to upload.
        </Typography>
        <Typography className={classes.textLine2}>
          You can download the csv template{" "}
          {props.customPatientIdMandatory ? (
            <a
              href={
                process.env.PUBLIC_URL +
                "/TemplateBatchPatientUploadWithCustomIdMandatory.csv"
              }
              download="Template Batch Patient Upload With Custom Id.csv"
            >
              here
            </a>
          ) : props.customPatientId ? (
            <a
              href={
                process.env.PUBLIC_URL +
                "/TemplateBatchPatientUploadWithCustomId.csv"
              }
              download="Template Batch Patient Upload With Custom Id.csv"
            >
              here
            </a>
          ) : (
            <a
              href={process.env.PUBLIC_URL + "/TemplateBatchPatientUpload.csv"}
              download="Template Batch Patient Upload.csv"
            >
              here
            </a>
          )}
          .
        </Typography>
        <PatientUploadDropzone
          setFile={setUploadFile}
          noFileError={uploadFileError}
          setNoFileError={setUploadFileError}
          invalidDataError={uploadErrors !== undefined}
        />
        <Typography variant="h5" component="h5" className={classes.subHeader}>
          Add Patients To Caseloads
        </Typography>
        <MultiSelectChip
          label="Caseloads"
          options={props.allCaseloads}
          selectedOptions={selectedCaseloads}
          setSelectedOptions={setSelectedCaseloads}
          className={classes.caseloadSelector}
        />

        <BatchUploadErrors errors={uploadErrors} />

        <div className={classes.actionButtonGroup}>
          <Button onClick={() => props.closeModal()} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={() => {
              if (uploadFile === undefined) {
                setUploadFileError(true);
                return;
              }

              setUploadFileError(false);
              setLoading(true);

              resolveWhileMounted(
                new PatientClient(
                  Configuration.SERVER_ROOT,
                  FetchOverride
                ).batchCreatePatients(
                  { data: uploadFile, fileName: "uploadedFile.csv" },
                  selectedCaseloads.map((x) => x.id)
                )
              )
                .then((patientMedications) => {
                  if (props.onSuccess) {
                    props.onSuccess(patientMedications + " patients added.");
                  }

                  props.closeModal();
                })
                .catch(setUploadErrors)
                .finally(() => setLoading(false));
            }}
          >
            Upload Patients
          </LoadingButton>
        </div>
      </Box>
    </Modal>
  );
};

export default BatchUploadPatientModal;
