import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import {
  ChangeType,
  QuestionnaireBuilderDataModel,
  SectionDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import { QuestionnaireViewModel } from "../../../../types/auto/types";
import SectionBuilder from "./SectionBuilder";
import { getNextQuestionNumberHelper } from "./QuestionnaireBuilderDataModelHelper";
import nameof from "../../../utils/NameOf";

interface Props {
  questionnaire: QuestionnaireBuilderDataModel;
  allQuestionnaires: QuestionnaireViewModel[];
  section: number;
  setQuestionnaireWIP: (f: string, v: string | SectionDataModel[]) => void;
  setQuestionNumberChange: (qNum: number, type: ChangeType) => void;
  setSectionNumberChange: (
    sn: number,
    sQNums: number[],
    type: ChangeType
  ) => void;
  setQuestionNumberRecalc: () => void;
  setSectionNumber: (sn: number) => void;
  keepAlive: () => void;
}

const BodyBuilder = (props: Props): JSX.Element => {
  const [questionNumber, setQuestionNumber] = useState<number>(
    getNextQuestionNumber
  );

  function getNextQuestionNumber() {
    return getNextQuestionNumberHelper(
      props.questionnaire,
      props.questionnaire.sections[props.section]
    );
  }

  useEffect(
    () => {
      var newQNum = getNextQuestionNumberHelper(
        props.questionnaire,
        props.questionnaire.sections[props.section]
      );
      setQuestionNumber(newQNum);
    },
    // eslint-disable-next-line
    [props.setQuestionNumberRecalc]
  );

  return (
    <Box>
      <Box
        display={"flex"}
        gap={2}
        padding={1}
        width={"100%"}
        flexWrap={"wrap"}
        justifyContent={"flex-start"}
      >
        <Box width={"100%"}>
          {props.questionnaire.sections.length !== 0 && (
            <SectionBuilder
              allQuestionnaires={props.allQuestionnaires}
              section={props.questionnaire.sections[props.section]}
              sectionNumber={props.section}
              setSectionWIP={(sn, f, v) => {
                props.keepAlive();
                if (props.questionnaire.sections != null) {
                  const newSectionsData: SectionDataModel[] =
                    props.questionnaire.sections.map((section, index) => {
                      if (index !== sn) {
                        return section;
                      } else {
                        return {
                          ...section,
                          [f]: v,
                        };
                      }
                    });
                  props.setQuestionnaireWIP(
                    nameof<QuestionnaireBuilderDataModel>("sections"),
                    newSectionsData
                  );
                }
              }}
              questionNumber={questionNumber}
              setQuestionNumberChange={(qNum, type) => {
                props.setQuestionNumberChange(qNum, type);
              }}
              setQuestionNumberRecalc={() => {
                props.setQuestionNumberRecalc();
              }}
              handleDeleteSection={(s, sn) => {
                const newSectionsData: SectionDataModel[] =
                  props.questionnaire.sections.filter(
                    (section) => section !== s
                  );
                props.setQuestionnaireWIP(
                  nameof<QuestionnaireBuilderDataModel>("sections"),
                  newSectionsData
                );
                props.setSectionNumber(sn);
                props.setQuestionNumberChange(0, ChangeType.Undefined);
                props.setSectionNumberChange(
                  sn,
                  s.questions.length === 0
                    ? []
                    : s.questions.map((q) => q.number!),
                  ChangeType.Delete
                );
                props.setQuestionNumberRecalc();
              }}
              questionnaire={props.questionnaire}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BodyBuilder;
