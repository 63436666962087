import { Button, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useContext } from "react";
import { useUnmountPromise } from "react-use";
import { theme } from "../../../../Theme";
import {
  PatientViewModel,
  ScheduleType,
  ScheduleViewModel,
} from "../../../../types/auto/types";
import { UserDispatchContext } from "../../elements/stores/UserStore";
import { GetReportingFrequencyString } from "../../schedule/PromptFrequencyReporting";
import QuestionnaireCard from "./QuestionnaireCard";
import { GetLastScheduledPromptDate } from "./ScheduledQuestionnaireCardHelper";
import { handleContinue } from "./ScheduledQuestionnaireRedirectHelper";

interface Props {
  scheduledQuestionnaire: ScheduleViewModel;
  patient: PatientViewModel;
  setValidRequest: (validRequest: boolean) => void;
  isStaff: boolean;
  readonly: boolean;
}

const ScheduledQuestionnaireCard = (props: Props): JSX.Element => {
  const setUser = useContext(UserDispatchContext);
  const resolveWhileMounted = useUnmountPromise();
  const useStyle = makeStyles((th: Theme) => ({
    questionnaireName: {
      fontWeight: "bold",
      display: "inline",
      marginRight: 2,
    },
    completeQuestionnaireNow: {
      color: "red",
      display: "inline",
    },
    questionnaireCompleteStatus: {
      display: "inline",
    },
    lastResponseTime: { fontStyle: "italic", marginTop: 15 },
    promptSchedule: { marginTop: 15 },
    questionnaireButton: {
      margin: 10,
      marginBottom: 5,
      padding: "default",
      width: "fit-content",
      whiteSpace: "nowrap",
    },
  }));
  const classes = useStyle(theme);

  function DisableQuestionnaireButtons(isPatientButton: boolean) {
    if (props.readonly) {
      return true;
    } else if (
      isPatientButton &&
      props.scheduledQuestionnaire.questionnaire?.authenticatedOnly
    ) {
      // If it is the patient button and the questionnaire has the authenticated only flag, disable the button.
      return true;
    }
    // If the schedule start date is in the future (e.g. for one time prompts), or we already have a response submitted against the schedules access token, disable the button.
    else if (
      props.scheduledQuestionnaire.startDate > new Date() ||
      props.scheduledQuestionnaire.isSubmitted
    ) {
      return true;
    } else {
      // If it is not a prompted schedule, enable the buttons.
      if (props.scheduledQuestionnaire.promptSchedule == null) {
        return false;
      }
      var promptSchedule = props.scheduledQuestionnaire.promptSchedule;
      var lastResponseDate = props.scheduledQuestionnaire.lastResponseDate;
      var lastPromptDate = promptSchedule.oneTimePrompt
        ? props.scheduledQuestionnaire.startDate
        : GetLastScheduledPromptDate(promptSchedule);
      var createdDate = promptSchedule.created;

      // We are recieving prompts, but have no responses just yet.
      if (lastResponseDate == null) {
        // The use case here is if we created a schedule on Monday, to be prompted weekly on Wednesdays, we want to disable the button
        // until it is Wednesday. Last prompted date will treat the previous Wednesday as the last theoretical prompt time, therefore before created date.
        if (lastPromptDate < createdDate) {
          return true;
        }

        // The use case here is if we schedule a weekly prompt on Tuesdays, on any given Tuesday, it will return that the most recent fire date is today,
        // as this comparrison is done on a date only basis. It will then set the time to be the scheduled prompt time, which could be later in the day,
        // e.g. prompt time is 22:00, its currently 13:00, making the lastPromptDate > now().
        if (lastPromptDate > new Date()) {
          return true;
        }
      } else {
        // We have made a response since the last prompted date, so disable the buttons.
        if (lastPromptDate < lastResponseDate) {
          return true;
        }
      }
      return false;
    }
  }

  const GetQuestionnaireCompleteStatusElement = (): JSX.Element => {
    if (props.scheduledQuestionnaire.isSubmitted) {
      return <></>;
    }
    if (props.scheduledQuestionnaire.startDate < new Date()) {
      if (props.scheduledQuestionnaire.promptSchedule === undefined) {
        return (
          <Typography className={classes.questionnaireCompleteStatus}>
            You do not receive prompts for this questionnaire so you may
            complete it at any time.
          </Typography>
        );
      } else if (!DisableQuestionnaireButtons(false)) {
        return (
          <Typography className={classes.completeQuestionnaireNow}>
            Please complete this questionnaire now.
          </Typography>
        );
      } else {
        return (
          <Typography className={classes.questionnaireCompleteStatus}>
            You are not due to complete this questionnaire at the moment.
          </Typography>
        );
      }
    } else {
      return (
        <Typography className={classes.questionnaireCompleteStatus}>
          You are not due to complete this questionnaire at the moment.
        </Typography>
      );
    }
  };

  return (
    <QuestionnaireCard
      title={props.scheduledQuestionnaire.caseload?.name || ""}
      body={
        <>
          <Typography className={classes.questionnaireName}>
            {props.scheduledQuestionnaire.questionnaire?.name}:
          </Typography>
          {GetQuestionnaireCompleteStatusElement()}
          <Typography className={classes.lastResponseTime}>
            {props.scheduledQuestionnaire.lastResponseDate == null
              ? ""
              : "You last completed this questionnaire on: " +
                moment(props.scheduledQuestionnaire.lastResponseDate).format(
                  "DD-MM-YYYY [at] HH:mm"
                ) +
                "."}
          </Typography>
          {props.scheduledQuestionnaire.promptSchedule && (
            <Typography className={classes.promptSchedule}>
              {GetReportingFrequencyString(
                props.scheduledQuestionnaire.promptSchedule,
                props.scheduledQuestionnaire.scheduleType ===
                  ScheduleType.OneOff,
                props.scheduledQuestionnaire.startDate
              )}
            </Typography>
          )}
        </>
      }
      buttons={
        <>
          <Button
            className={classes.questionnaireButton}
            aria-label={
              props.isStaff ? "Complete As Clinician" : "Complete questionnaire"
            }
            onClick={() =>
              handleContinue(
                false,
                props.scheduledQuestionnaire,
                props.patient,
                setUser,
                props.setValidRequest,
                resolveWhileMounted
              )
            }
            variant="contained"
            disabled={DisableQuestionnaireButtons(false)}
          >
            {props.isStaff ? "Complete As Clinician" : "Complete questionnaire"}
          </Button>
          {props.isStaff && (
            <Button
              className={classes.questionnaireButton}
              aria-label="Complete As Patient"
              onClick={() =>
                handleContinue(
                  true,
                  props.scheduledQuestionnaire,
                  props.patient,
                  setUser,
                  props.setValidRequest,
                  resolveWhileMounted
                )
              }
              variant="contained"
              disabled={DisableQuestionnaireButtons(true)}
            >
              Complete as Patient
            </Button>
          )}
        </>
      }
    />
  );
};

export default ScheduledQuestionnaireCard;
