import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { theme } from "../../../../Theme";
import {
  AnswerDataModel,
  QuestionDataModel,
  SectionDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import { makeStyles } from "@mui/styles";
import QuestionActions from "./QuestionActions";
import QuestionAnswers from "./QuestionAnswers";
import QuestionInfo from "./QuestionInfo";
import { getRequiredQuestionFields } from "./SectionBuilderQuestionAnswerHelper";
import {
  QuestionType,
  QuestionnaireViewModel,
} from "../../../../types/auto/types";
import { Mediation, WarningAmber, Link } from "@mui/icons-material";
import { CheckIsMobile } from "../../../utils/MobileStatus";

interface Props {
  question: QuestionDataModel;
  allQuestionnaires: QuestionnaireViewModel[];
  handleAddAnswer: (qNum: number) => void;
  handleEditQuestion: (q: QuestionDataModel) => void;
  handleDuplicateQuestion: (q: QuestionDataModel) => void;
  handleDeleteQuestion: (qNum: number) => void;
  handleDependsOn: (q: QuestionDataModel) => void;
  handleAutofillFrom: (q: QuestionDataModel) => void;
  handleDefaultOverride: (q: QuestionDataModel) => void;
  handleDeleteAnswer: (qNum: number, aNum: number) => void;
  handleEditAnswer: (answer: AnswerDataModel, qNum: number) => void;
  handleToggleMulti: (q: QuestionDataModel, qType: QuestionType) => void;
  handleToggleSkippable: (q: QuestionDataModel, qType: QuestionType) => void;
  handleToggleDay: (q: QuestionDataModel, qType: QuestionType) => void;
  sections: SectionDataModel[];
}

const QuestionDetails = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();

  const useStyle = makeStyles(() => ({
    container: {
      padding: 0,
      width: "100%",
    },
    accordian: { boxShadow: "none", borderTop: "solid 1px lightgray" },
    headerContainer: {
      backgroundColor: "#fafafa",
      width: "100%",
      padding: 15,
      display: isMobile ? "flex" : "grid",
      gridTemplateColumns: "1fr 220px",
      gridTemplateAreas: "'heading icons'",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    header: {
      fontWeight: "bolder",
      gridArea: "'heading'",
    },
    answersContainer: {
      backgroundColor: "white",
      width: "100%",
      padding: 5,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    infoContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      flexDirection: "row",
      overflow: "auto",
    },
    toggleQuestionType: {
      display: "flex",
      justifyContent: "flex-end",
      gridArea: "'icons'",
    },
  }));
  const classes = useStyle();
  return (
    <Container
      aria-label="Questionnaire response"
      className={classes.container}
      disableGutters
      key={"question-details-main-container-" + props.question.number}
    >
      <Box
        className={classes.headerContainer}
        key={"question-details-header-container-" + props.question.number}
      >
        <Typography
          className={classes.header}
          key={"question-details-header-" + props.question.number}
        >
          {props.question.text}
        </Typography>
        <Box className={classes.toggleQuestionType}>
          {props.question.autofillFrom && (
            <Tooltip
              title={
                "This question auto fills its answers from another question in another form."
              }
              enterTouchDelay={0}
              key={"autoFillFrom-tooltip-container-" + props.question.number}
            >
              <IconButton
                aria-label="View auto fill from."
                key={"autoFillFrom-icon-button-" + props.question.number}
                onClick={() => props.handleAutofillFrom(props.question)}
                sx={{ py: 0 }}
              >
                <Link
                  key={"autoFillFrom-icon-question-" + props.question.number}
                  sx={{
                    height: "1.25em",
                    width: "1.25em",
                    color: theme.palette.secondary.main,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {props.question.dependsOn && (
            <Tooltip
              title={
                "This question depends on certain other questions to be visible."
              }
              enterTouchDelay={0}
              key={"dependsOn-tooltip-container-" + props.question.number}
            >
              <IconButton
                aria-label="View depends on."
                key={"dependsOn-icon-button-" + props.question.number}
                onClick={() => props.handleDependsOn(props.question)}
                sx={{ py: 0 }}
              >
                <Mediation
                  key={"dependsOn-icon-question-" + props.question.number}
                  sx={{ color: "red" }}
                />
              </IconButton>
            </Tooltip>
          )}
          {props.question.defaultValueOverride && (
            <Tooltip
              title={"This question contains a default value override."}
              enterTouchDelay={0}
              key={
                "default-values-override-tooltip-container-" +
                props.question.number
              }
            >
              <IconButton
                key={
                  "default-values-override-icon-button-" + props.question.number
                }
                onClick={() => props.handleDefaultOverride(props.question)}
              >
                <WarningAmber
                  key={
                    "default-values-override-icon-question-" +
                    props.question.number
                  }
                  sx={{ color: "orange" }}
                />
              </IconButton>
            </Tooltip>
          )}
          {(props.question.questionType === QuestionType.Range ||
            props.question.questionType === QuestionType.SkippableRange) && (
            <FormGroup key={"toggle-skippable-range" + props.question.number}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={() =>
                      props.handleToggleSkippable(
                        props.question,
                        props.question.questionType!
                      )
                    }
                    checked={
                      props.question.questionType ===
                      QuestionType.SkippableRange
                    }
                  />
                }
                label="Skippable Range"
                key={
                  "toggle-skippable-range-form-control-" + props.question.number
                }
              />
            </FormGroup>
          )}
          {(props.question.questionType === QuestionType.Buttons ||
            props.question.questionType === QuestionType.ButtonsMulti) && (
            <FormGroup key={"toggle-buttons-multi-" + props.question.number}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={() =>
                      props.handleToggleMulti(
                        props.question,
                        props.question.questionType!
                      )
                    }
                    checked={
                      props.question.questionType === QuestionType.ButtonsMulti
                    }
                  />
                }
                label="Multi Select"
                key={
                  "toggle-buttons-multi-form-control-" + props.question.number
                }
              />
            </FormGroup>
          )}
          {(props.question.questionType === QuestionType.Date ||
            props.question.questionType === QuestionType.Day) && (
            <FormGroup key={"toggle-date-day-" + props.question.number}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={() =>
                      props.handleToggleDay(
                        props.question,
                        props.question.questionType!
                      )
                    }
                    checked={props.question.questionType === QuestionType.Day}
                  />
                }
                label="Day"
                key={
                  "toggle-buttons-multi-form-control-" + props.question.number
                }
              />
            </FormGroup>
          )}
          <Box display={"flex"} alignItems={"center"}>
            <QuestionActions
              question={props.question}
              handleEditQuestion={(q) => props.handleEditQuestion(q)}
              handleDuplicateQuestion={(q) => props.handleDuplicateQuestion(q)}
              handleDeleteQuestion={(qNum) => props.handleDeleteQuestion(qNum)}
              handleDependsOn={(q) => props.handleDependsOn(q)}
              handleAutofillFrom={(q) => props.handleAutofillFrom(q)}
              handleDefaultOverride={(q) => props.handleDefaultOverride(q)}
              key={"question-details-actions-" + props.question.number}
              handleAddAnswer={() =>
                props.question.number &&
                props.handleAddAnswer(props.question.number)
              }
            />
          </Box>
        </Box>
      </Box>
      <Accordion
        className={classes.accordian}
        TransitionProps={{ unmountOnExit: true }}
        disableGutters
        key={"question-details-accordian-container" + props.question.number}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={"question-details-" + props.question.number}
          id={"question-details-" + props.question.number}
          key={"question-details-accordian-summary-" + props.question.number}
        >
          <Typography key={"question-details-summary-" + props.question.number}>
            Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          key={"question-details-accordian-details-" + props.question.number}
        >
          <Box
            className={classes.infoContainer}
            key={
              "question-details-accordian-details-container-" +
              props.question.number
            }
          >
            <QuestionInfo
              questionWIP={props.question}
              allQuestionnaires={props.allQuestionnaires}
              requiredQuestionFields={
                props.question.questionType != null
                  ? getRequiredQuestionFields(props.question.questionType)
                  : []
              }
              key={"question-details-info" + props.question.number}
              sections={props.sections}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      {props.question.questionType !== QuestionType.Sketch &&
        props.question.questionType !== QuestionType.ReadOnly &&
        props.question.questionType !== QuestionType.Goals &&
        props.question.questionType !== QuestionType.Date &&
        props.question.questionType !== QuestionType.Day &&
        props.question.questionType !== QuestionType.DateRange && (
          <Accordion
            className={classes.accordian}
            TransitionProps={{ unmountOnExit: true }}
            disableGutters
            key={"answer-details-accordian-container" + props.question.number}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"answer-details-" + props.question.number}
              id={"answer-details-" + props.question.number}
              key={"answer-details-accordian-summary-" + props.question.number}
            >
              <Typography
                key={"answer-details-summary-" + props.question.number}
              >
                {props.question.questionType === QuestionType.Table ||
                props.question.questionType === QuestionType.MultiSelectorTable
                  ? "Columns"
                  : "Answers"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              key={"answer-details-accordian-details-" + props.question.number}
            >
              <Box
                className={classes.answersContainer}
                key={
                  "answer-details-accordian-details-container-" +
                  props.question.number
                }
              >
                <QuestionAnswers
                  answers={props.question.answers}
                  handleAddAnswer={() =>
                    props.question.number &&
                    props.handleAddAnswer(props.question.number)
                  }
                  handleDeleteAnswer={(answerNumber) => {
                    props.question.number &&
                      props.handleDeleteAnswer(
                        props.question.number,
                        answerNumber
                      );
                  }}
                  handleEditAnswer={(answer) => {
                    props.question.number &&
                      props.handleEditAnswer(answer, props.question.number);
                  }}
                  key={"question-answers-" + props.question.number}
                  questionType={props.question.questionType!}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
    </Container>
  );
};

export default QuestionDetails;
