import { Container, Theme, Typography } from "@mui/material";
import {
  MonitoringStatus,
  PatientViewModel,
  Role,
  ScheduleClient,
  ScheduleViewModel,
} from "../../../types/auto/types";
import { useState } from "react";
import PromptWarningDialog, {
  PromptActionWarningState,
} from "./dialog/PromptWarningDialog";
import CreateEditScheduleModal from "./create-edit-schedule/CreateEditScheduleModal";
import ActivePromptableActionButtons from "./ActivePromptableActionButtons";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../Theme";
import PromptableCard from "../elements/promptable-card/PromptableCard";
import { orderBy } from "lodash";

interface Props {
  patient: PatientViewModel;
  schedules: ScheduleViewModel[];
  refreshSchedules: () => void;
  monitoringStatusDescriptions: {
    [key in keyof typeof MonitoringStatus]?: string;
  };
  readonly: boolean;
}

const ActiveSchedules = (props: Props): JSX.Element => {
  const [scheduleToModify, setScheduleToModify] = useState<ScheduleViewModel>();
  const [createEditScheduleOpen, setCreateEditScheduleOpen] = useState(false);
  const [warningModalState, setWarningModalState] =
    useState<PromptActionWarningState>();
  const scheduleClient = new ScheduleClient(
    Configuration.SERVER_ROOT,
    FetchOverride
  );

  const useStyle = makeStyles((th: Theme) => ({
    cardContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: 0,
      padding: 0,
    },
  }));
  const classes = useStyle(theme);
  return (
    <>
      <PromptWarningDialog
        open={warningModalState !== undefined}
        onClose={() => setWarningModalState(undefined)}
        patient={props.patient}
        state={warningModalState}
        refreshList={props.refreshSchedules}
        name={scheduleToModify?.questionnaire?.name || "questionnaire"}
        promptName="schedule"
        onResumeSingle={() =>
          scheduleClient.resumeSchedule(scheduleToModify?.id)
        }
        onResumeAll={() =>
          scheduleClient.resumeAllSchedulesForUser(props.patient?.id)
        }
        onSuspendSingle={() =>
          scheduleClient.suspendSchedule(scheduleToModify?.id)
        }
        onSuspendAll={() =>
          scheduleClient.suspendAllSchedulesForUser(props.patient?.id)
        }
        onArchiveSingle={() =>
          scheduleClient.archiveSchedule(scheduleToModify?.id)
        }
        onArchiveAll={() =>
          scheduleClient.archiveAllSchedulesForUser(props.patient?.id)
        }
      />
      <CreateEditScheduleModal
        patient={props.patient}
        open={createEditScheduleOpen}
        closeModal={() => {
          setCreateEditScheduleOpen(false);
          props.refreshSchedules();
        }}
        schedule={scheduleToModify}
        refreshSchedules={props.refreshSchedules}
      />
      <Typography variant="h5" component="h2">
        Active Schedules
      </Typography>
      {!props.readonly && (
        <ActivePromptableActionButtons
          createTitle="Schedule a New Questionnaire"
          onCreateClick={() => {
            setScheduleToModify(undefined);
            setCreateEditScheduleOpen(true);
          }}
          showResumeAll={
            props.schedules.length > 0 &&
            props.schedules.some(
              (x) => x.monitoringStatus === MonitoringStatus.Suspended
            )
          }
          onResumeClick={() =>
            setWarningModalState(PromptActionWarningState.ResumeAll)
          }
          showSuspendAll={
            props.schedules.length > 0 &&
            props.schedules.some(
              (x) => x.monitoringStatus !== MonitoringStatus.Suspended
            ) &&
            props.schedules.some(
              (x) => x.promptSchedule == null || !x.promptSchedule.oneTimePrompt
            )
          }
          onSuspendClick={() =>
            setWarningModalState(PromptActionWarningState.SuspendAll)
          }
          showArchiveAll={props.schedules.length > 0}
          onArchiveClick={() =>
            setWarningModalState(PromptActionWarningState.ArchiveAll)
          }
        />
      )}
      {props.schedules.length === 0 ? (
        <Typography>
          There are no active schedules. Click the above button to add a new
          schedule.
        </Typography>
      ) : (
        <Container className={classes.cardContainer}>
          {orderBy(props.schedules, "created", "desc").map((x) => (
            <PromptableCard
              key={"activeSchedules" + x.id}
              title={x.questionnaire?.name || ""}
              monitoringStatus={x.monitoringStatus}
              createdDate={x.created}
              lastResponse={x.lastResponseDate}
              prompt={x.promptSchedule}
              showPrompt
              onEdit={() => {
                setScheduleToModify(x);
                setCreateEditScheduleOpen(true);
              }}
              onSuspendResume={(suspend) => {
                setScheduleToModify(x);
                setWarningModalState(
                  suspend
                    ? PromptActionWarningState.SuspendSingle
                    : PromptActionWarningState.ResumeSingle
                );
              }}
              onArchived={() => {
                setScheduleToModify(x);
                setWarningModalState(PromptActionWarningState.ArchiveSingle);
              }}
              role={Role.Clinician}
              monitoringStatusDescriptions={props.monitoringStatusDescriptions}
              readOnly={props.readonly}
              startDate={x.startDate}
            />
          ))}
        </Container>
      )}
    </>
  );
};
export default ActiveSchedules;
