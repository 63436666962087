import { useContext, useEffect, useState } from "react";
import {
  DraftOTPFailReasons,
  QuestionnaireClient,
  QuestionnaireResponseDataViewModel,
  QuestionnaireResponseViewModel,
  QuestionnaireViewModel,
  ResponseState,
} from "../../types/auto/types";
import { Configuration } from "../Constants";
import { FetchOverride } from "../utils/Request";
import { useNavigate, useSearchParams } from "react-router-dom";
import QuestionnaireForm from "../components/questionnaires/QuestionnaireForm";
import { Error500 } from "../components/elements/errors/Error500";
import { Alert, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMountedState, useUnmountPromise } from "react-use";
import { UserContext } from "../components/elements/stores/UserStore";
import PAXDTool from "../components/questionnaires/PaxDTool";
import DraftOTPModal from "../components/questionnaires/DraftOTPModal";

const Questionnaire = () => {
  let navigate = useNavigate();
  const user = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [error500, setError500] = useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [tokens, setTokens] = useState(searchParams.get("t")?.split(",") || []);
  const patientId = searchParams.get("p");
  const editing = searchParams.get("e") ? true : false;
  const fromNotification = searchParams.get("fn") ? true : false;
  const tenantId = searchParams.get("tId");
  const [questionnaire, setQuestionnaire] = useState<QuestionnaireViewModel>();
  const [questionnaireResponseData, setQuestionnaireResponseData] =
    useState<QuestionnaireResponseDataViewModel>();
  const [rerender, setRerender] = useState(false);
  const [loadQuestionnaire, setloadQuestionnaire] = useState(false);
  const [editingDate, setEditingDate] = useState(false);
  const [openSaveDraftModal, setOpenSaveDraftModal] = useState(false);
  const [remainingTokens, setRemainingTokens] = useState(tokens.length);
  const [caseloadId, setCaseloadId] = useState<number>();
  const [hasBeenAutofilled, setHasBeenAutofilled] = useState(false);
  const [hasAutofill, setHasAutofill] = useState(false);
  const [autofillQuestionnaireDetails, setAutofillQuestionnaireDetails] =
    useState<{ [key: string]: string }>();
  const combinedNotificationTokens = searchParams.get("t") ?? undefined;
  const [responseId, setResponseId] = useState<number>();
  const [hasUnsubmittedGoals, setHasUnsubmittedGoals] =
    useState<boolean>(false);
  const [goalDeletionWarningModal, setGoalDeletionWarningModal] =
    useState<boolean>(false);
  const [
    goalDeletionWarningModalOverride,
    setGoalDeletionWarningModalOverride,
  ] = useState<boolean>(false);
  const [apiCallFinished, setApiCallFinished] = useState<boolean>(false);
  const [responseState, setResponseState] = useState<ResponseState>();

  const [draftNotFoundError, setDraftNotFoundError] = useState(false);
  const [cancelError, setCancelError] = useState(false);
  const [draftOTPModal, setDraftOTPModal] = useState<boolean>(false);
  const [incorrectDraftOTPError, setIncorrectDraftOTPError] = useState<{
    isError: boolean;
    failCount: number;
  }>({ isError: false, failCount: 5 });
  const [submitCodeLoadingButton, setSubmitCodeLoadingButton] =
    useState<boolean>(false);
  const [cooldown, setCooldown] = useState<string>();
  const [noAttemptsRemainingError, setNoAttemptsRemainingError] =
    useState<boolean>(false);
  const [codeExpiredError, setCodeExpiredError] = useState<boolean>(false);
  const [contactError, setContactError] = useState<boolean>(false);
  const [contactErrorMessage, setContactErrorMessage] = useState<string>();
  const [codeUsedError, setCodeUsedError] = useState<boolean>(false);
  const [episodeId, setEpisodeId] = useState<string>();

  const useStyle = makeStyles({
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  });
  const classes = useStyle();

  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  const questionnaireClient = new QuestionnaireClient(
    Configuration.SERVER_ROOT,
    FetchOverride
  );

  function toggleGoalDeletionWarningModal(set: boolean, override: boolean) {
    setGoalDeletionWarningModal(set);
    setGoalDeletionWarningModalOverride(override);
  }

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        if (tokens.length > 0) {
          setloadQuestionnaire(true);
          await resolveWhileMounted(
            new QuestionnaireClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getQuestionnaireViaToken(
              tokens[0],
              isNaN(Number(tenantId)) ? -1 : Number(tenantId),
              editing
            )
          )
            .then(async (questionnaire: QuestionnaireViewModel) => {
              if (questionnaire.id !== undefined) {
                setQuestionnaire(questionnaire);
                if (questionnaire.sections && !hasBeenAutofilled) {
                  questionnaire.sections.forEach((section) => {
                    if (section.questions) {
                      section.questions.forEach((question) => {
                        if (question.autofillFrom !== undefined) {
                          setHasAutofill(true);
                        }
                      });
                    }
                  });
                }
                await resolveWhileMounted(
                  questionnaireClient.getQuestionnaireResponses(
                    tokens[0],
                    isNaN(Number(tenantId)) ? -1 : Number(tenantId),
                    ""
                  )
                )
                  .then((responses) => {
                    if (responses.state !== 0) {
                      setHasAutofill(false);
                    }
                    setQuestionnaireResponseData(responses);
                    setCaseloadId(responses.caseloadId);
                    setEpisodeId(responses.episodeId);
                    setResponseId(responses.id);
                    setloadQuestionnaire(false);
                    setHasUnsubmittedGoals(
                      responses.hasUnsubmittedGoals ?? false
                    );
                    setResponseState(responses.state);
                    setApiCallFinished(true);
                  })
                  .catch((emptyResponse) => {
                    if (emptyResponse.status === 400) {
                      setCaseloadId(emptyResponse.caseloadId);
                      setEpisodeId(emptyResponse.episodeId);
                      setResponseId(emptyResponse.responseId);
                      setResponseState(emptyResponse.responseState);
                      setloadQuestionnaire(false);
                    }
                  });
              } else {
                var array = tokens;
                array?.shift();
                setTokens(array);
                setRemainingTokens(array.length);
                setRerender((x) => !x);
                setloadQuestionnaire(false);
              }
            })
            .catch((e) => {
              if (e.status === 400) {
                var array = tokens;
                array?.shift();
                setTokens(array);
                setRemainingTokens(array.length);
                setRerender((x) => !x);
                setloadQuestionnaire(false);
              } else if (e.status === 401) {
                switch (e.reason) {
                  case DraftOTPFailReasons.RequiresValidation: {
                    break;
                  }
                  case DraftOTPFailReasons.ContactError: {
                    setContactError(true);
                    setContactErrorMessage(e.contactError);
                    break;
                  }
                  case DraftOTPFailReasons.ExpiredCode: {
                    setCodeExpiredError(true);
                    setCooldown(e.cooldown);
                    break;
                  }
                  case DraftOTPFailReasons.CodeAlreadyUsed: {
                    setCodeUsedError(true);
                    setCooldown(e.cooldown);
                    break;
                  }
                  case DraftOTPFailReasons.TooManyFailedAttempts: {
                    setNoAttemptsRemainingError(true);
                    setCooldown(e.cooldown);
                    break;
                  }
                }
                if (e.questionnaire.id !== undefined) {
                  setQuestionnaire(e.questionnaire);
                }
                setDraftOTPModal(true);
                setloadQuestionnaire(false);
              } else {
                setError500(true);
                setloadQuestionnaire(false);
              }
            });
        } else {
          setloadQuestionnaire(false);
          setOpenSubmitDialog(true);
        }
      }
    };
    effect();
    // eslint-disable-next-line
  }, [
    navigate,
    rerender,
    tokens,
    patientId,
    editing,
    resolveWhileMounted,
    isMountedState,
  ]);

  const errorMessage = {
    Error:
      "You are not currently logged in, therefore, past responses could not be loaded. Please cancel this response and try again if past responses are required.",
  };

  useEffect(() => {
    const effect = async () => {
      if (hasAutofill) {
        await resolveWhileMounted(
          questionnaireClient.getAutofillQuestionnairesViaToken(tokens[0])
        )
          .then((questionnaires) => {
            setAutofillQuestionnaireDetails(questionnaires);
          })
          .catch(() => {
            setAutofillQuestionnaireDetails(errorMessage);
          });
      }
    };
    effect();
    // eslint-disable-next-line
  }, [hasAutofill]);

  async function handleCancel() {
    questionnaireClient
      .deleteNewNonScheduledQuestionnairesViaTokens(
        tokens,
        Number(patientId),
        isNaN(Number(tenantId)) ? -1 : Number(tenantId)
      )
      .catch(() => {
        setCancelError(true);
      });
  }

  async function handleDraftDiscard() {
    questionnaireClient
      .discardDraftResponses(
        tokens[0],
        isNaN(Number(tenantId)) ? -1 : Number(tenantId)
      )
      .then(() => {
        setRerender((x) => !x);
        setHasUnsubmittedGoals(false);
      })
      .catch(() => {
        setDraftNotFoundError(true);
      });
  }

  async function handleAutofill() {
    questionnaireClient
      .autofillResponse(tokens[0])
      .then(() => {
        setHasBeenAutofilled(true);
        setHasAutofill(false);
        setRerender((x) => !x);
      })
      .catch(() => {});
  }

  async function handleLoadDraft(otpCode: string) {
    await resolveWhileMounted(
      questionnaireClient.getQuestionnaireResponses(
        tokens[0],
        isNaN(Number(tenantId)) ? -1 : Number(tenantId),
        otpCode
      )
    )
      .then((responses) => {
        setDraftOTPModal(false);
        if (responses.state !== 0) {
          setHasAutofill(false);
        }
        setQuestionnaireResponseData(responses);
        setCaseloadId(responses.caseloadId);
        setEpisodeId(responses.episodeId);
        setResponseId(responses.id);
        setloadQuestionnaire(false);
        setHasUnsubmittedGoals(responses.hasUnsubmittedGoals ?? false);
        setResponseState(responses.state);
        setApiCallFinished(true);
      })
      .catch((response) => {
        if (response.status === 401) {
          switch (response.reason) {
            case DraftOTPFailReasons.ExpiredCode: {
              setCooldown(response.cooldown);
              setCodeExpiredError(true);
              break;
            }
            case DraftOTPFailReasons.InvalidCode: {
              setIncorrectDraftOTPError({
                isError: true,
                failCount: response.attemptsRemaining,
              });
              break;
            }
            case DraftOTPFailReasons.TooManyFailedAttempts: {
              setCooldown(response.cooldown);
              setNoAttemptsRemainingError(true);
              break;
            }
          }
          setSubmitCodeLoadingButton(false);
        }
      });
  }

  return (
    <>
      {loadQuestionnaire ? (
        <CircularProgress
          className={classes.loadingSpinner}
          aria-label="Loading"
        />
      ) : questionnaire?.paxTaskNumber !== undefined &&
        questionnaire?.paxTaskNumber !== null ? (
        <PAXDTool questionnaire={questionnaire} patientId={patientId} />
      ) : draftOTPModal ? (
        <DraftOTPModal
          open={draftOTPModal}
          responseToken={tokens[0]}
          tenantId={isNaN(Number(tenantId)) ? -1 : Number(tenantId)}
          handleSubmit={(otpCode) => handleLoadDraft(otpCode)}
          incorrectDraftOTPError={incorrectDraftOTPError}
          submitCodeLoadingButton={submitCodeLoadingButton}
          setSubmitCodeLoadingButton={(x) => setSubmitCodeLoadingButton(x)}
          cooldown={cooldown}
          noAttemptsRemainingError={noAttemptsRemainingError}
          codeExpiredError={codeExpiredError}
          contactError={contactError}
          contactErrorMessage={contactErrorMessage}
          codeUsedError={codeUsedError}
        ></DraftOTPModal>
      ) : (
        <QuestionnaireForm
          patientId={patientId}
          questionnaire={questionnaire}
          openSubmitDialog={openSubmitDialog}
          autofillQuestionnaireDetails={autofillQuestionnaireDetails}
          submitResponses={(
            responses: Array<QuestionnaireResponseViewModel>,
            date: Date,
            state: ResponseState
          ) =>
            new QuestionnaireClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).submitQuestionnaireResponse(
              tokens[0],
              isNaN(Number(tenantId)) ? -1 : Number(tenantId),
              responses,
              editing,
              editingDate,
              state,
              date,
              null
            )
          }
          getNextQuestionnaire={(draft) => {
            if (tokens.length > 1) {
              setOpenSubmitDialog(false);
              setOpenSaveDraftModal(false);
              var array = tokens;
              array?.shift();
              setTokens(array);
              setRemainingTokens(array.length);
              setRerender((x) => !x);
              setQuestionnaireResponseData(
                new QuestionnaireResponseDataViewModel()
              );
              window.scrollTo({ top: 0 });
            } else {
              if (draft) {
                setOpenSaveDraftModal(true);
              } else {
                setOpenSubmitDialog(true);
              }
            }
          }}
          hasAutofill={hasAutofill}
          questionnaireResponseData={questionnaireResponseData}
          editing={editing}
          editingDate={editingDate}
          setEditingDate={setEditingDate}
          goal={false}
          role={user.role}
          fromNotification={fromNotification}
          submitDraft={(
            responses: Array<QuestionnaireResponseViewModel>,
            date: Date,
            section: number,
            state: ResponseState
          ) =>
            new QuestionnaireClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).submitQuestionnaireResponse(
              tokens[0],
              isNaN(Number(tenantId)) ? -1 : Number(tenantId),
              responses,
              editing,
              editingDate,
              state,
              date,
              section
            )
          }
          responseToken={tokens[0]}
          handleCancel={async () => await handleCancel()}
          tenantId={tenantId}
          handleDiscard={() => handleDraftDiscard()}
          handleAutofill={() => handleAutofill()}
          editDraftError={draftNotFoundError}
          openSaveDraftModal={openSaveDraftModal}
          remainingTokens={remainingTokens}
          setOpenSaveDraftModal={setOpenSaveDraftModal}
          caseloadId={caseloadId}
          episodeId={episodeId}
          combinedNotificationTokens={combinedNotificationTokens}
          responseId={responseId}
          setHasUnsubmittedGoals={(x) => setHasUnsubmittedGoals(x)}
          hasUnsubmittedGoals={hasUnsubmittedGoals}
          toggleGoalDeletionWarningModal={(x, override) =>
            toggleGoalDeletionWarningModal(x, override)
          }
          goalDeletionWarningModal={goalDeletionWarningModal}
          goalDeletionWarningModalOverride={goalDeletionWarningModalOverride}
          apiCallFinished={apiCallFinished}
          responseState={responseState}
        />
      )}
      {draftNotFoundError && <Alert severity="error">Draft not found</Alert>}
      {cancelError && (
        <Alert severity="error">Cancel questionnaire failed</Alert>
      )}
      {error500 && <Error500 />}
    </>
  );
};

export default Questionnaire;
