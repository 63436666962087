import moment from "moment";
import {
  StaffViewModel,
  PatientViewModel,
  Role,
  SortType,
} from "../../../types/auto/types";
import { AbstractUserCardType, UserDescription } from "./SearchTypes";

export const PatientsToAbstractUserCardType = (
  patient: PatientViewModel[] | undefined,
  handleClick?: (patient: PatientViewModel) => void,
  handleRemoveFromCaseload?: (patient: PatientViewModel) => void
): AbstractUserCardType[] | undefined =>
  patient?.map((patient) => ({
    firstName: patient.firstName || "",
    lastName: patient.lastName || "",
    id: patient.id,
    nhsNumber: patient.nhsNumber,
    dateOfBirth: patient.dateOfBirth,
    role: Role.Patient,
    lastResponse: patient.lastResponse,
    registeredDate: patient.created,
    descriptions: [
      {
        type: SortType.TcNumber,
        name: "True Colours ID",
        value: patient?.id?.toString(),
        mobileOnly: true,
      },
      {
        type: SortType.NhsNumber,
        name: "NHS Number",
        value: patient?.nhsNumber?.toString() || "Not Provided",
      },
      {
        type: SortType.DateOfBirth,
        name: "Date of Birth",
        value: patient?.dateOfBirth
          ? moment(patient?.dateOfBirth).format("DD/MM/YYYY")
          : "Not Provided",
      },
      {
        type: SortType.LastResponse,
        name: "Last Response",
        value: patient.lastResponse
          ? moment(patient?.lastResponse).format("DD/MM/YYYY hh:mm A")
          : "No Response",
      },
    ],
    handleClick: handleClick && (() => handleClick(patient)),
    handleRemoveFromCaseload:
      handleRemoveFromCaseload && (() => handleRemoveFromCaseload(patient)),
  }));

export const PIRedactedPatientsToAbstractUserCardType = (
  patient: PatientViewModel[] | undefined,
  handleClick?: (patient: PatientViewModel) => void,
  handleRemoveFromCaseload?: (patient: PatientViewModel) => void
): AbstractUserCardType[] | undefined =>
  patient?.map((patient) => ({
    firstName: undefined,
    lastName: undefined,
    id: patient.customPatientId ? Number(patient.customPatientId) : undefined,
    nhsNumber: undefined,
    dateOfBirth: undefined,
    yearOfBirth: patient.yearOfBirth,
    role: Role.Patient,
    lastResponse: patient.lastResponse,
    registeredDate: patient.created,
    descriptions: [
      {
        type: SortType.CustomID,
        name: "Custom ID",
        value: patient?.id?.toString(),
        mobileOnly: true,
      },
      {
        type: SortType.YearOfBirth,
        name: "Year of Birth",
        value: patient?.yearOfBirth
          ? String(patient.yearOfBirth)
          : "Not Provided",
      },
      {
        type: SortType.LastResponse,
        name: "Last Response",
        value: patient.lastResponse
          ? moment(patient?.lastResponse).format("DD/MM/YYYY hh:mm A")
          : "No Response",
      },
    ],
    handleClick: handleClick && (() => handleClick(patient)),
    handleRemoveFromCaseload:
      handleRemoveFromCaseload && (() => handleRemoveFromCaseload(patient)),
  }));

export const CliniciansToAbstractUserCardType = (
  clinicians: StaffViewModel[] | undefined,
  handleClick?: (clinician: StaffViewModel) => void,
  handleRemoveFromCaseload?: (patient: StaffViewModel) => void
): AbstractUserCardType[] | undefined =>
  clinicians?.map((clinician) => ({
    firstName: clinician.firstName || "",
    lastName: clinician.lastName || "",
    id: clinician.id,
    role: Role.Clinician,
    descriptions: [
      {
        type: SortType.TcNumber,
        name: "True Colours ID",
        value: clinician.id.toString(),
        mobileOnly: true,
      },
    ],
    handleClick: handleClick && (() => handleClick(clinician)),
    handleRemoveFromCaseload:
      handleRemoveFromCaseload && (() => handleRemoveFromCaseload(clinician)),
  }));

export const ReadOnlyToAbstractUserCardType = (
  readOnlyUsers: StaffViewModel[] | undefined,
  handleClick?: (readOnly: StaffViewModel) => void,
  handleRemoveFromCaseload?: (patient: StaffViewModel) => void
): AbstractUserCardType[] | undefined =>
  readOnlyUsers?.map((readOnly) => ({
    firstName: readOnly.firstName || "",
    lastName: readOnly.lastName || "",
    id: readOnly.id,
    role: Role.ReadOnly,
    descriptions: [
      {
        type: SortType.TcNumber,
        name: "True Colours ID",
        value: readOnly.id.toString(),
        mobileOnly: true,
      },
    ],
    handleClick: handleClick && (() => handleClick(readOnly)),
    handleRemoveFromCaseload:
      handleRemoveFromCaseload && (() => handleRemoveFromCaseload(readOnly)),
  }));

export const ReadOnlyPIRedactedToAbstractUserCardType = (
  readOnlyPIRedactedUsers: StaffViewModel[] | undefined,
  handleClick?: (readOnlyPIRedacted: StaffViewModel) => void,
  handleRemoveFromCaseload?: (patient: StaffViewModel) => void
): AbstractUserCardType[] | undefined =>
  readOnlyPIRedactedUsers?.map((readOnlyPIRedacted) => ({
    firstName: readOnlyPIRedacted.firstName || "",
    lastName: readOnlyPIRedacted.lastName || "",
    id: readOnlyPIRedacted.id,
    role: Role.ReadOnlyPIRedacted,
    descriptions: [
      {
        type: SortType.TcNumber,
        name: "True Colours ID",
        value: readOnlyPIRedacted.id.toString(),
        mobileOnly: true,
      },
    ],
    handleClick: handleClick && (() => handleClick(readOnlyPIRedacted)),
    handleRemoveFromCaseload:
      handleRemoveFromCaseload &&
      (() => handleRemoveFromCaseload(readOnlyPIRedacted)),
  }));

export const AdminsToAbstractUserCardType = (
  admins: StaffViewModel[] | undefined,
  handleClick?: (admin: StaffViewModel) => void
): AbstractUserCardType[] | undefined =>
  admins?.map((admin) => ({
    firstName: admin.firstName || "",
    lastName: admin.lastName || "",
    id: admin.id,
    role: Role.Admin,
    descriptions: [
      {
        type: SortType.TcNumber,
        name: "True Colours ID",
        value: admin.id.toString(),
        mobileOnly: true,
      },
    ],
    handleClick: handleClick && (() => handleClick(admin)),
  }));

export const PatientSortValues: UserDescription[] = [
  {
    type: SortType.FirstName,
    value: "firstName",
    name: "First Name",
  },
  {
    type: SortType.LastName,
    value: "lastName",
    name: "Last Name",
  },
  {
    type: SortType.TcNumber,
    value: "id",
    name: "TC Number",
  },
  {
    type: SortType.NhsNumber,
    value: "nhsNumber",
    name: "NHS Number",
  },
  {
    type: SortType.DateOfBirth,
    value: "dateOfBirth",
    name: "Date of Birth",
  },
  {
    type: SortType.YearOfBirth,
    value: "yearOfBirth",
    name: "Year of Birth",
  },
  {
    type: SortType.LastResponse,
    value: "lastResponse",
    name: "Last Response",
  },
  {
    type: SortType.RegisteredDate,
    value: "registeredDate",
    name: "Registered Date",
  },
  {
    type: SortType.CustomID,
    value: "customID",
    name: "Custom ID",
  },
];

export const PatientPIRedactedSortValues: UserDescription[] = [
  {
    type: SortType.YearOfBirth,
    value: "yearOfBirth",
    name: "Year of Birth",
  },
  {
    type: SortType.LastResponse,
    value: "lastResponse",
    name: "Last Response",
  },
  {
    type: SortType.RegisteredDate,
    value: "registeredDate",
    name: "Registered Date",
  },
  {
    type: SortType.CustomID,
    value: "customID",
    name: "Custom ID",
  },
];

export const StaffSortValues: UserDescription[] = [
  {
    type: SortType.FirstName,
    value: "firstName",
    name: "First Name",
  },
  {
    type: SortType.LastName,
    value: "lastName",
    name: "Last Name",
  },
  {
    type: SortType.TcNumber,
    value: "id",
    name: "TC Number",
  },
];
