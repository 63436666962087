import { useState } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import AssignPatientsToQuestionnaireModal from "./AssignPatientsToQuestionnaireModal";

interface Props {
  questionnaireId: number;
  patientId: number;
  refreshQuestionnaires: () => void;
  onSave?: () => void;
}
const AssignPatientsToQuestionnaire = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles(() => ({
    button: {
      width: isMobile ? "100%" : "default",
      marginTop: isMobile ? "10px" : 0,
    },
  }));
  const classes = useStyle(theme);
  const [
    assignPatientsToQuestionnaireModalOpen,
    setAssignPatientsToQuestionnaireModalOpen,
  ] = useState(false);

  return (
    <>
      <AssignPatientsToQuestionnaireModal
        open={assignPatientsToQuestionnaireModalOpen}
        closeModal={() => {
          setAssignPatientsToQuestionnaireModalOpen(false);
        }}
        questionnaireId={props.questionnaireId}
        patientId={props.patientId}
        refreshQuestionnaires={props.refreshQuestionnaires}
        onSave={() => {
          props.onSave && props.onSave();
        }}
      />
      <Button
        variant="contained"
        onClick={() => setAssignPatientsToQuestionnaireModalOpen(true)}
        className={classes.button}
      >
        Edit dashboard questionnaire
      </Button>
    </>
  );
};

export default AssignPatientsToQuestionnaire;
