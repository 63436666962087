import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import {
  QuestionDataModel,
  SectionDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import {
  GoalCategory,
  QuestionType,
  QuestionnaireViewModel,
} from "../../../../types/auto/types";
import { RequiredQuestionFields } from "./SectionBuilderQuestionAnswerHelper";
import { ToTitleCase } from "../../../utils/TitleCase";
interface Props {
  questionWIP: QuestionDataModel;
  requiredQuestionFields: number[];
  allQuestionnaires: QuestionnaireViewModel[];
  sections: SectionDataModel[];
}

const QuestionInfo = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    headerInfoPair: {
      display: "flex",
      paddingRight: 50,
    },
    header: {
      fontWeight: "bold",
      paddingRight: 5,
    },
    undefined: {
      fontStyle: "italic",
    },
  });
  const classes = useStyle(theme);

  function dependsOnString() {
    var dOString = "undefined";
    if (props.questionWIP.dependsOn) {
      dOString = "";
      for (const [key, value] of Object.entries(props.questionWIP.dependsOn)) {
        dOString =
          dOString +
          "Question: " +
          key +
          ", Answer(s): " +
          value.join(",") +
          "; ";
      }
    }
    return dOString;
  }

  function getReferredQuestion(): string {
    if (props.questionWIP.referenceQuestionNumber == null) {
      return "";
    } else {
      var referredQuestionText = "";
      props.sections.forEach((section) => {
        section.questions.forEach((question) => {
          if (question.number === props.questionWIP.referenceQuestionNumber) {
            referredQuestionText = question.text ?? "";
            return;
          }
        });
      });
      return referredQuestionText;
    }
  }

  function autofillFromString() {
    var aFString = "undefined";
    if (props.questionWIP.autofillFrom !== undefined) {
      var id = props.questionWIP.autofillFrom.questionnaireId;
      var questionnaire = props.allQuestionnaires.filter(
        (questionnaire) => questionnaire.id === id
      );
      aFString =
        "Questionnaire: " +
        questionnaire[0].name +
        ", Question: " +
        props.questionWIP.autofillFrom.questionNumber +
        "; ";
    }
    return aFString;
  }

  return (
    <>
      <Box className={classes.headerInfoPair}>
        <Typography className={classes.header}>Number: </Typography>
        <Typography
          className={props.questionWIP.number == null ? classes.undefined : ""}
        >
          {props.questionWIP.number ?? "undefined"}
        </Typography>
      </Box>
      <Box className={classes.headerInfoPair}>
        <Typography className={classes.header}>Text: </Typography>
        <Typography
          className={props.questionWIP.text == null ? classes.undefined : ""}
        >
          {props.questionWIP.text ?? "undefined"}
        </Typography>
      </Box>
      <Box className={classes.headerInfoPair}>
        <Typography className={classes.header}>Graph Label: </Typography>
        <Typography
          className={
            props.questionWIP.shortText == null ? classes.undefined : ""
          }
        >
          {props.questionWIP.shortText ?? "undefined"}
        </Typography>
      </Box>
      <Box className={classes.headerInfoPair}>
        <Typography className={classes.header}>Number Override: </Typography>
        <Typography
          className={
            props.questionWIP.displayName == null ? classes.undefined : ""
          }
        >
          {props.questionWIP.displayName ?? "undefined"}
        </Typography>
      </Box>
      <Box className={classes.headerInfoPair}>
        <Typography className={classes.header}>SNOMED Code: </Typography>
        <Typography
          className={props.questionWIP.snomed == null ? classes.undefined : ""}
        >
          {props.questionWIP.snomed ?? "undefined"}
        </Typography>
      </Box>
      <Box className={classes.headerInfoPair}>
        <Typography className={classes.header}>Hide Number: </Typography>
        <Typography
          className={
            props.questionWIP.hideNumber == null ? classes.undefined : ""
          }
        >
          {props.questionWIP.hideNumber == null
            ? "undefined"
            : props.questionWIP.hideNumber.toString()}
        </Typography>
      </Box>
      <Box className={classes.headerInfoPair}>
        <Typography className={classes.header}>Question Type: </Typography>
        <Typography
          className={
            props.questionWIP.questionType == null ? classes.undefined : ""
          }
        >
          {props.questionWIP.questionType == null
            ? "undefined"
            : QuestionType[props.questionWIP.questionType]}
        </Typography>
      </Box>
      <Box className={classes.headerInfoPair}>
        <Typography className={classes.header}>Subheading: </Typography>
        <Typography
          className={
            props.questionWIP.subheading == null ? classes.undefined : ""
          }
        >
          {props.questionWIP.subheading ?? "undefined"}
        </Typography>
      </Box>
      <Box className={classes.headerInfoPair}>
        <Typography className={classes.header}>Note: </Typography>
        <Typography
          className={props.questionWIP.note == null ? classes.undefined : ""}
        >
          {props.questionWIP.note ?? "undefined"}
        </Typography>
      </Box>
      <Box className={classes.headerInfoPair}>
        <Typography className={classes.header}>Divider: </Typography>
        <Typography
          className={props.questionWIP.divider == null ? classes.undefined : ""}
        >
          {props.questionWIP.divider == null
            ? "undefined"
            : props.questionWIP.divider.toString()}
        </Typography>
      </Box>
      {props.questionWIP.questionType !== QuestionType.ReadOnly && (
        <Box className={classes.headerInfoPair}>
          <Typography className={classes.header}>Mandatory: </Typography>
          <Typography
            className={
              props.questionWIP.mandatory == null ? classes.undefined : ""
            }
          >
            {props.questionWIP.mandatory == null
              ? "undefined"
              : props.questionWIP.mandatory.toString()}
          </Typography>
        </Box>
      )}
      <Box className={classes.headerInfoPair}>
        <Typography className={classes.header}>Depends On: </Typography>
        <Typography
          className={
            props.questionWIP.dependsOn == null ? classes.undefined : ""
          }
        >
          {dependsOnString()}
        </Typography>
      </Box>
      <Box className={classes.headerInfoPair}>
        <Typography className={classes.header}>Autofill From: </Typography>
        <Typography
          className={
            props.questionWIP.autofillFrom == null ? classes.undefined : ""
          }
        >
          {autofillFromString()}
        </Typography>
      </Box>
      {props.requiredQuestionFields.includes(RequiredQuestionFields.Labels) && (
        <Box className={classes.headerInfoPair}>
          <Typography className={classes.header}>Labels: </Typography>
          <Typography>
            {"[" + props.questionWIP.labels?.toString() + "]"}
          </Typography>
        </Box>
      )}
      {props.requiredQuestionFields.includes(14) && (
        <Box className={classes.headerInfoPair}>
          <Typography className={classes.header}>Field name</Typography>
          <Typography>Some snomed field</Typography>
        </Box>
      )}
      {props.requiredQuestionFields.includes(
        RequiredQuestionFields.MaxAnswers
      ) && (
        <Box className={classes.headerInfoPair}>
          <Typography className={classes.header}>Max Answers: </Typography>
          <Typography
            className={
              props.questionWIP.maxAnswers == null ? classes.undefined : ""
            }
          >
            {props.questionWIP.maxAnswers ?? "undefined"}
          </Typography>
        </Box>
      )}
      {props.requiredQuestionFields.includes(RequiredQuestionFields.Rows) && (
        <>
          <Box className={classes.headerInfoPair}>
            <Typography className={classes.header}>Rows: </Typography>
            <Typography>
              {props.questionWIP.rows != null
                ? "[" + props.questionWIP.rows.join(", ") + "]"
                : ""}
            </Typography>
          </Box>
          <Box className={classes.headerInfoPair}>
            <Typography className={classes.header}>Row Count: </Typography>
            <Typography>{props.questionWIP.rowNumber}</Typography>
          </Box>
        </>
      )}
      {props.requiredQuestionFields.includes(
        RequiredQuestionFields.RowObjectName
      ) && (
        <Box className={classes.headerInfoPair}>
          <Typography className={classes.header}>Row Name: </Typography>
          <Typography>{props.questionWIP.rowObjectName}</Typography>
        </Box>
      )}
      {props.requiredQuestionFields.includes(
        RequiredQuestionFields.Headers
      ) && (
        <Box className={classes.headerInfoPair}>
          <Typography className={classes.header}>Headers: </Typography>
          <Typography>
            {props.questionWIP.headers != null
              ? "[" + props.questionWIP.headers.join(", ") + "]"
              : ""}
          </Typography>
        </Box>
      )}
      {props.requiredQuestionFields.includes(RequiredQuestionFields.Format) && (
        <Box className={classes.headerInfoPair}>
          <Typography className={classes.header}>Wrapped List: </Typography>
          <Typography>
            {props.questionWIP.format === "tight" ? "true" : "false"}
          </Typography>
        </Box>
      )}
      {props.requiredQuestionFields.includes(
        RequiredQuestionFields.GoalCategory
      ) && (
        <Box className={classes.headerInfoPair}>
          <Typography className={classes.header}>Goal Category: </Typography>
          <Typography>
            {ToTitleCase(
              GoalCategory[props.questionWIP.goalCategory]
                .toString()
                .replace("AndOr", "/")
            )}
          </Typography>
        </Box>
      )}
      {props.requiredQuestionFields.includes(
        RequiredQuestionFields.DateRange
      ) && (
        <>
          <Box className={classes.headerInfoPair}>
            <Typography className={classes.header}>Minimum Date: </Typography>
            <Typography>
              {props.questionWIP.dateMin
                ? new Date(props.questionWIP.dateMin).toLocaleDateString(
                    "en-GB"
                  )
                : "Not Defined"}
            </Typography>
          </Box>
          <Box className={classes.headerInfoPair}>
            <Typography className={classes.header}>Maximum Date: </Typography>
            <Typography>
              {props.questionWIP.dateMax
                ? new Date(props.questionWIP.dateMax).toLocaleDateString(
                    "en-GB"
                  )
                : "Not Defined"}
            </Typography>
          </Box>
        </>
      )}
      {props.requiredQuestionFields.includes(
        RequiredQuestionFields.ReadOnlyReferenceQuestion
      ) && (
        <Box className={classes.headerInfoPair}>
          <Typography className={classes.header}>
            Read only question referring:{" "}
          </Typography>
          <Typography>{getReferredQuestion()}</Typography>
        </Box>
      )}
    </>
  );
};

export default QuestionInfo;
