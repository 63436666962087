import { useState } from "react";
import UserPagination from "./UserPagination";
import SimpleSearchFilters from "./SimpleSearchFilters";
import { Container, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  controlElements: React.ReactNode;
  elements: React.ReactElement[];
  filterCallback: (searchTerm: string, enableArchived: boolean) => void;
  searchTerm?: string;
  enableArchived?: boolean;
  itemsPerPage?: number;
  helpText?: string;
}

const PaginatedView = (props: Props): JSX.Element => {
  const itemsPerPage = props.itemsPerPage ?? 20;
  const pageCount = Math.ceil(
    props.elements.length / Math.max(itemsPerPage, 1)
  );

  var [page, setPage] = useState<number>(1);

  const useStyle = makeStyles((th: Theme) => ({
    elementContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 5,
    },
    paginatedView: {
      marginTop: 8,
    },
  }));

  const classes = useStyle();

  return (
    <Container className={classes.paginatedView} disableGutters>
      <SimpleSearchFilters
        filterCallback={props.filterCallback}
        searchTerm={props.searchTerm}
        enableArchived={props.enableArchived}
        clearSearch={() => {
          setPage(1);
        }}
        helpText={props.helpText}
      />

      {props.controlElements}

      <Container className={classes.elementContainer} disableGutters>
        {props.elements
          .slice(itemsPerPage * (Math.max(1, page) - 1), itemsPerPage * page)
          .map((element) => {
            return element;
          })}
      </Container>

      {pageCount > 1 && (
        <UserPagination
          totalPages={pageCount}
          page={page}
          onPageChange={setPage}
        />
      )}
    </Container>
  );
};

export default PaginatedView;
