import React from "react";
import { useContext, useEffect, useState } from "react";
import {
  QuestionnaireClient,
  QuestionnaireResponseViewModel,
  QuestionnaireViewModel,
} from "../../types/auto/types";
import { Configuration } from "../Constants";
import { FetchOverride } from "../utils/Request";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Error500 } from "../components/elements/errors/Error500";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMountedState, useUnmountPromise } from "react-use";
import { UserContext } from "../components/elements/stores/UserStore";
import PreviewQuestionnaireForm from "../components/questionnaires/PreviewQuestionnaireForm";

const PreviewQuestionnaire: React.FC = () => {
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const user = useContext(UserContext);
  const [error500, setError500] = useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [questionnaire, setQuestionnaire] = useState<QuestionnaireViewModel>();
  const [rerender, setRerender] = useState(false);
  const [loadQuestionnaire, setloadQuestionnaire] = useState(false);

  const questionnaireId = searchParams.get("qId");
  const version = searchParams.get("v");

  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        if (questionnaireId && version) {
          setloadQuestionnaire(true);
          await resolveWhileMounted(
            new QuestionnaireClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getQuestionnaireViaVersion(
              parseInt(questionnaireId),
              parseInt(version)
            )
          )
            .then(async (questionnaire: QuestionnaireViewModel) => {
              if (questionnaire.id !== undefined) {
                setQuestionnaire(questionnaire);
                setloadQuestionnaire(false);
              } else {
                setRerender((x) => !x);
                setloadQuestionnaire(false);
              }
            })
            .catch((e) => {
              if (e.status === 400) {
                setRerender((x) => !x);
                setloadQuestionnaire(false);
              } else {
                setError500(true);
                setloadQuestionnaire(false);
              }
            });
        } else {
          setloadQuestionnaire(false);
          setOpenSubmitDialog(true);
        }
      }
    };
    effect();
  }, [
    navigate,
    rerender,
    resolveWhileMounted,
    isMountedState,
    questionnaireId,
    version,
  ]);

  const useStyle = makeStyles({
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  });

  const classes = useStyle();
  return (
    <>
      {loadQuestionnaire ? (
        <CircularProgress
          className={classes.loadingSpinner}
          aria-label="Loading"
        />
      ) : (
        <PreviewQuestionnaireForm
          questionnaire={questionnaire}
          openSubmitDialog={openSubmitDialog}
          submitResponses={(responses: QuestionnaireResponseViewModel[]) =>
            new QuestionnaireClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getQuestionnaireScoreFromResponse(
              responses,
              parseInt(questionnaireId || ""),
              parseInt(version || "")
            )
          }
          role={user.role}
        />
      )}
      {error500 && <Error500 />}
    </>
  );
};

export default PreviewQuestionnaire;
