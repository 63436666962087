import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MouseEventHandler } from "react";
import { theme } from "../../../../Theme";

interface Props {
  character: string;
  selected: boolean;
  onChange?: MouseEventHandler<HTMLButtonElement>;
}

const DayItem = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    button: {
      borderRadius: "50%",
      maxWidth: "15px",
      minWidth: "15px",
      maxHeight: "30px",
      minHeight: "30px",
    },
  });
  const classes = useStyle(theme);
  return (
    <Button
      className={classes.button}
      variant={props.selected ? "contained" : "outlined"}
      onClick={props.onChange}
    >
      {props.character[0]}
    </Button>
  );
};

export default DayItem;
