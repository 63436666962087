import {
  GoalClient,
  PatientViewModel,
  QuestionnaireClient,
  TimelineCardType,
  PatientTimelineDataViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import WarningDialog from "../../elements/dialog/WarningDialog";

interface Props {
  timelineEntree: PatientTimelineDataViewModel;
  patient: PatientViewModel;
  deletePrompt: boolean;
  setDeletePrompt: React.Dispatch<React.SetStateAction<boolean>>;
  setHideCard: React.Dispatch<React.SetStateAction<boolean>>;
  currentTimelineLength: number;
  totalTimelineLength: number;
  totalTimelineLengthFiltered: number;
  setCurrentTimelineLength: React.Dispatch<React.SetStateAction<number>>;
  setTotalTimelineLengthFiltered: React.Dispatch<React.SetStateAction<number>>;
  setTotalTimelineLength: React.Dispatch<React.SetStateAction<number>>;
  setHiddenArrayIndex: () => void;
  isLastItem: boolean;
  setErrorClinicianNotLinked: React.Dispatch<React.SetStateAction<boolean>>;
}
export const DeletePromptTimeline = (props: Props): JSX.Element => {
  return (
    <WarningDialog
      title="Delete response"
      errorText="Error deleting timeline response"
      body={`This will delete the response ${
        props.timelineEntree.name
      } on the ${props.timelineEntree.submitted?.toLocaleDateString()} at ${props.timelineEntree.submitted
        ?.toLocaleTimeString()
        .slice(0, 5)} for patient ${props.patient?.firstName} ${
        props.patient?.lastName
      }. Are you sure this is intended?`}
      continueButtonText="Delete response"
      open={props.deletePrompt}
      onCancel={() => props.setDeletePrompt(false)}
      setOpen={() => props.setDeletePrompt(false)}
      onContinue={() =>
        props.timelineEntree.type === TimelineCardType.Goal
          ? new GoalClient(Configuration.SERVER_ROOT, FetchOverride)
              .deleteGoalResponse(
                props.timelineEntree.responseId,
                props.timelineEntree.patientId
              )
              .then((didSucceed) => {
                if (didSucceed) {
                  props.setErrorClinicianNotLinked(false);
                  props.setDeletePrompt(false);
                  props.setHideCard(true);
                  props.setTotalTimelineLengthFiltered(
                    props.totalTimelineLengthFiltered - 1
                  );
                  props.setCurrentTimelineLength(
                    props.currentTimelineLength - 1
                  );
                  props.setTotalTimelineLength(props.totalTimelineLength - 1);
                  if (props.isLastItem) {
                    props.setHiddenArrayIndex();
                  }
                } else {
                  props.setErrorClinicianNotLinked(true);
                }
              })
          : new QuestionnaireClient(Configuration.SERVER_ROOT, FetchOverride)
              .deleteQuestionnaireResponse(props.timelineEntree.responseId)
              .then((didSucceed) => {
                if (didSucceed) {
                  props.setErrorClinicianNotLinked(false);
                  props.setDeletePrompt(false);
                  props.setHideCard(true);
                  props.setTotalTimelineLengthFiltered(
                    props.totalTimelineLengthFiltered - 1
                  );
                  props.setCurrentTimelineLength(
                    props.currentTimelineLength - 1
                  );
                  props.setTotalTimelineLength(props.totalTimelineLength - 1);
                  if (props.isLastItem) {
                    props.setHiddenArrayIndex();
                  }
                } else {
                  props.setErrorClinicianNotLinked(true);
                }
              })
      }
    />
  );
};
