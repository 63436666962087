import { IdentityClient } from "../../types/auto/types";
import { Configuration } from "../Constants";
import { getCookie } from "./cookie";
import { FetchOverride } from "./Request";

export const expirationCookieExpired = (customTime: number = 0): boolean => {
  return (
    getCookie(Configuration.EXPIRATION_COOKIE_NAME).length === 0 ||
    isExpiredWithin(customTime)
  );
};

export const isExpiredWithin = (customTime: number = 0): boolean => {
  return (
    parseInt(getCookie(Configuration.EXPIRATION_COOKIE_NAME)) -
      Math.floor(Date.now() / 1000) <=
    customTime
  );
};

export const getPath = (url: string): string | null => {
  const urlParts = url.split("/");
  if (urlParts.length < 4) {
    return null;
  }
  return "/" + urlParts.slice(3).join("/");
};

export const isContextual = window.location.href.indexOf("contextual") > -1;

export const logout = (
  currentLocation: string | null,
  isContextual: boolean
) => {
  isContextual
    ? (window.location.href = Configuration.SITEBASE + "/contextualTimeout")
    : (window.location.href = new IdentityClient(
        Configuration.SERVER_ROOT,
        FetchOverride
      ).getLogoutToRedirectLinkURL(
        "/login" +
          (currentLocation == null
            ? ""
            : currentLocation
                .replace("?p=", ",")
                .replace("?s=", ",")
                .replace("?qId=", ",")
                .replaceAll("&", "_"))
      ));
};
