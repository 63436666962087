import React, { useRef } from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryScatter,
} from "victory";
import { SymptomsDataPointViewModel } from "../../../../../types/auto/types";
import { ExtendedBubbleViewModel } from "./SymptomsChart";
import { GetDateInterval } from "../ChartHelper";

interface Props {
  width: number;
  mobileSelectedDomain:
    | {
        x?: DomainTuple | undefined;
        y?: DomainTuple | undefined;
      }
    | undefined;
  setZoomDomain: React.Dispatch<
    React.SetStateAction<
      | {
          x?: DomainTuple | undefined;
          y?: DomainTuple | undefined;
        }
      | undefined
    >
  >;
  xAxisValues: number[];
  dateInterval: string | undefined;
  xAxisLabels: number[][];
  setDateInterval: React.Dispatch<React.SetStateAction<string | undefined>>;
  setXAxisValues: React.Dispatch<React.SetStateAction<number[]>>;
  fromDate: Date | undefined;
  toDate: Date | undefined;
  data: ExtendedBubbleViewModel;
}

const MobileZoomable = (props: Props): JSX.Element => {
  const initialDateInterval = useRef(props.dateInterval);
  const initialXAxisValues = useRef(props.xAxisValues);

  return (
    <VictoryChart
      padding={{ top: 0, bottom: 30, left: 100, right: 50 }}
      width={props.width}
      height={90}
      containerComponent={
        <VictoryBrushContainer
          brushDimension="x"
          brushDomain={props.mobileSelectedDomain}
          onBrushDomainChange={(b) => {
            props.setZoomDomain(b);

            const newDateInterval = GetDateInterval(
              new Date(b.x[0]),
              new Date(b.x[1])
            );

            if (newDateInterval !== props.dateInterval) {
              props.setDateInterval(newDateInterval);
              switch (newDateInterval) {
                case "year":
                  props.setXAxisValues(props.xAxisLabels[0]);
                  break;
                case "month":
                  props.setXAxisValues(props.xAxisLabels[1]);
                  break;
                case "week":
                  props.setXAxisValues(props.xAxisLabels[2]);
                  break;
                default:
                  props.setXAxisValues(props.xAxisLabels[3]);
                  break;
              }
            }
          }}
          style={{ margin: "auto" }}
          height={90}
        />
      }
      aria-label={"Symptoms Graph For " + props.data.chartName + " Mobile Zoom"}
    >
      <VictoryAxis
        style={{
          grid: { stroke: "lightgray", strokeWidth: 0.5 },
        }}
        tickValues={initialXAxisValues.current}
        tickFormat={(t: number) => {
          switch (initialDateInterval.current) {
            case "year":
              return new Date(t).toLocaleDateString("en-GB", {
                year: "numeric",
              });
            case "month":
              return new Date(t).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "short",
              });
            default:
              return new Date(t).toLocaleDateString("en-GB");
          }
        }}
        fixLabelOverlap
      />
      <VictoryScatter
        style={{
          data: {
            fill: (d) =>
              d.datum.score === 0
                ? "rgba(0, 0, 0, 0)"
                : d.datum.score < 0
                ? "gray"
                : props.data.colour,
            opacity: "80%",
          },
        }}
        data={props.data.data?.map((x) => {
          return {
            x: x.x,
            y: x.y?.replace(" ", " \n"),
            size: x.size / 100.0,
            score: x.score,
            totalScore: x.totalScore,
          } as SymptomsDataPointViewModel;
        })}
      />
    </VictoryChart>
  );
};

export default MobileZoomable;
