import { useEffect, useState } from "react";
import { Configuration } from "../../../Constants";
import TimeoutAlert from "./TimeoutAlert";
import {
  expirationCookieExpired,
  getPath,
  isContextual,
  isExpiredWithin,
  logout,
} from "../../../utils/timeout";

export const TimeoutComponent = (): JSX.Element => {
  const [timeoutSwitch, setTimeoutSwitch] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  var currentLocation = getPath(window.location.href);
  const contextual = isContextual;
  useEffect(() => {
    const handler = setTimeout(() => {
      if (isExpiredWithin(60)) {
        setOpenAlert(true);
      }
      if (expirationCookieExpired()) {
        logout(currentLocation, contextual);
      }
      setTimeoutSwitch(!timeoutSwitch);
    }, Configuration.TIMEOUTCHECK);
    return () => clearTimeout(handler);
  }, [timeoutSwitch, currentLocation, contextual]);
  return (
    <>
      <TimeoutAlert open={openAlert} setOpen={setOpenAlert} />
    </>
  );
};

export default TimeoutComponent;
