import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { CheckIsMobile } from "../../utils/MobileStatus";

interface Props {
  open: boolean;
  responseToken: string;
  tenantId: number;
  handleSubmit: (otpCode: string) => void;
  incorrectDraftOTPError: { isError: boolean; failCount: number };
  submitCodeLoadingButton: boolean;
  setSubmitCodeLoadingButton: (x: boolean) => void;
  cooldown?: string;
  noAttemptsRemainingError: boolean;
  codeExpiredError: boolean;
  contactError: boolean;
  contactErrorMessage?: string;
  codeUsedError: boolean;
}

const DraftOTPModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();

  const inputRefs = useRef<HTMLInputElement[]>([]);

  const [passwordInput, setPasswordInput] = useState<string[]>(
    new Array(6).fill("")
  );

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    i: number
  ) {
    const value = e.target.value;

    if (/[^0-9]/.test(value)) return;

    var newPasswordInput = [...passwordInput];
    newPasswordInput[i] = value;
    setPasswordInput(newPasswordInput);

    if (value && i < inputRefs.current?.length - 1) {
      inputRefs.current[i + 1].focus();
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>, i: number) {
    switch (e.key) {
      case "Backspace": {
        if (passwordInput[i] === "" && i > 0) {
          inputRefs.current[i - 1].focus();
        }
        break;
      }
      case "Enter": {
        if (i === passwordInput.length - 1) {
          props.setSubmitCodeLoadingButton(true);
          props.handleSubmit(passwordInput.join(""));
        }
        break;
      }
      case "ArrowLeft": {
        if (passwordInput[i] === "" && i > 0) {
          inputRefs.current[i - 1].focus();
        }
        break;
      }
      case "ArrowRight": {
        if (passwordInput[i] === "" && i < 5) {
          inputRefs.current[i + 1].focus();
        }
        break;
      }
    }
  }

  function handlePasteEvent(
    e: React.ClipboardEvent<HTMLDivElement>,
    i: number
  ) {
    var length = 0;
    if (i === 0) {
      var value = e.clipboardData.getData("text");
      if (value.length <= 6) {
        length = value.length;
        var newPasswordInput = [...passwordInput];
        for (let x = 0; x < value.length; x++) {
          if (/[0-9]/.test(value[x])) {
            newPasswordInput[x] = value[x];
          } else {
            newPasswordInput[x] = "";
          }
        }
        setPasswordInput(newPasswordInput);
      }
    }
    inputRefs.current[length === 0 ? 0 : length - 1].focus();
    return;
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>Draft Response One Time Password</DialogTitle>
      {props.codeUsedError && (
        <DialogContent>
          The current access code for this response has already been used. A new
          code can be generated for this response at{" "}
          {props.cooldown != null
            ? new Date(props.cooldown).toLocaleTimeString()
            : "date not set, please contact support."}
          .
        </DialogContent>
      )}
      {props.noAttemptsRemainingError && (
        <DialogContent>
          Too many failed attempts. A new code can be generated for this
          response at{" "}
          {props.cooldown != null
            ? new Date(props.cooldown).toLocaleTimeString()
            : "date not set, please contact support."}
          .
        </DialogContent>
      )}
      {props.codeExpiredError && (
        <DialogContent>
          Response access code has expired. A new code can be generated for this
          response at{" "}
          {props.cooldown != null
            ? new Date(props.cooldown).toLocaleTimeString()
            : "date not set, please contact support."}
          .
        </DialogContent>
      )}
      {props.contactError && (
        <DialogContent>
          Draft access code could not be sent:{" "}
          {props.contactErrorMessage ??
            "Could not find contact details for the patient."}
        </DialogContent>
      )}
      {!props.noAttemptsRemainingError &&
        !props.codeExpiredError &&
        !props.codeUsedError &&
        !props.contactError && (
          <>
            <DialogContent sx={{ paddingBottom: 1 }}>
              <Typography>Please enter your access code:</Typography>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                flexWrap={"nowrap"}
                gap={"5px"}
                paddingTop={"10px"}
              >
                {passwordInput.map((x, i) => (
                  <TextField
                    key={i}
                    value={x}
                    inputProps={{
                      maxLength: 1,
                      style: {
                        textAlign: "center",
                        fontWeight: "bold",
                        maxWidth: "30px",
                      },
                    }}
                    onChange={(e) => handleChange(e, i)}
                    onKeyDown={(e) => handleKeyDown(e, i)}
                    inputRef={(el) => (inputRefs.current[i] = el)}
                    sx={{ textAlign: "center" }}
                    autoComplete="off"
                    onPaste={(e) => {
                      if (i !== 0) {
                        e.preventDefault();
                        return false;
                      }
                      handlePasteEvent(e, i);
                    }}
                  />
                ))}
              </Box>
            </DialogContent>
            <DialogActions>
              <Box
                display={"flex"}
                minHeight={isMobile ? "0px" : "75px"}
                flexWrap={"wrap"}
                width={"100%"}
                paddingLeft={1}
                paddingRight={1}
                paddingBottom={1}
                gap={"20px"}
                justifyContent={"flex-end"}
              >
                {props.incorrectDraftOTPError.isError && (
                  <Alert
                    severity="error"
                    sx={{
                      maxHeight: "65px",
                      padding: 0,
                      paddingLeft: 1,
                      minWidth: "280px",
                    }}
                  >
                    <AlertTitle>Incorrect access code</AlertTitle>
                    Number of attempts remaining:{" "}
                    {props.incorrectDraftOTPError.failCount}
                  </Alert>
                )}
                {!props.incorrectDraftOTPError.isError && (
                  <Alert
                    severity="info"
                    sx={{
                      maxHeight: "65px",
                      padding: 0,
                      paddingLeft: 1,
                      width: "280px",
                    }}
                  >
                    {
                      "Your 6 digit access code has been sent to your preferred contact."
                    }
                  </Alert>
                )}
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifySelf={"flex-end"}
                >
                  <LoadingButton
                    variant="contained"
                    sx={{
                      marginBottom: "5px",
                      marginRight: "5px",
                      maxHeight: "36px",
                      maxWidth: "90px",
                    }}
                    loading={props.submitCodeLoadingButton}
                    onClick={() => {
                      props.setSubmitCodeLoadingButton(true);
                      props.handleSubmit(passwordInput.join(""));
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Box>
            </DialogActions>
          </>
        )}
    </Dialog>
  );
};

export default DraftOTPModal;
