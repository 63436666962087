import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { Edit, Archive } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";
import CreateEditCaseloadModal from "./CreateEditCaseloadModal";
import {
  CaseloadClient,
  CaseloadViewModel,
  CustomIdViewModel,
} from "../../../types/auto/types";
import WarningDialog from "../elements/dialog/WarningDialog";
import { FetchOverride } from "../../utils/Request";
import { Configuration } from "../../Constants";
import { Error400 } from "../elements/errors/Error400";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import EditCaseloadQuestionnairesModal from "./EditCaseloadQuestionnairesModal";
import GradingIcon from "@mui/icons-material/Grading";

interface Props {
  caseload: CaseloadViewModel;
  refresh: () => void;
  integration?: boolean;
  customCaseloadIdOptions?: CustomIdViewModel;
}
const CaseloadActions = (props: Props): JSX.Element => {
  const [anchorActionList, setAnchorActionList] =
    React.useState<null | HTMLElement>(null);
  const [openEditCaseload, setOpenEditCaseload] = React.useState(false);
  const [openArchiveCaseload, setOpenArchiveCaseload] = React.useState(false);
  const [openEditQuestionnaires, setOpenEditQuestionnaires] =
    React.useState(false);
  const [errorArchivingCaseload, setErrorArchivingCaseload] =
    React.useState(false);

  const useStyle = makeStyles(() => ({
    vert: {
      marginLeft: "auto",
      padding: "0",
      minWidth: "24px",
    },
  }));
  const classes = useStyle();
  const handleClose = () => {
    setAnchorActionList(null);
  };

  function onClose() {
    setOpenArchiveCaseload(false);
    setErrorArchivingCaseload(false);
  }

  return (
    <>
      {(!props.integration || props.caseload.active) && (
        <Button
          onClick={(event) =>
            setAnchorActionList((val) =>
              val === null ? event.currentTarget : null
            )
          }
          aria-label="Open Caseload Category Action List"
          className={classes.vert}
        >
          <MoreVertIcon />
          <Menu
            open={anchorActionList !== null}
            anchorEl={anchorActionList}
            aria-label="Caseload Action List"
            onClose={handleClose}
          >
            {props.caseload.active && (
              <div key={"editActionsContainer"}>
                <MenuItem
                  onClick={() => setOpenEditCaseload(true)}
                  aria-label="Edit caseload"
                >
                  <Edit />
                  Edit caseload
                </MenuItem>
                <MenuItem
                  onClick={() => setOpenEditQuestionnaires(true)}
                  aria-label="Edit questionnaires"
                >
                  <GradingIcon />
                  Edit questionnaires
                </MenuItem>
              </div>
            )}
            {!props.integration && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenArchiveCaseload(true);
                }}
                aria-label={
                  props.caseload.active
                    ? "Archive caseload"
                    : "Unarchive caseload"
                }
              >
                {props.caseload.active ? (
                  <>
                    <Archive />
                    Archive caseload
                  </>
                ) : (
                  <>
                    <UnarchiveIcon />
                    Unarchive caseload
                  </>
                )}
              </MenuItem>
            )}
          </Menu>
        </Button>
      )}
      <CreateEditCaseloadModal
        open={openEditCaseload}
        setOpen={setOpenEditCaseload}
        caseload={props.caseload}
        refresh={props.refresh}
        integration={props.integration}
        customCaseloadIdOptions={props.customCaseloadIdOptions}
      />
      <EditCaseloadQuestionnairesModal
        open={openEditQuestionnaires}
        setOpen={setOpenEditQuestionnaires}
        caseload={props.caseload}
        refresh={props.refresh}
      />
      <WarningDialog
        errorText={
          `Error ` +
          (props.caseload.active ? "archiving" : "unarchiving") +
          ` caseload`
        }
        title={(props.caseload.active ? "Archive" : "Unarchive") + " Caseload"}
        body={
          (props.caseload.active ? "Archiving" : "Unarchiving") +
          ` a caseload will:\n` +
          (props.caseload.active
            ? `\t\u2022 Remove it from all caseload dropdowns on the system.
\t\u2022 Require staff to provide a reason for accessing the patient, if this is their only link.`
            : `\t\u2022 Add it to all caseload dropdowns on the system, for relevant staff/patients.
\t\u2022 Reinstate any links between staff and patients.`) +
          `\n\nAre you sure you want to ` +
          (props.caseload.active ? "archive" : "unarchive") +
          ` "` +
          props.caseload.name +
          `"?`
        }
        continueButtonText={props.caseload.active ? "Archive" : "Unarchive"}
        open={openArchiveCaseload}
        onCancel={() => onClose()}
        setOpen={() => setOpenArchiveCaseload}
        onContinue={async () => {
          var editedCaseload = props.caseload;
          editedCaseload.active = !editedCaseload.active;
          new CaseloadClient(Configuration.SERVER_ROOT, FetchOverride)
            .editTeamCaseload(editedCaseload)
            .then(() => {
              onClose();
              props.refresh();
            })
            .catch(() => {
              setErrorArchivingCaseload(true);
              setOpenArchiveCaseload(false);
            });
        }}
      />
      {errorArchivingCaseload && <Error400 />}
    </>
  );
};

export default CaseloadActions;
