import { Card, CardContent, Container, Theme, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ErrorType,
  PreferredContact,
  Role,
  StaffClient,
  StaffViewModel,
  TenantClient,
} from "../../../types/auto/types";
import AvatarLogo from "../elements/nav/AvatarLogo";
import { theme as customTheme, theme } from "../../../Theme";
import {
  CheckIsLargeDevice,
  CheckIsMobile,
  CheckIsSmallDevice,
} from "../../utils/MobileStatus";
import { useCommonStyle } from "../../utils/CommonStyles";
import CreateEditStaff from "./create-edit-staff/CreateEditStaff";
import { useCallback, useEffect, useState } from "react";
import { useUnmountPromise } from "react-use";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import { makeStyles } from "@mui/styles";
import ContactPreferences, {
  generateErrorText,
  isSelectedValid,
} from "../elements/contact-preferences/ContactPreferences";
import { isNullOrEmptyString } from "../../HelperFunctions";
import HandleError from "../elements/errors/HandleError";

interface Props {
  staff: StaffViewModel;
  reloadStaff: () => void;
  setStaff: React.Dispatch<React.SetStateAction<StaffViewModel | undefined>>;
}

const ViewStaffDetails = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const isLargeDevice = CheckIsLargeDevice();

  const useStyle = useCommonStyle(
    customTheme,
    isSmallDevice,
    isMobile,
    isLargeDevice
  );
  const classes = useStyle(customTheme);

  const useExtraStyles = makeStyles((th: Theme) => ({
    outerBox: { display: "flex", flexDirection: "column", width: "100%" },
    buttonBox: { justifyContent: "flex-end" },
    cardBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    emailTitle: {
      fontWeight: "bold",
      display: "inline",
    },
    error: {
      color: "red",
      textAlign: "center",
    },
  }));
  const extraClasses = useExtraStyles(customTheme);
  const [loading, setLoading] = useState(false);

  const staffUsername = props.staff.firstName + " " + props.staff.lastName;
  const [, setReRender] = useState<boolean>(false);
  const [integration, setIntegration] = useState<boolean>(true);
  const resolveWhileMounted = useUnmountPromise();
  const getPreference = useCallback(() => {
    return props.staff.preferredContact === PreferredContact.Email
      ? [PreferredContact.Email]
      : props.staff.preferredContact === PreferredContact.Mobile
      ? [PreferredContact.Mobile]
      : [PreferredContact.Email, PreferredContact.Mobile];
  }, [props.staff.preferredContact]);

  const [messagingPreferences, setMessagingPreferences] = useState<
    PreferredContact[]
  >(getPreference());

  const [statusCode, setStatusCode] = useState<number>();
  const [prefError, setPrefError] = useState<ErrorType | undefined>(
    isSelectedValid(
      props.staff.mobileNumber,
      props.staff.contactEmail,
      props.staff.preferredContact
    )
      ? undefined
      : ErrorType.NullContact
  );

  const handlePreferenceChange = (
    event: React.MouseEvent<HTMLElement>,
    newMessagingPreferences: PreferredContact[]
  ) => {
    if (newMessagingPreferences === messagingPreferences) {
      return;
    }
    if (newMessagingPreferences.length) {
      const staffPreference =
        newMessagingPreferences.length === 2
          ? PreferredContact.Both
          : newMessagingPreferences[0];
      props.setStaff((x) => {
        if (x === undefined) {
          return x;
        }
        x.preferredContact = staffPreference;
        return x;
      });
      setLoading(true);
      new StaffClient(Configuration.SERVER_ROOT, FetchOverride)
        .updateMessagingPreferences(
          props.staff.id,
          props.staff.preferredContact
        )
        .then(() => {
          setMessagingPreferences(newMessagingPreferences);
          props.reloadStaff();
          setLoading(false);
        })
        .catch((e) => {
          setPrefError(e);
        });
    }
  };

  useEffect(() => {
    const effect = async () => {
      setMessagingPreferences(getPreference());
      setPrefError(
        isSelectedValid(
          props.staff.mobileNumber,
          props.staff.contactEmail,
          props.staff.preferredContact
        )
          ? undefined
          : ErrorType.NullContact
      );
      setStatusCode(undefined);
      await resolveWhileMounted(
        new TenantClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).getIsTenantIntegrated()
      )
        .then((x) => {
          setIntegration(x);
        })
        .catch((e) => setStatusCode(e.statusCode));
    };
    effect();
  }, [resolveWhileMounted, getPreference, props.staff]);

  return (
    <Grid container spacing={2}>
      {!integration && props.staff.role === Role.Clinician && (
        <Grid xs={12}>
          <CreateEditStaff
            staff={props.staff}
            setReRenderView={setReRender}
            reloadStaff={props.reloadStaff}
            style={{ marginTop: 0 }}
            role={Role.Clinician}
          />
        </Grid>
      )}
      {!integration && props.staff.role === Role.ReadOnly && (
        <CreateEditStaff
          staff={props.staff}
          setReRenderView={setReRender}
          reloadStaff={props.reloadStaff}
          style={{ marginTop: 0 }}
          role={Role.ReadOnly}
        />
      )}
      {props.staff.role === Role.Admin && (
        <Grid xs={12}>
          <CreateEditStaff
            staff={props.staff}
            setReRenderView={setReRender}
            reloadStaff={props.reloadStaff}
            style={{ marginTop: 0 }}
            role={Role.Admin}
          />
        </Grid>
      )}
      <HandleError statusCode={statusCode} />
      <Grid sm={12} md={6}>
        <Card className={classes.card} elevation={0}>
          <CardContent
            className={classes.cardContent}
            sx={{ ":last-child": { paddingBottom: 1 } }}
          >
            <Container className={classes.avatarContainer}>
              <AvatarLogo
                username={staffUsername}
                backgroundColour={theme.palette.secondary.main}
                colour="white"
                size={56}
              />
            </Container>
            {(
              [
                { name: "First Name", value: props.staff.firstName },
                { name: "Last Name", value: props.staff.lastName },
                {
                  name: "True Colours ID",
                  value: props.staff.id,
                  mobileOnly: true,
                },
              ] as {
                name: string;
                value: string;
              }[]
            ).map((x, key) => (
              <Container className={classes.textContainer} key={key}>
                <Typography className={classes.textField}>{x.name}:</Typography>
                <Typography className={classes.textValue}>{x.value}</Typography>
              </Container>
            ))}
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Typography className={extraClasses.emailTitle}>
                Login email:
              </Typography>
              <Typography sx={{ display: "inline" }}>
                {props.staff.email}
              </Typography>
            </Container>
          </CardContent>
        </Card>
      </Grid>
      <Grid sm={12} md={6}>
        <Card className={classes.card} elevation={0}>
          <CardContent sx={{ ":last-child": { paddingBottom: 1 } }}>
            <Typography className={classes.contactDetails}>
              Contact details
            </Typography>
            {(
              [
                { name: "Mobile Number", value: props.staff.mobileNumber },
                { name: "Email", value: props.staff.contactEmail },
              ] as {
                name: string;
                value: string;
              }[]
            ).map((x, key) => (
              <Container className={classes.textContainer2} key={key}>
                <Typography className={classes.textField2}>
                  {x.name}:
                </Typography>
                <Typography className={classes.textValue2}>
                  {x.value}
                </Typography>
              </Container>
            ))}
            <Typography className={classes.cardTitle}>
              Contact preference
            </Typography>
            <ContactPreferences
              messagingPreferences={messagingPreferences}
              onChange={handlePreferenceChange}
              fromPatientForm={false}
              hasMobile={!isNullOrEmptyString(props.staff.mobileNumber)}
              hasEmail={!isNullOrEmptyString(props.staff.contactEmail)}
              loading={loading}
            />
            {prefError != null && (
              <Typography className={extraClasses.error}>
                {generateErrorText(prefError)}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ViewStaffDetails;
