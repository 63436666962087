import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

const useStyle = makeStyles({
  unauthorized: {
    paddingTop: "25vh",
    justifyItems: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  p: {
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
  },
});

const Unauthorized = () => {
  const classes = useStyle();
  return (
    <Container maxWidth="sm">
      <div className={classes.unauthorized}>
        <Typography
          variant="h1"
          align="center"
          color="primary"
          className={classes.bold}
          gutterBottom
        >
          403
        </Typography>
        <Typography
          variant="h2"
          align="center"
          color="primary"
          className={classes.bold}
          gutterBottom
        >
          Access Denied
        </Typography>
        <Typography align="center" gutterBottom>
          You do not have the necessary permission to view this page.
        </Typography>
        <Link to="/" className={classes.link}>
          Go to home page
        </Link>
      </div>
    </Container>
  );
};

export default Unauthorized;
