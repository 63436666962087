import { Day } from "../../../../types/auto/types";
import DayItem from "./DayItem";

interface Props {
  className?: string;
  daysOfWeek: Day[];
  setDaysOfWeek: (daysOfWeek: Day[]) => void;
}

const DayPicker = (props: Props): JSX.Element => {
  const MakeDayOfWeekButton = (dayOfWeekVal: string): JSX.Element => {
    const dayOfWeekEnum = Day[dayOfWeekVal as keyof typeof Day];
    return (
      <DayItem
        key={dayOfWeekVal}
        selected={props.daysOfWeek.includes(dayOfWeekEnum)}
        character={dayOfWeekVal}
        onChange={() => {
          if (
            props.daysOfWeek.includes(dayOfWeekEnum) &&
            props.daysOfWeek.length > 1
          ) {
            const daysOfWeek = props.daysOfWeek.filter(
              (y) => y !== dayOfWeekEnum
            );
            props.setDaysOfWeek(daysOfWeek);
          } else {
            props.daysOfWeek.push(dayOfWeekEnum);
            props.setDaysOfWeek(props.daysOfWeek);
          }
        }}
      />
    );
  };

  return (
    <div className={props.className}>
      {Object.keys(Day)
        .filter((x) => isNaN(Number(x)))
        .map((x) => MakeDayOfWeekButton(x))}
    </div>
  );
};

export default DayPicker;
