import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../../Theme";
import { useEffect } from "react";
import {
  CaseloadViewModel,
  PatientViewModel,
} from "../../../../types/auto/types";
import { useMountedState } from "react-use";

interface Props {
  allCaseloads: CaseloadViewModel[];
  patient: PatientViewModel;
  itemIndex: number | undefined;
  setCaseloadId: (
    caseloadId: number | undefined,
    episodeId: string | undefined,
    itemIndex: number | undefined
  ) => void;
  error?: boolean;
}

const CaseloadDropdown = (props: Props) => {
  const { allCaseloads, itemIndex, setCaseloadId, error } = props;

  const useStyle = makeStyles({
    formControl: { marginBottom: 20, width: "100%" },
    errorText: { color: "red" },
  });
  const classes = useStyle(customTheme);

  const isMountedState = useMountedState();

  useEffect(() => {
    if (allCaseloads.length === 1 && isMountedState()) {
      setCaseloadId(allCaseloads[0].id, allCaseloads[0].episodeId, 0);
    }
    // Including setCaseloadId in dependency array causes infinite recursion
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCaseloads, isMountedState]);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="patientCaseload">Patient Caseload</InputLabel>
      <Select
        variant="outlined"
        labelId="patientCaseload"
        label="Patient Caseload"
        value={itemIndex ?? ""}
        onChange={(event) => {
          const x = event.target.value as number;
          setCaseloadId(allCaseloads[x].id, allCaseloads[x].episodeId, x);
        }}
        error={error}
      >
        {allCaseloads.map((val, i) => (
          <MenuItem key={i} value={i} role="menuitem">
            {val.name}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText className={classes.errorText}>
          Please select a caseload
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CaseloadDropdown;
