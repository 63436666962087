import { Divider, Menu, MenuItem, Theme } from "@mui/material";
import { theme } from "../../../../Theme";
import AvatarLogo from "../nav/AvatarLogo";
import { makeStyles } from "@mui/styles";
import {
  IdentityClient,
  Role,
  UserViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { useState } from "react";

interface Props {
  user?: UserViewModel;
  setContactPrefOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTenancyChangeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tenancyChangeEnabled: boolean;
}

const UserManagement = (props: Props): JSX.Element => {
  var identityClient = new IdentityClient(
    Configuration.SERVER_ROOT,
    FetchOverride
  );
  const useStyle = makeStyles((th: Theme) => ({
    settings: {
      marginLeft: "auto",
    },
    username: {
      textAlign: "center",
      fontWeight: "bolder",
      cursor: "default",
      pointerEvents: "none",
    },
  }));
  const classes = useStyle(theme);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <MenuItem
        aria-label="User Setting"
        tabIndex={0}
        id="userSettings"
        role="menuitem"
        className={classes.settings}
        onClick={(event) =>
          setAnchorEl((val) => (val === null ? event.currentTarget : null))
        }
      >
        <AvatarLogo
          username={props.user?.userName}
          backgroundColour="white"
          colour={theme.palette.secondary.main}
        />
        <Menu
          role="group"
          open={anchorEl !== null}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
        >
          <MenuItem
            aria-label="Username"
            role="menuitem"
            className={classes.username}
          >
            {props.user?.userName}
          </MenuItem>
          <Divider />
          {props.tenancyChangeEnabled && (
            <MenuItem
              aria-label="Change current tenancy"
              role="menuitem"
              onClick={() => {
                props.setTenancyChangeOpen(true);
              }}
            >
              Change Tenancy
            </MenuItem>
          )}
          {props.user?.patientLoginDisabled === false &&
            props.user?.role !== Role.NoAccess && (
              <MenuItem
                aria-label="Update contact preference"
                role="menuitem"
                onClick={() => {
                  props.setContactPrefOpen(true);
                }}
              >
                Contact Preference
              </MenuItem>
            )}
          <MenuItem
            aria-label="Change user password"
            role="menuitem"
            onClick={() => {
              window.location.href = identityClient.getChangePasswordURL();
            }}
          >
            Change Password
          </MenuItem>
          <MenuItem
            aria-label="Logout"
            role="menuitem"
            onClick={() => {
              window.location.href = identityClient.getLogoutURL();
            }}
          >
            Log out
          </MenuItem>
        </Menu>
      </MenuItem>
    </>
  );
};

export default UserManagement;
