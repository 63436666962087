import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  PatientDashboardOptionsModel,
  QuestionnaireBuilderDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import {
  DefaultQuestionValuesViewModel,
  QuestionType,
} from "../../../../types/auto/types";
import { useState } from "react";
import nameof from "../../../utils/NameOf";
import DefaultValuesAccordionComponent from "./DefaultValuesAccordionComponent";

interface Props {
  open: boolean;
  questionnaire: QuestionnaireBuilderDataModel;
  handleClose: () => void;
  setQuestionnaireWIP: (
    field: string,
    value:
      | string
      | boolean
      | number
      | null
      | PatientDashboardOptionsModel
      | DefaultQuestionValuesViewModel[]
      | undefined
  ) => void;
  keepAlive: () => void;
}

const GlobalDefaultValuesModal = (props: Props): JSX.Element => {
  const [globalDefaultValuesWIP, setGlobalDefaultValuesWIP] = useState<
    DefaultQuestionValuesViewModel[]
  >(props.questionnaire.globalDefaultValues ?? []);

  const [noResponseError, setNoResponseError] = useState<boolean[]>(
    createNoResponseErrorArray
  );

  function createNoResponseErrorArray() {
    var boolArray = [];
    for (let type in QuestionType) {
      if (isNaN(Number(type))) {
        boolArray.push(false);
      }
    }
    return boolArray;
  }

  function validateInputs(qType: QuestionType) {
    var valuesToVerify = globalDefaultValuesWIP.find(
      (x) => x.questionType === qType
    );
    if (
      valuesToVerify?.answer == null ||
      valuesToVerify?.answer === "" ||
      valuesToVerify.score == null ||
      valuesToVerify.score.toString() === ""
    ) {
      setNoResponseError(
        noResponseError.map((x, i) => {
          if (i === qType) {
            return true;
          } else {
            return x;
          }
        })
      );
      return { valid: false, value: valuesToVerify };
    } else {
      if (
        valuesToVerify.displayValue == null ||
        valuesToVerify.displayValue.toString() === ""
      ) {
        valuesToVerify.displayValue = undefined;
      }
      setNoResponseError(
        noResponseError.map((x, i) => {
          if (i === qType) {
            return false;
          } else {
            return x;
          }
        })
      );
      return { valid: true, value: valuesToVerify };
    }
  }

  function handleLocalChange(
    questionType: QuestionType,
    field: string,
    event: any
  ) {
    props.keepAlive();
    var localCopy = globalDefaultValuesWIP.map((x) => {
      return new DefaultQuestionValuesViewModel({ ...x });
    });
    if (field === nameof<DefaultQuestionValuesViewModel>("answer")) {
      switch (questionType) {
        case QuestionType.Date:
        case QuestionType.Day:
        case QuestionType.DateRange: {
          if (event != null) {
            event = new Date(event);
            if (!isNaN(event.getDate())) {
              var newDate = event.toLocaleDateString("en-GB", {
                day: "numeric",
                month: "long",
                year: "numeric",
              });
              if (
                localCopy.findIndex((x) => x.questionType === questionType) ===
                -1
              ) {
                localCopy.push(
                  new DefaultQuestionValuesViewModel({
                    questionType: questionType,
                    [field]: newDate,
                  })
                );
              } else {
                localCopy = localCopy.map((x) => {
                  if (x.questionType === questionType) {
                    return new DefaultQuestionValuesViewModel({
                      ...x,
                      [field]: newDate,
                    });
                  } else {
                    return new DefaultQuestionValuesViewModel({ ...x });
                  }
                });
              }
            } else {
              if (
                localCopy.findIndex((x) => x.questionType === questionType) ===
                -1
              ) {
                localCopy.push(
                  new DefaultQuestionValuesViewModel({
                    questionType: questionType,
                    [field]: "",
                  })
                );
              } else {
                localCopy = localCopy.map((x) => {
                  if (x.questionType === questionType) {
                    return new DefaultQuestionValuesViewModel({
                      ...x,
                      [field]: "",
                    });
                  } else {
                    return new DefaultQuestionValuesViewModel({ ...x });
                  }
                });
              }
            }
          }
          break;
        }
        default: {
          if (
            localCopy.findIndex((x) => x.questionType === questionType) === -1
          ) {
            localCopy.push(
              new DefaultQuestionValuesViewModel({
                questionType: questionType,
                [field]:
                  questionType === QuestionType.CheckBoxSingle
                    ? event.toString()
                    : event.target.value,
              })
            );
          } else {
            localCopy = localCopy.map((x) => {
              if (x.questionType === questionType) {
                return new DefaultQuestionValuesViewModel({
                  ...x,
                  [field]:
                    questionType === QuestionType.CheckBoxSingle
                      ? event.toString()
                      : event.target.value,
                });
              } else {
                return new DefaultQuestionValuesViewModel({ ...x });
              }
            });
          }
          break;
        }
      }
    } else {
      if (localCopy.findIndex((x) => x.questionType === questionType) === -1) {
        localCopy.push(
          new DefaultQuestionValuesViewModel({
            questionType: questionType,
            [field]: event.target.value,
          })
        );
      } else {
        localCopy = localCopy.map((x) => {
          if (x.questionType === questionType) {
            return new DefaultQuestionValuesViewModel({
              ...x,
              [field]: event.target.value,
            });
          } else {
            return new DefaultQuestionValuesViewModel({ ...x });
          }
        });
      }
    }
    setGlobalDefaultValuesWIP(localCopy);
  }

  function handleUpdate(qType: QuestionType) {
    props.keepAlive();
    var validatedModel = validateInputs(qType);
    if (
      validatedModel != null &&
      validatedModel.valid &&
      validatedModel.value != null
    ) {
      var localCopy = props.questionnaire.globalDefaultValues
        ? [...props.questionnaire.globalDefaultValues]
        : [];
      var index = localCopy.findIndex((x) => x.questionType === qType);
      if (index === -1) {
        localCopy.push(validatedModel.value);
      } else {
        localCopy[index] = validatedModel.value;
      }
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("globalDefaultValues"),
        localCopy
      );
    }
  }

  function handleDelete(qType: QuestionType) {
    var localCopy = props.questionnaire.globalDefaultValues
      ? [...props.questionnaire.globalDefaultValues]
      : [];
    props.setQuestionnaireWIP(
      nameof<QuestionnaireBuilderDataModel>("globalDefaultValues"),
      localCopy
        .filter((i) => i.questionType !== qType)
        .map((x) => {
          return new DefaultQuestionValuesViewModel({ ...x });
        })
    );
    setNoResponseError(
      noResponseError.map((x, i) => {
        if (i === QuestionType.Textbox) {
          return false;
        } else {
          return x;
        }
      })
    );
    props.keepAlive();
  }

  return (
    <Dialog
      open={props.open}
      key={"global-default-values-modal"}
      scroll="paper"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle key={"global-default-values-modal-title"}>
        Global Default Values
      </DialogTitle>
      <DialogContent key={"global-default-values-modal-content"}>
        <Box
          display="flex"
          flexWrap={"wrap"}
          width={"100%"}
          key={"global-default-values-modal-content-container"}
        >
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={QuestionType.Buttons}
            handleChange={(qT, f, e) => handleLocalChange(qT, f, e)}
            globalDefaultValuesWIP={globalDefaultValuesWIP}
            handleUpdate={(qType) => handleUpdate(qType)}
            handleDelete={(qType) => handleDelete(qType)}
            noResponseError={noResponseError[QuestionType.Buttons]}
          />
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={QuestionType.ButtonsMulti}
            handleChange={(qT, f, e) => handleLocalChange(qT, f, e)}
            globalDefaultValuesWIP={globalDefaultValuesWIP}
            handleUpdate={(qType) => handleUpdate(qType)}
            handleDelete={(qType) => handleDelete(qType)}
            noResponseError={noResponseError[QuestionType.ButtonsMulti]}
          />
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={QuestionType.CheckBoxSingle}
            handleChange={(qT, f, e) => handleLocalChange(qT, f, e)}
            globalDefaultValuesWIP={globalDefaultValuesWIP}
            handleUpdate={(qType) => handleUpdate(qType)}
            handleDelete={(qType) => handleDelete(qType)}
            noResponseError={noResponseError[QuestionType.CheckBoxSingle]}
          />
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={QuestionType.CheckBoxList}
            handleChange={(qT, f, e) => handleLocalChange(qT, f, e)}
            globalDefaultValuesWIP={globalDefaultValuesWIP}
            handleUpdate={(qType) => handleUpdate(qType)}
            handleDelete={(qType) => handleDelete(qType)}
            noResponseError={noResponseError[QuestionType.CheckBoxList]}
          />
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={QuestionType.Date}
            handleChange={(qT, f, e) => handleLocalChange(qT, f, e)}
            globalDefaultValuesWIP={globalDefaultValuesWIP}
            handleUpdate={(qType) => handleUpdate(qType)}
            handleDelete={(qType) => handleDelete(qType)}
            noResponseError={noResponseError[QuestionType.Date]}
          />
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={QuestionType.Day}
            handleChange={(qT, f, e) => handleLocalChange(qT, f, e)}
            globalDefaultValuesWIP={globalDefaultValuesWIP}
            handleUpdate={(qType) => handleUpdate(qType)}
            handleDelete={(qType) => handleDelete(qType)}
            noResponseError={noResponseError[QuestionType.Day]}
          />
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={QuestionType.DateRange}
            handleChange={(qT, f, e) => handleLocalChange(qT, f, e)}
            globalDefaultValuesWIP={globalDefaultValuesWIP}
            handleUpdate={(qType) => handleUpdate(qType)}
            handleDelete={(qType) => handleDelete(qType)}
            noResponseError={noResponseError[QuestionType.DateRange]}
          />
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={QuestionType.DropDown}
            handleChange={(qT, f, e) => handleLocalChange(qT, f, e)}
            globalDefaultValuesWIP={globalDefaultValuesWIP}
            handleUpdate={(qType) => handleUpdate(qType)}
            handleDelete={(qType) => handleDelete(qType)}
            noResponseError={noResponseError[QuestionType.DropDown]}
          />
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={QuestionType.Number}
            handleChange={(qT, f, e) => handleLocalChange(qT, f, e)}
            globalDefaultValuesWIP={globalDefaultValuesWIP}
            handleUpdate={(qType) => handleUpdate(qType)}
            handleDelete={(qType) => handleDelete(qType)}
            noResponseError={noResponseError[QuestionType.Number]}
          />
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={QuestionType.Range}
            handleChange={(qT, f, e) => handleLocalChange(qT, f, e)}
            globalDefaultValuesWIP={globalDefaultValuesWIP}
            handleUpdate={(qType) => handleUpdate(qType)}
            handleDelete={(qType) => handleDelete(qType)}
            noResponseError={noResponseError[QuestionType.Range]}
          />
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={QuestionType.SkippableRange}
            handleChange={(qT, f, e) => handleLocalChange(qT, f, e)}
            globalDefaultValuesWIP={globalDefaultValuesWIP}
            handleUpdate={(qType) => handleUpdate(qType)}
            handleDelete={(qType) => handleDelete(qType)}
            noResponseError={noResponseError[QuestionType.SkippableRange]}
          />
          <DefaultValuesAccordionComponent
            questionnaire={props.questionnaire}
            questionType={QuestionType.Textbox}
            handleChange={(qT, f, e) => handleLocalChange(qT, f, e)}
            globalDefaultValuesWIP={globalDefaultValuesWIP}
            handleUpdate={(qType) => handleUpdate(qType)}
            handleDelete={(qType) => handleDelete(qType)}
            noResponseError={noResponseError[QuestionType.Textbox]}
          />
        </Box>
      </DialogContent>
      <DialogActions key={"global-default-values-modal-actions"}>
        <Button
          variant="contained"
          onClick={() => props.handleClose()}
          key={"global-default-values-modal-close-button"}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GlobalDefaultValuesModal;
