import React, { forwardRef, useState } from "react";
import {
  Container,
  FormControlLabel,
  IconButton,
  Popover,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { TenantOptionsViewModel } from "../../../../../types/auto/types";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { makeStyles } from "@mui/styles";
import { FilterAlt } from "@mui/icons-material";

interface Props {
  fromDate: Date;
  setFromDate: React.Dispatch<React.SetStateAction<Date>>;
  toDate: Date;
  setToDate: React.Dispatch<React.SetStateAction<Date>>;
  separateSummaryGraphs: boolean;
  setSeparateSummaryGraphs: React.Dispatch<React.SetStateAction<boolean>>;
  separateCustomSummaryGraphsIds: number[];
  setSeparateCustomSummaryGraphsIds: React.Dispatch<
    React.SetStateAction<number[]>
  >;
  tenancyOptions: TenantOptionsViewModel | undefined;
  showCompletedGoals: boolean;
  setShowCompletedGoals: React.Dispatch<React.SetStateAction<boolean>>;
  setFirstMedicationLoad: React.Dispatch<React.SetStateAction<boolean>>;
  setFirstSummaryLoad: React.Dispatch<React.SetStateAction<boolean>>;
  setFirstSymptomsLoad: React.Dispatch<React.SetStateAction<boolean>>;
  setFirstGoalResponsesLoad: React.Dispatch<React.SetStateAction<boolean>>;
  setFilterChange: React.Dispatch<React.SetStateAction<boolean>>;
  disabledCustomGraphIds: number[];
}

const GraphFilter = forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const useStyle = makeStyles(() => ({
      popoverContainer: { padding: "20px" },
      title: { marginBottom: "20px" },
      datePickerContainer: {
        margin: 0,
        padding: 0,
        marginRight: 10,
        marginLeft: 10,
        flex: 1,
      },
      masterDateContainer: {
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "row",
      },
      switch: {
        marginLeft: 10,
        marginTop: 20,
      },
      buttonContainer: {
        textAlign: "right",
        zIndex: 100,
        width: "100%",
        "@media print": { display: "none" },
      },
      icon: {
        fontSize: 30,
      },
    }));
    const classes = useStyle();

    function validateDate(dateString: string) {
      const regex = new RegExp(
        "^(?:(?:31(\\/)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(/)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$"
      );

      return regex.test(dateString);
    }

    return (
      <div ref={ref}>
        <Popover
          id="graphFilterPopover"
          open={anchorEl !== null}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Container className={classes.popoverContainer}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Typography component="h2" variant="h5" className={classes.title}>
                Filter Graphs
              </Typography>
              <Container className={classes.masterDateContainer}>
                <Container className={classes.datePickerContainer}>
                  <DatePicker
                    label="From"
                    value={props.fromDate}
                    PopperProps={{
                      placement: "bottom-start",
                      modifiers: [
                        {
                          name: "flip",
                          options: {
                            fallbackPlacements: ["top-start", "right"],
                          },
                        },
                      ],
                    }}
                    onChange={(newValue: Date | null) => {
                      if (newValue != null) {
                        newValue = new Date(newValue);
                        if (
                          !isNaN(newValue.getDate()) &&
                          validateDate(
                            newValue.getDate() +
                              "/" +
                              (newValue.getMonth() + 1) +
                              "/" +
                              newValue.getFullYear()
                          )
                        ) {
                          props.setFromDate(newValue);
                          props.setFirstGoalResponsesLoad(true);
                          props.setFirstMedicationLoad(true);
                          props.setFirstSummaryLoad(true);
                          props.setFirstSymptomsLoad(true);
                        }
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          props.fromDate >= props.toDate
                            ? "From date cannot be after to date"
                            : ""
                        }
                        error={props.fromDate >= props.toDate}
                      />
                    )}
                    maxDate={props.toDate}
                    inputFormat="dd/MM/yyyy"
                  />
                </Container>
                <Container className={classes.datePickerContainer}>
                  <DatePicker
                    label="To"
                    value={props.toDate}
                    PopperProps={{
                      placement: "bottom-start",
                      modifiers: [
                        {
                          name: "flip",
                          options: {
                            fallbackPlacements: ["top-start", "right"],
                          },
                        },
                      ],
                    }}
                    onChange={(newValue: Date | null) => {
                      if (newValue != null) {
                        newValue = new Date(newValue);
                        if (
                          !isNaN(newValue.getDate()) &&
                          validateDate(
                            newValue.getDate() +
                              "/" +
                              (newValue.getMonth() + 1) +
                              "/" +
                              newValue.getFullYear()
                          )
                        ) {
                          props.setToDate(newValue);
                          props.setFirstGoalResponsesLoad(true);
                          props.setFirstMedicationLoad(true);
                          props.setFirstSummaryLoad(true);
                          props.setFirstSymptomsLoad(true);
                        }
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={props.fromDate >= props.toDate}
                        helperText={
                          props.fromDate >= props.toDate
                            ? "To date cannot be before from date"
                            : ""
                        }
                      />
                    )}
                    minDate={props.fromDate}
                    inputFormat="dd/MM/yyyy"
                  />
                </Container>
              </Container>
              <Container>
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.separateSummaryGraphs}
                      onChange={() => {
                        props.setFirstSummaryLoad(true);
                        props.setFilterChange((x) => !x);
                        props.setSeparateSummaryGraphs((x) => !x);
                      }}
                      color="secondary"
                    />
                  }
                  label="Separate Summary Graphs"
                  className={classes.switch}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.showCompletedGoals}
                      onChange={() => {
                        props.setShowCompletedGoals((x) => !x);
                        props.setFirstGoalResponsesLoad((x) => !x);
                      }}
                      color="secondary"
                    />
                  }
                  label="Show Completed Goals"
                  className={classes.switch}
                />
              </Container>
              <Container key={"container-for-custom-summary-toggle"}>
                {props.tenancyOptions?.customSummaryGraphs !== undefined && (
                  <>
                    {props.tenancyOptions?.customSummaryGraphs.map(
                      (customSummaryGraph, i) => {
                        return (
                          <FormControlLabel
                            key={"formcontrol-for-custom-summary-toggle-" + i}
                            control={
                              <Switch
                                key={"switch-for-custom-summary-toggle-" + i}
                                checked={props.separateCustomSummaryGraphsIds.includes(
                                  customSummaryGraph.id
                                )}
                                onChange={(e) => {
                                  if (!e.target.checked) {
                                    const removedIdList =
                                      props.separateCustomSummaryGraphsIds.filter(
                                        (id) => id !== customSummaryGraph.id
                                      );
                                    props.setSeparateCustomSummaryGraphsIds(
                                      removedIdList
                                    );
                                  } else {
                                    props.setSeparateCustomSummaryGraphsIds(
                                      (x) => {
                                        return [...x, customSummaryGraph.id];
                                      }
                                    );
                                  }
                                  props.setFilterChange((x) => !x);
                                }}
                                color="secondary"
                                disabled={props.disabledCustomGraphIds.includes(
                                  customSummaryGraph.id
                                )}
                              />
                            }
                            label={
                              "Separate " +
                              customSummaryGraph.title +
                              " Summary Graph"
                            }
                            className={classes.switch}
                          />
                        );
                      }
                    )}
                  </>
                )}
              </Container>
            </LocalizationProvider>
          </Container>
        </Popover>
        <Container className={classes.buttonContainer} disableGutters>
          <IconButton
            color="primary"
            aria-label="filter graph"
            onClick={(e) => {
              setAnchorEl(e.currentTarget as HTMLButtonElement);
            }}
          >
            <FilterAlt className={classes.icon} />
          </IconButton>
        </Container>
      </div>
    );
  }
);

export default GraphFilter;
