import { useEffect, useState } from "react";
import { Alert, Button, Container, Divider } from "@mui/material";
import FormErrorText from "../elements/errors/FormErrorText";
import QuestionnaireFooter from "./QuestionnaireFooter";
import {
  QuestionnaireResponseDataViewModel,
  QuestionnaireResponseViewModel,
  QuestionnaireViewModel,
  QuestionViewModel,
  QuestionnaireResponseScoreViewModel,
  Role,
  QuestionType,
  DefaultQuestionValuesViewModel,
} from "../../../types/auto/types";
import QuestionnaireHeader from "./QuestionnaireHeader";
import QuestionnaireSection from "./questions/QuestionnaireSection";
import { Configuration } from "../../Constants";
import { useMountedState, useUnmountPromise } from "react-use";
import parse from "html-react-parser";
import { isDependsValidInternal } from "./questions/QuestionBoxHelper";
import { ScoresAccordian } from "../patient/timeline/ScoresAccordian";
import FormSuccessText from "../elements/errors/FormSuccessText";

interface Props {
  questionnaire: QuestionnaireViewModel | undefined;
  openSubmitDialog: boolean;
  submitResponses: (
    responses: QuestionnaireResponseViewModel[]
  ) => Promise<QuestionnaireResponseScoreViewModel>;
  role: Role | undefined;
}

const PreviewQuestionnaireForm = (props: Props): JSX.Element => {
  const [section, setSection] = useState(0);
  const [questionnaireResponseData] =
    useState<QuestionnaireResponseDataViewModel>();
  const [responses, setResponses] = useState(
    new Array<QuestionnaireResponseViewModel>()
  );

  const [score, setScores] = useState(
    new QuestionnaireResponseScoreViewModel()
  );

  const [questions, setQuestions] = useState(new Array<QuestionViewModel>());

  const [, setRerender] = useState(false);
  const [onCoverPage, setOnCoverPage] = useState(
    props.questionnaire?.coverPage || props.questionnaire?.consentCheck
      ? true
      : false
  );

  const resolveWhileMounted = useUnmountPromise();

  const [validationText, setValidationText] = useState("");
  const [sectionComplete, setSectionComplete] = useState(false);
  const [submissionText, setSubmissionText] = useState("");
  const [submitButtonLoad, setSubmitButtonLoad] = useState(false);
  const [responseDate, setResponseDate] = useState(
    questionnaireResponseData?.submitted ?? new Date()
  );
  const [consentCheck, setConsentCheck] = useState(false);
  const [inaccessibleSections, setInaccessibleSections] = useState<number[]>(
    []
  );
  const [notValidatedSections, setNotValidatedSections] = useState<number[]>(
    []
  );

  const isMountedState = useMountedState();

  useEffect(() => {
    let questionsToPush: QuestionViewModel[] = [];
    props.questionnaire?.sections?.forEach((element) => {
      if (element.questions !== undefined) {
        element.questions.forEach((question) => {
          questionsToPush.push(question);
        });
      }
    });
    setQuestions(questionsToPush);
  }, [props.questionnaire]);

  useEffect(() => {
    if (notValidatedSections.length === 0)
      props.questionnaire?.sections?.forEach((x, index) => {
        var validated = validateResponses(false, index);
        if (!validated.valid) setNotValidatedSections((x) => [...x, index]);
      });
    // eslint-disable-next-line
  }, [section]);

  useEffect(() => {
    if (isMountedState() && questionnaireResponseData?.responses) {
      setResponses(questionnaireResponseData?.responses);
    }
  }, [setResponses, questionnaireResponseData, isMountedState]);

  useEffect(() => {
    checkAccessibleSections();
    // eslint-disable-next-line
  }, [section]);

  const navigateSection = (newSection: number) => {
    setSubmissionText("");
    setSubmitButtonLoad(true);
    if (newSection < 0) {
      // go back to questionnaires page
      window.location.href = Configuration.SITEBASE + "/questionnaires";
    }

    var sectionValid = false;
    while (!sectionValid) {
      sectionValid = true;
      var checkedSection = props?.questionnaire?.sections?.at(newSection);
      if (
        checkedSection !== undefined &&
        checkedSection.dependsOn !== undefined
      ) {
        sectionValid = isDependsValidInternal(
          checkedSection.dependsOn,
          responses,
          questions
        );
      }
      if (!sectionValid) {
        if (newSection < section) {
          newSection--;
        } else {
          newSection++;
        }
      }

      if (newSection >= (props.questionnaire?.sections?.length ?? 0)) {
        break;
      }
      if (newSection <= 0) {
        break;
      }
    }

    var validated = validateResponses(true);
    if (newSection === props.questionnaire?.sections?.length) {
      let validatedAll = validateAllResponses();
      if (validatedAll.valid) {
        const submit = async () => {
          setResponses(validatedAll.filtered);
          // Submit the responses if this is the last section
          if (newSection === props.questionnaire?.sections?.length) {
            await resolveWhileMounted(
              props.submitResponses(validatedAll.filtered)
            )
              .then(async (scores: QuestionnaireResponseScoreViewModel) => {
                setScores(scores);
                setSubmitButtonLoad(false);
              })
              .catch(() => {
                setSubmitButtonLoad(false);
              });
          }
        };
        submit();
      }
    } else if (validated.valid) {
      setNotValidatedSections((x) => {
        let indexOfSection = [...x].indexOf(section);
        if (indexOfSection !== -1) {
          return [...x].filter((x, i) => i !== indexOfSection);
        } else return x;
      });
      setSection(newSection);
      setSubmitButtonLoad(false);
      window.scrollTo({ top: 0 });
    } else {
      setNotValidatedSections((x) => {
        let indexOfSection = [...x].indexOf(section);
        if (indexOfSection === -1) {
          return [...x, section];
        } else return x;
      });
      setValidationText("");
      setSection(newSection);
      setSubmitButtonLoad(false);
      window.scrollTo({ top: 0 });
    }
  };
  const checkQuestionType = (questionNumber: number) => {
    let foundQuestion = questions?.find((x) => x.number === questionNumber);
    if (foundQuestion) {
      return foundQuestion.questionType;
    }
    return -1;
  };

  const isDependsValid = (question: QuestionViewModel) => {
    var satisfied;

    // Check that each answer condition is satisfied by the actual response
    for (let k in question.dependsOn) {
      satisfied = false;
      var response = responses.find((x) => x.number === parseInt(k))?.answer;

      var questionType = checkQuestionType(parseInt(k));

      for (let key in question.dependsOn[k]) {
        if (response !== undefined && questionType !== 1) {
          satisfied = parseInt(key) === parseInt(response);
        }

        if (questionType === 1) {
          // If dependsOn answer is 0, satisfied is true if response is empty
          if (question.dependsOn[k].find((x) => x.valueOf) === 0) {
            if (
              responses.find((x) => x.number === parseInt(k))?.answer == null
            ) {
              satisfied = true;
            } else {
              satisfied =
                responses.find((x) => x.number === parseInt(k))?.answer
                  ?.length === 0 ||
                responses.find((x) => x.number === parseInt(k))?.answer === "";
            }
            //If dependsOn answer is 1, satisfied is true if response is not empty
          } else if (question.dependsOn[k].find((x) => x.valueOf) === 1) {
            if (
              responses.find((x) => x.number === parseInt(k))?.answer == null
            ) {
              satisfied = false;
            } else {
              satisfied =
                responses.find((x) => x.number === parseInt(k))?.answer
                  ?.length !== 0 ||
                responses.find((x) => x.number === parseInt(k))?.answer !== "";
            }
          }
        }
        if (!satisfied) {
          return false;
        }
      }
    }

    return true;
  };

  const sectionCompleteCheck = () => {
    var validated = validateResponses(false);
    setSectionComplete(validated.valid);
  };

  const isDefaultValid = (
    questionType: QuestionType,
    globalDefaults: DefaultQuestionValuesViewModel[],
    dependsValid: boolean,
    dependsOnQuestion: boolean,
    mandatory?: boolean,
    defaultValueOverride?: DefaultQuestionValuesViewModel
  ) => {
    if (!dependsValid || dependsOnQuestion) {
      return { isValid: false, response: undefined };
    }

    var isRange =
      questionType === QuestionType.Range ||
      questionType === QuestionType.SkippableRange;

    var defaultViewModel =
      defaultValueOverride != null
        ? defaultValueOverride
        : globalDefaults.find((x) => x.questionType === questionType);

    if (defaultViewModel !== undefined && (isRange || !mandatory)) {
      return {
        isValid: true,
        response: defaultViewModel.answer,
      };
    }
    return { isValid: false, response: undefined };
  };

  const checkAccessibleSections = () => {
    let sectionArray: number[] = [];
    if (
      props.questionnaire?.sections !== undefined &&
      props.questionnaire?.sections !== null &&
      Array.isArray(props.questionnaire?.sections)
    )
      for (let i = 0; i < props.questionnaire.sections.length; i++) {
        let section = props.questionnaire.sections[i];
        if (section.dependsOn !== null && section.dependsOn !== undefined) {
          if (!isDependsValidInternal(section.dependsOn, responses, questions))
            sectionArray.push(i);
        }
      }
    setInaccessibleSections(sectionArray);
  };

  const validateResponses = (
    shouldUpdateText: boolean,
    sectionIndex: number = section
  ) => {
    var missingResponses = "";
    var regexFails = "";
    var dependsValid = false;
    var filteredResponses = new Array<QuestionnaireResponseViewModel>();
    props.questionnaire?.sections![sectionIndex].questions?.forEach((x) => {
      var questionIndex = responses.findIndex((r) => r.number === x.number);
      dependsValid = isDependsValid(x);

      // Flag the response missing if mandatory and dependsOn conditions are fulfilled
      if (questionIndex < 0 && x.mandatory && dependsValid) {
        missingResponses =
          missingResponses + (x.displayName ?? x.number.toString()) + ", ";
      }

      if (
        questionIndex >= 0 &&
        dependsValid &&
        x.questionType === QuestionType.Textbox &&
        x.answers![0].validationRegex &&
        regexFails.indexOf(x.displayName ?? x.number.toString()) < 0 &&
        responses[questionIndex].answer!.match(
          new RegExp(x.answers![0].validationRegex as string)
        ) == null
      ) {
        regexFails = regexFails + (x.displayName ?? x.number.toString()) + ", ";
      }
      // Clear responses for hidden questions
      if (!dependsValid) {
        filteredResponses = filteredResponses.concat(
          responses.filter((r) => r.number === x.number)
        );
      }
    });
    return updateValidationText(
      missingResponses,
      regexFails,
      filteredResponses,
      shouldUpdateText
    );
  };

  function getAllDependancyQuestionNumbers() {
    if (props.questionnaire == null || props.questionnaire.sections == null) {
      setValidationText("Questionnaire content not available for validation.");
      return { valid: false, dependancyQuestions: [] };
    }

    var dependancyQuestions: number[] = [];

    for (var i = 0; i < props.questionnaire.sections.length; i++) {
      var sectionDependsOn = props.questionnaire.sections[i].dependsOn;
      if (sectionDependsOn != null) {
        dependancyQuestions.push(Number(Object.keys(sectionDependsOn)[0]));
      }

      var questions = props.questionnaire.sections[i].questions ?? [];
      for (var x = 0; x < questions.length; x++) {
        var questionDependsOn = questions[x].dependsOn;
        if (questionDependsOn != null) {
          dependancyQuestions.push(Number(Object.keys(questionDependsOn)[0]));
        }
      }
    }

    return { valid: true, dependancyQuestions };
  }

  const validateAllResponses = () => {
    var missingResponses = "";
    var regexFails = "";
    var dependsValid = false;
    var globalDefaults = props.questionnaire?.globalDefaultValues ?? [];
    var defaultResponses = new Array<QuestionnaireResponseViewModel>();
    var filteredResponses = new Array<QuestionnaireResponseViewModel>();
    var dependsOnQuestions = getAllDependancyQuestionNumbers();
    if (!dependsOnQuestions.valid) {
      return { valid: false, filtered: [] };
    }
    props.questionnaire?.sections?.forEach((x, index) => {
      let skipSection = false;
      let sectionHeading = x.heading ?? index + 1;
      let sectionMissingResponses = "";
      let sectionRegexFails = "";
      if (x.dependsOn !== null && x.dependsOn !== undefined) {
        if (!isDependsValidInternal(x.dependsOn, responses, questions))
          skipSection = true;
      }
      if (!skipSection) {
        x.questions?.forEach((y) => {
          var questionIndex = responses.findIndex((r) => r.number === y.number);
          dependsValid = isDependsValid(y);

          var addDefault = isDefaultValid(
            y.questionType,
            globalDefaults,
            dependsValid,
            dependsOnQuestions.dependancyQuestions.includes(y.number),
            y.mandatory,
            y.defaultValueOverride
          );

          // Flag the response missing if mandatory and dependsOn conditions are fulfilled
          if (
            questionIndex < 0 &&
            y.mandatory &&
            dependsValid &&
            !addDefault.isValid
          ) {
            sectionMissingResponses =
              sectionMissingResponses +
              (y.displayName ?? y.number.toString()) +
              ", ";
          }

          if (questionIndex < 0 && addDefault.isValid) {
            defaultResponses.push(
              new QuestionnaireResponseViewModel({
                number: y.number,
                answer:
                  y.questionType === QuestionType.DateRange &&
                  addDefault.response
                    ? new Date(addDefault.response) < y.minimumDate! ||
                      new Date(addDefault.response) > y.maximumDate!
                      ? ""
                      : addDefault.response
                    : addDefault.response,
                isDefaultValue: true,
              })
            );
          }

          if (
            questionIndex >= 0 &&
            dependsValid &&
            y.questionType === QuestionType.Textbox &&
            y.answers![0].validationRegex &&
            sectionRegexFails.indexOf(y.displayName ?? y.number.toString()) <
              0 &&
            responses[questionIndex].answer!.match(
              new RegExp(y.answers![0].validationRegex as string)
            ) == null
          ) {
            sectionRegexFails =
              sectionRegexFails + (y.displayName ?? y.number.toString()) + ", ";
          }
          // Clear responses for hidden questions
          if (!dependsValid) {
            filteredResponses = filteredResponses.concat(
              responses.filter((r) => r.number === y.number)
            );
          }
        });
        if (sectionMissingResponses.length > 0)
          missingResponses += `${sectionMissingResponses.slice(
            0,
            -2
          )} from Section ${sectionHeading}, `;
        if (sectionRegexFails.length > 0)
          regexFails += `${sectionRegexFails.slice(
            0,
            -2
          )} from Section ${sectionHeading}, `;
      }
    });
    return updateValidationText(
      missingResponses,
      regexFails,
      filteredResponses,
      undefined,
      defaultResponses
    );
  };

  const updateValidationText = (
    missingResponses: string,
    regexFails: string,
    filteredResponses: QuestionnaireResponseViewModel[],
    shouldUpdateText = true,
    defaultResponses?: QuestionnaireResponseViewModel[]
  ) => {
    var missingText =
      "Please respond to the following questions: " +
      missingResponses.slice(0, -2);
    var regexText =
      "The following answers are invalid: " + regexFails.slice(0, -2);
    var validAndFilteredResponses = {
      valid: true,
      filtered: new Array<QuestionnaireResponseViewModel>(),
    };
    var validationText =
      missingResponses.length > 0 && regexFails.length > 0
        ? missingText + " / " + regexText
        : missingResponses.length > 0
        ? missingText
        : regexFails.length > 0
        ? regexText
        : "";

    if (validationText !== "") {
      shouldUpdateText && setValidationText(validationText);
      shouldUpdateText && setSubmitButtonLoad(false);
      validAndFilteredResponses.valid = false;
      return validAndFilteredResponses;
    } else if (responseDate === null || isNaN(responseDate.getTime())) {
      shouldUpdateText &&
        setValidationText("Please enter a valid response date.");
      shouldUpdateText && setSubmitButtonLoad(false);
      validAndFilteredResponses.valid = false;
      return validAndFilteredResponses;
    } else if (responseDate > new Date() || responseDate.getFullYear() < 1900) {
      shouldUpdateText &&
        setValidationText(
          "Response date cannot be in the future or before 1900."
        );
      shouldUpdateText && setSubmitButtonLoad(false);
      validAndFilteredResponses.valid = false;
      return validAndFilteredResponses;
    }

    shouldUpdateText && setValidationText("");
    validAndFilteredResponses.filtered = responses.filter(
      (r) => !filteredResponses.includes(r)
    );

    if (defaultResponses != null) {
      validAndFilteredResponses.filtered = [
        ...validAndFilteredResponses.filtered,
        ...defaultResponses,
      ];
    }

    return validAndFilteredResponses;
  };

  const emptyFunc: () => void = () => {};

  const returnToQuestionnaire = () => {
    window.location.href = Configuration.SITEBASE + "/questionnaires";
  };

  const backgroundImage =
    props.questionnaire?.sections?.[section]?.backgroundImage;

  return (
    <>
      {onCoverPage && (
        <>
          {parse(props.questionnaire?.coverPage ?? "")}{" "}
          {props.questionnaire?.consentCheck && (
            <>
              <div>
                <input
                  type="checkbox"
                  id="consentCheckbox"
                  style={{ margin: "10px", marginBottom: "30px" }}
                  onChange={(e) => {
                    setConsentCheck(e.target.checked);
                  }}
                />
                <label htmlFor="consentCheckbox">
                  I consent to the collection and processing of my sensitive
                  information for the purpose of this questionnaire.
                </label>
              </div>
            </>
          )}
          <Button
            onClick={() => setOnCoverPage(false)}
            variant="contained"
            disabled={!consentCheck && props.questionnaire?.consentCheck}
          >
            Start Questionnaire
          </Button>
          <hr />
          <Button
            variant="contained"
            onClick={() => {
              navigateSection(-1);
            }}
          >
            Return to patient
          </Button>
        </>
      )}
      {!onCoverPage && (
        <Container
          sx={{
            backgroundImage: backgroundImage
              ? `url(${backgroundImage})`
              : undefined,
            backgroundSize: "cover",
            backgroundRepeat: "repeat-y",
            backgroundPosition: "center",
          }}
        >
          {submissionText.length !== 0 && (
            <Alert severity="success" hidden={submissionText.length === 0}>
              {submissionText}
            </Alert>
          )}
          <QuestionnaireHeader
            questionnaire={props.questionnaire}
            editingDate={false}
            setEditingDate={emptyFunc}
            responseDate={responseDate}
            setResponseDate={setResponseDate}
            role={props.role}
            saveDraft={emptyFunc}
            saveDraftButtonLoading={false}
            goal={false}
            fromNotification={false}
            responseState={questionnaireResponseData?.state}
            preview={true}
          />
          <Divider key="header-divider" />
          <FormSuccessText
            message="This section is completed."
            Show={
              sectionComplete &&
              (props.questionnaire?.displaySectionComplete ?? false)
            }
          />
          <QuestionnaireSection
            section={(props.questionnaire?.sections || [])[section]}
            responses={responses}
            setResponses={setResponses}
            copyright={props.questionnaire?.copyright}
            setRerender={() => setRerender((x) => !x)}
            preview={true}
            questions={questions}
            sectionCompleteCheck={sectionCompleteCheck}
            checkAccessibleSections={checkAccessibleSections}
            setHasUnsubmittedGoals={() => {}}
          />
          <Divider key="footer-divider" />
          <FormErrorText
            errorText={validationText}
            isInvalid={validationText.length > 0}
          />
          <QuestionnaireFooter
            key={"Questionnaire-footer"}
            totalSections={props.questionnaire?.sections?.length || 0}
            sectionNumber={section}
            sections={props.questionnaire?.sections}
            navigateSection={(section) => navigateSection(section)}
            inaccessibleSections={inaccessibleSections}
            notValidatedSections={notValidatedSections}
            submitButtonLoad={submitButtonLoad}
            preview={true}
            isNewResponse={false}
          />
          {score.categoryScores !== undefined ? (
            <ScoresAccordian
              questionnaireScores={score.categoryScores}
              index={1}
            />
          ) : (
            <></>
          )}
          <Button
            variant="contained"
            onClick={returnToQuestionnaire}
            style={{ marginTop: "10px" }}
          >
            Return to Questionnaires
          </Button>
        </Container>
      )}
    </>
  );
};

export default PreviewQuestionnaireForm;
