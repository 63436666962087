import { Dispatch, SetStateAction } from "react";
import { Autocomplete, Checkbox, FormControl, TextField } from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";

interface INameAndId {
  id: number;
  name?: string | undefined;
}

interface Props<OptionType extends INameAndId> {
  label: string;
  options: OptionType[];
  selectedOptions: OptionType[];
  setSelectedOptions: Dispatch<SetStateAction<OptionType[]>>;
  onFilter: (filterText: string) => void;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  onChange?: () => void;
  noOptionsText?: string;
}

function MultiSelectChipSubset<OptionType extends INameAndId>(
  props: Props<OptionType>
): JSX.Element {
  return (
    <FormControl className={props.className}>
      <Autocomplete
        defaultValue={props.selectedOptions}
        disableClearable
        disabled={props.disabled}
        multiple
        options={props.options.sort((a, b) =>
          (a.name?.toLowerCase() ?? "") < (b.name?.toLowerCase() ?? "") ? -1 : 1
        )}
        value={props.selectedOptions}
        limitTags={5}
        onChange={(_, selectedOptions) => {
          props.setSelectedOptions(selectedOptions);
        }}
        onInputChange={(event, newInputValue) => {
          props.onFilter(newInputValue);
        }}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name || ""}
        filterOptions={(x) => x}
        noOptionsText={props.noOptionsText}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={option.id}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={
                  <CheckBox fontSize="small" aria-label={option.name} />
                }
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={props.error}
            label={props.label}
            helperText={props.error && props.errorText}
          />
        )}
      />
    </FormControl>
  );
}

export default MultiSelectChipSubset;
