import {
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import {
  DatasetClient,
  DatasetViewModel,
  MeasurementViewModel,
  PatientDatasetViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import MeasurementTextField from "./MeasurementTextField";
import { useMountedState, useUnmountPromise } from "react-use";
import FormErrorText from "../../elements/errors/FormErrorText";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

interface Props {
  patientDataset: PatientDatasetViewModel;
  setPatientDataset: React.Dispatch<
    React.SetStateAction<PatientDatasetViewModel>
  >;
  setReRender: React.Dispatch<React.SetStateAction<boolean>>;
  datasets: DatasetViewModel[];
  unselectedDatasetError: boolean;
  setUnselectedDatasetError: React.Dispatch<React.SetStateAction<boolean>>;
  measurementAmountError: boolean;
  setMeasurementAmountError: React.Dispatch<React.SetStateAction<boolean>>;
  editing: boolean;
  measurements: MeasurementViewModel[];
  setMeasurements: React.Dispatch<React.SetStateAction<MeasurementViewModel[]>>;
  setMeasurementError: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeCheck: (patientDataset: PatientDatasetViewModel) => boolean;
  dateError: boolean;
  setDateError: React.Dispatch<React.SetStateAction<boolean>>;
  measurementError: boolean;
}
const PatientDatasetForm = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    textFieldContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
      width: "100%",
      marginBottom: 15,
    },
    textField: {
      width: "100%",
    },
    errorText: { color: "red" },
  });
  const classes = useStyle();
  const isMountedState = useMountedState();
  const resolveWhileMounted = useUnmountPromise();

  const datasetId = props.patientDataset.datasetId;
  const setUnselectedMeasurementError = props.setUnselectedDatasetError;
  const setMeasurements = props.setMeasurements;

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        if (datasetId !== 0) {
          await resolveWhileMounted(
            new DatasetClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getMeasurementsLinkedToDataset(datasetId)
          ).then((measurements) => {
            setMeasurements(measurements);
            setUnselectedMeasurementError(false);
          });
        }
      }
    };
    effect();
  }, [
    datasetId,
    isMountedState,
    resolveWhileMounted,
    setUnselectedMeasurementError,
    setMeasurements,
  ]);

  return (
    <Container className={classes.textFieldContainer} disableGutters>
      <FormControl>
        <InputLabel id="datasets">Datasets</InputLabel>
        <Select
          required
          variant="outlined"
          labelId="datasets"
          label="Datasets"
          disabled={props.editing}
          value={
            props.patientDataset.datasetId &&
            props.patientDataset.datasetId >= 0
              ? props.patientDataset.datasetId
              : ""
          }
          onChange={(event) => {
            var datasetId = event.target.value as number;
            props.setPatientDataset((x) => {
              x.datasetId = datasetId;
              x.measurements = undefined;
              props.setReRender((x) => !x);
              return x;
            });
            new DatasetClient(Configuration.SERVER_ROOT, FetchOverride)
              .getMeasurementsLinkedToDataset(datasetId)
              .then((measurements) => {
                props.setMeasurements(measurements);
                props.setUnselectedDatasetError(false);
              });
            props.setMeasurementError(false);
            props.setMeasurementAmountError(false);
          }}
          error={props.unselectedDatasetError}
        >
          {props.datasets.map((val) => (
            <MenuItem key={val.id} value={val.id} role="menuitem">
              {val.name}
            </MenuItem>
          ))}
        </Select>
        {props.unselectedDatasetError && (
          <FormHelperText className={classes.errorText}>
            Please select a dataset
          </FormHelperText>
        )}
      </FormControl>
      {props.measurements.map((measurement, key) => {
        return (
          <MeasurementTextField
            measurement={measurement}
            patientDataset={props.patientDataset}
            setPatientDataset={props.setPatientDataset}
            setReRender={props.setReRender}
            key={key}
            setMeasurementError={props.setMeasurementError}
            onChangeCheck={props.onChangeCheck}
            measurementError={props.measurementError}
          />
        );
      })}

      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={props.patientDataset.testDate}
            label="Test Date"
            PopperProps={{
              placement: "bottom-start",
              modifiers: [
                {
                  name: "flip",
                  options: {
                    fallbackPlacements: ["top-start", "right"],
                  },
                },
              ],
            }}
            onChange={(newValue: Date | null) => {
              if (newValue != null) {
                props.setPatientDataset((x) => {
                  x.testDate = newValue;
                  props.setReRender((x) => !x);
                  return x;
                });
              } else {
                props.setDateError(true);
              }
            }}
            renderInput={(params) => <TextField {...params} />}
            disableFuture={true}
            inputFormat="dd/MM/yyyy"
            disableMaskedInput
          />
          <FormErrorText
            errorText="Please select a test date"
            isInvalid={props.dateError}
          />
        </LocalizationProvider>
      </FormControl>
    </Container>
  );
};

export default PatientDatasetForm;
