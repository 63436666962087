import {
  Box,
  Modal,
  Typography,
  FormControl,
  InputAdornment,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { makeStyles } from "@mui/styles";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { theme } from "../../../Theme";
import { PatientViewModel } from "../../../types/auto/types";

interface Props {
  open?: boolean;
  closeModal: () => void;
  patient?: PatientViewModel;
  savedDraftLink: string;
  fromNotification: boolean;
  remainingTokens?: number;
  nextQuestionnaire: () => void;
}

const SaveDraftModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "80%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    subHeader: {
      fontSize: 18,
      marginBottom: 15,
      marginTop: 20,
    },
  });
  const classes = useStyle(theme);

  return (
    <Modal
      open={
        props.open !== null && props.open !== undefined ? props.open : false
      }
    >
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          {"Draft Saved"}
        </Typography>
        <hr></hr>
        <Typography className={classes.subHeader}>
          {props.fromNotification
            ? "To continue this response, access it via this link:"
            : "To continue this response, edit it via the timeline or access it via this link:"}
        </Typography>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="saved-draft-link">
            Link to questionnaire
          </InputLabel>
          <OutlinedInput
            id="saved-draft-link"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="copy link to clipboard"
                  onClick={() => {
                    navigator.clipboard.writeText(props.savedDraftLink);
                  }}
                  edge="end"
                >
                  {<ContentCopyIcon />}
                </IconButton>
              </InputAdornment>
            }
            value={props.savedDraftLink}
            label="Link to questionnaire"
          />
        </FormControl>
        <hr />
        {props.fromNotification &&
        props.remainingTokens !== null &&
        props.remainingTokens !== undefined &&
        props.remainingTokens <= 1 ? (
          <Typography className={classes.subHeader}>
            You may now close this window.
          </Typography>
        ) : props.remainingTokens !== null &&
          props.remainingTokens !== undefined &&
          props.remainingTokens > 1 ? (
          <Button variant="contained" onClick={props.nextQuestionnaire}>
            Next Questionnaire
          </Button>
        ) : (
          <Button variant="contained" onClick={props.closeModal}>
            Return To Patient
          </Button>
        )}
      </Box>
    </Modal>
  );
};

export default SaveDraftModal;
