import { useCallback, useEffect, useState } from "react";
import {
  PatientClient,
  PatientViewModel,
  Role,
  TenantClient,
  TenantOptionsViewModel,
  UserViewModel,
  SortOrder,
} from "../../../../types/auto/types";
import SummaryChart from "./summary/SummaryChart";
import GraphFilter from "./header/GraphFilter";
import SymptomsCharts from "./symptoms/SymptomsChart";
import GoalResponsesChart from "./goal-responses/GoalResponsesChart";
import GraphNoData from "../../elements/graph/GraphNoData";
import MedicationChart from "./medication/MedicationChart";
import { useUnmountPromise } from "react-use";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { CircularProgress, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import HandleError from "../../elements/errors/HandleError";

interface Props {
  patient: PatientViewModel;
  user: UserViewModel;
  tenancyOptions?: TenantOptionsViewModel;
}

const graphWidth = 1000;
const graphHeight = 500;

const GraphsPage = (props: Props): JSX.Element => {
  const [statusCode, setStatusCode] = useState<number>();
  const [fromDate, setFromDate] = useState<Date>(
    ((): Date => {
      var date = new Date();
      date.setFullYear(date.getFullYear() - 1);
      return date;
    })()
  );
  const [datesUpdated, setDatesUpdated] = useState(false);
  const [toDate, setToDate] = useState<Date>(
    props.patient.lastResponse || new Date()
  );
  const [separateSummaryGraphs, setSeparateSummaryGraphs] = useState(false);
  const [separateCustomSummaryGraphsIds, setSeparateCustomSummaryGraphsId] =
    useState<number[]>([]);
  const [showCompletedGoals, setShowCompletedGoals] = useState(true);
  const [disabledCustomGraphIds, setDisabledCustomGraphIds] = useState<
    number[]
  >([]);

  // Graphs without data
  const [medicationNoData, setMedicationNoData] = useState(true);
  const [summaryNoData, setSummaryNoData] = useState(true);
  const [symptomsNoData, setSymptomsNoData] = useState(true);
  const [goalNoData, setGoalNoData] = useState(true);

  const isStaff =
    props.user.role === Role.SuperAdmin ||
    props.user.role === Role.Admin ||
    props.user.role === Role.Clinician ||
    props.user.role === Role.ReadOnly ||
    props.user.role === Role.ReadOnlyPIRedacted;

  const resolveWhileMounted = useUnmountPromise();

  const [firstMedicationGraphLoad, setFirstMedicationGraphLoad] =
    useState(false);
  const [firstSummaryGraphLoad, setFirstSummaryGraphLoad] = useState(false);
  const [firstSymptomsGraphLoad, setFirstSymptomsGraphLoad] = useState(false);
  const [firstGoalResponsesGraphLoad, setFirstGoalResponsesGraphLoad] =
    useState(false);
  const [filterChange, setFilterChange] = useState(false);

  const filterRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null && !medicationNoData) {
        setTimeout(() => {
          node.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 0); // Delay to ensure DOM is ready
      }
    },
    [medicationNoData]
  );

  const useStyle = makeStyles((th: Theme) => ({
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  }));
  const classes = useStyle(theme);
  useEffect(() => {
    const effect = async () => {
      if (!datesUpdated) {
        var tenantClient = new TenantClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        );
        setStatusCode(undefined);
        await resolveWhileMounted(
          new PatientClient(Configuration.SERVER_ROOT, FetchOverride)
            .getPatientDataInputDate(props.patient.id, SortOrder.Descending)
            .then((LastInput) => {
              setToDate(LastInput);
            })
            .catch((e) => setStatusCode(e.statusCode))
        );
        await resolveWhileMounted(
          new PatientClient(Configuration.SERVER_ROOT, FetchOverride)
            .getPatientDataInputDate(props.patient.id, SortOrder.Ascending)
            .then((firstInput) => {
              const startDate = new Date(firstInput);
              startDate.setDate(firstInput.getDate() - 1);
              setFromDate(startDate);
            })
            .catch(() => {
              tenantClient.getDefaultRangeGraphYears().then((years) => {
                var startDate = new Date(toDate);
                startDate.setFullYear(startDate.getFullYear() - years);
                setFromDate(startDate);
              });
            })
        );
        await resolveWhileMounted(
          tenantClient
            .getTenantGraphSeperationDefault()
            .then((graphSeperationDefault) =>
              setSeparateSummaryGraphs(graphSeperationDefault)
            )
        );
        setDatesUpdated(true);
        setFirstMedicationGraphLoad(true);
        setFirstSummaryGraphLoad(true);
        setFirstSymptomsGraphLoad(true);
        setFirstGoalResponsesGraphLoad(true);
        setMedicationNoData(props.tenancyOptions?.allowMedication ?? true);
      }
    };
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resolveWhileMounted,
    props.patient.id,
    props.tenancyOptions?.allowMedication,
  ]);

  return (
    <>
      <HandleError statusCode={statusCode} />
      {!(medicationNoData && summaryNoData && symptomsNoData && goalNoData) &&
        datesUpdated && (
          <GraphFilter
            ref={filterRef}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            separateSummaryGraphs={separateSummaryGraphs}
            setSeparateSummaryGraphs={setSeparateSummaryGraphs}
            separateCustomSummaryGraphsIds={separateCustomSummaryGraphsIds}
            setSeparateCustomSummaryGraphsIds={setSeparateCustomSummaryGraphsId}
            tenancyOptions={props.tenancyOptions}
            showCompletedGoals={showCompletedGoals}
            setShowCompletedGoals={setShowCompletedGoals}
            setFirstGoalResponsesLoad={setFirstGoalResponsesGraphLoad}
            setFirstMedicationLoad={setFirstMedicationGraphLoad}
            setFirstSummaryLoad={setFirstSummaryGraphLoad}
            setFirstSymptomsLoad={setFirstSymptomsGraphLoad}
            setFilterChange={setFilterChange}
            disabledCustomGraphIds={disabledCustomGraphIds}
          />
        )}
      {props.tenancyOptions?.allowMedication && datesUpdated && (
        <MedicationChart
          patient={props.patient}
          fromDate={fromDate}
          toDate={toDate}
          setNoData={setMedicationNoData}
          hideGraph={medicationNoData}
          isStaff={isStaff}
          firstMedicationLoad={firstMedicationGraphLoad}
          setFirstMedicationLoad={setFirstMedicationGraphLoad}
          hideLegendByDefault={
            props.tenancyOptions.graphLegendsHiddenByDefault?.medicationGraph
          }
          collapsedGraph={
            props.tenancyOptions?.collapsedGraphs?.medicationGraph ?? false
          }
        />
      )}

      {datesUpdated && (
        <SummaryChart
          patient={props.patient}
          user={props.user}
          fromDate={fromDate}
          toDate={toDate}
          separateGraph={separateSummaryGraphs}
          separateCustomGraphsIds={separateCustomSummaryGraphsIds}
          setNoData={setSummaryNoData}
          hideGraph={summaryNoData}
          tenancyOptions={props.tenancyOptions}
          firstSummaryLoad={firstSummaryGraphLoad}
          setFirstSummaryLoad={setFirstSummaryGraphLoad}
          collapsedGraph={
            props.tenancyOptions?.collapsedGraphs?.summaryGraph ?? false
          }
          filterChange={filterChange}
          setDisabledCustomGraphIds={setDisabledCustomGraphIds}
          disabledCustomGraphIds={disabledCustomGraphIds}
        />
      )}

      {datesUpdated && (
        <SymptomsCharts
          patient={props.patient}
          user={props.user}
          fromDate={fromDate}
          toDate={toDate}
          setNoData={setSymptomsNoData}
          hideGraph={symptomsNoData}
          firstSymptomsLoad={firstSymptomsGraphLoad}
          setFirstSymptomsLoad={setFirstSymptomsGraphLoad}
          collapsedGraph={
            props.tenancyOptions?.collapsedGraphs?.symptomsGraph ?? true
          }
        />
      )}

      {datesUpdated && (
        <GoalResponsesChart
          patient={props.patient}
          user={props.user}
          fromDate={fromDate}
          toDate={toDate}
          showCompletedGoals={showCompletedGoals}
          setNoData={setGoalNoData}
          hideGraph={goalNoData}
          tenancyOptions={props.tenancyOptions}
          firstGoalResponsesLoad={firstGoalResponsesGraphLoad}
          setFirstGoalResponsesLoad={setFirstGoalResponsesGraphLoad}
          collapsedGraph={
            props.tenancyOptions?.collapsedGraphs?.goalResponsesGraph ?? false
          }
        />
      )}
      {medicationNoData &&
        summaryNoData &&
        symptomsNoData &&
        goalNoData &&
        datesUpdated && <GraphNoData width={graphWidth} height={graphHeight} />}
      {!datesUpdated && (
        <CircularProgress
          className={classes.loadingSpinner}
          aria-label="Loading"
        />
      )}
    </>
  );
};

export default GraphsPage;
