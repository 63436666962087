import { Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../Theme";
import {
  MonitoringStatus,
  Role,
  ScheduleViewModel,
} from "../../../types/auto/types";
import { GroupBy } from "../../utils/GroupBy";
import PromptableCard from "../elements/promptable-card/PromptableCard";

interface Props {
  schedules: ScheduleViewModel[];
  monitoringStatusDescriptions: {
    [key in keyof typeof MonitoringStatus]?: string;
  };
  readonly: boolean;
}

const ParticipationHistory = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    cardContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: 0,
      padding: 0,
    },
    title: { marginTop: 20 },
  }));
  const classes = useStyle(theme);

  return (
    <>
      <Typography variant="h5" component="h2" className={classes.title}>
        Participation History
      </Typography>
      <Container className={classes.cardContainer}>
        {Object.entries(
          GroupBy(props.schedules, (schedule) => schedule.caseload?.name ?? "")
        ).map(([caseloadName, scheduleList]) => (
          <PromptableCard
            key={"participationHistory" + caseloadName}
            title={caseloadName}
            monitoringStatus={
              scheduleList.some(
                (x) => x.monitoringStatus === MonitoringStatus.Active
              )
                ? MonitoringStatus.Active
                : MonitoringStatus.Completed
            }
            createdDate={
              scheduleList.reduce((prev, curr) =>
                prev.created > curr.created ? curr : prev
              ).created
            }
            lastResponse={
              scheduleList.reduce((prev, curr) =>
                prev.updated < curr.updated ? curr : prev
              ).updated
            }
            prompt={undefined}
            role={Role.Clinician}
            monitoringStatusDescriptions={props.monitoringStatusDescriptions}
            readOnly={props.readonly}
          />
        ))}
      </Container>
    </>
  );
};
export default ParticipationHistory;
