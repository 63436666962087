export const PrintChart = (graphContainerId: string) => {
  var graphs = document.querySelectorAll('[id^="graphContainer"]');
  var accordions = document.querySelectorAll(".MuiAccordion-root");
  accordions.forEach((accordion) => {
    if (!accordion.classList.contains("Mui-expanded")) {
      accordion.classList.add("noPrint");
    } else {
      accordion.classList.remove("noPrint");
    }
  });
  graphs.forEach((x) => {
    if (x.id !== graphContainerId) {
      var accordion = x.closest(".MuiAccordion-root");
      if (
        accordion &&
        accordion !==
          document
            .getElementById(graphContainerId)
            ?.closest(".MuiAccordion-root")
      ) {
        accordion.classList.add("noPrint");
      }
      document.getElementById(x.id)?.classList.add("noPrint");
    }
  });
  window.print();
  graphs.forEach((x) => {
    if (x.id !== graphContainerId) {
      const accordion = x.closest(".MuiAccordion-root");
      if (
        accordion &&
        accordion !==
          document
            .getElementById(graphContainerId)
            ?.closest(".MuiAccordion-root")
      ) {
        accordion.classList.remove("noPrint");
      }
      document.getElementById(x.id)?.classList.remove("noPrint");
    }
  });
};
