import { MeasurementViewModel } from "../../../../types/auto/types";
import { AllowListViewPatientReasonRegex } from "../../../Constants";

export const CurrentDate = new Date();
export enum MeasurementError {
  NotANumber,
  InvalidValue,
  InvalidCharacter,
  NoError,
}

export function IsMeasurementValueValid(
  measurement: MeasurementViewModel,
  measurementValue: string
): MeasurementError {
  if (
    measurement.maxMeasurement !== undefined &&
    measurement.maxMeasurement !== null
  ) {
    if (Number.isNaN(Number(measurementValue))) {
      return MeasurementError.NotANumber;
    } else {
      var measurementInt = parseFloat(measurementValue);
    }
    if (
      measurementInt > measurement.maxMeasurement ||
      measurementInt < measurement.minMeasurement
    ) {
      return MeasurementError.InvalidValue;
    }
  } else {
    if (measurementValue.length > 0) {
      var regexp = new RegExp(AllowListViewPatientReasonRegex);
      var notInAllowList = regexp.test(measurementValue);
      if (notInAllowList) {
        return MeasurementError.InvalidCharacter;
      }
    }
  }
  return MeasurementError.NoError;
}
