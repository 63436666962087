import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  DataModelType,
  QuestionDataModel,
  QuestionnaireBuilderDataModel,
  SectionDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import DependsOnSelector from "./DependsOnSelector";
import { DependsOn } from "./DependsOnBuilder";
import { useEffect, useState } from "react";
import nameof from "../../../utils/NameOf";

interface Props {
  open: boolean;
  index: number;
  type: DataModelType;
  questionnaire: QuestionnaireBuilderDataModel;
  question?: QuestionDataModel;
  dependsOn: DependsOn;
  handleClose: () => void;
  handleSubmit: (
    index: number,
    field: string,
    dependsOn: DependsOn | null
  ) => void;
}

const DependsOnEditModal = (props: Props): JSX.Element => {
  const [dependsOnWIP, setDependsOnWIP] = useState<DependsOn>(props.dependsOn);
  const [singleDependsOn, setSingleDependsOn] = useState<DependsOn>({});
  const [noQuestionError, setNoQuestionError] = useState<boolean>(true);
  const [noAnswerError, setNoAnswerError] = useState<boolean>(true);

  function handleLocalChange(e: any, isQ: boolean) {
    var newDep = {};
    if (isQ) {
      newDep = {
        ...newDep,
        [e.target.value.toString()]: [],
      };
      setSingleDependsOn(newDep);
      setNoAnswerError(true);
      if (e.target.value === -1) {
        setNoQuestionError(true);
      } else {
        setNoQuestionError(false);
      }
    } else {
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(singleDependsOn)) {
        newDep = {
          ...newDep,
          [key]: Array.isArray(e.target.value)
            ? e.target.value
            : [e.target.value],
        };
      }
      setSingleDependsOn(newDep);
      if (e.target.value.length === 0) {
        setNoAnswerError(true);
      } else {
        setNoAnswerError(false);
      }
    }
  }

  function handleChange(index: number) {
    var updatedDep = {};
    if (Object.keys(dependsOnWIP).length === 0) {
      for (const [key, value] of Object.entries(singleDependsOn)) {
        updatedDep = {
          ...updatedDep,
          [key.toString()]: value,
        };
      }
    } else if (index === Object.keys(dependsOnWIP).length) {
      for (const [key, value] of Object.entries(singleDependsOn)) {
        updatedDep = {
          ...dependsOnWIP,
          [key.toString()]: value,
        };
      }
    } else {
      let i = 0;
      for (const [key, value] of Object.entries(dependsOnWIP)) {
        if (i === index) {
          for (const [key, value] of Object.entries(singleDependsOn)) {
            updatedDep = {
              ...updatedDep,
              [key.toString()]: value,
            };
          }
        } else {
          updatedDep = {
            ...updatedDep,
            [key.toString()]: value,
          };
        }
        i++;
      }
    }
    props.handleSubmit(
      props.index,
      nameof<SectionDataModel>("dependsOn"),
      updatedDep
    );
    setNoQuestionError(true);
    setNoAnswerError(true);
  }

  function handleDelete(index: number) {
    var updatedDep: DependsOn | null = {};
    let i = 0;
    if (Object.keys(dependsOnWIP).length === 1) {
      updatedDep = null;
    } else {
      for (const [key, value] of Object.entries(dependsOnWIP)) {
        if (i !== index) {
          updatedDep = {
            ...updatedDep,
            [key]: value,
          };
        }
        i++;
      }
    }
    props.handleSubmit(
      props.index,
      nameof<SectionDataModel>("dependsOn"),
      updatedDep
    );
  }

  useEffect(() => {
    setDependsOnWIP(props.dependsOn);
  }, [props.dependsOn]);

  return (
    <Dialog open={props.open} maxWidth="lg" fullWidth>
      <DialogTitle>
        {(props.type === DataModelType.Section ? "Section " : "Question ") +
          (props.type === DataModelType.Section
            ? props.index + 1
            : props.index) +
          " Depends On"}
      </DialogTitle>
      <DialogContent>
        <Box>
          <DependsOnSelector
            questionnaire={props.questionnaire}
            dependsOn={dependsOnWIP}
            singleDependsOn={singleDependsOn}
            setSingleDependsOn={(dO) => setSingleDependsOn(dO)}
            handleChange={(i) => handleChange(i)}
            handleLocalChange={(e, isQ) => handleLocalChange(e, isQ)}
            handleDelete={(i) => handleDelete(i)}
            handleClose={() => props.handleClose()}
            noQuestionError={noQuestionError}
            noAnswerError={noAnswerError}
            resetErrors={(to) => {
              setNoQuestionError(to);
              setNoAnswerError(to);
            }}
          ></DependsOnSelector>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DependsOnEditModal;
