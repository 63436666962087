import React, { useRef } from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
} from "victory";
import { ExtendedLineViewModel } from "../../../elements/graph/GraphLegend";
import {
  DataScatter,
  GroupedLine,
  VersionChangeScatter,
  NoResponseScatter,
  updateNoResponseYValues,
} from "./LineGraphCoreHelper";
import { GetDateInterval } from "../ChartHelper";

interface Props {
  width: number;
  mobileSelectedDomain:
    | {
        x?: DomainTuple | undefined;
        y?: DomainTuple | undefined;
      }
    | undefined;
  setZoomDomain: React.Dispatch<
    React.SetStateAction<
      | {
          x?: DomainTuple | undefined;
          y?: DomainTuple | undefined;
        }
      | undefined
    >
  >;
  xAxisValues: number[];
  data: ExtendedLineViewModel[];
  dateInterval: string | undefined;
  xAxisLabels: number[][];
  setDateInterval: React.Dispatch<React.SetStateAction<string | undefined>>;
  setXAxisValues: React.Dispatch<React.SetStateAction<number[]>>;
  fromDate: Date | undefined;
  toDate: Date | undefined;
  yDomain: DomainTuple;
}

const MobileZoomable = (props: Props): JSX.Element => {
  const initialDateInterval = useRef(props.dateInterval);
  const initialXAxisValues = useRef(props.xAxisValues);

  return (
    <VictoryChart
      padding={{ top: 0, bottom: 30, left: 50, right: 50 }}
      width={props.width}
      height={90}
      domainPadding={{ x: 20, y: 20 }}
      domain={{
        x:
          initialXAxisValues.current.length > 1
            ? [
                initialXAxisValues.current[0],
                initialXAxisValues.current[
                  initialXAxisValues.current.length - 1
                ],
              ]
            : undefined,
        y: props.yDomain,
      }}
      containerComponent={
        <VictoryBrushContainer
          brushDimension="x"
          brushDomain={props.mobileSelectedDomain}
          onBrushDomainChange={(b) => {
            props.setZoomDomain(b);

            const newDateInterval = GetDateInterval(
              new Date(b.x[0]),
              new Date(b.x[1])
            );

            if (newDateInterval !== props.dateInterval) {
              props.setDateInterval(newDateInterval);
              switch (newDateInterval) {
                case "year":
                  props.setXAxisValues(props.xAxisLabels[0]);
                  break;
                case "month":
                  props.setXAxisValues(props.xAxisLabels[1]);
                  break;
                case "week":
                  props.setXAxisValues(props.xAxisLabels[2]);
                  break;
                default:
                  props.setXAxisValues(props.xAxisLabels[3]);
                  break;
              }
            }
          }}
          style={{ margin: "auto" }}
          height={90}
        />
      }
      aria-label={
        "GraphMobileZoom" + props.data && props.data.length === 1
          ? props.data[0].lineName
          : "all"
      }
    >
      <VictoryAxis
        style={{
          grid: { stroke: "lightgray", strokeWidth: 0.5 },
        }}
        tickValues={initialXAxisValues.current}
        tickFormat={(t: number) => {
          switch (initialDateInterval.current) {
            case "year":
              return new Date(t).toLocaleDateString("en-GB", {
                year: "numeric",
              });
            case "month":
              return new Date(t).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "short",
              });
            default:
              return new Date(t).toLocaleDateString("en-GB");
          }
        }}
        fixLabelOverlap
      />
      {GroupedLine(props.data, "mobile-zoom")}
      {DataScatter(props.data, "mobile-zoom")}
      {VersionChangeScatter(props.data, "mobile-zoom")}
      {NoResponseScatter(
        updateNoResponseYValues(
          props.data,
          typeof props.yDomain[1] === "number" ? props.yDomain[1] * 7.14 : 0.5
        ),
        "mobile-zoom"
      )}
    </VictoryChart>
  );
};

export default MobileZoomable;
