import { Container, Typography } from "@mui/material";
import {
  QuestionType,
  QuestionViewModel,
  PatientTimelineDataViewModel,
  GoalViewModel,
  GoalClient,
  GoalCategory,
} from "../../../../types/auto/types";
import parse from "html-react-parser";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { DisplayQuestionResponse } from "../../../utils/QuestionnaireResponse";

interface Props {
  question: QuestionViewModel;
  index: number;
  timelineEntree: PatientTimelineDataViewModel;
}
export const ResponseContent = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    scoringHeading: {
      fontWeight: "bold",
      fontSize: "18px",
    },
    questionAnswerSection: {
      margin: 0,
      padding: 0,
    },
    question: {
      fontWeight: "bolder",
    },
    answer: {
      textIndent: "10px",
    },
  }));
  const classes = useStyle();
  const [questionnaireGoals, setQuestionnaireGoals] = useState<GoalViewModel[]>(
    []
  );

  useEffect(() => {
    if (
      props.question.questionType === QuestionType.Goals &&
      props.timelineEntree.questionnaire != null
    ) {
      new GoalClient(Configuration.SERVER_ROOT, FetchOverride)
        .getQuestionnaireGoals(
          props.timelineEntree.patientId,
          props.timelineEntree.questionnaire.id,
          props.question.goalCategory ?? GoalCategory.General,
          props.timelineEntree.responseId
        )
        .then((goals) => setQuestionnaireGoals(goals));
    }
  });

  return (
    <Container
      className={classes.questionAnswerSection}
      key={"questionAnswer" + props.index + props.timelineEntree.responseId}
    >
      <Typography
        key={"question" + props.index + props.timelineEntree.responseId}
        className={classes.question}
      >
        {!props.question.hideNumber &&
          (props.question.displayName ?? props.question.number) + " "}
        {props.question.text &&
        props.question.text.trim().length > 0 &&
        !props.question.text.trim().endsWith(":")
          ? parse(props.question.text + ":")
          : parse(props.question.text ?? "")}
      </Typography>
      {props.question.disableResponses ? null : (
        <Typography
          key={"answer" + props.index + props.timelineEntree.responseId}
          className={classes.answer}
        >
          {DisplayQuestionResponse(
            props.question,
            props.timelineEntree.questionnaireResponse ?? [],
            questionnaireGoals,
            "answer" + props.index + props.timelineEntree.responseId
          )}
        </Typography>
      )}
    </Container>
  );
};
