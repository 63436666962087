import { Button, CircularProgress, Container, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  PatientClient,
  PatientViewModel,
  TimelineCardType,
  PatientTimelineDataViewModel,
  UserViewModel,
  Role,
  TenantClient,
} from "../../../../types/auto/types";
import { Configuration, LoadMoreAmount } from "../../../Constants";
import { GroupBy } from "../../../utils/GroupBy";
import { FetchOverride } from "../../../utils/Request";
import TimelineView from "./TimelineView";
import { SearchOff } from "@mui/icons-material";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { FilteringTimelineBar } from "../timeline/FilteringTimelineBar";
import { useMountedState, useUnmountPromise } from "react-use";
import HandleError from "../../elements/errors/HandleError";

interface Props {
  patient: PatientViewModel;
  user: UserViewModel;
  printingTemplateQuestionnaires: number[];
}

const ViewPatientTimeline = (props: Props) => {
  const patientClient = new PatientClient(
    Configuration.SERVER_ROOT,
    FetchOverride
  );

  const [patientTimeline, setPatientTimeline] = React.useState<{
    [x: string]: PatientTimelineDataViewModel[];
  }>({});

  var [currentTimelineLength, setCurrentTimelineLength] =
    React.useState(LoadMoreAmount);

  var [totalTimelineLength, setTotalTimelineLength] = React.useState(0);
  var [totalTimelineLengthFiltered, setTotalTimelineLengthFiltered] =
    React.useState(0);
  var [timelineAsList, setTimelineAsList] = React.useState<
    PatientTimelineDataViewModel[]
  >([]);
  var [offset, setOffset] = React.useState(0);
  const [load, setLoad] = React.useState(false);
  const [loadMoreSpinner, setLoadMoreSpinner] = React.useState(false);
  const [filtering, setFiltering] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [dateRangeStart, setDateRangeStart] = React.useState("");
  const [dateRangeEnd, setDateRangeEnd] = React.useState("");
  const [responseTypeFilter, setResponseTypeFilter] = React.useState(
    TimelineCardType.All
  );
  const [firstTimeLoad, setFirstTimeLoad] = React.useState(false);
  const [CanEditResponses, setCanEditResponses] = React.useState(false);
  const [statusCode, setStatusCode] = React.useState<number>();

  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  var isStaff =
    props.user.role === Role.ReadOnlyPIRedacted ||
    props.user.role === Role.ReadOnly ||
    props.user.role === Role.Clinician ||
    props.user.role === Role.Admin ||
    props.user.role === Role.SuperAdmin;

  const getInitialTimeline = useCallback(async () => {
    if (isMountedState()) {
      setLoad(true);
      setStatusCode(undefined);
      if (isStaff) {
        if (props.user.role !== Role.ReadOnly) {
          setCanEditResponses(true);
          await resolveWhileMounted(
            new TenantClient(Configuration.SERVER_ROOT, FetchOverride)
              .getClinicianEditEnabled()
              .then((x) => {
                setCanEditResponses(x);
              })
              .catch((e) => setStatusCode(e.statusCode))
          );
        }
        await resolveWhileMounted(
          new PatientClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getPatientTimeline(props.patient.id, LoadMoreAmount, 0)
        )
          .then(async (fullTimeline) => {
            setTimelineAsList(fullTimeline);
            setPatientTimeline(
              GroupBy(
                fullTimeline,
                (timelineItem) => timelineItem.submitted?.toDateString() ?? ""
              )
            );
            await resolveWhileMounted(
              new PatientClient(
                Configuration.SERVER_ROOT,
                FetchOverride
              ).getPatientTimelineCount(props.patient.id)
            )
              .then((totalLength) => {
                setTotalTimelineLength(totalLength);
                setLoad(false);
                setCurrentTimelineLength(fullTimeline.length);
                setFiltering(false);
                setOffset(0);
              })
              .catch((e) => setStatusCode(e.statusCode));
          })
          .catch((e) => setStatusCode(e.statusCode));
      } else {
        await resolveWhileMounted(
          new PatientClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getCurrentPatientTimeline(LoadMoreAmount, 0)
        )
          .then(async (fullTimeline) => {
            setTimelineAsList(fullTimeline);
            setPatientTimeline(
              GroupBy(
                fullTimeline,
                (timelineItem) => timelineItem.submitted?.toDateString() ?? ""
              )
            );
            await resolveWhileMounted(
              new PatientClient(
                Configuration.SERVER_ROOT,
                FetchOverride
              ).getCurrentPatientTimelineCount()
            )
              .then((totalLength) => {
                setTotalTimelineLength(totalLength);
                setLoad(false);
                setCurrentTimelineLength(fullTimeline.length);
                setFiltering(false);
                setOffset(0);
              })
              .catch((e) => setStatusCode(e.statusCode));
          })
          .catch((e) => setStatusCode(e.statusCode));
      }
    }
  }, [
    props.patient.id,
    props.user.role,
    isMountedState,
    resolveWhileMounted,
    isStaff,
  ]);

  useEffect(() => {
    if (!firstTimeLoad && !filtering) {
      getInitialTimeline();
    }
    setFirstTimeLoad(true);
  }, [
    getInitialTimeline,
    props.patient.id,
    patientTimeline,
    totalTimelineLength,
    filtering,
    resolveWhileMounted,
    firstTimeLoad,
  ]);

  async function filterPatientTimeline(
    searchTerm: string,
    dateRangeStart: string,
    dateRangeEnd: string,
    responseType: TimelineCardType | null
  ) {
    if (isMountedState()) {
      setLoad(true);
      setFiltering(true);
      setStatusCode(undefined);
      if (isStaff) {
        await resolveWhileMounted(
          patientClient.filterPatientTimeline(
            props.patient.id,
            searchTerm,
            dateRangeStart,
            dateRangeEnd,
            responseType ?? TimelineCardType.All,
            LoadMoreAmount,
            0
          )
        )
          .then(async (filteredTimeline) => {
            setTimelineAsList(filteredTimeline);
            setPatientTimeline(
              GroupBy(
                filteredTimeline,
                (timelineItem) => timelineItem.submitted?.toDateString() ?? ""
              )
            );
            setCurrentTimelineLength(filteredTimeline.length);
            await resolveWhileMounted(
              patientClient.getFilteredPatientTimelineCount(
                props.patient.id,
                searchTerm,
                dateRangeStart,
                dateRangeEnd,
                responseType ?? TimelineCardType.All
              )
            )
              .then((filteredCount) => {
                setTotalTimelineLengthFiltered(filteredCount);
                setOffset(0);
                setLoad(false);
                setSearchText(searchTerm);
                setDateRangeStart(dateRangeStart);
                setDateRangeEnd(dateRangeEnd);
                setResponseTypeFilter(responseType ?? TimelineCardType.All);
              })
              .catch((e) => setStatusCode(e.statusCode));
          })
          .catch((e) => setStatusCode(e.statusCode));
      } else {
        await resolveWhileMounted(
          patientClient.filterCurrentPatientTimeline(
            searchTerm,
            dateRangeStart,
            dateRangeEnd,
            responseType ?? TimelineCardType.All,
            LoadMoreAmount,
            0
          )
        )
          .then(async (filteredTimeline) => {
            setTimelineAsList(filteredTimeline);
            setPatientTimeline(
              GroupBy(
                filteredTimeline,
                (timelineItem) => timelineItem.submitted?.toDateString() ?? ""
              )
            );
            setCurrentTimelineLength(filteredTimeline.length);
            await resolveWhileMounted(
              patientClient.getFilteredCurrentPatientTimelineCount(
                searchTerm,
                dateRangeStart,
                dateRangeEnd,
                responseType ?? TimelineCardType.All
              )
            )
              .then((filteredCount) => {
                setTotalTimelineLengthFiltered(filteredCount);
                setOffset(0);
                setLoad(false);
                setSearchText(searchTerm);
                setDateRangeStart(dateRangeStart);
                setDateRangeEnd(dateRangeEnd);
                setResponseTypeFilter(responseType ?? TimelineCardType.All);
              })
              .catch((e) => setStatusCode(e.statusCode));
          })
          .catch((e) => setStatusCode(e.statusCode));
      }
    }
  }

  async function loadMore() {
    if (isMountedState()) {
      setLoadMoreSpinner(true);
      setStatusCode(undefined);
      if (filtering) {
        if (isStaff) {
          await resolveWhileMounted(
            patientClient.filterPatientTimeline(
              props.patient.id,
              searchText,
              dateRangeStart,
              dateRangeEnd,
              responseTypeFilter,
              LoadMoreAmount,
              offset + LoadMoreAmount
            )
          )
            .then((filteredTimeline) => {
              const fullTimelineAsList = [
                ...timelineAsList,
                ...filteredTimeline,
              ];
              setTimelineAsList(fullTimelineAsList);
              const timelineDict = GroupBy(
                fullTimelineAsList,
                (timelineItem) => timelineItem.submitted?.toDateString() ?? ""
              );
              setPatientTimeline(timelineDict);
              setCurrentTimelineLength(fullTimelineAsList.length);
              setLoadMoreSpinner(false);
            })
            .catch((e) => setStatusCode(e.statusCode));
        } else {
          await resolveWhileMounted(
            patientClient.filterCurrentPatientTimeline(
              searchText,
              dateRangeStart,
              dateRangeEnd,
              responseTypeFilter,
              LoadMoreAmount,
              offset + LoadMoreAmount
            )
          )
            .then((filteredTimeline) => {
              const fullTimelineAsList = [
                ...timelineAsList,
                ...filteredTimeline,
              ];
              setTimelineAsList(fullTimelineAsList);
              const timelineDict = GroupBy(
                fullTimelineAsList,
                (timelineItem) => timelineItem.submitted?.toDateString() ?? ""
              );
              setPatientTimeline(timelineDict);
              setCurrentTimelineLength(fullTimelineAsList.length);
              setLoadMoreSpinner(false);
            })
            .catch((e) => setStatusCode(e.statusCode));
        }
      } else {
        if (isStaff) {
          await resolveWhileMounted(
            patientClient.getPatientTimeline(
              props.patient.id,
              LoadMoreAmount,
              offset + LoadMoreAmount
            )
          )
            .then((loadedTimeline) => {
              const fullTimelineAsList = [...timelineAsList, ...loadedTimeline];
              setTimelineAsList(fullTimelineAsList);
              const timelineDict = GroupBy(
                fullTimelineAsList,
                (timelineItem) => timelineItem.submitted?.toDateString() ?? ""
              );
              setPatientTimeline(timelineDict);
              setCurrentTimelineLength(fullTimelineAsList.length);
              setLoadMoreSpinner(false);
            })
            .catch((e) => setStatusCode(e.statusCode));
        } else {
          await resolveWhileMounted(
            patientClient.getCurrentPatientTimeline(
              LoadMoreAmount,
              offset + LoadMoreAmount
            )
          )
            .then((loadedTimeline) => {
              const fullTimelineAsList = [...timelineAsList, ...loadedTimeline];
              setTimelineAsList(fullTimelineAsList);
              const timelineDict = GroupBy(
                fullTimelineAsList,
                (timelineItem) => timelineItem.submitted?.toDateString() ?? ""
              );
              setPatientTimeline(timelineDict);
              setCurrentTimelineLength(fullTimelineAsList.length);
              setLoadMoreSpinner(false);
            })
            .catch((e) => setStatusCode(e.statusCode));
        }
      }
      setOffset(offset + LoadMoreAmount);
    }
  }

  const useStyle = makeStyles(() => ({
    button: {
      display: filtering
        ? currentTimelineLength >= totalTimelineLengthFiltered
          ? "none"
          : "block"
        : currentTimelineLength >= totalTimelineLength
        ? "none"
        : "block",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    noResults: {
      textAlign: "center",
      marginTop: "10%",
    },
    noResultsIcon: {
      fontSize: "4em",
    },
    noResultsText: {
      fontSize: "1.8em",
      textAlign: "center",
    },
    loadContainer: {
      display: "block",
      textAlign: "center",
      marginTop: "10%",
    },
    loadMoreSpinnerContainer: {
      display: loadMoreSpinner ? "block" : "none",
      textAlign: "center",
    },
    timelineContainer: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    displaying: {
      textAlign: "right",
      paddingRight: 5,
      paddingTop: 5,
    },
  }));
  const classes = useStyle();
  return (
    <>
      <FilteringTimelineBar
        filteringOfTimeline={filterPatientTimeline}
        getInitialTimeline={getInitialTimeline}
      />
      <HandleError statusCode={statusCode} />
      {load && (
        <Container className={classes.loadContainer}>
          <CircularProgress aria-label="Loading" />
        </Container>
      )}
      {Object.keys(patientTimeline).length > 0 && !load && (
        <Container className={classes.timelineContainer} disableGutters>
          <Typography className={classes.displaying}>
            Displaying {currentTimelineLength} out of{" "}
            {filtering ? totalTimelineLengthFiltered : totalTimelineLength}
          </Typography>
          <TimelineView
            items={patientTimeline}
            patient={props.patient}
            user={props.user}
            canEditResponses={CanEditResponses}
            refreshTimeline={() => {
              setLoad(true);
              if (filtering) {
                if (isStaff) {
                  patientClient
                    .filterPatientTimeline(
                      props.patient.id,
                      searchText,
                      dateRangeStart,
                      dateRangeEnd,
                      responseTypeFilter,
                      offset + LoadMoreAmount,
                      0
                    )
                    .then((filteredTimeline) => {
                      setTimelineAsList(filteredTimeline);
                      const timelineDict = GroupBy(
                        filteredTimeline,
                        (timelineItem) =>
                          timelineItem.submitted?.toDateString() ?? ""
                      );
                      setPatientTimeline(timelineDict);
                      setLoad(false);
                    });
                } else {
                  patientClient
                    .filterCurrentPatientTimeline(
                      searchText,
                      dateRangeStart,
                      dateRangeEnd,
                      responseTypeFilter,
                      offset + LoadMoreAmount,
                      0
                    )
                    .then((filteredTimeline) => {
                      setTimelineAsList(filteredTimeline);
                      const timelineDict = GroupBy(
                        filteredTimeline,
                        (timelineItem) =>
                          timelineItem.submitted?.toDateString() ?? ""
                      );
                      setPatientTimeline(timelineDict);
                      setLoad(false);
                    });
                }
              } else {
                if (isStaff) {
                  patientClient
                    .getPatientTimeline(
                      props.patient.id,
                      offset + LoadMoreAmount,
                      0
                    )
                    .then((fullTimeline) => {
                      setTimelineAsList(fullTimeline);
                      setPatientTimeline(
                        GroupBy(
                          fullTimeline,
                          (timelineItem) =>
                            timelineItem.submitted?.toDateString() ?? ""
                        )
                      );
                      setLoad(false);
                    });
                } else {
                  patientClient
                    .getCurrentPatientTimeline(offset + LoadMoreAmount, 0)
                    .then((fullTimeline) => {
                      setTimelineAsList(fullTimeline);
                      setPatientTimeline(
                        GroupBy(
                          fullTimeline,
                          (timelineItem) =>
                            timelineItem.submitted?.toDateString() ?? ""
                        )
                      );
                      setLoad(false);
                    })
                    .catch((e) => setStatusCode(e.statusCode));
                }
              }
            }}
            totalTimelineLength={totalTimelineLength}
            setTotalTimelineLength={setTotalTimelineLength}
            totalTimelineLengthFiltered={totalTimelineLengthFiltered}
            setTotalTimelineLengthFiltered={setTotalTimelineLengthFiltered}
            currentTimelineLength={currentTimelineLength}
            setCurrentTimelineLength={setCurrentTimelineLength}
            questionnairesWithPrintingTemplateEnabled={
              props.printingTemplateQuestionnaires
            }
          />
          <Container className={classes.loadMoreSpinnerContainer}>
            <CircularProgress aria-label="Loading" />
          </Container>
          <Container className={classes.buttonContainer}>
            <Button
              onClick={loadMore}
              className={classes.button}
              variant="contained"
              disabled={loadMoreSpinner || load}
            >
              <ReadMoreIcon /> Load {LoadMoreAmount} more &emsp;
            </Button>
          </Container>
        </Container>
      )}
      {Object.keys(patientTimeline).length === 0 && !load && (
        <Container className={classes.noResults}>
          <SearchOff className={classes.noResultsIcon} />
          <Typography className={classes.noResultsText}>
            No data has been recorded for this patient.
          </Typography>
        </Container>
      )}
    </>
  );
};

export default ViewPatientTimeline;
