import React from "react";
import { Container, Typography } from "@mui/material";
import { Report } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

interface Props {
  width: number;
  height: number;
}

const GraphError = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    container: {
      marginTop: 30,
      height: props.height,
      width: props.width,
      textAlign: "center",
      paddingTop: 175,
      color: "gray",
    },
    typography: {
      fontSize: 25,
    },
    icon: { fontSize: 50 },
  }));
  const classes = useStyle();

  return (
    <Container className={classes.container}>
      <Report className={classes.icon} />
      <Typography className={classes.typography}>
        Graph Could Not Be Loaded
      </Typography>
    </Container>
  );
};

export default GraphError;
