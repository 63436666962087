import { useContext, useEffect, useState } from "react";
import {
  QuestionnaireClient,
  QuestionnaireResponseDataViewModel,
  QuestionnaireViewModel,
} from "../../types/auto/types";
import { Configuration } from "../Constants";
import { FetchOverride } from "../utils/Request";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMountedState, useUnmountPromise } from "react-use";
import { UserContext } from "../components/elements/stores/UserStore";
import PrintingTemplateForm from "../components/questionnaires/PrintingTemplateForm";
import HandleError from "../components/elements/errors/HandleError";

interface Props {}

const PrintingTemplate = (props: Props): JSX.Element => {
  let navigate = useNavigate();
  const user = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [statusCode, setStatusCode] = useState<number>();
  const editing = searchParams.get("e") ? true : false;
  const [questionnaire, setQuestionnaire] = useState<QuestionnaireViewModel>();
  const [questionnaireResponseData, setQuestionnaireResponseData] =
    useState<QuestionnaireResponseDataViewModel>();
  const [rerender] = useState(false);
  const [loadQuestionnaire, setLoadQuestionnaire] = useState(false);
  const [caseloadId, setCaseloadId] = useState<number>();
  const token = searchParams.get("t");
  const patientId = searchParams.get("p");
  const questionnaireId = searchParams.get("qId");
  const version = searchParams.get("v");
  const tenantId = searchParams.get("tId");
  const [responseId, setResponseId] = useState<number>();
  const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);

  const questionnaireClient = new QuestionnaireClient(
    Configuration.SERVER_ROOT,
    FetchOverride
  );

  const useStyle = makeStyles({
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
    backgroundEagerLoad: {
      display: "none",
    },
  });
  const classes = useStyle();

  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  useEffect(() => {
    const effect = async () => {
      if (!isMountedState()) {
        return;
      }

      if (token) {
        setLoadQuestionnaire(true);
        setStatusCode(undefined);
        await resolveWhileMounted(
          questionnaireClient.getQuestionnaireViaToken(
            token,
            isNaN(Number(tenantId)) ? -1 : Number(tenantId),
            true
          )
        )
          .then(async (questionnaire: QuestionnaireViewModel) => {
            if (questionnaire.id) {
              setQuestionnaire(questionnaire);
              await resolveWhileMounted(
                questionnaireClient.getQuestionnaireResponses(
                  token,
                  isNaN(Number(tenantId)) ? -1 : Number(tenantId),
                  ""
                )
              )
                .then((responses) => {
                  setQuestionnaireResponseData(responses);
                  setCaseloadId(responses.caseloadId);
                  setResponseId(responses.id);
                })
                .catch((caseloadId) => setCaseloadId(caseloadId.caseloadId));
            }
          })
          .catch((e) => setStatusCode(e.status))
          .finally(() => setLoadQuestionnaire(false));
      } else if (questionnaireId && version) {
        setLoadQuestionnaire(true);
        setStatusCode(undefined);
        await resolveWhileMounted(
          questionnaireClient.getQuestionnaireViaVersion(
            Number(questionnaireId),
            Number(version)
          )
        )
          .then((q) => setQuestionnaire(q))
          .finally(() => setLoadQuestionnaire(false));
      }
    };
    effect();
    // eslint-disable-next-line
  }, [navigate, rerender, editing, resolveWhileMounted, isMountedState]);

  useEffect(() => {
    // Questionnaire has not loaded yet, so do not attempt to load images
    if (questionnaire == null) {
      return;
    }

    // Get the image URLs, ignoring empty background images
    const imageUrls = questionnaire.sections
      ?.map((x) => x.backgroundImage)
      .filter((x) => x != null);

    // If no background images to load, set images loaded to true
    if (imageUrls?.length === 0) {
      setImagesLoaded(true);
      return;
    }

    let loadedImagesCount = 0;
    imageUrls?.forEach((url) => {
      const image = new Image();
      image.src = url!;
      image.onload = () => {
        loadedImagesCount++;

        // Once all images have loaded, set images loaded to true
        if (loadedImagesCount === imageUrls.length) {
          setImagesLoaded(true);
        }
      };
    });
  }, [questionnaire]);

  return (
    <>
      {loadQuestionnaire ? (
        <>
          <CircularProgress
            className={classes.loadingSpinner}
            aria-label="Loading"
            key={"loading-spinner"}
          />
        </>
      ) : questionnaire == null ? (
        <Alert severity="error" key={"error-cannot-load"}>
          Printing Template could not be loaded. Please contact support.
        </Alert>
      ) : imagesLoaded ? (
        <PrintingTemplateForm
          patientId={patientId ?? undefined}
          questionnaire={questionnaire}
          questionnaireResponseData={questionnaireResponseData}
          role={user.role}
          caseloadId={caseloadId}
          questionnaireResponseId={responseId}
          key={"printing-template-form"}
        />
      ) : (
        <>
          <CircularProgress
            className={classes.loadingSpinner}
            aria-label="Loading"
            key={"loading-spinner"}
          />
        </>
      )}
      <HandleError statusCode={statusCode} />
    </>
  );
};

export default PrintingTemplate;
