import { useEffect, useState } from "react";
import { ScoringTransformationDataModel } from "./QuestionnaireBuilderDataModelHelper";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import TransformationSelector from "./TransformationSelector";

interface Props {
  open: boolean;
  transformation: ScoringTransformationDataModel[];
  handleCancel: () => void;
  setTransformationWIP: (scoring: ScoringTransformationDataModel[]) => void;
}

const ScoringRulesModal = (props: Props): JSX.Element => {
  const [transformationWIP, setTransformationWIP] = useState<
    ScoringTransformationDataModel[]
  >(props.transformation);

  function handleChange(
    index: number,
    newTrans: ScoringTransformationDataModel,
    arrString?: string
  ) {
    // this needs to update the specific entry in the array of TransformationDataModels, then set the new scoreRules array for the specific category.
    var deepCopy = structuredClone(props.transformation);
    deepCopy[index] = structuredClone(newTrans);
    deepCopy[index].transformation = arrString
      ?.split(",")
      .map((v) => Number(v));
    props.setTransformationWIP(deepCopy);
  }

  function handleDelete(index: number) {
    // this needs to delete the specific entry in the array of TransformationDataModels, then set the new scoreRules array for the specific category.
    var deepCopy = structuredClone(props.transformation);
    var updatedTransformation = deepCopy.filter((t, i) => i !== index);
    props.setTransformationWIP(updatedTransformation);
  }

  useEffect(() => {
    setTransformationWIP(props.transformation);
  }, [props.transformation]);

  return (
    <Dialog
      open={props.open}
      maxWidth="lg"
      fullWidth
      key={"scoring-transformation-rules-main-modal"}
    >
      <DialogTitle key={"scoring-transformation-rules-main-modal-title"}>
        Scoring Transformation Rules
      </DialogTitle>
      <DialogContent key={"scoring-transformation-rules-main-modal-content"}>
        <Box key={"scoring-transformation-rules-main-modal-selector-container"}>
          <TransformationSelector
            transformWIP={transformationWIP}
            handleChange={(i, st, arrString) => handleChange(i, st, arrString)}
            handleDelete={(i) => handleDelete(i)}
            handleCancel={() => props.handleCancel()}
            key={"scoring-transformation-rules-main-modal-selector"}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ScoringRulesModal;
