import { CircularProgress, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  EnumClient,
  MonitoringStatus,
  PatientViewModel,
  ScheduleClient,
  ScheduleViewModel,
} from "../../../types/auto/types";
import { theme as customTheme } from "../../../Theme";
import ActiveSchedules from "./ActiveSchedules";
import ArchivedSchedules from "./ArchivedSchedules";
import ParticipationHistory from "./ParticipationHistory";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { useEffect, useState } from "react";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import { useMountedState, useUnmountPromise } from "react-use";

interface Props {
  patient: PatientViewModel;
  readonly: boolean;
}

const ScheduleQuestionnaire = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles((th: Theme) => ({
    outerContainer: {
      padding: isMobile ? 0 : 20,
      display: "block",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      borderRadius: "4px",
      marginTop: "10px",
    },
    innerContainer: {
      marginTop: 10,
      paddingLeft: 5,
      paddingRight: 0,
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  }));
  const classes = useStyle(customTheme);
  const [schedules, setSchedules] = useState<ScheduleViewModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [monitoringStatusDescriptions, setMonitoringStatusDescriptions] =
    useState<{ [key in keyof typeof MonitoringStatus]?: string }>({});
  const [componentLoaded, setComponentLoaded] = useState(false);

  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  useEffect(() => {
    const effect = async () => {
      if (isMountedState() && !componentLoaded) {
        setComponentLoaded(true);
        await resolveWhileMounted(
          new EnumClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getMonitoringStatusDescriptions()
        ).then((x) => {
          setMonitoringStatusDescriptions(x);
        });
        setLoading(true);
        await resolveWhileMounted(
          new ScheduleClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getSchedulesForPatient(props.patient.id)
        )
          .then((retrievedSchedules) =>
            setSchedules(
              retrievedSchedules !== undefined && retrievedSchedules !== null
                ? retrievedSchedules
                : []
            )
          )
          .catch(() => {
            if (isMountedState())
              setError("Could not load schedules. Please try again later");
          })
          .finally(() => {
            if (isMountedState()) setLoading(false);
          });
      }
    };
    effect();
  }, [
    props,
    setSchedules,
    isMountedState,
    resolveWhileMounted,
    componentLoaded,
  ]);

  return (
    <Container className={classes.innerContainer}>
      {loading && (
        <CircularProgress
          className={classes.loadingSpinner}
          aria-label="Loading"
        />
      )}
      {error && <Typography>{error}</Typography>}
      {!loading && error === undefined && (
        <>
          <ActiveSchedules
            patient={props.patient}
            schedules={schedules.filter(
              (x) =>
                x.monitoringStatus !== MonitoringStatus.Archived &&
                x.monitoringStatus !==
                  MonitoringStatus.ArchivedDueToEndDateReached &&
                x.monitoringStatus !==
                  MonitoringStatus.ArchivedDueToMissedResponses &&
                x.monitoringStatus !==
                  MonitoringStatus.ArchivedDueToAnsweredResponses &&
                x.monitoringStatus !==
                  MonitoringStatus.ArchivedDueToPatientRemovalFromCaseload
            )}
            refreshSchedules={async () =>
              await resolveWhileMounted(
                new ScheduleClient(
                  Configuration.SERVER_ROOT,
                  FetchOverride
                ).getSchedulesForPatient(props.patient.id)
              ).then((retrievedSchedules) =>
                setSchedules(
                  retrievedSchedules !== undefined &&
                    retrievedSchedules !== null
                    ? retrievedSchedules
                    : []
                )
              )
            }
            monitoringStatusDescriptions={monitoringStatusDescriptions}
            readonly={props.readonly}
          />
          <ArchivedSchedules
            schedules={schedules.filter(
              (x) =>
                x.monitoringStatus === MonitoringStatus.Archived ||
                x.monitoringStatus ===
                  MonitoringStatus.ArchivedDueToEndDateReached ||
                x.monitoringStatus ===
                  MonitoringStatus.ArchivedDueToMissedResponses ||
                x.monitoringStatus ===
                  MonitoringStatus.ArchivedDueToAnsweredResponses ||
                x.monitoringStatus ===
                  MonitoringStatus.ArchivedDueToPatientRemovalFromCaseload
            )}
            patient={props.patient}
            refreshSchedules={() =>
              new ScheduleClient(Configuration.SERVER_ROOT, FetchOverride)
                .getSchedulesForPatient(props.patient.id)
                .then((retrievedSchedules) =>
                  setSchedules(
                    retrievedSchedules !== undefined &&
                      retrievedSchedules !== null
                      ? retrievedSchedules
                      : []
                  )
                )
            }
            monitoringStatusDescriptions={monitoringStatusDescriptions}
            readonly={props.readonly}
          />
          <ParticipationHistory
            schedules={schedules}
            monitoringStatusDescriptions={monitoringStatusDescriptions}
            readonly={props.readonly}
          />
        </>
      )}
    </Container>
  );
};
export default ScheduleQuestionnaire;
