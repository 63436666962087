import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  Container,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import {
  MedicationCategoryViewModel,
  MedicationClient,
  MedicationViewModel,
} from "../../../../types/auto/types";
import { CheckIsMobile, CheckIsSmallDevice } from "../../../utils/MobileStatus";
import React from "react";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import MedicationCard from "../MedicationCard";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MedicationCategoryActions from "./MedicationCategoryActions";
import SquareIcon from "@mui/icons-material/Square";

interface Props {
  medicationCategory: MedicationCategoryViewModel;
  refresh: () => void;
  medicationCategories: MedicationCategoryViewModel[];
}
const MedicationCategoryCard = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const useStyle = makeStyles((th: Theme) => ({
    card: {
      marginTop: "10px",
      width: isMobile ? "100%" : "49.5%",
      marginRight: isMobile ? "auto" : "0.5%",
      minWidth: isSmallDevice ? "180px" : "250px",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      height: "fit-content",
      "&:nth-child(even)": {
        marginRight: isMobile ? "auto" : "0",
        marginLeft: isMobile ? "auto" : "0.5%",
      },
    },
    buttonContainer: {
      marginLeft: "auto",
      display: "flex",
    },
    expandButton: {
      marginLeft: "auto",
    },
    subTitle: {
      fontWeight: "bold",
    },
    cardContent: {
      display: "flex",
      paddingLeft: "10px",
    },
    nameTitle: {
      whiteSpace: "nowrap",
    },
    cardActions: {
      paddingLeft: "10px",
      cursor: "pointer",
    },
    collapse: {
      width: "100%",
    },
    noMedications: {
      padding: 10,
      paddingTop: 0,
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  }));

  const classes = useStyle(theme);
  const [medications, setMedications] = React.useState<MedicationViewModel[]>(
    []
  );
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  function resetMedications() {
    setLoading(true);
    new MedicationClient(Configuration.SERVER_ROOT, FetchOverride)
      .getMedicationsLinkedToMedicationCategory(props.medicationCategory.id)
      .then((meds) => {
        setLoading(false);
        setMedications(meds);
      });
  }

  return (
    <>
      <Card className={classes.card} elevation={0}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h5" component="h2" className={classes.nameTitle}>
            <SquareIcon htmlColor={props.medicationCategory.colour} />
            {props.medicationCategory.name}
          </Typography>
          <Container className={classes.buttonContainer} disableGutters>
            <MedicationCategoryActions
              medicationCategory={props.medicationCategory}
              refresh={props.refresh}
              linkedMedications={medications.length}
              resetMedications={resetMedications}
            />
          </Container>
        </CardContent>
        <CardActions
          className={classes.cardActions}
          onClick={() => {
            setExpanded((x) => !x);
            if (!expanded && medications.length === 0) {
              resetMedications();
            }
          }}
        >
          <Typography className={classes.subTitle}>Medication</Typography>
          <div className={classes.expandButton}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </div>
        </CardActions>
        <Collapse
          className={classes.collapse}
          in={expanded}
          timeout="auto"
          unmountOnExit
        >
          {medications.map((medication, key) => (
            <MedicationCard
              medication={medication}
              refresh={props.refresh}
              medicationCategories={props.medicationCategories}
              medicationCategoryId={props.medicationCategory.id}
              key={key}
              resetMedications={resetMedications}
            />
          ))}
          {medications.length === 0 && !loading && (
            <Typography className={classes.noMedications}>
              Add medications by selecting the "add medication" option from the
              category dropdown
            </Typography>
          )}
          {loading && (
            <CircularProgress
              className={classes.loadingSpinner}
              aria-label="Loading"
            />
          )}
        </Collapse>
      </Card>
    </>
  );
};

export default MedicationCategoryCard;
