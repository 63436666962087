import {
  SortDirection,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { IHeadCell } from "../../../../types/IHeadCell";

interface Props<ViewModel> {
  headCells: IHeadCell[];
  onRequestSort?: (event: React.MouseEvent<unknown>, property: any) => void;
  sortDirection?: SortDirection;
  orderBy?: keyof ViewModel;
  centred?: boolean;
}

const TableHeader = <ViewModel,>(props: Props<ViewModel>) => {
  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              props.orderBy === headCell.id ? props.sortDirection : false
            }
            style={{
              fontWeight: "bold",
              paddingLeft: index === 0 ? 0 : "default",
              textAlign: props.centred ? "center" : "left",
            }}
          >
            {props.onRequestSort && props.orderBy && props.sortDirection ? (
              <TableSortLabel
                active={props.orderBy === headCell.id}
                direction={
                  props.orderBy === headCell.id ? props.sortDirection : "asc"
                }
                onClick={(event: React.MouseEvent<unknown>) =>
                  props.onRequestSort && props.onRequestSort(event, headCell.id)
                }
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
