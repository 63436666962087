import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  QuestionDataModel,
  QuestionnaireBuilderDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import AutofillFromSelector from "./AutofillFromSelector";
import { AutofillFrom } from "./AutofillFromBuilder";
import { useEffect, useState } from "react";
import { QuestionnaireViewModel, Autofill } from "../../../../types/auto/types";
import nameof from "../../../utils/NameOf";

interface Props {
  open: boolean;
  index: number;
  questionnaire: QuestionnaireBuilderDataModel;
  allQuestionnaires: QuestionnaireViewModel[];
  question?: QuestionDataModel;
  autofillFrom: AutofillFrom;
  handleClose: () => void;
  handleSubmit: (
    index: number,
    field: string,
    autofillFrom: AutofillFrom | null
  ) => void;
}

const AutofillFromEditModal = (props: Props): JSX.Element => {
  const [autofillFromWIP, setAutofillFromWIP] = useState<AutofillFrom>(
    props.autofillFrom
  );
  const [singleAutofillFrom, setSingleAutofillFrom] =
    useState<AutofillFrom>(undefined);
  const [noQuestionnaireError, setNoQuestionnaireError] =
    useState<boolean>(true);
  const [noQuestionError, setNoQuestionError] = useState<boolean>(true);

  function handleLocalChange(e: any, isQ: boolean) {
    var newAf = new Autofill();
    if (isQ) {
      if (e.target.value === "") {
        newAf.questionnaireId = -1;
      } else {
        newAf.questionnaireId = e.target.value;
      }
      setSingleAutofillFrom(newAf);
      setNoQuestionError(true);
      if (e.target.value === -1 || e.target.value === "") {
        setNoQuestionnaireError(true);
      } else {
        setNoQuestionnaireError(false);
      }
    } else {
      if (singleAutofillFrom !== undefined) {
        newAf.questionnaireId = singleAutofillFrom.questionnaireId;
        const { questionNumber, questionnaireVersion } = JSON.parse(
          e.target.value
        );
        newAf.questionnaireVersion = questionnaireVersion;
        newAf.questionNumber = questionNumber;
        setSingleAutofillFrom(newAf);
        if (e.target.value.length === 0) {
          setNoQuestionError(true);
        } else {
          setNoQuestionError(false);
        }
      }
    }
  }

  function handleChange(index: number) {
    props.handleSubmit(
      props.index,
      nameof<QuestionDataModel>("autofillFrom"),
      singleAutofillFrom
    );
    setNoQuestionnaireError(true);
    setNoQuestionError(true);
  }

  function handleDelete(index: number) {
    var updatedTar: AutofillFrom;
    props.handleSubmit(
      props.index,
      nameof<QuestionDataModel>("autofillFrom"),
      updatedTar
    );
  }

  useEffect(() => {
    setAutofillFromWIP(props.autofillFrom);
  }, [props.autofillFrom]);

  return (
    <Dialog open={props.open} maxWidth="lg" fullWidth>
      <DialogTitle>{"Question " + props.index + " Autofill From"}</DialogTitle>
      <DialogContent>
        <Box>
          <AutofillFromSelector
            question={props.question}
            questionnaire={props.questionnaire}
            allQuestionnaires={props.allQuestionnaires}
            autofillFrom={autofillFromWIP}
            singleAutofillFrom={singleAutofillFrom}
            setSingleAutofillFrom={(dO) => setSingleAutofillFrom(dO)}
            handleChange={(i) => handleChange(i)}
            handleLocalChange={(e, isQ) => handleLocalChange(e, isQ)}
            handleDelete={(i) => handleDelete(i)}
            handleClose={() => props.handleClose()}
            noQuestionnaireError={noQuestionnaireError}
            noQuestionError={noQuestionError}
            resetErrors={(to) => {
              setNoQuestionnaireError(to);
              setNoQuestionError(to);
            }}
          ></AutofillFromSelector>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AutofillFromEditModal;
