import { VictoryLine, VictoryScatter, VictoryTooltip } from "victory";
import { ExtendedMedicationViewModel } from "../../../elements/graph/GraphMedicationLegend";
import { MedicationStatus } from "../../../../../types/auto/types";
export const GroupedLine = (
  data: ExtendedMedicationViewModel[],
  keySuffix: string
): JSX.Element[] =>
  data.map((group, index) => (
    // Data lines
    <VictoryLine
      key={"line-" + index + keySuffix}
      interpolation="linear"
      style={{
        data: {
          stroke: group.hidden
            ? "rgba(0, 0, 0, 0)"
            : group.colour === null || group.colour === undefined
            ? "rgba(0, 0, 0, 1)"
            : group.colour,
        },
      }}
      data={group.data}
    />
  ));

export const DataScatter = (
  data: ExtendedMedicationViewModel[],
  keySuffix: string
): JSX.Element[] =>
  data.map((group, index) => (
    // Data point scatter
    <VictoryScatter
      key={"scatter-" + index + keySuffix}
      style={{
        data: {
          stroke: group.hidden
            ? "rgba(0, 0, 0, 0)"
            : group.colour === null || group.colour === undefined
            ? "rgba(0, 0, 0, 1)"
            : group.colour,
          strokeWidth: "2",
          fill: group.hidden ? "rgba(0, 0, 0, 0)" : "rgb(255, 255, 255)",
        },
      }}
      size={4}
      symbol={(data) =>
        data.datum.status === MedicationStatus.Ongoing ? "triangleUp" : "circle"
      }
      data={group.data}
      labels={(data) =>
        group.hidden
          ? []
          : [
              group.lineName || "",
              data.datum.x === undefined || isNaN(data.datum.x)
                ? ""
                : (data.datum.x as Date).toLocaleDateString("en-GB"),
              "Status: " + MedicationStatus[data.datum.status],
            ]
      }
      labelComponent={
        <VictoryTooltip
          pointerLength={20}
          flyoutPadding={{
            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
          }}
          flyoutStyle={{
            fill: "rgba(65, 65, 65, 0.8)",
            strokeWidth: 1,
            overflowWrap: "break-word",
          }}
          style={{
            fill: "white",
            overflowWrap: "break-word",
          }}
          renderInPortal
        />
      }
    />
  ));
