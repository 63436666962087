import {
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../../Theme";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import DateSelect from "../../elements/form-elements/DateSelect";
import {
  ArchiveCountType,
  CaseloadClient,
  CaseloadType,
  CaseloadViewModel,
  EnumClient,
  PatientViewModel,
  PromptFrequency,
  PromptScheduleViewModel,
  QuestionnaireViewModel,
  ScheduleType,
  ScheduleViewModel,
  StaleDuration,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import CaseloadQuestionnaireDropdowns from "./CaseloadQuestionnaireDropdowns";
import { useUnmountPromise } from "react-use";
import { EventRepeat, DateRange, LooksOne } from "@mui/icons-material";
import InfoHint from "../../elements/form-elements/InfoHint";
import { ScheduleFormSetupArchived } from "../../../HelpText";
import ArchivePromptScheduling from "./PromptScheduling/ArchivePromptScheduling";
import PromptScheduling from "./PromptScheduling/PromptScheduling";
import OneOffScheduling from "./OneOffScheduling";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { GetStringTimeOfDay } from "./PromptScheduling/AtTimeScheduling";

interface Props {
  schedule: ScheduleViewModel;
  setSchedule: Dispatch<SetStateAction<ScheduleViewModel>>;
  patient: PatientViewModel;
  editing: boolean;
  selectedQuestionnaires: QuestionnaireViewModel[];
  setSelectedQuestionnaires: Dispatch<SetStateAction<QuestionnaireViewModel[]>>;
  caseloadError?: boolean;
  questionnaireError?: boolean;
  promptSchedule?: PromptScheduleViewModel;
  setPromptSchedule: React.Dispatch<
    React.SetStateAction<PromptScheduleViewModel | undefined>
  >;
  disabledPromt: boolean;
  setCaseloadError: React.Dispatch<React.SetStateAction<boolean>>;
  setQuestionnaireError: React.Dispatch<React.SetStateAction<boolean>>;
  daysBeforeReminders: number[];
}

const CreateEditScheduleForm = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    form: { width: "100%" },
    formControl: { marginBottom: 20 },
    receivePrompts: { display: "flex" },
    multiSelect: { width: "100%" },
    hint: { marginTop: 10 },
    staleDurationToggle: {
      marginBottom: 20,
      display: "block",
    },
    scheduleType: { marginRight: "10px" },
    scheduleTypeWithText: {
      display: isMobile ? "block" : "flex",
      marginBottom: "20px",
    },
    scheduleTypeAssistText: {
      whiteSpace: "pre-line",
      fontSize: "12px",
      alignSelf: "center",
    },
  });
  const classes = useStyle(customTheme);

  const [, setRerender] = useState(false); // Force the page to rerender

  const [allQuestionnaires, setAllQuestionnaires] = useState<
    QuestionnaireViewModel[]
  >([]);

  const [allCaseloads, setAllCaseloads] = useState<CaseloadViewModel[]>([]);
  const [itemIndex, setItemIndex] = useState<number>();

  const [staleDurationDescriptions, setStaleDurationDescriptions] = useState<{
    [x: number]: string | undefined;
  }>({});

  const resolveWhileMounted = useUnmountPromise();

  useEffect(() => {
    const effect = async () => {
      await resolveWhileMounted(
        new CaseloadClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).getCaseloadsForPatient(props.patient.id, CaseloadType.Shared, false)
      ).then((c) => setAllCaseloads(c));
      await new EnumClient(Configuration.SERVER_ROOT, FetchOverride)
        .getStaleDurationDescriptions()
        .then((descriptions) => {
          setStaleDurationDescriptions(descriptions);
        });
    };
    effect();
  }, [resolveWhileMounted, setStaleDurationDescriptions, props.patient.id]);

  function getScheduleTypeAssistedText(scheduleType: ScheduleType): string {
    if (scheduleType === ScheduleType.Open) {
      return "Open Schedule \n Add questionnaires to the questionnaires page, complete as often as required.";
    } else if (scheduleType === ScheduleType.Prompted) {
      return "Prompted Schedule \n Complete once every cycle, prompt is sent as configured below.";
    } else {
      return "One-Off Schedule \n Complete a single prompted response. \n";
    }
  }
  const handleScheduleTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newScheduleType: ScheduleType
  ) => {
    if (newScheduleType == null) {
      return;
    }
    if (newScheduleType !== ScheduleType.Open) {
      props.setPromptSchedule((x) => {
        if (props.schedule.promptSchedule != null) {
          x = props.schedule.promptSchedule;
        } else {
          x = new PromptScheduleViewModel();
          x.frequency = PromptFrequency.Daily;
          if (newScheduleType === ScheduleType.OneOff) {
            x.oneTimePrompt = true;
          } else {
            x.oneTimePrompt = false;
            x.oneTimePromptResponseDueDate = undefined;
          }
        }
        return x;
      });
    } else {
      props.setPromptSchedule((x) => {
        x = undefined;
        return x;
      });
    }
    props.setSchedule((x) => {
      x.scheduleType = newScheduleType;
      return x;
    });
    setRerender((y) => !y);
  };

  const setStartDate = (startDate: Date) => {
    setRerender((x) => !x);
    props.setSchedule((x) => {
      x.startDate = startDate;
      return x;
    });
  };

  return (
    <FormControl className={classes.form}>
      {!props.editing && (
        <CaseloadQuestionnaireDropdowns
          allCaseloads={allCaseloads}
          patient={props.patient}
          caseloadId={props.schedule.caseloadId}
          itemIndex={itemIndex}
          caseloadError={props.caseloadError}
          setCaseloadId={(caseloadId, episodeId, itemIndex) => {
            setItemIndex(itemIndex);
            props.setSchedule((s) => {
              s.episodeId = episodeId;
              s.caseloadId = allCaseloads.filter(
                (x) => x.id === caseloadId
              )[0].id;

              // Get the questionnaires for the caseload
              resolveWhileMounted(
                new CaseloadClient(
                  Configuration.SERVER_ROOT,
                  FetchOverride
                ).getQuestionnairesForCaseload(caseloadId)
              ).then((questionnaires: QuestionnaireViewModel[]) => {
                setAllQuestionnaires(questionnaires);
                props.setSelectedQuestionnaires([]);
              });

              props.setCaseloadError(false);
              setRerender((x) => !x);
              return s;
            });
          }}
          selectedQuestionnaires={props.selectedQuestionnaires}
          setSelectedQuestionnaires={(value) => {
            props.setQuestionnaireError(false);
            props.setSelectedQuestionnaires(value);
          }}
          selectedQuestionnairesError={props.questionnaireError}
          allQuestionnaires={allQuestionnaires}
          caseloadType={CaseloadType.Shared}
          setPromptSchedule={props.setPromptSchedule}
          schedule={props.schedule}
        />
      )}
      <Box className={classes.scheduleTypeWithText}>
        <ToggleButtonGroup
          value={props.schedule.scheduleType ?? ScheduleType.OneOff}
          exclusive
          onChange={handleScheduleTypeChange}
          aria-label="schedule type"
          color="secondary"
          className={classes.scheduleType}
        >
          <ToggleButton
            value={ScheduleType.OneOff}
            aria-label="one-off schedule"
            disabled={
              props.editing &&
              props.schedule.scheduleType !== ScheduleType.OneOff
            }
          >
            <LooksOne />
          </ToggleButton>
          <ToggleButton
            value={ScheduleType.Open}
            aria-label="open schedule"
            disabled={
              props.editing && props.schedule.scheduleType !== ScheduleType.Open
            }
          >
            <DateRange />
          </ToggleButton>
          <ToggleButton
            value={ScheduleType.Prompted}
            aria-label="prompted schedule"
            disabled={
              props.editing &&
              props.schedule.scheduleType !== ScheduleType.Prompted
            }
          >
            <EventRepeat />
          </ToggleButton>
        </ToggleButtonGroup>
        <Typography className={classes.scheduleTypeAssistText}>
          {getScheduleTypeAssistedText(props.schedule.scheduleType)}
        </Typography>
      </Box>
      {props.schedule.scheduleType === ScheduleType.OneOff &&
        props.promptSchedule != null && (
          <OneOffScheduling
            oneTimePrompt={props.promptSchedule}
            startDate={props.schedule.startDate}
            setSendTime={(newDate) => {
              if (props.promptSchedule != null) {
                props.promptSchedule.timeOfDay = GetStringTimeOfDay(newDate);
                props.setPromptSchedule(props.promptSchedule);
                setStartDate(newDate);
              }
            }}
            setOneTimePrompt={(details) => {
              setRerender((x) => !x);
              props.setPromptSchedule(details);
            }}
            editing={props.editing}
            tenantId={props.patient.tenantId ?? 0}
            timeOfDay={
              props.promptSchedule == null
                ? undefined
                : props.promptSchedule.timeOfDay
            }
            daysBeforeReminders={props.daysBeforeReminders}
          />
        )}
      {props.schedule.scheduleType !== ScheduleType.OneOff && (
        <FormControl className={classes.formControl}>
          <DateSelect
            editing={props.editing}
            startDate={props.schedule.startDate}
            setStartDate={setStartDate}
            endDate={props.schedule.endDate}
            setEndDate={(endDate: Date) => {
              setRerender((x) => !x);
              props.setSchedule((x) => {
                x.endDate = endDate;
                return x;
              });
            }}
            indefiniteEndDate={props.schedule.continueIndefinitely || false}
            setIndefiniteEndDate={(indefinite: boolean) => {
              setRerender((x) => !x);
              props.setSchedule((x) => {
                x.continueIndefinitely = indefinite;
                return x;
              });
            }}
          />
        </FormControl>
      )}
      {props.schedule.scheduleType !== ScheduleType.OneOff &&
        props.promptSchedule != null &&
        props.setPromptSchedule !== undefined && (
          <PromptScheduling
            schedulingDetails={props.promptSchedule}
            setSchedulingDetails={(details) => {
              setRerender((x) => !x);
              props.setPromptSchedule(details);
            }}
            setRerender={setRerender}
          />
        )}
      {props.schedule.scheduleType !== ScheduleType.OneOff && (
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={
                  props.schedule.staleDuration !== StaleDuration.Never &&
                  props.schedule.staleDuration != null
                }
                color="secondary"
                onChange={(e) => {
                  if (!props.schedule.staleDuration) {
                    props.setSchedule((x) => {
                      x.staleDuration = StaleDuration.SixMonths;
                      return x;
                    });
                  } else {
                    props.setSchedule((x) => {
                      x.staleDuration = StaleDuration.Never;
                      return x;
                    });
                  }
                  setRerender((y) => !y);
                }}
              />
            }
            label="Prompt Clinician When Stale"
          />
        </FormControl>
      )}
      {props.schedule.staleDuration != null &&
        props.schedule.staleDuration !== StaleDuration.Never &&
        props.schedule.scheduleType !== ScheduleType.OneOff && (
          <>
            <ToggleButtonGroup
              value={props.schedule.staleDuration}
              exclusive
              onChange={(_, value) => {
                if (value !== null) {
                  props.setSchedule((x) => {
                    x.staleDuration = value;
                    return x;
                  });
                  setRerender((y) => !y);
                }
              }}
              aria-label="stale-duration"
              className={classes.staleDurationToggle}
            >
              {Object.keys(StaleDuration)
                .filter((x) => isNaN(Number(x)) && x !== StaleDuration[0])
                .map((x) => (
                  <ToggleButton
                    key={x}
                    value={StaleDuration[x as keyof typeof StaleDuration]}
                    aria-label={x}
                  >
                    {staleDurationDescriptions === undefined
                      ? x
                      : staleDurationDescriptions[x as unknown as number] ===
                        null
                      ? x
                      : staleDurationDescriptions[x as unknown as number]}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          </>
        )}
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={props.schedule.sendNotificationOnSubmission ?? false}
              color="secondary"
              onChange={(e) => {
                props.setSchedule((x) => {
                  x.sendNotificationOnSubmission =
                    !x.sendNotificationOnSubmission;
                  return x;
                });
                setRerender((y) => !y);
              }}
            />
          }
          label="Prompt Clinician On Submission"
        />
      </FormControl>
      {props.promptSchedule != null &&
        props.schedule.scheduleType === ScheduleType.Prompted && (
          <FormControl className={classes.formControl}>
            <div className={classes.receivePrompts}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      props.promptSchedule.archivesAfter !== undefined &&
                      props.promptSchedule.archivesAfter !== null
                    }
                    color="secondary"
                    onChange={() =>
                      props.setPromptSchedule((x) => {
                        if (x === undefined) {
                          x = new PromptScheduleViewModel();
                        }
                        x.archiveCountType =
                          x.archiveCountType !== undefined
                            ? undefined
                            : ArchiveCountType.TotalMissedResponses;
                        x.archivesAfter =
                          x.archivesAfter !== undefined ? undefined : 1;

                        setRerender((y) => !y);
                        return x;
                      })
                    }
                  />
                }
                label="Setup Prompt Archiving"
              />
              <InfoHint
                helpText={ScheduleFormSetupArchived}
                className={classes.hint}
              />
            </div>
            {props.promptSchedule.archivesAfter !== undefined &&
              props.promptSchedule.archivesAfter !== null && (
                <ArchivePromptScheduling
                  archivesAfter={props.promptSchedule.archivesAfter}
                  setArchivesAfter={(archivesAfter) =>
                    props.setPromptSchedule((x) => {
                      if (x === undefined) {
                        x = new PromptScheduleViewModel();
                      }
                      x.archivesAfter = archivesAfter;
                      setRerender((x) => !x);
                      return x;
                    })
                  }
                  archiveCountType={props.promptSchedule.archiveCountType}
                  setArchiveCountType={(archiveCountType) =>
                    props.setPromptSchedule((x) => {
                      if (x === undefined) {
                        x = new PromptScheduleViewModel();
                      }
                      x.archiveCountType = archiveCountType;
                      setRerender((x) => !x);
                      return x;
                    })
                  }
                />
              )}
          </FormControl>
        )}
    </FormControl>
  );
};

export default CreateEditScheduleForm;
