import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import {
  Edit,
  Delete,
  ContentCopy,
  Add,
  Mediation,
  WarningAmber,
} from "@mui/icons-material";
import DownloadingIcon from "@mui/icons-material/Downloading";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";
import { QuestionDataModel } from "./QuestionnaireBuilderDataModelHelper";
import { QuestionType } from "../../../../types/auto/types";

interface Props {
  question: QuestionDataModel;
  handleEditQuestion: (question: QuestionDataModel) => void;
  handleDuplicateQuestion: (question: QuestionDataModel) => void;
  handleDeleteQuestion: (qNum: number) => void;
  handleAddAnswer: (qNum: number) => void;
  handleDependsOn: (q: QuestionDataModel) => void;
  handleAutofillFrom: (q: QuestionDataModel) => void;
  handleDefaultOverride: (q: QuestionDataModel) => void;
}
const QuestionActions = (props: Props): JSX.Element => {
  const [anchorActionList, setAnchorActionList] =
    React.useState<null | HTMLElement>(null);

  const useStyle = makeStyles(() => ({
    vert: {
      marginLeft: "auto",
      padding: "0",
      minWidth: "24px",
    },
  }));
  const classes = useStyle();
  const handleClose = () => {
    setAnchorActionList(null);
  };

  return (
    <>
      <Button
        onClick={(event) =>
          setAnchorActionList((val) =>
            val === null ? event.currentTarget : null
          )
        }
        aria-label="Open Questionnaire Action List"
        className={classes.vert}
        key={"question-actions-" + props.question.number}
        id={"al"}
      >
        <MoreVertIcon
          key={"question-actions-more-vert-icon-" + props.question.number}
        />
        <Menu
          open={anchorActionList !== null}
          anchorEl={anchorActionList}
          aria-label="Question Action List"
          onClose={handleClose}
          key={"question-actions-menu-" + props.question.number}
          id={"question-actions-menu-" + props.question.number}
        >
          {(((props.question.questionType === QuestionType.Textbox ||
            props.question.questionType === QuestionType.Number ||
            props.question.questionType === QuestionType.Range ||
            props.question.questionType === QuestionType.SkippableRange ||
            props.question.questionType === QuestionType.Date ||
            props.question.questionType === QuestionType.CheckBoxSingle) &&
            props.question.answers.length < 1) ||
            ((props.question.questionType === QuestionType.Buttons ||
              props.question.questionType === QuestionType.ButtonsMulti ||
              props.question.questionType === QuestionType.Table ||
              props.question.questionType === QuestionType.MultiSelectorTable ||
              props.question.questionType === QuestionType.DropDown ||
              props.question.questionType === QuestionType.CheckBoxList) &&
              props.question.answers.length <
                (props.question.questionType === QuestionType.DropDown
                  ? 30
                  : 15))) && (
            <MenuItem
              aria-label={
                props.question.questionType === QuestionType.Table ||
                props.question.questionType === QuestionType.MultiSelectorTable
                  ? "Add Column"
                  : "Add Answer"
              }
              onClick={() => {
                props.handleAddAnswer(props.question.number!);
              }}
              key={"question-actions-menu-add-answer-" + props.question.number}
              id={"question-actions-menu-add-answer-" + props.question.number}
            >
              <Add />
              {props.question.questionType === QuestionType.Table ||
              props.question.questionType === QuestionType.MultiSelectorTable
                ? "Add Column"
                : "Add Answer"}
            </MenuItem>
          )}
          <MenuItem
            aria-label="Edit Question"
            onClick={() => {
              props.handleEditQuestion(props.question);
            }}
            key={"question-actions-menu-edit-" + props.question.number}
            id={"question-actions-menu-edit-" + props.question.number}
          >
            <Edit />
            Edit Question
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.handleDuplicateQuestion(props.question);
            }}
            aria-label="Duplicate Question"
            key={"question-actions-menu-duplicate-" + props.question.number}
            id={"question-actions-menu-duplicate-" + props.question.number}
          >
            <ContentCopy />
            Duplicate Question
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.handleDeleteQuestion(props.question.number!);
            }}
            aria-label="Delete Question"
            key={"question-actions-menu-delete-" + props.question.number}
            id={"question-actions-menu-delete-" + props.question.number}
          >
            <Delete />
            Delete Question
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.handleDependsOn(props.question);
            }}
            aria-label="Edit Depends On"
            key={"question-actions-menu-dependsOn-" + props.question.number}
            id={"question-actions-menu-dependsOn-" + props.question.number}
          >
            <Mediation key={"dependsOn-icon"} />
            Edit Depends On
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.handleAutofillFrom(props.question);
            }}
            aria-label="Edit Autofill From"
            key={"question-actions-menu-autofillFrom-" + props.question.number}
            id={"question-actions-menu-autofillFrom-" + props.question.number}
          >
            <DownloadingIcon />
            Edit Autofill From
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.handleDefaultOverride(props.question);
            }}
            aria-label="Default Value Override"
            key={
              "question-actions-menu-defaultvalueoverride-" +
              props.question.number
            }
            id={
              "question-actions-menu-defaultvalueoverride-" +
              props.question.number
            }
          >
            <WarningAmber />
            Add Default Value Override
          </MenuItem>
        </Menu>
      </Button>
    </>
  );
};

export default QuestionActions;
