import React, { useEffect } from "react";
import {
  CaseloadClient,
  CaseloadViewModel,
  QuestionnaireClient,
  QuestionnaireViewModel,
} from "../../../types/auto/types";
import MultiSelectChip from "../elements/form-elements/MultiSelectChip";
import {
  Box,
  Button,
  Container,
  Modal,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useUnmountPromise } from "react-use";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { LoadingButton } from "@mui/lab";
import { Error400 } from "../elements/errors/Error400";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  caseload: CaseloadViewModel | undefined;
  refresh: () => void;
}

const EditCaseloadQuestionnairesModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "80%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles((th: Theme) => ({
    textFieldContainer: {
      margin: 0,
      padding: 5,
      gap: 10,
      width: "100%",
      marginBottom: 15,
      display: "flex",
      flexDirection: "column",
    },
    actionButtonGroup: {
      marginTop: 20,
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      justifyContent: isMobile ? "center" : "end",
      gap: isMobile ? "4px" : "8px",
    },
    subHeader: {
      fontSize: 20,
      marginBottom: 10,
    },
    outerContainer: {
      backgroundColor: "white",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      paddingBottom: "10px",
    },
    questionnaireSelector: {
      width: "100%",
    },
  }));
  const classes = useStyle();
  const [questionnaires, setQuestionnaires] = React.useState<
    QuestionnaireViewModel[]
  >([]);
  const [selectedQuestionnaires, setSelectedQuestionnaires] = React.useState<
    QuestionnaireViewModel[]
  >([]);
  const resolveWhileMounted = useUnmountPromise();
  const [loading, setLoading] = React.useState(false);
  const [errorUpdatingQuestionnaires, setErrorUpdatingQuestionnaires] =
    React.useState(false);

  function onClose() {
    props.setOpen(false);
    setErrorUpdatingQuestionnaires(false);
    setLoading(false);
  }

  useEffect(() => {
    if (props.open) {
      const effect = async () => {
        await resolveWhileMounted(
          new QuestionnaireClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getAllQuestionnaires(true)
        ).then((questionnaireData: QuestionnaireViewModel[]) => {
          setQuestionnaires(questionnaireData);
        });
      };
      effect();
    }
  }, [props.caseload, resolveWhileMounted, props.open]);

  useEffect(() => {
    if (props.open) {
      const effect = async () => {
        if (props.caseload !== undefined) {
          new CaseloadClient(Configuration.SERVER_ROOT, FetchOverride)
            .getAllQuestionnairesLinkedToSharedCaseload(props.caseload.id)
            .then((questionnaireData: QuestionnaireViewModel[]) => {
              setSelectedQuestionnaires(questionnaireData);
            });
        }
      };
      effect();
    }
  }, [props.caseload, props.open]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h5" component="h5" className={classes.subHeader}>
          Edit Questionnaires
        </Typography>
        <Container className={classes.textFieldContainer}>
          <MultiSelectChip
            label={"Questionnaires"}
            options={questionnaires}
            selectedOptions={selectedQuestionnaires}
            setSelectedOptions={setSelectedQuestionnaires}
            className={classes.questionnaireSelector}
          />
        </Container>
        <div className={classes.actionButtonGroup}>
          <Button onClick={() => onClose()} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              if (props.caseload !== undefined) {
                new CaseloadClient(Configuration.SERVER_ROOT, FetchOverride)
                  .assignQuestionnairesToCaseload(
                    props.caseload.id,
                    selectedQuestionnaires.map((x) => x.id)
                  )
                  .then(() => {
                    onClose();
                  })
                  .catch(() => {
                    setLoading(false);
                    setErrorUpdatingQuestionnaires(true);
                  });
              }
            }}
          >
            {"Save Changes"}
          </LoadingButton>
        </div>
        {errorUpdatingQuestionnaires && <Error400 />}
      </Box>
    </Modal>
  );
};

export default EditCaseloadQuestionnairesModal;
