import { Typography } from "@mui/material";
import {
  AnswerViewModel,
  GoalViewModel,
  QuestionnaireResponseViewModel,
  QuestionType,
  QuestionViewModel,
} from "../../types/auto/types";
import MultiSelectorTableResponse from "../components/elements/table/MultiSelectorTableResponse";
import GenericTable from "../components/elements/table/GenericTable";

const loadMultiAnswer = (
  questionnaireResponse: QuestionnaireResponseViewModel[],
  question: QuestionViewModel
) => {
  var output = questionnaireResponse
    ?.find((response) => response.number === question.number)
    ?.answer?.split(",")
    .map((x) => {
      return question.answers!.find((y) => y.number === Number(x))?.text;
    });
  return output?.toString();
};

type TableData = { [key: string]: string | number };

export function DisplayQuestionResponse(
  question: QuestionViewModel,
  questionnaireResponse: QuestionnaireResponseViewModel[],
  questionnaireGoals: GoalViewModel[],
  tableId: string
): JSX.Element | JSX.Element[] {
  return question.questionType === QuestionType.Buttons ? (
    <>
      {
        question.answers!.find((answer) => {
          return (
            answer.number?.toString() ===
            questionnaireResponse?.find(
              (response) => response.number === question.number
            )?.answer
          );
        })?.text
      }
    </>
  ) : question.questionType === QuestionType.ButtonsMulti ? (
    <>{loadMultiAnswer(questionnaireResponse, question)}</>
  ) : question.questionType === QuestionType.Sketch ? (
    <>
      <img
        src={
          questionnaireResponse?.find(
            (response) => response.number === question.number
          )?.answer
        }
        alt="Sketched user input"
      ></img>
    </>
  ) : question.questionType === QuestionType.Table ? (
    <GenericTable
      center={true}
      id={tableId}
      head={question.answers?.map((a: AnswerViewModel) => a.text ?? "") ?? []}
      rows={JSON.parse(
        questionnaireResponse?.find(
          (response) => response.number === question.number
        )?.answer ?? "[]"
      ).map((x: TableData, indexX: number) =>
        Object.keys(x)
          .filter((m) => m !== "row")
          .map((y, indexY) =>
            question.rows && indexY === 0 ? (
              question.rows?.at(indexX) === "Other" ? (
                <>{JSON.stringify(x[y])}</>
              ) : (
                question.rows?.at(indexX)
              )
            ) : (
              <>{JSON.stringify(x[y])}</>
            )
          )
      )}
    />
  ) : question.questionType === QuestionType.MultiSelectorTable ? (
    <MultiSelectorTableResponse
      question={question}
      answers={question.answers}
      response={
        questionnaireResponse?.find(
          (response) => response.number === question.number
        )?.answer ?? "[]"
      }
    />
  ) : question.questionType === QuestionType.SkippableRange ? (
    questionnaireResponse?.find(
      (response) => response.number === question.number
    )?.answer === "-1" ? (
      <>Skipped</>
    ) : (
      <>
        {
          questionnaireResponse?.find(
            (response) => response.number === question.number
          )?.answer
        }
      </>
    )
  ) : question.questionType === QuestionType.Goals ? (
    questionnaireGoals.map((goal) => <Typography>{goal.question}</Typography>)
  ) : question.questionType === QuestionType.ReadOnly ? (
    <></>
  ) : (
    <>
      {
        questionnaireResponse?.find(
          (response) => response.number === question.number
        )?.answer
      }
    </>
  );
}
