import Grid from "@mui/material/Unstable_Grid2";
import {
  PatientViewModel,
  PatientTimelineDataViewModel,
  UserViewModel,
  TimelineCardType,
} from "../../../../types/auto/types";
import { ResponseCard } from "./TimelineCards/ResponseCard";
import { MedicationTimelineCard } from "./TimelineCards/MedicationTimelineCard";
import { DatasetTimelineCard } from "./TimelineCards/DatasetTimelineCard";

interface Props {
  timelineEntrees: PatientTimelineDataViewModel[];
  patient: PatientViewModel;
  user: UserViewModel;
  canEditResponses: boolean;
  refreshTimeline: () => void;
  currentTimelineLength: number;
  totalTimelineLength: number;
  totalTimelineLengthFiltered: number;
  setCurrentTimelineLength: React.Dispatch<React.SetStateAction<number>>;
  setTotalTimelineLengthFiltered: React.Dispatch<React.SetStateAction<number>>;
  setTotalTimelineLength: React.Dispatch<React.SetStateAction<number>>;
  setHiddenArrayIndex: () => void;
  questionnairesWithPrintingTemplateEnabled: number[];
}
export const TimelineGrid = (props: Props): JSX.Element => {
  return (
    <Grid aria-label="Timeline grid">
      {props.timelineEntrees.map((timelineEntree, index) => {
        if (
          timelineEntree.type === TimelineCardType.Goal ||
          timelineEntree.type === TimelineCardType.Questionnaire
        ) {
          return (
            <ResponseCard
              timelineEntree={timelineEntree}
              index={index}
              key={"timeline component" + index}
              patient={props.patient}
              user={props.user}
              canEditResponses={props.canEditResponses}
              refreshTimeline={props.refreshTimeline}
              currentTimelineLength={props.currentTimelineLength}
              setCurrentTimelineLength={props.setCurrentTimelineLength}
              totalTimelineLength={props.totalTimelineLength}
              setTotalTimelineLength={props.setTotalTimelineLength}
              totalTimelineLengthFiltered={props.totalTimelineLengthFiltered}
              setTotalTimelineLengthFiltered={
                props.setTotalTimelineLengthFiltered
              }
              setHiddenArrayIndex={props.setHiddenArrayIndex}
              isLastItem={props.timelineEntrees.length === 1}
              questionnairesWithPrintingTemplateEnabled={
                props.questionnairesWithPrintingTemplateEnabled
              }
            />
          );
        } else if (timelineEntree.type === TimelineCardType.Medication) {
          return (
            <MedicationTimelineCard
              timelineEntree={timelineEntree}
              index={index}
              key={"timeline component" + index}
              patient={props.patient}
              user={props.user}
              currentTimelineLength={props.currentTimelineLength}
              totalTimelineLength={props.totalTimelineLength}
              totalTimelineLengthFiltered={props.totalTimelineLengthFiltered}
              setHiddenArrayIndex={props.setHiddenArrayIndex}
              isLastItem={props.timelineEntrees.length === 1}
            />
          );
        } else if (timelineEntree.type === TimelineCardType.Dataset) {
          return (
            <DatasetTimelineCard
              timelineEntree={timelineEntree}
              index={index}
              key={"timeline component" + index}
              patient={props.patient}
              user={props.user}
              currentTimelineLength={props.currentTimelineLength}
              totalTimelineLength={props.totalTimelineLength}
              totalTimelineLengthFiltered={props.totalTimelineLengthFiltered}
              setHiddenArrayIndex={props.setHiddenArrayIndex}
              isLastItem={props.timelineEntrees.length === 1}
            />
          );
        } else {
          return <></>;
        }
      })}
    </Grid>
  );
};
