import { Box, Button, Modal, Typography, Theme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { theme } from "../../../Theme";
import { PatientMedicationClient } from "../../../types/auto/types";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import PatientUploadDropzone from "../patient/create-patient/PatientUploadDropzone";
import BatchUploadErrors from "../patient/create-patient/BatchUploadErrors";

interface Props {
  open: boolean;
  closeModal: () => void;
  onSuccess?: (response: string) => void;
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  border: "none",
};

const BatchUploadMedicationModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles((th: Theme) => ({
    header: {
      marginBottom: 5,
    },
    subHeader: {
      fontSize: 20,
      marginBottom: 0,
      marginTop: 20,
    },
    text: {
      margin: 0,
      padding: 0,
      color: th.palette.grey[600],
    },
    textLine2: {
      marginBottom: 20,
      color: th.palette.grey[600],
    },
    actionButtonGroup: {
      display: "flex",
      justifyContent: "end",
      marginTop: 20,
      gap: "8px",
    },
  }));

  const classes = useStyle(theme);
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState<Blob>();
  const [uploadFileError, setUploadFileError] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<{
    [line: string]: string[];
  }>();

  // Clear uploaded file on open
  useEffect(() => {
    setUploadFile(undefined);
    setUploadErrors(undefined);
  }, [props.open]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          Batch Upload Medication
        </Typography>
        <Typography className={classes.text}>
          Please upload a csv containing the medication that you wish to upload.
        </Typography>
        <Typography className={classes.textLine2}>
          You can download the csv template{" "}
          <a
            href={process.env.PUBLIC_URL + "/TemplateBatchMedicationUpload.csv"}
            download="Template Batch Medication Upload.csv"
          >
            here
          </a>
          .
        </Typography>
        <PatientUploadDropzone
          setFile={setUploadFile}
          noFileError={uploadFileError}
          setNoFileError={setUploadFileError}
          invalidDataError={uploadErrors !== undefined}
        />

        <BatchUploadErrors errors={uploadErrors} />

        <div className={classes.actionButtonGroup}>
          <Button onClick={() => props.closeModal()} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={() => {
              if (uploadFile === undefined) {
                setUploadFileError(true);
                return;
              }

              setUploadFileError(false);
              setLoading(true);

              new PatientMedicationClient(
                Configuration.SERVER_ROOT,
                FetchOverride
              )
                .batchCreateMedication({
                  data: uploadFile,
                  fileName: "uploadedFile.csv",
                })
                .then((patientMedications) => {
                  if (props.onSuccess) {
                    props.onSuccess(
                      patientMedications + " medications added to patients."
                    );
                  }

                  props.closeModal();
                })
                .catch(setUploadErrors)
                .finally(() => setLoading(false));
            }}
          >
            Upload Medication
          </LoadingButton>
        </div>
      </Box>
    </Modal>
  );
};

export default BatchUploadMedicationModal;
