import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext, useEffect, useState } from "react";
import {
  IdentityClient,
  Role,
  TenantClient,
  TenantViewModel,
  UserViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { UserContext, UserDispatchContext } from "../stores/UserStore";
import PageTitle from "../page/PageTitle";
import NavigationBar from "./NavigationBar";
import LogoBar from "./LogoBar";
import Footer from "../page/Footer";
import { useUnmountPromise } from "react-use";
import TimeoutComponent from "../page/TimeoutComponent";
import Unauthorized from "../../../views/Unauthorized";

interface IProps {
  children?: JSX.Element;
  title: string;
  requiredRoles?: Role[];
  contextual?: boolean;
}

const styles = makeStyles({
  gridContainer: {
    display: "grid",
    gridTemplateAreas: `
      "header"
      "main_content"
      "footer"
    `,
    gridTemplateRows: "auto 1fr auto",
    gridTemplateColumns: "100%",
    position: "relative",
    minHeight: "100vh",
    "@media print": {
      backgroundColor: "#EAE8E1",
      WebkitPrintColorAdjust: "exact",
    },
  },
  header: {
    gridArea: "header",
    "@media print": { display: "none" },
  },
  mainContent: {
    gridArea: "main_content",
    paddingBottom: "2.5rem",
    printColorAdjust: "exact",
    "@media print": {
      backgroundColor: "#EAE8E1",
      WebkitPrintColorAdjust: "exact",
      paddingBottom: 0,
    },
  },
  titleHeader: {
    fontSize: 0, // Add a header with no font size to improve readability for screen readers without interfering with the styling of the page
    "@media print": { display: "none" },
  },
  footer: {
    gridArea: "footer",
    textAlign: "center",
    position: "absolute",
    bottom: "0",
    width: "100%",
    height: "2.5rem",
    "@media print": { display: "none" },
  },
});

const AuthedRoute = ({
  children,
  title,
  requiredRoles,
  contextual,
}: IProps): React.ReactElement => {
  const classes = styles();
  const dispatch = useContext(UserDispatchContext);
  const user = useContext(UserContext) as UserViewModel;
  const [auth, setAuth] = useState(false);
  const [tenant, setTenant] = useState<TenantViewModel>();
  const resolveWhileMounted = useUnmountPromise();

  useEffect(() => {
    const effect = async () => {
      if (!auth) {
        await resolveWhileMounted(
          new IdentityClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).isAuthed()
        )
          .then((usr: UserViewModel) => {
            if (usr.email != null || usr.patientLoginDisabled === true) {
              dispatch(usr);
              setAuth(true);
            } else {
              window.location.href = new IdentityClient(
                Configuration.SERVER_ROOT,
                FetchOverride
              ).getLogoutURL();
            }
          })
          .catch((error) => console.log(error));
      } else {
        new TenantClient(Configuration.SERVER_ROOT, FetchOverride)
          .getTenant(undefined)
          .then((tenancy) => {
            setTenant(tenancy);
          });
      }
    };
    effect();
  }, [auth, dispatch, user, resolveWhileMounted]);

  // If the route requires a role and the user's role isn't that role, return
  if (requiredRoles && user.role && !requiredRoles.includes(user.role)) {
    return <Unauthorized />;
  }

  if (!auth) {
    return <></>;
  } else {
    return (
      <PageTitle title={title}>
        <Container className={classes.gridContainer}>
          {!contextual && (
            <div className={classes.header}>
              <LogoBar tenant={tenant ?? new TenantViewModel()} />
              <NavigationBar />
            </div>
          )}
          <h1 className={classes.titleHeader}>{title}</h1>
          <div className={classes.mainContent}>{children}</div>
          <Footer
            className={classes.footer}
            footer={tenant?.tenantOptions?.footer ?? ""}
          />
          <TimeoutComponent />
        </Container>
      </PageTitle>
    );
  }
};

export default AuthedRoute;
