import { Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MeasurementViewModel } from "../../../../types/auto/types";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import CreateEditMeasurement from "./CreateEditMeasurement";

interface Props {
  measurement: MeasurementViewModel;
  datasetId: number;
  resetMeasurements: () => void;
}
const MeasurementCard = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    cardContent: {
      display: isMobile ? "block" : "flex",
      padding: "6px",
      paddingLeft: "12px",
      "&:last-child": {
        paddingBottom: 5,
      },
    },
  });
  const classes = useStyle();
  return (
    <Card variant="outlined" elevation={0}>
      <CardContent className={classes.cardContent}>
        <Typography>
          {props.measurement.name}{" "}
          {props.measurement.maxMeasurement !== null &&
          props.measurement.maxMeasurement !== undefined
            ? "(" +
              props.measurement.minMeasurement +
              " to " +
              props.measurement.maxMeasurement +
              ")"
            : ""}
        </Typography>
        <CreateEditMeasurement
          measurement={props.measurement}
          datasetId={props.datasetId}
          resetMeasurements={props.resetMeasurements}
        />
      </CardContent>
    </Card>
  );
};

export default MeasurementCard;
