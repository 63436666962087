import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { theme } from "../../../../Theme";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import StaffDetailsForm from "./CreateEditStaffForm";
import {
  IsNameValid,
  IsEmailValid,
} from "../../../utils/UserDetailsValidation";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import {
  ErrorType,
  IStaffViewModel,
  Role,
  StaffClient,
  StaffViewModel,
} from "../../../../types/auto/types";

interface Props {
  open: boolean;
  closeModal: () => void;
  staff?: StaffViewModel;
  onSave?: () => void;
  setReRenderView?: React.Dispatch<React.SetStateAction<boolean>>;
  role: Role;
}

export const CreateEditStaffCall = (
  isCreate: boolean,
  staff: StaffViewModel,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setFirstNameError: React.Dispatch<React.SetStateAction<boolean>>,
  setLastNameError: React.Dispatch<React.SetStateAction<boolean>>,
  setEmailError: React.Dispatch<React.SetStateAction<boolean>>,
  closeModal: () => void,
  onSave: (() => void) | undefined,
  role: Role,
  setError: React.Dispatch<React.SetStateAction<ErrorType | undefined>>,
  setReRenderView?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading(true);

  const isFirstNameValid = IsNameValid(staff.firstName);
  const isLastNameValid = IsNameValid(staff.lastName);
  const isEmailValid = IsEmailValid(staff.contactEmail);

  setFirstNameError(!isFirstNameValid);
  setLastNameError(!isLastNameValid);
  setEmailError(!isEmailValid);

  if (!(isFirstNameValid && isLastNameValid && isEmailValid)) {
    setLoading(false);
    return;
  }

  if (isCreate) {
    new StaffClient(Configuration.SERVER_ROOT, FetchOverride)
      .createStaff(staff, role)
      .then(() => {
        onSave && onSave();
        closeModal();
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => setLoading(false));
  } else {
    new StaffClient(Configuration.SERVER_ROOT, FetchOverride)
      .updateDetails(staff)
      .then(() => {
        onSave && onSave();
        closeModal();
      })
      .finally(() => setLoading(false));
  }

  setReRenderView && setReRenderView((x) => !x);
};

const CreateEditStaffModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "60%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    border: "none",
    paddingBottom: 2,
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      display: "flex",
      justifyContent: "end",
      gap: "8px",
    },
    error: {
      marginTop: "10px",
    },
  });
  const classes = useStyle(theme);
  const [staff, setStaff] = useState(
    new StaffViewModel({
      firstName: "",
      lastName: "",
      email: "",
    } as IStaffViewModel)
  );
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState<ErrorType | undefined>(undefined);
  const [, setReRender] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const creating = !props.staff;
  const errorText =
    error !== undefined
      ? error === ErrorType.OIDCInvite
        ? "Failed to send invite, please contact support team"
        : error === ErrorType.InvalidModel
        ? "Invalid staff details provided."
        : error === ErrorType.ExisitingEmail
        ? "A user with this email address already exists. Please use a different email."
        : "System error please contact support team"
      : "";

  useEffect(() => {
    if (creating) {
      setStaff({
        firstName: "",
        lastName: "",
        email: "",
      } as StaffViewModel);
    } else {
      setStaff(props.staff!);
    }
    setReRender((x) => !x);
  }, [creating, props.staff, props.open]);

  const role =
    props.role === Role.ReadOnly
      ? "Read Only"
      : props.role === Role.ReadOnlyPIRedacted
      ? "Read Only PI Redacted"
      : Role[props.role];

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          {creating ? "Create " + role : "Save " + role}
        </Typography>

        <StaffDetailsForm
          staff={staff}
          setStaff={setStaff}
          firstNameError={firstNameError}
          setFirstNameError={setFirstNameError}
          lastNameError={lastNameError}
          setLastNameError={setLastNameError}
          emailError={emailError}
          setEmailError={setEmailError}
          setReRender={setReRender}
        />
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              setError(undefined);
              props.closeModal();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () =>
              CreateEditStaffCall(
                props.staff === undefined,
                staff,
                setLoading,
                setFirstNameError,
                setLastNameError,
                setEmailError,
                () => {
                  props.closeModal();
                  setError(undefined);
                },
                props.onSave,
                props.role,
                setError,
                props.setReRenderView
              )
            }
          >
            {creating ? "Create " + role : "Save Changes"}
          </LoadingButton>
        </div>
        {error !== undefined && (
          <Alert severity="error" className={classes.error}>
            {errorText}
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default CreateEditStaffModal;
