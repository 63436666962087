import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

interface Props {
  open: boolean;
  handleContinue: () => void;
  handleCancel: () => void;
}

function DeleteGoalsPrompt(props: Props) {
  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="warning-dialog-title"
        aria-describedby="warning-dialog-description"
      >
        <DialogTitle id="warning-dialog-title">{"Discard Goals?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="warning-dialog-description">
            This response contains goals that will be deleted if you proceed. Do
            you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleCancel()} variant="outlined">
            Cancel
          </Button>
          <Button onClick={() => props.handleContinue()} variant="contained">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default DeleteGoalsPrompt;
