import {
  Role,
  TenantViewModel,
  UserClient,
} from "../../../../types/auto/types";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  closeModal: () => void;
  userTenancies: TenantViewModel[];
  currentTenancy: number;
  setCurrentTenancy: (tenancy: number) => void;
  userRole?: Role;
}

const ChangeTenancyModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "80%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      display: "flex",
      justifyContent: "end",
      marginTop: 20,
      gap: "8px",
    },
    error: {
      marginTop: "10px",
    },
    formControl: {
      width: "100%",
    },
  });
  const classes = useStyle(theme);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(false);
  const [selectedTenantId, setSelectedTenantId] = useState<number | undefined>(
    undefined
  );
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedTenantId(props.currentTenancy);
  }, [props.currentTenancy]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h5" className={classes.header}>
          Change Tenancy
        </Typography>
        <FormControl className={classes.formControl}>
          <InputLabel id="tenancy">Tenancy</InputLabel>
          <Select
            aria-label="Current User Tenancy"
            variant="outlined"
            labelId="tenancy"
            label="Tenancy"
            value={selectedTenantId}
            onChange={(event) =>
              setSelectedTenantId(event.target.value as number)
            }
            error={error}
          >
            {props.userTenancies
              .sort((a, b) =>
                (a.name?.toLowerCase() ?? "") < (b.name?.toLowerCase() ?? "")
                  ? -1
                  : 1
              )
              .filter((tenancy) => {
                if (
                  tenancy.testTenancy &&
                  props.userRole !== Role.Admin &&
                  props.userRole !== Role.SuperAdmin
                ) {
                  return false;
                } else {
                  return true;
                }
              })
              .map((val, index) => (
                <MenuItem key={index} value={val.id} role="menuitem">
                  {val.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              setRefresh(!refresh);
              props.closeModal();
              setError(false);
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={error}
            onClick={() => {
              setLoading(true);
              if (selectedTenantId !== undefined) {
                new UserClient(Configuration.SERVER_ROOT, FetchOverride)
                  .setCurrentTenancy(selectedTenantId)
                  .then(() => {
                    setLoading(false);
                    props.closeModal();
                  })
                  .then(() => {
                    navigate("/search");
                    navigate(0);
                  })
                  .catch((e) => {
                    setError(e);
                    setLoading(false);
                  });
              }
            }}
          >
            {"Save changes"}
          </LoadingButton>
        </div>
        {error && (
          <Alert severity="error" className={classes.error}>
            An error occured
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default ChangeTenancyModal;
