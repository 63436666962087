import { Container, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { MedicationViewModel } from "../../../types/auto/types";
import {
  IsDosageValid,
  IsNameValid,
  IsUnitsValid,
} from "./CreateEditMedicationModalHelper";

interface Props {
  medication: MedicationViewModel;
  setMedication: React.Dispatch<React.SetStateAction<MedicationViewModel>>;
  setReRender: React.Dispatch<React.SetStateAction<boolean>>;
  dosageError: boolean;
  nameError: boolean;
  unitsError: boolean;
  setNameError: React.Dispatch<React.SetStateAction<boolean>>;
  setDosageError: React.Dispatch<React.SetStateAction<boolean>>;
  setUnitsError: React.Dispatch<React.SetStateAction<boolean>>;
}
const MedicationDetailsForm = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    textFieldContainer: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      gap: 10,
      width: "100%",
      marginBottom: 15,
    },
    textField: {
      width: "100%",
    },
  });
  const classes = useStyle();

  return (
    <Container className={classes.textFieldContainer}>
      <TextField
        label="Name"
        className={classes.textField}
        required
        value={props.medication.name === undefined ? "" : props.medication.name}
        onChange={(e) => {
          props.setMedication((x) => {
            x.name = e.target.value;
            props.setReRender((x) => !x);
            return x;
          });
          props.setNameError(!IsNameValid(e.target.value));
        }}
        error={props.nameError}
        helperText={
          props.nameError && "Name must be between 1 and 20 characters long"
        }
      />
      <TextField
        label="Units"
        className={classes.textField}
        required
        value={
          props.medication.units === undefined ? "" : props.medication.units
        }
        onChange={(e) => {
          props.setMedication((x) => {
            x.units = e.target.value;
            props.setReRender((x) => !x);
            return x;
          });
          props.setUnitsError(!IsUnitsValid(e.target.value));
        }}
        error={props.unitsError}
        helperText={
          props.unitsError && "Units must be between 1 and 5 characters long"
        }
      />
      <TextField
        label="Min Dose"
        className={classes.textField}
        required
        value={
          props.medication.minDosage === undefined
            ? "0"
            : props.medication.minDosage
        }
        type="number"
        onChange={(e) => {
          var minDosage = e.target.value === "" ? "0" : e.target.value;
          props.setMedication((x) => {
            x.minDosage = Number.parseInt(minDosage);
            props.setReRender((x) => !x);
            return x;
          });
          props.setDosageError(
            !IsDosageValid(
              Number.parseInt(minDosage),
              props.medication.maxDosage ?? 0
            )
          );
        }}
        error={props.dosageError}
        helperText={
          props.dosageError &&
          "Min dosage must be less than max dosage and a positive number"
        }
      />
      <TextField
        label="Max Dose"
        className={classes.textField}
        required
        type="number"
        value={
          props.medication.maxDosage === undefined
            ? "0"
            : props.medication.maxDosage
        }
        onChange={(e) => {
          var maxDosage = e.target.value === "" ? "0" : e.target.value;
          props.setMedication((x) => {
            x.maxDosage = Number.parseInt(maxDosage);
            props.setReRender((x) => !x);
            return x;
          });
          props.setDosageError(
            !IsDosageValid(
              props.medication.minDosage ?? 0,
              Number.parseInt(maxDosage)
            )
          );
        }}
        error={props.dosageError}
        helperText={
          props.dosageError &&
          "Max dosage must be greater than min dosage and between 1 and 10000"
        }
      />
    </Container>
  );
};

export default MedicationDetailsForm;
