import {
  Alert,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import {
  QuestionnaireBuilderDataModel,
  SectionDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import nameof from "../../../utils/NameOf";
import { theme } from "../../../../Theme";
import { SplitButton } from "../../elements/button/SplitButton";

interface Props {
  questionnaire: QuestionnaireBuilderDataModel;
  setQuestionnaireWIP: (field: string, value: string) => void;
  navigateSection: (
    currentSection: number,
    addSectionDirection: boolean | null
  ) => void;
  section: number;
  sections: SectionDataModel[] | undefined;
  keepAlive: () => void;
}

const HeaderBuilder = (props: Props): JSX.Element => {
  const [version] = useState<string>(
    props.questionnaire.version
      ? "Version " + props.questionnaire.version
      : "Version 1"
  );

  const useStyle = makeStyles({
    titles: {
      marginTop: "10px",
    },
    textarea: {
      backgroundColor: theme.palette.background.default,
    },
    tooltip: {
      width: "40px",
      height: "40px",
    },
    errorText: {
      color: "red",
      fontWeight: "bold",
    },
    sectionSelect: {
      backgroundColor: theme.palette.background.default,
    },
    description: {
      width: "calc(100% - 48px)",
    },
    beforeAfterSectionButton: {
      backgroundColor: theme.palette.background.default,
    },
  });
  const classes = useStyle();

  const nameField = "name-field";
  const titleField = "title-field";
  const descField = "description-field";

  function handleChange(e: any) {
    props.keepAlive();
    if (e.target.id === nameField) {
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("name"),
        e.target.value
      );
    } else if (e.target.id === titleField) {
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("title"),
        e.target.value
      );
    } else if (e.target.id === descField) {
      props.setQuestionnaireWIP(
        nameof<QuestionnaireBuilderDataModel>("description"),
        e.target.value
      );
    }
  }

  return (
    <Box
      display={"flex"}
      padding={1}
      gap={2}
      flexWrap={"wrap"}
      key={"header-builder-container"}
    >
      <Typography
        variant="h5"
        component="h2"
        className={classes.titles}
        key={"header-builder-header"}
      >
        {(props.questionnaire.name === "" || props.questionnaire.name == null
          ? "New Questionnaire"
          : props.questionnaire.name) +
          " - " +
          version}
      </Typography>

      <Grid container spacing={2} alignItems={"center"}>
        <Grid xs={6}>
          <TextField
            id={nameField}
            key={nameField + props.questionnaire.id}
            label={"Name"}
            placeholder="Name"
            value={
              props.questionnaire.name === "New Questionnaire" ||
              props.questionnaire.name == null
                ? ""
                : props.questionnaire.name
            }
            onChange={(e) => {
              handleChange(e);
            }}
            className={classes.textarea}
            fullWidth
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            id={titleField}
            key={titleField + props.questionnaire.id}
            label={"Title"}
            placeholder="Title"
            value={props.questionnaire.title ?? ""}
            onChange={(e) => {
              handleChange(e);
            }}
            className={classes.textarea}
            fullWidth
          />
        </Grid>
        <Grid xs={6}>
          <Stack direction={"row"} alignItems={"center"}>
            <TextField
              id={descField}
              key={descField + props.questionnaire.id}
              label={"Description"}
              placeholder="Description"
              value={props.questionnaire.description ?? ""}
              onChange={(e) => {
                handleChange(e);
              }}
              className={`${classes.textarea} ${classes.description}`}
              multiline
              maxRows={4}
            />
            <Tooltip
              title={"This textfield accepts HTML markup."}
              enterTouchDelay={0}
              className={classes.tooltip}
              key={"description-field-tooltip-container"}
            >
              <IconButton key={"description-field-info-icon-button"}>
                <InfoIcon key={"description-field-info-icon"} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid
          xs={6}
          sm={5}
          md={4}
          lg={3}
          alignContent={"center"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <SplitButton
            name="add-section"
            options={["Add Section Before", "Add Section After"]}
            defaultIndex={1}
            useButtonWidth
            fillContainer
            onClick={(x) => {
              props.keepAlive();
              if (x.index === 0) {
                props.navigateSection(props.section, false);
              }
              if (x.index === 1) {
                props.navigateSection(props.section, true);
              }
            }}
          />
        </Grid>
        <Grid xs={5} sm={4} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="section-select-label">Go to section</InputLabel>
            <Select
              labelId="section-select-label"
              id="section-select"
              className={classes.sectionSelect}
              variant="outlined"
              label="Go to section"
              value={
                props.sections && props.sections.length === 0
                  ? ""
                  : props.section
              }
              disabled={!(props.sections && props.sections.length > 1)}
              onChange={(event) =>
                props.navigateSection(event.target.value as number, null)
              }
            >
              {props.sections &&
                props.sections.map((val, index) => (
                  <MenuItem
                    key={val.heading + "-" + index}
                    value={index}
                    role="menuitem"
                  >
                    {!val.heading || val.heading === ""
                      ? `Section ${index + 1}`
                      : val.heading}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        display={"inline-grid"}
        width={"100%"}
        key={"header-builder-authenticated-field-container"}
      >
        {props.questionnaire.authenticatedOnly && (
          <Alert
            key={"header-builder-authenticated-field-alert"}
            severity="info"
          >
            {"Questionnaire can only be accessed by logging in."}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default HeaderBuilder;
