import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  QuestionDataModel,
  QuestionnaireBuilderDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import {
  DefaultQuestionValuesViewModel,
  QuestionType,
} from "../../../../types/auto/types";
import nameof from "../../../utils/NameOf";
import { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CheckIsMobile } from "../../../utils/MobileStatus";

interface Props {
  questionnaire: QuestionnaireBuilderDataModel;
  questionType: QuestionType;
  handleChange: (questionType: QuestionType, field: string, event: any) => void;
  globalDefaultValuesWIP?: DefaultQuestionValuesViewModel[];
  defaultValuesOverride?: DefaultQuestionValuesViewModel;
  handleUpdate: (qType: QuestionType) => void;
  question?: QuestionDataModel;
  noResponseError: boolean;
  handleDelete: (qType: QuestionType) => void;
}

const DefaultValuesAccordionComponent = (props: Props): JSX.Element => {
  const [questionName] = useState<string>(getQuestionName);

  const [localChecked, setLocalChecked] = useState<boolean>(false);

  const isMobile = CheckIsMobile();

  function getQuestionName() {
    switch (props.questionType) {
      case QuestionType.Buttons: {
        return "Buttons";
      }
      case QuestionType.ButtonsMulti: {
        return "Multi Buttons";
      }
      case QuestionType.CheckBoxList: {
        return "Checkbox List";
      }
      case QuestionType.CheckBoxSingle: {
        return "Checkbox";
      }
      case QuestionType.DropDown: {
        return "Dropdown";
      }
      case QuestionType.SkippableRange: {
        return "Skippable Range";
      }
      case QuestionType.DateRange: {
        return "Date Range";
      }
      default: {
        return QuestionType[props.questionType];
      }
    }
  }

  return (
    <Accordion
      sx={{ width: "100%" }}
      TransitionProps={{ unmountOnExit: true }}
      key={"global-default-values-modal-" + questionName + "-accordian"}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="questionName-content"
        id={questionName + "-header"}
        key={"accordian-summary-" + questionName}
      >
        <Box
          display={"flex"}
          justifyContent={isMobile ? "" : "space-between"}
          width={"100%"}
          flexWrap={isMobile ? "wrap" : "nowrap"}
          key={"accordian-summary-" + questionName + "-container"}
        >
          <Box display={"flex"} width={"20%"}>
            <Typography key={"accordian-summary" + questionName + "title"}>
              {questionName}
            </Typography>
          </Box>
          {((props.question == null &&
            (props.questionnaire.globalDefaultValues?.find(
              (x) => x.questionType === props.questionType
            )?.answer ||
              props.questionnaire.globalDefaultValues?.find(
                (x) => x.questionType === props.questionType
              )?.score ||
              props.questionnaire.globalDefaultValues?.find(
                (x) => x.questionType === props.questionType
              )?.displayValue)) ||
            props.question?.defaultValueOverride != null) && (
            <Box
              display={"flex"}
              width={"80%"}
              justifyContent={"space-between"}
            >
              <Box display={"flex"} width={"100%"} flexWrap={"wrap"}>
                <Typography
                  key={"accordian-summary" + questionName + "title-response"}
                  fontStyle={"italic"}
                >
                  Response:
                </Typography>
                <Box width={"100%"}>
                  <Typography
                    key={
                      "accordian-summary" +
                      questionName +
                      "title-response-value"
                    }
                    fontStyle={"italic"}
                  >
                    {props.question != null
                      ? props.question?.defaultValueOverride != null
                        ? props.question?.defaultValueOverride.answer
                        : ""
                      : props.questionnaire.globalDefaultValues != null
                      ? props.questionnaire.globalDefaultValues.find(
                          (x) => x.questionType === props.questionType
                        )?.answer
                      : ""}
                  </Typography>
                </Box>
              </Box>
              <Box display={"flex"} width={"60%"} flexWrap={"wrap"}>
                <Typography
                  key={"accordian-summary" + questionName + "title-score"}
                  fontStyle={"italic"}
                >
                  Score:
                </Typography>
                <Box width={"100%"}>
                  <Typography
                    key={
                      "accordian-summary" + questionName + "title-score-value"
                    }
                    fontStyle={"italic"}
                  >
                    {props.question != null
                      ? props.question?.defaultValueOverride != null
                        ? props.question?.defaultValueOverride.score
                        : ""
                      : props.questionnaire.globalDefaultValues != null
                      ? props.questionnaire.globalDefaultValues.find(
                          (x) => x.questionType === props.questionType
                        )?.score
                      : ""}
                  </Typography>
                </Box>
              </Box>
              <Box display={"flex"} width={"100%"} flexWrap={"wrap"}>
                <Typography
                  key={
                    "accordian-summary" + questionName + "title-display-value"
                  }
                  fontStyle={"italic"}
                >
                  Display Value:
                </Typography>
                <Box width={"100%"}>
                  <Typography
                    key={
                      "accordian-summary" +
                      questionName +
                      "title-display-value-value"
                    }
                    fontStyle={"italic"}
                  >
                    {props.question != null
                      ? props.question?.defaultValueOverride != null
                        ? props.question?.defaultValueOverride.displayValue
                        : ""
                      : props.questionnaire.globalDefaultValues != null
                      ? props.questionnaire.globalDefaultValues.find(
                          (x) => x.questionType === props.questionType
                        )?.displayValue
                      : ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails key={"accordian-details-" + questionName}>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          key={"accordian-details" + questionName + "container"}
          flexWrap={isMobile ? "wrap" : "nowrap"}
        >
          <Box
            display={"flex"}
            alignItems={"space-between"}
            gap={"10px"}
            marginRight={"10px"}
            marginBottom={isMobile ? "10px" : "0px"}
            flexWrap={isMobile ? "wrap" : "nowrap"}
            key={"accordian-details" + questionName + "desc-container"}
          >
            {props.questionType === QuestionType.Date ? (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                key={"localisation-" + questionName}
              >
                <DatePicker
                  views={["year", "month"]}
                  inputFormat="MM/YYYY"
                  disableFuture={true}
                  value={
                    props.globalDefaultValuesWIP != null
                      ? props.globalDefaultValuesWIP.find(
                          (x) => x.questionType === props.questionType
                        )?.answer ?? ""
                      : props.defaultValuesOverride != null
                      ? props.defaultValuesOverride.answer ?? ""
                      : ""
                  }
                  onChange={(e) =>
                    props.handleChange(
                      props.questionType,
                      nameof<DefaultQuestionValuesViewModel>("answer"),
                      e
                    )
                  }
                  PopperProps={{
                    placement: "bottom-start",
                    modifiers: [
                      {
                        name: "flip",
                        options: {
                          fallbackPlacements: ["top-start", "right"],
                        },
                      },
                    ],
                    disablePortal: true,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={false}
                      sx={{
                        fieldset: {
                          legend: {
                            width: "unset",
                          },
                        },
                      }}
                      key={"day-" + questionName}
                    />
                  )}
                />
              </LocalizationProvider>
            ) : props.questionType === QuestionType.Day ? (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                key={"localisation-" + questionName}
              >
                <DatePicker
                  views={["year", "month", "day"]}
                  inputFormat="DD/MM/YYYY"
                  disableFuture={true}
                  value={
                    props.globalDefaultValuesWIP != null
                      ? props.globalDefaultValuesWIP.find(
                          (x) => x.questionType === props.questionType
                        )?.answer ?? ""
                      : props.defaultValuesOverride != null
                      ? props.defaultValuesOverride.answer ?? ""
                      : ""
                  }
                  onChange={(e) =>
                    props.handleChange(
                      props.questionType,
                      nameof<DefaultQuestionValuesViewModel>("answer"),
                      e
                    )
                  }
                  PopperProps={{
                    placement: "bottom-start",
                    modifiers: [
                      {
                        name: "flip",
                        options: {
                          fallbackPlacements: ["top-start", "right"],
                        },
                      },
                    ],
                    disablePortal: true,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={false}
                      sx={{
                        fieldset: {
                          legend: {
                            width: "unset",
                          },
                        },
                      }}
                      key={"day-" + questionName}
                    />
                  )}
                />
              </LocalizationProvider>
            ) : props.questionType === QuestionType.DateRange ? (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                key={"localisation-" + questionName}
              >
                <DatePicker
                  views={["year", "month", "day"]}
                  inputFormat="DD/MM/YYYY"
                  value={
                    props.globalDefaultValuesWIP != null
                      ? props.globalDefaultValuesWIP.find(
                          (x) => x.questionType === props.questionType
                        )?.answer ?? ""
                      : props.defaultValuesOverride != null
                      ? props.defaultValuesOverride.answer ?? ""
                      : ""
                  }
                  onChange={(e) =>
                    props.handleChange(
                      props.questionType,
                      nameof<DefaultQuestionValuesViewModel>("answer"),
                      e
                    )
                  }
                  PopperProps={{
                    placement: "bottom-start",
                    modifiers: [
                      {
                        name: "flip",
                        options: {
                          fallbackPlacements: ["top-start", "right"],
                        },
                      },
                    ],
                    disablePortal: true,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={false}
                      sx={{
                        fieldset: {
                          legend: {
                            width: "unset",
                          },
                        },
                      }}
                      key={"day-" + questionName}
                    />
                  )}
                />
              </LocalizationProvider>
            ) : props.questionType === QuestionType.CheckBoxSingle ? (
              <FormControlLabel
                control={<Checkbox checked={localChecked} />}
                key={"checkbox-" + questionName}
                label={
                  props.globalDefaultValuesWIP != null
                    ? props.globalDefaultValuesWIP.find(
                        (x) => x.questionType === props.questionType
                      )?.answer ?? ""
                    : props.defaultValuesOverride != null
                    ? props.defaultValuesOverride.answer ?? ""
                    : ""
                }
                onChange={(e) => {
                  setLocalChecked((x) => !x);
                  props.handleChange(
                    props.questionType,
                    nameof<DefaultQuestionValuesViewModel>("answer"),
                    !localChecked
                  );
                }}
              />
            ) : (
              <TextField
                placeholder={"Response"}
                label={"Response"}
                variant="outlined"
                type={
                  props.questionType === QuestionType.Buttons ||
                  props.questionType === QuestionType.ButtonsMulti ||
                  props.questionType === QuestionType.Number ||
                  props.questionType === QuestionType.Range ||
                  props.questionType === QuestionType.SkippableRange ||
                  props.questionType === QuestionType.CheckBoxList
                    ? "number"
                    : "text"
                }
                value={
                  props.globalDefaultValuesWIP != null
                    ? props.globalDefaultValuesWIP.find(
                        (x) => x.questionType === props.questionType
                      )?.answer ?? ""
                    : props.defaultValuesOverride != null
                    ? props.defaultValuesOverride.answer ?? ""
                    : ""
                }
                onChange={(e) =>
                  props.handleChange(
                    props.questionType,
                    nameof<DefaultQuestionValuesViewModel>("answer"),
                    e
                  )
                }
              />
            )}
            <TextField
              placeholder={"Score"}
              label={"Score"}
              variant="outlined"
              type={"number"}
              value={
                props.globalDefaultValuesWIP != null
                  ? props.globalDefaultValuesWIP.find(
                      (x) => x.questionType === props.questionType
                    )?.score ?? ""
                  : props.defaultValuesOverride != null
                  ? props.defaultValuesOverride.score ?? ""
                  : ""
              }
              onChange={(e) =>
                props.handleChange(
                  props.questionType,
                  nameof<DefaultQuestionValuesViewModel>("score"),
                  e
                )
              }
            />
            <TextField
              placeholder={"Display Value"}
              label={"Display Value"}
              variant="outlined"
              value={
                props.globalDefaultValuesWIP != null
                  ? props.globalDefaultValuesWIP.find(
                      (x) => x.questionType === props.questionType
                    )?.displayValue ?? ""
                  : props.defaultValuesOverride != null
                  ? props.defaultValuesOverride.displayValue ?? ""
                  : ""
              }
              onChange={(e) =>
                props.handleChange(
                  props.questionType,
                  nameof<DefaultQuestionValuesViewModel>("displayValue"),
                  e
                )
              }
            />
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            alignContent={"center"}
            justifyContent={"center"}
            gap={"5px"}
          >
            <Box
              key={"accordian-delete" + questionName + "button-container"}
              maxHeight={"36.5px"}
            >
              <Button
                variant="contained"
                onClick={() => {
                  props.handleDelete(props.questionType);
                }}
                key={"accordian-delete" + questionName + "button"}
              >
                Delete
              </Button>
            </Box>
            <Box
              key={"accordian-update" + questionName + "button-container"}
              maxHeight={"36.5px"}
            >
              <Button
                variant="contained"
                onClick={() => {
                  props.handleUpdate(props.questionType);
                }}
                key={"accordian-update" + questionName + "button"}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Box>
        {props.noResponseError && (
          <Alert sx={{ marginTop: "10px" }} severity="error">
            Please input a value for both the response and score fields.
          </Alert>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default DefaultValuesAccordionComponent;
