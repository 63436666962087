import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import {
  Frequency,
  MedicationClient,
  MedicationViewModel,
  PatientMedicationViewModel,
} from "../../../../types/auto/types";
import {
  AllowListViewPatientReasonRegex,
  Configuration,
} from "../../../Constants";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { FetchOverride } from "../../../utils/Request";
import {
  CurrentDate,
  IsDosageValid,
  IsValidDateRange,
  IsValidFrequencyAmount,
  IsValidFrequencySelect,
} from "./PatientMedicationHelper";
import FormErrorText from "../../elements/errors/FormErrorText";

interface Props {
  patientMedication: PatientMedicationViewModel;
  setPatientMedication: React.Dispatch<
    React.SetStateAction<PatientMedicationViewModel>
  >;
  setReRender: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMedication: React.Dispatch<
    React.SetStateAction<MedicationViewModel | undefined>
  >;
  medications: MedicationViewModel[];
  selectedMedication: MedicationViewModel | undefined;
  unselectedMedicationError: boolean;
  setUnselectedMedicationError: React.Dispatch<React.SetStateAction<boolean>>;
  dosageError: boolean;
  setDosageError: React.Dispatch<React.SetStateAction<boolean>>;
  invalidDateRange: boolean;
  setInvalidDateRange: React.Dispatch<React.SetStateAction<boolean>>;
  frequencyAmountError: boolean;
  setFrequencyAmountError: React.Dispatch<React.SetStateAction<boolean>>;
  invalidFrequency: boolean;
  setInvalidFrequency: React.Dispatch<React.SetStateAction<boolean>>;
}
const PatientMedicationForm = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    textFieldContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
      width: "100%",
      marginBottom: 15,
    },
    textField: {
      width: "100%",
    },
    dateRangeContainer: {
      padding: 0,
      paddingBottom: isMobile ? 10 : 0,
      margin: 0,
      display: isMobile ? "block" : "flex",
      width: "100%",
    },
    fromDate: {
      width: isMobile ? "100%" : "50%",
      marginRight: isMobile ? 0 : "10px",
    },
    toDate: {
      width: isMobile ? "100%" : "50%",
    },
    toDateError: {
      width: isMobile ? "100%" : "50%",
      "& button": {
        color: "rgb(211, 47, 47)",
      },
      "& label": {
        color: "rgb(211, 47, 47)",
        "&.Mui-focused": {
          color: "rgb(211, 47, 47)",
        },
      },
      "& > div::before": {
        borderBottomColor: "rgb(211, 47, 47) !important",
      },
      "& > div::after": {
        borderBottomColor: "rgb(211, 47, 47)",
      },
    },
    doseField: {
      width: "40%",
    },
    frequencySelector: {
      width: "70%",
    },
    frequencySelectorSelect: {
      color: "rgba(0, 0, 0, 0.38)",
    },
    frequencySelectorSelectError: {
      color: "rgb(211, 47, 47) !important",
      "& svg": {
        color: "rgb(211, 47, 47)",
      },
    },
    doseApplicableCheckbox: {
      marginLeft: "15px",
      maxWidth: isMobile ? "100%" : "17.5%",
      minWidth: isMobile ? "100%" : "17.5%",
    },
    errorText: { color: "red" },
    dosageUnits: { alignSelf: "flex-end" },
    dosageInformation: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      gap: 10,
    },
    frequencyInformation: {
      display: "flex",
      paddingLeft: 0,
    },
    frequencyAmount: {
      width: isMobile ? "45%" : "30%",
      marginRight: "10px",
    },
    ongoingCheckbox: {
      marginLeft: "15px",
      maxWidth: isMobile ? "100%" : "17.5%",
      minWidth: isMobile ? "100%" : "17.5%",
    },
  });
  const classes = useStyle();

  const [invalidNoteCharacter, setInvalidNoteCharacter] = React.useState(false);

  function handleChange(e: any) {
    var patientMedicationNote = e.target.value;
    if (patientMedicationNote.length > 0) {
      var regexp = new RegExp(AllowListViewPatientReasonRegex);
      var notInAllowList = regexp.test(patientMedicationNote);
      if (notInAllowList) {
        setInvalidNoteCharacter(true);
        return;
      } else {
        setInvalidNoteCharacter(false);
      }
    }
    props.setPatientMedication((x) => {
      x.notes = e.target.value;
      props.setReRender((x) => !x);
      return x;
    });
  }

  return (
    <Container className={classes.textFieldContainer} disableGutters>
      <FormControl>
        <InputLabel id="medication">Medication</InputLabel>
        <Select
          required
          variant="outlined"
          labelId="medication"
          label="Medication"
          value={
            props.patientMedication.medicationId &&
            props.patientMedication.medicationId >= 0
              ? props.patientMedication.medicationId
              : ""
          }
          onChange={(event) => {
            var medicationId = event.target.value as number;
            props.setPatientMedication((x) => {
              x.medicationId = medicationId;
              props.setReRender((x) => !x);
              return x;
            });
            new MedicationClient(Configuration.SERVER_ROOT, FetchOverride)
              .getMedication(medicationId)
              .then((med) => {
                props.setSelectedMedication(med);
                props.setUnselectedMedicationError(false);
              });
          }}
          error={props.unselectedMedicationError}
        >
          {props.medications.map((val) => (
            <MenuItem key={val.id} value={val.id} role="menuitem">
              {val.name}
            </MenuItem>
          ))}
        </Select>
        {props.unselectedMedicationError && (
          <FormHelperText className={classes.errorText}>
            Please select a medication
          </FormHelperText>
        )}
      </FormControl>
      <Container className={classes.dosageInformation} disableGutters>
        <TextField
          label={
            "Dose" +
            (props.selectedMedication === undefined
              ? ""
              : " (" + (props.selectedMedication.units ?? "") + ")")
          }
          className={classes.doseField}
          type="number"
          disabled={props.patientMedication.doseNotApplicable === true}
          value={
            props.patientMedication.dose === undefined ||
            props.patientMedication.dose === null ||
            props.patientMedication.doseNotApplicable === true
              ? ""
              : props.patientMedication.dose
          }
          onChange={(e) => {
            if (props.selectedMedication === undefined) {
              props.setUnselectedMedicationError(true);
              return;
            }
            var dose = e.target.value === "" ? "0" : e.target.value;
            props.setPatientMedication((x) => {
              x.dose = Number.parseInt(dose);
              props.setReRender((x) => !x);
              return x;
            });
            props.setDosageError(
              !IsDosageValid(
                props.selectedMedication,
                dose,
                props.patientMedication.doseNotApplicable
              )
            );
          }}
          error={props.dosageError}
          helperText={props.dosageError && "Please select a valid dosage."}
        />
        <Container className={classes.frequencyInformation} disableGutters>
          <TextField
            type="number"
            className={classes.frequencyAmount}
            label="Every"
            disabled={props.patientMedication.doseNotApplicable === true}
            value={
              props.patientMedication.frequencyAmount === undefined ||
              props.patientMedication.frequencyAmount === null ||
              props.patientMedication.doseNotApplicable === true
                ? ""
                : props.patientMedication.frequencyAmount
            }
            onChange={(e) => {
              if (props.selectedMedication === undefined) {
                props.setUnselectedMedicationError(true);
                return;
              }
              var frequencyAmount =
                e.target.value === "" ? "0" : e.target.value;
              props.setPatientMedication((x) => {
                x.frequencyAmount = Number.parseInt(frequencyAmount);
                props.setReRender((x) => !x);
                return x;
              });
              props.setFrequencyAmountError(
                !IsValidFrequencyAmount(
                  Number.parseInt(frequencyAmount),
                  props.patientMedication.doseNotApplicable
                )
              );
            }}
            error={props.frequencyAmountError}
            helperText={
              props.frequencyAmountError &&
              "Please select a valid frequency occurrence."
            }
          />
          <FormControl className={classes.frequencySelector}>
            <InputLabel
              id="frequency"
              className={
                props.patientMedication.doseNotApplicable === true
                  ? classes.frequencySelectorSelect
                  : props.invalidFrequency === true
                  ? classes.frequencySelectorSelectError
                  : ""
              }
            >
              Frequency
            </InputLabel>
            <Select
              className={
                props.invalidFrequency === true
                  ? classes.frequencySelectorSelectError
                  : ""
              }
              value={
                props.patientMedication.frequency === undefined ||
                props.patientMedication.frequency === null ||
                props.patientMedication.doseNotApplicable === true
                  ? ""
                  : props.patientMedication.frequency
              }
              labelId="frequency"
              label="Frequency"
              variant="outlined"
              disabled={props.patientMedication.doseNotApplicable === true}
              onChange={(event) => {
                if (props.selectedMedication === undefined) {
                  props.setUnselectedMedicationError(true);
                  return;
                }
                props.setPatientMedication((x) => {
                  if (event.target.value === "") {
                    x.frequency = undefined;
                    return x;
                  }
                  x.frequency = event.target.value as Frequency;
                  props.setReRender((x) => !x);
                  return x;
                });
                props.setInvalidFrequency(
                  !IsValidFrequencySelect(
                    event.target.value,
                    props.patientMedication.doseNotApplicable
                  )
                );
              }}
              error={props.invalidFrequency}
            >
              <MenuItem
                key="null"
                value={""}
                role="menuitem"
                aria-label="no frequency"
              ></MenuItem>
              {Object.keys(Frequency)
                .filter((x) => isNaN(Number(x)))
                .map((val) => {
                  return (
                    <MenuItem
                      key={val}
                      value={Frequency[val as keyof typeof Frequency]}
                      role="menuitem"
                    >
                      {val}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Container>
        <FormControlLabel
          className={classes.doseApplicableCheckbox}
          control={
            <Checkbox
              checked={props.patientMedication.doseNotApplicable ?? false}
              onChange={(event) => {
                if (props.selectedMedication === undefined) {
                  props.setUnselectedMedicationError(true);
                  return;
                }
                var doseNotApplicable = event.target.checked;
                props.setPatientMedication((x) => {
                  x.doseNotApplicable = doseNotApplicable;
                  props.setReRender((x) => !x);
                  props.patientMedication.dose = undefined;
                  props.patientMedication.frequencyAmount = undefined;
                  props.patientMedication.frequency = undefined;
                  return x;
                });
                props.setDosageError(
                  !IsDosageValid(
                    props.selectedMedication,
                    props.patientMedication.dose,
                    doseNotApplicable
                  )
                );
                props.setFrequencyAmountError(
                  !IsValidFrequencyAmount(
                    props.patientMedication.frequencyAmount,
                    doseNotApplicable
                  )
                );
                props.setInvalidFrequency(
                  !IsValidFrequencySelect(
                    props.patientMedication.frequency,
                    doseNotApplicable
                  )
                );
              }}
            />
          }
          label="Dose not applicable"
        />
      </Container>
      <FormErrorText
        errorText="Please select a valid frequency."
        isInvalid={props.invalidFrequency}
      />
      <TextField
        label="Notes"
        className={classes.textField}
        error={invalidNoteCharacter}
        multiline
        value={
          props.patientMedication.notes === undefined ||
          props.patientMedication.notes === null
            ? ""
            : props.patientMedication.notes
        }
        type="number"
        onChange={(e) => {
          handleChange(e);
        }}
        helperText={invalidNoteCharacter && "Invalid character used."}
      />
      <Container className={classes.dateRangeContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            label="From Date"
            value={
              props.patientMedication.startDate ??
              new Date(
                CurrentDate.getFullYear(),
                CurrentDate.getMonth(),
                CurrentDate.getDate()
              )
            }
            PopperProps={{
              placement: "bottom-start",
              modifiers: [
                {
                  name: "flip",
                  options: {
                    fallbackPlacements: ["top-start", "right"],
                  },
                },
              ],
            }}
            onChange={(e: any) => {
              if (e === null) e = CurrentDate;
              props.setPatientMedication((x) => {
                x.startDate = e;
                props.setReRender((x) => !x);
                return x;
              });
              props.setInvalidDateRange(
                !IsValidDateRange(
                  e,
                  props.patientMedication.endDate,
                  props.patientMedication.ongoing
                )
              );
            }}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                className={classes.fromDate}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            label="To Date"
            disabled={props.patientMedication.ongoing === true}
            value={
              props.patientMedication.endDate === undefined ||
              props.patientMedication.endDate === null ||
              props.patientMedication.ongoing === true
                ? null
                : props.patientMedication.endDate
            }
            PopperProps={{
              placement: "bottom-start",
              modifiers: [
                {
                  name: "flip",
                  options: {
                    fallbackPlacements: ["top-start", "right"],
                  },
                },
              ],
            }}
            onChange={(e: any) => {
              if (e === null) {
                props.setPatientMedication((x) => {
                  x.endDate = undefined;
                  props.setReRender((x) => !x);
                  return x;
                });
                props.setInvalidDateRange(false);
                return;
              }
              props.setPatientMedication((x) => {
                x.endDate = e;
                props.setReRender((x) => !x);
                return x;
              });
              props.setInvalidDateRange(
                !IsValidDateRange(
                  props.patientMedication.startDate ??
                    new Date(
                      CurrentDate.getFullYear(),
                      CurrentDate.getMonth(),
                      CurrentDate.getDate()
                    ),
                  e,
                  props.patientMedication.ongoing
                )
              );
            }}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                className={
                  props.invalidDateRange ? classes.toDateError : classes.toDate
                }
              />
            )}
          />
        </LocalizationProvider>
        <FormControlLabel
          className={classes.ongoingCheckbox}
          control={
            <Checkbox
              checked={props.patientMedication.ongoing ?? false}
              onChange={(event) => {
                var ongoing = event.target.checked;
                props.setPatientMedication((x) => {
                  x.ongoing = ongoing;
                  props.setReRender((x) => !x);
                  props.patientMedication.endDate = undefined;
                  return x;
                });
                props.setInvalidDateRange(
                  !IsValidDateRange(
                    props.patientMedication.startDate ??
                      new Date(
                        CurrentDate.getFullYear(),
                        CurrentDate.getMonth(),
                        CurrentDate.getDate()
                      ),
                    props.patientMedication.endDate,
                    ongoing
                  )
                );
              }}
            />
          }
          label="Ongoing"
        />
      </Container>
      <FormErrorText
        errorText="Please select a valid date range"
        isInvalid={props.invalidDateRange}
      />
    </Container>
  );
};

export default PatientMedicationForm;
