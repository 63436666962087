import { Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { theme } from "../../../../Theme";
import { makeStyles } from "@mui/styles";

interface Props {
  helpText: string;
  fontSize?: "inherit" | "medium" | "large" | "small";
  className?: string;
}

const InfoHint = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    icon: {
      color: "gray",
      cursor: "help",
    },
  });
  const classes = useStyle(theme);

  return (
    <Tooltip title={props.helpText} disableInteractive arrow>
      <HelpOutlineIcon
        className={[classes.icon, props.className].join(" ")}
        fontSize={props.fontSize || "small"}
      />
    </Tooltip>
  );
};

export default InfoHint;
