import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Container,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import {
  QuestionnaireViewModel,
  QuestionnaireClient,
  TenancyOptionErrors,
} from "../../../types/auto/types";
import { Configuration } from "../../Constants";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { FetchOverride } from "../../utils/Request";

interface Props {
  questionnaire: QuestionnaireViewModel;
  open: boolean;
  closeModal: () => void;
  refresh: () => void;
}
const RenameQuestionnaireModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "100%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      display: "flex",
      justifyContent: "end",
      marginTop: 20,
      gap: "8px",
    },
    textField: {
      width: "100%",
      marginBottom: "10px",
    },
  });
  const classes = useStyle();
  const [questionnaire, setQuestionnaire] =
    React.useState<QuestionnaireViewModel>(props.questionnaire);
  const [, setReRender] = React.useState<boolean>(false);
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<TenancyOptionErrors | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  const IsNameValid = (name: string | undefined) => {
    return name && name.trim() !== "";
  };

  useEffect(() => {
    // Deep copy patient prop to fix state update problems
    setQuestionnaire(
      JSON.parse(JSON.stringify(props.questionnaire), function (key, value) {
        return value;
      })
    );
    setReRender((x) => !x);
  }, [props.open, props.questionnaire]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "80%" : "40%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          Rename Questionnaire
        </Typography>
        <Container>
          <TextField
            label="Name"
            className={classes.textField}
            required
            value={questionnaire.name ? questionnaire.name : ""}
            onChange={(e) => {
              setQuestionnaire((x) => {
                x.name = e.target.value;
                setReRender((x) => !x);
                return x;
              });
              setNameError(!IsNameValid(e.target.value));
            }}
            error={nameError}
            helperText={nameError && "Name cannot be empty"}
          />
        </Container>
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              props.closeModal();
              setNameError(false);
              setReRender((x) => !x);
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            disabled={nameError}
            loading={loading}
            onClick={async () => {
              setLoading(true);
              if (!IsNameValid(questionnaire.name)) {
                setLoading(false);
                setNameError(true);
                return;
              }
              new QuestionnaireClient(Configuration.SERVER_ROOT, FetchOverride)
                .renameQuestionnaire(questionnaire)
                .then(() => {
                  setLoading(false);
                  props.closeModal();
                  props.refresh();
                })
                .catch((error) => {
                  setLoading(false);
                  setError(error);
                });
              setNameError(false);
            }}
          >
            Save Changes
          </LoadingButton>
        </div>
        {error !== undefined && (
          <Alert severity="error">
            {error === TenancyOptionErrors.TenancyNotSupported
              ? "Tenancy does not support editing questionnaires."
              : "Details for questionnaire are invalid."}
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default RenameQuestionnaireModal;
