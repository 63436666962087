import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { makeStyles } from "@mui/styles";

export interface ButtonOption {
  index: number;
  value: string;
}

interface Props {
  name: string;
  options: string[];
  onClick: (option: ButtonOption) => void;
  onSelected?: (option: ButtonOption) => void;
  defaultIndex?: number;
  useButtonWidth?: boolean;
  fillContainer?: boolean;
}

export const SplitButton = (props: Props): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(
    props.defaultIndex ?? 0
  );

  const useStyle = makeStyles(() => ({
    fullwidth: {
      width: "100%",
    },
  }));

  const classes = useStyle();

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    if (props.onSelected) {
      props.onSelected({ index, value: props.options[selectedIndex] });
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label={`${props.name} button group`}
        className={props.fillContainer ? classes.fullwidth : ""}
      >
        <Button
          onClick={() =>
            props.onClick({
              index: selectedIndex,
              value: props.options[selectedIndex],
            } as ButtonOption)
          }
          className={props.fillContainer ? classes.fullwidth : ""}
        >
          {props.options[selectedIndex]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label={`select-${props.name}`}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={
          props.useButtonWidth === true
            ? { width: anchorRef.current?.clientWidth }
            : {}
        }
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {props.options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
