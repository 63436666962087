import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  PatientDashboardOptionsModel,
  QuestionnaireBuilderDataModel,
  ScoringDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import { useState } from "react";
import QuestionnaireSettingsModal from "./QuestionnaireSettingsModal";
import QuestionnaireDashboardOptionsModal from "./QuestionnaireDashboardOptionsModal";
import { LoadingButton } from "@mui/lab";
import QuestionnaireScoringWarningModal from "./QuestionnaireScoringWarningModal";
import QuestionnaireScoringModal from "./QuestionnaireScoringModal";
import nameof from "../../../utils/NameOf";
import {
  DefaultQuestionValuesViewModel,
  QuestionnaireViewModel,
} from "../../../../types/auto/types";
import GlobalDefaultValuesModal from "./GlobalDefaultValuesModal";

interface Props {
  questionnaire: QuestionnaireBuilderDataModel;
  allQuestionnaires: QuestionnaireViewModel[];
  setQuestionnaireWIP: (
    field: string,
    value:
      | string
      | boolean
      | number
      | null
      | PatientDashboardOptionsModel
      | undefined
      | ScoringDataModel[]
      | DefaultQuestionValuesViewModel[]
  ) => void;
  previewCoverPage: boolean;
  setPreviewCoverPage: React.Dispatch<React.SetStateAction<boolean>>;
  enableDashboardOptions: boolean;
  handleSave: () => void;
  handleSaveAndClose: () => void;
  saveLoading: boolean;
  saveAndCloseLoading: boolean;
  saveDisabled: boolean;
  setSaveLoading: () => void;
  setSaveAndCloseLoading: () => void;
  keepAlive: () => void;
}

const QuestionnaireSettings = (props: Props): JSX.Element => {
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);
  const [openDashboardModal, setOpenDashboardModal] = useState<boolean>(false);
  const [
    openQuestionnaireScoringWarningModal,
    setOpenQuestionnaireScoringWarningModal,
  ] = useState<boolean>(false);
  const [openQuestionnaireScoringModal, setOpenQuestionnaireScoringModal] =
    useState<boolean>(false);
  const [openGlobalDefaultValueModal, setOpenGlobalDefaultValueModal] =
    useState<boolean>(false);

  return (
    <Grid
      container
      spacing={1}
      sx={{
        my: 2,
      }}
    >
      <Grid xs={6} sm={4} md={12}>
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={() => {
            props.setSaveLoading();
            props.handleSave();
          }}
          loading={props.saveLoading}
          disabled={props.saveDisabled}
        >
          Save
        </LoadingButton>
      </Grid>
      <Grid xs={6} sm={4} md={12}>
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={() => {
            props.setSaveAndCloseLoading();
            props.handleSaveAndClose();
          }}
          loading={props.saveAndCloseLoading}
        >
          Save and Close
        </LoadingButton>
      </Grid>
      <Grid xs={6} sm={4} md={12}>
        <Button
          onClick={() => {
            setOpenQuestionnaireScoringWarningModal(true);
          }}
          variant="contained"
          fullWidth
          key={"questionnaire-scoring-button"}
        >
          Questionnaire Scoring
        </Button>
        <QuestionnaireScoringWarningModal
          open={openQuestionnaireScoringWarningModal}
          handleContinue={() => {
            setOpenQuestionnaireScoringWarningModal(false);
            setOpenQuestionnaireScoringModal(true);
          }}
          handleStay={() => {
            setOpenQuestionnaireScoringWarningModal(false);
          }}
          key={"questionnaire-scoring-warning-modal"}
        />
        {openQuestionnaireScoringModal && (
          <QuestionnaireScoringModal
            open={openQuestionnaireScoringModal}
            questionnaire={props.questionnaire}
            allQuestionnaires={props.allQuestionnaires}
            handleCancel={() => setOpenQuestionnaireScoringModal(false)}
            setQuestionnaireWIP={(scoring) => {
              props.setQuestionnaireWIP(
                nameof<QuestionnaireBuilderDataModel>("scoring"),
                scoring
              );
            }}
            key={"questionnaire-scoring-modal"}
            keepAlive={props.keepAlive}
          />
        )}
      </Grid>
      {props.questionnaire.coverPage && (
        <Grid xs={6} sm={4} md={12}>
          <Box>
            <FormGroup key={"preview-coverpage"}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={() => props.setPreviewCoverPage((x) => !x)}
                    checked={props.previewCoverPage}
                  />
                }
                label="Preview Cover Page"
                key={"preview-coverpage-form-control"}
              />
            </FormGroup>
          </Box>
        </Grid>
      )}
      <Grid xs={6} sm={4} md={12}>
        <Button
          onClick={() => setOpenSettingsModal(true)}
          variant="contained"
          fullWidth
        >
          Questionnaire Settings
        </Button>
        {openSettingsModal && (
          <QuestionnaireSettingsModal
            open={openSettingsModal}
            questionnaire={props.questionnaire}
            handleCancel={() => setOpenSettingsModal(false)}
            setQuestionnaireWIP={props.setQuestionnaireWIP}
            key={"question-settings-modal"}
            keepAlive={props.keepAlive}
          />
        )}
      </Grid>
      <Grid xs={6} sm={4} md={12}>
        <Button
          onClick={() => setOpenGlobalDefaultValueModal(true)}
          variant="contained"
          fullWidth
        >
          Global Default Values
        </Button>
        {openGlobalDefaultValueModal && (
          <GlobalDefaultValuesModal
            open={openGlobalDefaultValueModal}
            questionnaire={props.questionnaire}
            handleClose={() => setOpenGlobalDefaultValueModal(false)}
            setQuestionnaireWIP={props.setQuestionnaireWIP}
            key={"question-settings-modal"}
            keepAlive={props.keepAlive}
          />
        )}
      </Grid>
      {props.enableDashboardOptions && (
        <Grid xs={6} sm={4} md={12}>
          <Box>
            <Button
              onClick={() => {
                setOpenDashboardModal(true);
                props.keepAlive();
              }}
              variant="contained"
              fullWidth
            >
              Dashboard Options
            </Button>
            {openDashboardModal && (
              <QuestionnaireDashboardOptionsModal
                open={openDashboardModal}
                questionnaire={props.questionnaire}
                handleClose={() => {
                  setOpenDashboardModal(false);
                }}
                setQuestionnaireWIP={props.setQuestionnaireWIP}
                key={"dashboard-options-modal"}
                keepAlive={props.keepAlive}
              />
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default QuestionnaireSettings;
