import { Frequency, MedicationViewModel } from "../../../../types/auto/types";

export const CurrentDate = new Date();
const MaxDate = new Date(2100, 1, 1);
const MinDate = new Date(1899, 12, 31);

export const IsDosageValid = (
  selectedMedication: MedicationViewModel,
  dose: number | string | undefined,
  doseNotApplicable: boolean | undefined
): boolean => {
  if (dose === undefined || dose === null) {
    if (doseNotApplicable === false || doseNotApplicable === undefined)
      return false;
    else return true;
  } else {
    if (typeof dose === "string") dose = Number.parseInt(dose);
    return (
      !(dose > selectedMedication.maxDosage) &&
      !(dose < selectedMedication.minDosage)
    );
  }
};

export const IsValidDateRange = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  ongoing: boolean | undefined
): boolean => {
  if (
    startDate === undefined ||
    startDate === null ||
    isNaN(new Date(startDate).getTime()) ||
    startDate >= MaxDate ||
    startDate <= MinDate
  )
    return false;
  if (
    endDate === undefined ||
    endDate === null ||
    isNaN(new Date(endDate).getTime()) ||
    endDate >= MaxDate ||
    endDate <= MinDate
  ) {
    if (ongoing === false || ongoing === undefined || ongoing === null)
      return false;
    else return true;
  } else {
    return !(startDate > endDate);
  }
};

export const IsValidFrequencyAmount = (
  frequencyAmount: number | string | undefined,
  doseNotApplicable: boolean | undefined
): boolean => {
  if (frequencyAmount === undefined || frequencyAmount === null) {
    if (
      doseNotApplicable === false ||
      doseNotApplicable === undefined ||
      doseNotApplicable === null
    )
      return false;
    else return true;
  } else {
    if (typeof frequencyAmount === "string")
      frequencyAmount = Number.parseInt(frequencyAmount);
    return !(frequencyAmount < 1);
  }
};

export const IsValidFrequencySelect = (
  frequencySelect: string | Frequency | undefined,
  doseNotApplicable: boolean | undefined
): boolean => {
  if (
    frequencySelect === undefined ||
    frequencySelect === null ||
    frequencySelect === ""
  ) {
    if (
      doseNotApplicable === false ||
      doseNotApplicable === undefined ||
      doseNotApplicable === null
    )
      return false;
    else return true;
  } else {
    return true;
  }
};
