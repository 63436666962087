import { Chip, Container, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { theme } from "../../../../Theme";
import MedicationIcon from "@mui/icons-material/Medication";
import { MedicationLineViewModel } from "../../../../types/auto/types";

interface Props {
  data: ExtendedMedicationViewModel[];
  setData: React.Dispatch<React.SetStateAction<ExtendedMedicationViewModel[]>>;
}

export interface ExtendedMedicationViewModel extends MedicationLineViewModel {
  hidden: boolean;
}

const GraphLegend = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    container: {
      width: "100%",
      display: "flex",
      textAlign: "center",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      justifyItems: "center",
      userSelect: "none",
      flexWrap: "wrap",
    },
    legendItemContainer: {
      display: "flex",
      flexDirection: "row",
      cursor: "pointer",
      marginLeft: "5px",
      marginRight: "5px",
      marginTop: "10px",
    },
    chip: {
      paddingLeft: "5px",
      marginBottom: "10px",
      cursor: "pointer",
      transition: "opacity 3s",
    },
  }));
  const classes = useStyle(theme);

  return (
    <Container className={classes.container}>
      {props.data.map((x, index) => (
        <div
          key={"legend" + index}
          role="presentation"
          className={classes.legendItemContainer}
          onClick={() =>
            /* Handle clicking on the legend item to hide the data */
            props.setData((x) =>
              x.map((y: ExtendedMedicationViewModel, i) => {
                if (i === index) {
                  y.hidden = !y.hidden;
                }
                return y;
              })
            )
          }
        >
          <Tooltip title={(x.hidden ? "Show " : "Hide ") + "Medication Line"}>
            <Chip
              icon={<MedicationIcon style={{ color: "white" }} />}
              label={x.lineName}
              className={classes.chip}
              onClick={() => {}} // On Click must exist on the chip component for the background colours to be rendered on print
              style={{
                backgroundColor:
                  x.colour === null || x.colour === undefined
                    ? "rgba(0, 0, 0, 1)"
                    : x.colour,
                color: "white",
                opacity: x.hidden ? 0.3 : 1,
                textDecoration: x.hidden ? "line-through" : undefined,
              }}
            />
          </Tooltip>
        </div>
      ))}
    </Container>
  );
};

export default GraphLegend;
