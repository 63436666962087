import { useState } from "react";
import {
  Box,
  MobileStepper,
  Theme,
  Tooltip,
  tooltipClasses,
  mobileStepperClasses,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../../Theme";
import { LoadingButton } from "@mui/lab";
import { SectionDataModel } from "./QuestionnaireBuilderDataModelHelper";

interface Props {
  section: number;
  totalSections: number;
  sections: SectionDataModel[] | undefined;
  navigateSection: (
    currentSection: number,
    addSectionDirection: boolean | null
  ) => void;
  cancelLoading: boolean;
}

const FooterBuilder = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    stepper: {
      backgroundColor: "#EAE8E1",
      marginBottom: "10px",
      [`& .${mobileStepperClasses.dot}:not(.${mobileStepperClasses.dotActive})`]:
        {
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgba(10, 174, 245, 0.5)",
            transition:
              "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },
        },
    },
    sectionToolTip: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
        {
          marginTop: "-5px",
        },
      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
        {
          transform: `translate(0, calc(100px + (${
            (document.getElementById("root")?.getBoundingClientRect().height ??
              0) - 75
          }px - 100vh))) !important`,
          transition: "cubic-bezier(0.4, 0, 0.2, 1) !important",
        },
    },
  }));

  const classes = useStyle(customTheme);
  const [toolTip, setToolTip] = useState("");
  const showNext = props.section + 1 < props.totalSections;
  var previousButton = props.section < 1 ? "Cancel" : "Back";

  return (
    <Box key="footer-box">
      <Tooltip
        open={true}
        title={toolTip}
        enterTouchDelay={0}
        classes={{ popper: classes.sectionToolTip }}
      >
        <MobileStepper
          className={classes.stepper}
          variant="dots"
          steps={props.totalSections}
          position="static"
          activeStep={props.section}
          onMouseOver={(x) => {
            if (
              x.target instanceof HTMLDivElement &&
              x.target.parentNode?.childNodes !== undefined &&
              x.target.classList.contains(mobileStepperClasses.dot) &&
              !x.target.classList.contains(mobileStepperClasses.dotActive)
            ) {
              setToolTip(
                (props.sections || [])[
                  Array.from(x.target.parentNode?.childNodes).indexOf(x.target)
                ].heading ||
                  `Section ${
                    Array.from(x.target.parentNode?.childNodes).indexOf(
                      x.target
                    ) + 1
                  }`
              );
            }
          }}
          onMouseOut={() => {
            setToolTip("");
          }}
          onClick={(x) => {
            if (
              x.target instanceof HTMLDivElement &&
              x.target.parentNode?.childNodes !== undefined &&
              x.target.classList.contains(mobileStepperClasses.dot) &&
              !x.target.classList.contains(mobileStepperClasses.dotActive)
            ) {
              props.navigateSection(
                Array.from(x.target.parentNode?.childNodes).indexOf(x.target),
                null
              );
              setToolTip("");
            }
          }}
          nextButton={
            <LoadingButton
              variant="contained"
              onClick={() => {
                props.navigateSection(props.section + 1, null);
              }}
              sx={{ minWidth: "120px" }}
              disabled={!showNext}
            >
              Next
            </LoadingButton>
          }
          backButton={
            <LoadingButton
              variant="contained"
              onClick={() => {
                props.navigateSection(props.section - 1, null);
              }}
              loading={props.cancelLoading}
              sx={{ minWidth: "120px" }}
            >
              {previousButton}
            </LoadingButton>
          }
        />
      </Tooltip>
    </Box>
  );
};

export default FooterBuilder;
