import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useMountedState, useUnmountPromise } from "react-use";
import {
  ArchiveCountType,
  CaseloadClient,
  CaseloadType,
  CaseloadViewModel,
  EnumClient,
  GoalCategory,
  GoalClient,
  GoalOwner,
  GoalViewModel,
  PatientViewModel,
  PromptFrequency,
  PromptScheduleViewModel,
  Role,
} from "../../../types/auto/types";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { theme } from "../../../Theme";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import CaseloadDropdown from "../elements/caseload-dropdown/CaseloadDropdown";
import InfoHint from "../elements/form-elements/InfoHint";
import {
  ScheduleFormReceivePrompts,
  ScheduleFormSetupArchived,
} from "../../HelpText";
import { ToTitleCase } from "../../utils/TitleCase";
import PromptScheduling from "../schedule/create-edit-schedule/PromptScheduling/PromptScheduling";
import ArchivePromptScheduling from "../schedule/create-edit-schedule/PromptScheduling/ArchivePromptScheduling";

interface Props {
  goal?: GoalViewModel;
  patient: PatientViewModel;
  open: boolean;
  closeModal: () => void;
  role?: Role;
  questionnaireId?: number;
  goalCategory?: GoalCategory;
  caseloadId?: number;
  questionnaireResponseId?: number;
  episodeId?: string;
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const IsCaseloadIdValid = (caseloadId: number | undefined): boolean =>
  caseloadId !== undefined && caseloadId >= 0;
const IsQuestionValid = (question: string | undefined): boolean =>
  question !== undefined &&
  question !== null &&
  question.replace(" ", "") !== "";
const IsTimeValid = (timeString: string | undefined): boolean => {
  if (!timeString) {
    return false;
  }
  const timeRegex = /^(?:2[0-3]|[01]?[0-9]):[0-5]?[0-9]/;
  return timeRegex.test(timeString);
};

const CreateEditGoalModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      display: "flex",
      justifyContent: "end",
      marginTop: 20,
      gap: "8px",
    },
    formControl: { marginBottom: 20 },
    receivePrompts: { display: "flex" },
    hint: { marginTop: 10 },
    ownerToggle: {
      marginBottom: 20,
      display: "block",
    },
    goalOwnerContainer: { margin: 0, padding: 0, marginBottom: 30 },
    textField: {
      width: "100%",
    },
  });
  const classes = useStyle(theme);
  const [caseloadId, setCaseloadId] = useState<number>();
  const [question, setQuestion] = useState("");
  const [prompt, setPrompt] = useState<PromptScheduleViewModel>();
  const [caseloadValidationError, setCaseloadValidationError] = useState(false);
  const [owner, setOwner] = useState(GoalOwner.Patient);
  const [goalOwnerDescriptions, setGoalOwnerDescriptions] = useState<{
    [x: number]: string | undefined;
  }>();
  const [goalQuestionValidationError, setGoalQuestionValidationError] =
    useState(false);

  const [editingForm, setEditingForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setRerender] = useState(false);
  const [allCaseloads, setAllCaseloads] = useState<CaseloadViewModel[]>([]);
  const [itemIndex, setItemIndex] = useState<number>();
  const [episodeId, setEpisodeId] = useState<string>();
  const [goalCategory, setGoalCategory] = useState<GoalCategory>();
  const [unauthorised, setUnauthorised] = useState<boolean>(false);

  const isMountedState = useMountedState();
  const resolveWhileMounted = useUnmountPromise();

  const isStaff =
    props.role === Role.SuperAdmin ||
    props.role === Role.Admin ||
    props.role === Role.Clinician ||
    props.role === Role.ReadOnly ||
    props.role === Role.ReadOnlyPIRedacted;

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        setQuestion(props.goal?.question || "");
        setCaseloadId(
          props.goal?.caseloadId
            ? props.goal?.caseloadId
            : props.caseloadId
            ? props.caseloadId
            : -1
        );
        setPrompt(props.goal?.promptSchedule);
        setEditingForm(props.goal !== undefined);
        setOwner(
          props.goal?.owner === undefined ? GoalOwner.Patient : props.goal.owner
        );
        setGoalCategory(
          props.goal != null && props.goal.goalCategory != null
            ? props.goal.goalCategory
            : GoalCategory.General
        );
      }
    };
    effect();
  }, [props.goal, setQuestion, props.open, isMountedState, props.caseloadId]);

  useEffect(() => {
    const effect = async () => {
      if (props.open) {
        await resolveWhileMounted(
          new EnumClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getGoalOwnerDescriptions()
        ).then((x) => {
          setGoalOwnerDescriptions(x);
        });
      }
    };
    effect();
  }, [setGoalOwnerDescriptions, props.open, resolveWhileMounted]);

  useEffect(() => {
    const effect = async () => {
      if (isStaff) {
        await resolveWhileMounted(
          new CaseloadClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getCaseloadsForPatient(props.patient.id, CaseloadType.Shared, true)
        ).then((c) => setAllCaseloads(c));
      } else {
        await resolveWhileMounted(
          new CaseloadClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getCaseloadsForCurrentPatient(CaseloadType.Shared)
        ).then((c) => setAllCaseloads(c));
      }
    };
    effect();
  }, [isStaff, setAllCaseloads, props.patient.id, resolveWhileMounted]);

  useEffect(
    () => {
      if (allCaseloads.length > 0 && props.episodeId != null) {
        setItemIndex(
          allCaseloads.findIndex((x) => x.episodeId === props.episodeId)
        );
        setEpisodeId(props.episodeId);
      } else if (allCaseloads.length > 0 && props.caseloadId != null) {
        setItemIndex(allCaseloads.findIndex((x) => x.id === props.caseloadId));
        setEpisodeId(props.episodeId);
      }
    },
    // eslint-disable-next-line
    [allCaseloads]
  );

  const modalName = (editingForm ? "Edit" : "Create") + " Goal";
  const buttonText = (editingForm ? "Save" : "Create") + " Goal";
  const isPromptChecked = prompt !== undefined && prompt !== null;

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "100%" : "60%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          {modalName}
        </Typography>
        {!editingForm && (
          <CaseloadDropdown
            allCaseloads={allCaseloads}
            patient={props.patient}
            itemIndex={itemIndex}
            setCaseloadId={(caseloadId, episodeId, itemIndex) => {
              setItemIndex(itemIndex);
              setCaseloadId(caseloadId);
              setEpisodeId(episodeId);
              setCaseloadValidationError(!IsCaseloadIdValid(caseloadId));
            }}
            error={caseloadValidationError}
          />
        )}
        <Container className={classes.goalOwnerContainer}>
          <FormLabel>Goal Owner</FormLabel>
          <ToggleButtonGroup
            value={owner}
            exclusive
            onChange={(_, value) => value !== null && setOwner(value)}
            aria-label="goal-owner"
            className={classes.ownerToggle}
          >
            {Object.keys(GoalOwner)
              .filter((x) => isNaN(Number(x)))
              .map((x) => (
                <ToggleButton
                  key={x}
                  value={GoalOwner[x as keyof typeof GoalOwner]}
                  aria-label={x}
                >
                  {goalOwnerDescriptions === undefined
                    ? x
                    : goalOwnerDescriptions[x as unknown as number]}
                </ToggleButton>
              ))}
          </ToggleButtonGroup>
        </Container>

        <TextField
          label="Goal details"
          value={question}
          fullWidth
          onChange={(event) => {
            var value = event.target.value;

            setGoalQuestionValidationError(!IsQuestionValid(value));
            setQuestion(value);
          }}
          error={goalQuestionValidationError}
          helperText={
            goalQuestionValidationError && "Please enter a goal question"
          }
        />
        <FormControl className={classes.formControl}>
          <div className={classes.receivePrompts}>
            <FormControlLabel
              control={
                <Switch
                  checked={isPromptChecked}
                  color="secondary"
                  disabled={
                    (props.patient.contactEmail == null &&
                      props.patient.mobileNumber == null) ||
                    (!props.goal?.submitted &&
                      props.questionnaireResponseId != null)
                  }
                  onChange={() => {
                    setPrompt((x) => {
                      if (x === undefined) {
                        if (props.goal?.promptSchedule != null) {
                          x = props.goal.promptSchedule;
                        } else {
                          x = new PromptScheduleViewModel();
                          x.frequency = PromptFrequency.Daily;
                        }
                      } else {
                        x = undefined;
                      }
                      return x;
                    });
                    setRerender((y) => !y);
                  }}
                />
              }
              label="Receive Prompts"
            />
            <InfoHint
              helpText={ScheduleFormReceivePrompts}
              className={classes.hint}
            />
          </div>
        </FormControl>
        {prompt !== undefined && prompt !== null && (
          <>
            <PromptScheduling
              schedulingDetails={prompt}
              setSchedulingDetails={(details) => {
                setRerender((x) => !x);
                setPrompt(details);
              }}
              setRerender={setRerender}
            />
            <FormControl className={classes.formControl}>
              <div className={classes.receivePrompts}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        prompt.archivesAfter !== undefined &&
                        prompt.archivesAfter !== null
                      }
                      color="secondary"
                      onChange={() =>
                        setPrompt((x) => {
                          if (x === undefined) {
                            x = new PromptScheduleViewModel();
                          }
                          x.archiveCountType =
                            x.archiveCountType !== undefined
                              ? undefined
                              : ArchiveCountType.TotalMissedResponses;
                          x.archivesAfter =
                            x.archivesAfter !== undefined ? undefined : 1;

                          setRerender((y) => !y);
                          return x;
                        })
                      }
                    />
                  }
                  label="Setup Prompt Archiving"
                />
                <InfoHint
                  helpText={ScheduleFormSetupArchived}
                  className={classes.hint}
                />
              </div>
              {prompt.archivesAfter !== undefined &&
                prompt.archivesAfter !== null && (
                  <ArchivePromptScheduling
                    archivesAfter={prompt.archivesAfter}
                    setArchivesAfter={(archivesAfter) =>
                      setPrompt((x) => {
                        if (x === undefined) {
                          x = new PromptScheduleViewModel();
                        }
                        x.archivesAfter = archivesAfter;
                        setRerender((x) => !x);
                        return x;
                      })
                    }
                    archiveCountType={prompt.archiveCountType}
                    setArchiveCountType={(archiveCountType) =>
                      setPrompt((x) => {
                        if (x === undefined) {
                          x = new PromptScheduleViewModel();
                        }
                        x.archiveCountType = archiveCountType;
                        setRerender((x) => !x);
                        return x;
                      })
                    }
                  />
                )}
            </FormControl>
          </>
        )}

        {editingForm &&
          props.goal != null &&
          props.goal.goalCategory != null && (
            <FormControl className={classes.textField}>
              <InputLabel id={"label-for-goal-category-dropdown"}>
                Goal Category
              </InputLabel>
              <Select
                onChange={(e) =>
                  setGoalCategory(e.target.value as GoalCategory)
                }
                value={goalCategory}
                labelId={"label-for-goal-category-dropdown"}
                label="Goal Category"
                key={"question-form-goal-category"}
              >
                {Object.values(GoalCategory)
                  .filter((x) => !isNaN(Number(x)))
                  .map((x) => {
                    return (
                      <MenuItem
                        key={
                          "question-form-goal-category-" +
                          GoalCategory[Number(x)].toString()
                        }
                        value={x}
                      >
                        {ToTitleCase(
                          GoalCategory[Number(x)]
                            .toString()
                            .replace("AndOr", "/")
                        )}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          )}

        <div className={classes.actionButtonGroup}>
          <Button onClick={() => props.closeModal()} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={
              (isPromptChecked && !IsTimeValid(prompt?.timeOfDay)) ||
              unauthorised
            }
            onClick={async () => {
              setLoading(true);

              // Do validation checks
              var caseloadValid = IsCaseloadIdValid(caseloadId);
              var questionValid = IsQuestionValid(question);
              setCaseloadValidationError(!caseloadValid);
              setGoalQuestionValidationError(!questionValid);

              if (isPromptChecked) {
                if (prompt?.frequencyAmount == null) {
                  prompt!.frequencyAmount = 1;
                }
              }

              if (!caseloadValid || !questionValid) {
                setLoading(false);
                return;
              }

              var goalClient = new GoalClient(
                Configuration.SERVER_ROOT,
                FetchOverride
              );
              if (editingForm) {
                var goal = props.goal as GoalViewModel;
                goal.question = question;
                goal.promptSchedule = prompt;
                goal.owner = owner;
                goal.questionnaireId = props.goal?.questionnaireId;
                goal.goalCategory =
                  props.goal != null && props.goal.goalCategory != null
                    ? goalCategory
                    : undefined;
                goalClient.putGoal(goal).then(() => {
                  props.closeModal();
                  setLoading(false);
                });
              } else {
                goalClient
                  .createGoal({
                    patientId: props.patient.id,
                    caseloadId: caseloadId,
                    question: question,
                    promptSchedule: prompt,
                    owner: owner,
                    questionnaireId: props.questionnaireId,
                    goalCategory: props.goalCategory,
                    questionnaireResponseId: props.questionnaireResponseId,
                    submitted: props.questionnaireResponseId ? false : true,
                    episodeId: episodeId,
                  } as GoalViewModel)
                  .then(() => {
                    props.closeModal();
                    setLoading(false);
                  })
                  .catch(() => {
                    setUnauthorised(true);
                    setLoading(false);
                  });
              }
            }}
          >
            {buttonText}
          </LoadingButton>
        </div>
        {unauthorised && (
          <Alert severity="error">
            Your session has expired. Please save this questionnaire as a draft
            and reload it to create goals.
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default CreateEditGoalModal;
