import {
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext } from "react";
import { theme } from "../../../../Theme";
import { Gender, PatientViewModel, Role } from "../../../../types/auto/types";
import { UserContext } from "../../elements/stores/UserStore";
import {
  IsBirthDateValid,
  IsGenderValid,
  IsNameValid,
  IsNHSNumberValid,
  IsCustomPatientIdValid,
} from "./CreateEditPatientModalHelper";
import NHSNumberInput from "./NHSNumberInput";

interface Props {
  patient: PatientViewModel;
  setPatient: React.Dispatch<React.SetStateAction<PatientViewModel>>;
  firstNameError: boolean;
  setFirstNameError: React.Dispatch<React.SetStateAction<boolean>>;
  lastNameError: boolean;
  setLastNameError: React.Dispatch<React.SetStateAction<boolean>>;
  birthDateError: boolean;
  setBirthDateError: React.Dispatch<React.SetStateAction<boolean>>;
  genderError: boolean;
  setGenderError: React.Dispatch<React.SetStateAction<boolean>>;
  formattedPatientNumber: string;
  setFormattedPatientNumber: React.Dispatch<React.SetStateAction<string>>;
  nhsNumberError: boolean;
  setNhsNumberError: React.Dispatch<React.SetStateAction<boolean>>;
  setReRender: React.Dispatch<React.SetStateAction<boolean>>;
  nhsNumberRequired: boolean;
  customPatientId: boolean;
  formattedCustomPatientId: string;
  setFormattedCustomPatientId: React.Dispatch<React.SetStateAction<string>>;
  customPatientIdError: boolean;
  setCustomPatientIdError: React.Dispatch<React.SetStateAction<boolean>>;
  customPatientIdDisplayName: string;
  customPatientIdRegex: string;
  customPatientIdMandatory: boolean;
}

const PatientDetailsForm = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    textFieldContainer: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "row",
      gap: 10,
      width: "100%",
      marginBottom: 15,
    },
    textField: {
      width: "100%",
    },
  });
  const classes = useStyle(theme);
  const user = useContext(UserContext);

  return (
    <>
      <Container className={classes.textFieldContainer}>
        <TextField
          label="First Name"
          className={classes.textField}
          required
          value={
            props.patient.firstName === undefined ? "" : props.patient.firstName
          }
          onChange={(e) => {
            props.setPatient((x) => {
              x.firstName = e.target.value;
              props.setReRender((x) => !x);
              return x;
            });
            props.setFirstNameError(!IsNameValid(e.target.value));
          }}
          error={props.firstNameError}
          helperText={
            props.firstNameError && "Please provide a valid first name"
          }
        />
        <TextField
          label="Last Name"
          className={classes.textField}
          required
          value={
            props.patient.lastName === undefined ? "" : props.patient.lastName
          }
          onChange={(e) => {
            props.setPatient((x) => {
              x.lastName = e.target.value;
              props.setReRender((x) => !x);
              return x;
            });
            props.setLastNameError(!IsNameValid(e.target.value));
          }}
          error={props.lastNameError}
          helperText={props.lastNameError && "Please provide a valid last name"}
        />
      </Container>
      <Container className={classes.textFieldContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Birth Date *"
            openTo="year"
            value={props.patient.dateOfBirth}
            PopperProps={{
              placement: "bottom-start",
              modifiers: [
                {
                  name: "flip",
                  options: {
                    fallbackPlacements: ["top-start", "right"],
                  },
                },
              ],
            }}
            onChange={(newValue: Date | null) => {
              newValue != null &&
                props.setPatient((x) => {
                  x.dateOfBirth = newValue;
                  props.setReRender((x) => !x);
                  return x;
                });
              props.setBirthDateError(
                !IsBirthDateValid(props.patient.dateOfBirth)
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textField}
                error={props.birthDateError}
                helperText={
                  props.birthDateError && "Please enter a valid birth date"
                }
              />
            )}
            className={classes.textField}
            disableFuture={true}
            inputFormat="dd/MM/yyyy"
            disableMaskedInput
          />
        </LocalizationProvider>
        <FormControl fullWidth>
          <InputLabel>Gender *</InputLabel>
          <Select
            required
            error={props.genderError}
            value={
              props.patient.gender !== undefined ? props.patient.gender : ""
            }
            label="Gender"
            onChange={(e) => {
              props.setPatient((x) => {
                x.gender = e.target.value as Gender;
                props.setReRender((x) => !x);
                return x;
              });
              props.setGenderError(!IsGenderValid(props.patient.gender));
            }}
          >
            <MenuItem value={Gender.Male}>Male</MenuItem>
            <MenuItem value={Gender.Female}>Female</MenuItem>
            <MenuItem value={Gender.Other}>Other</MenuItem>
            <MenuItem value={Gender.Unknown}>Unknown</MenuItem>
          </Select>
          <FormHelperText>
            {props.genderError && "Please enter a gender"}
          </FormHelperText>
        </FormControl>
      </Container>
      <Container className={classes.textFieldContainer}>
        <TextField
          label="NHS Number"
          className={classes.textField}
          style={props.customPatientId ? {} : { width: "50%" }}
          required={props.nhsNumberRequired}
          value={props.formattedPatientNumber}
          onChange={(e) => {
            props.setFormattedPatientNumber((e.target as any).formattedValue);
            props.setPatient((x) => {
              e.target.value === ""
                ? (x.nhsNumber = undefined)
                : (x.nhsNumber = e.target.value as any as number);
              props.setReRender((x) => !x);
              return x;
            });
          }}
          onBlur={() => {
            props.setNhsNumberError(
              !IsNHSNumberValid(
                props.patient.nhsNumber,
                props.nhsNumberRequired
              )
            );
          }}
          error={props.nhsNumberError}
          helperText={
            props.nhsNumberError && "Please provide a valid NHS number"
          }
          InputProps={{
            inputComponent: NHSNumberInput,
          }}
        />
        {props.customPatientId &&
          (user.role === Role.Clinician ||
            user.role === Role.Admin ||
            user.role === Role.SuperAdmin) && (
            <Container className={classes.textFieldContainer}>
              <TextField
                label={props.customPatientIdDisplayName}
                className={classes.textField}
                required={props.customPatientIdMandatory}
                value={props.formattedCustomPatientId}
                onChange={(e) => {
                  props.setFormattedCustomPatientId(e.target.value);
                  props.setPatient((x) => {
                    e.target.value === ""
                      ? (x.customPatientId = undefined)
                      : (x.customPatientId = e.target.value);
                    props.setReRender((x) => !x);
                    return x;
                  });
                  props.setCustomPatientIdError(
                    !IsCustomPatientIdValid(
                      e.target.value,
                      props.customPatientIdRegex,
                      props.customPatientIdMandatory
                    )
                  );
                }}
                error={props.customPatientIdError}
                helperText={
                  props.customPatientIdError &&
                  "Please provide a valid " +
                    props.customPatientIdDisplayName +
                    "."
                }
                inputProps={{ maxLength: 50 }}
              />
            </Container>
          )}
        {props.patient.id !== undefined &&
          !props.customPatientId &&
          (user.role === Role.Admin || user.role === Role.SuperAdmin) && (
            <FormControlLabel
              control={
                <Switch
                  checked={!props.patient.active}
                  onChange={(e) => {
                    props.setPatient((x) => {
                      x.active = !e.target.checked;
                      props.setReRender((x) => !x);
                      return x;
                    });
                  }}
                  name="archived"
                />
              }
              label="Archived"
            />
          )}
      </Container>
      {props.patient.id !== undefined &&
        props.customPatientId &&
        (user.role === Role.Admin || user.role === Role.SuperAdmin) && (
          <FormControlLabel
            control={
              <Switch
                checked={!props.patient.active}
                onChange={(e) => {
                  props.setPatient((x) => {
                    x.active = !e.target.checked;
                    props.setReRender((x) => !x);
                    return x;
                  });
                }}
                name="archived"
              />
            }
            label="Archived"
          />
        )}
    </>
  );
};

export default PatientDetailsForm;
