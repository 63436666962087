import { useState, useRef } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  MobileStepper,
  Button,
  Theme,
  Tooltip,
  tooltipClasses,
  mobileStepperClasses,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../Theme";
import { SectionViewModel } from "../../../types/auto/types";

interface Props {
  totalSections: number;
  sectionNumber: number;
  sections: SectionViewModel[] | undefined;
  navigateSection: (value: number, override?: boolean) => void;
  inaccessibleSections: number[];
  notValidatedSections: number[];
  submitButtonLoad: boolean;
  preview?: boolean;
  isNewResponse: boolean;
  backgroundColour?: string;
}

export const QuestionnaireFooter = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    stepper: {
      backgroundColor: props.backgroundColour ?? "#EAE8E1",
      marginBottom: "10px",
      [`& .${mobileStepperClasses.dot}:not(.${mobileStepperClasses.dotActive})`]:
        {
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgba(10, 174, 245, 0.5)",
            transition:
              "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },
        },
    },
    sectionToolTip: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
        {
          marginTop: "-5px",
        },
      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
        {
          transform: `translate(0, calc(100px + (${
            (document.getElementById("root")?.getBoundingClientRect().height ??
              0) - 75
          }px - 100vh))) !important`,
          transition: "cubic-bezier(0.4, 0, 0.2, 1) !important",
        },
    },
    navButton: {
      width: "100px",
    },
    inaccessibleStep: {
      cursor: "auto !important",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.1) !important",
      },
    },
    notValidatedStep: {
      backgroundColor: "rgba(211, 47, 47, 0.75)",
      [`&.${mobileStepperClasses.dotActive}`]: {
        backgroundColor: "#005f8f",
      },
    },
  }));
  const classes = useStyle(customTheme);
  const [toolTip, setToolTip] = useState("");
  const stepperRef = useRef<HTMLInputElement>(null);
  const setInaccessible = () => {
    props.sections?.forEach((section, index) => {
      if (stepperRef.current !== null) {
        let classListOfIndex = stepperRef.current?.getElementsByClassName(
          mobileStepperClasses.dot
        )[index]?.classList;
        if (classListOfIndex !== undefined)
          if (props.inaccessibleSections.includes(index))
            classListOfIndex.add(classes.inaccessibleStep);
          else if (classListOfIndex.contains(classes.inaccessibleStep))
            classListOfIndex.remove(classes.inaccessibleStep);
      }
    });
  };
  const setNotValidated = () => {
    props.sections?.forEach((section, index) => {
      if (stepperRef.current !== null) {
        let classListOfIndex = stepperRef.current?.getElementsByClassName(
          mobileStepperClasses.dot
        )[index]?.classList;
        if (classListOfIndex !== undefined)
          if (!classListOfIndex.contains(classes.inaccessibleStep))
            if (props.notValidatedSections.includes(index))
              classListOfIndex.add(classes.notValidatedStep);
            else if (classListOfIndex.contains(classes.notValidatedStep))
              classListOfIndex.remove(classes.notValidatedStep);
      }
    });
  };
  var nextButton =
    props.sectionNumber === props.totalSections - 1
      ? props.preview
        ? "Calculate Scores"
        : "Submit"
      : "Next";
  var previousButton = props.sectionNumber < 1 ? "Cancel" : "Back";

  setInaccessible();
  setNotValidated();
  return (
    <Box key="footer-box">
      <Tooltip
        open={true}
        title={toolTip}
        enterTouchDelay={0}
        placement="bottom"
        classes={{ popper: classes.sectionToolTip }}
      >
        <MobileStepper
          ref={stepperRef}
          className={classes.stepper}
          variant="dots"
          steps={props.totalSections}
          position="static"
          activeStep={props.sectionNumber}
          onMouseOver={(x) => {
            if (
              x.target instanceof HTMLDivElement &&
              x.target.parentNode?.childNodes !== undefined &&
              x.target.classList.contains(mobileStepperClasses.dot) &&
              !x.target.classList.contains(mobileStepperClasses.dotActive) &&
              !x.target.classList.contains(classes.inaccessibleStep)
            ) {
              setToolTip(
                (props.sections || [])[
                  Array.from(x.target.parentNode?.childNodes).indexOf(x.target)
                ].heading ||
                  `Section ${
                    Array.from(x.target.parentNode?.childNodes).indexOf(
                      x.target
                    ) + 1
                  }`
              );
            }
          }}
          onMouseOut={() => {
            setToolTip("");
          }}
          onClick={(x) => {
            if (
              x.target instanceof HTMLDivElement &&
              x.target.parentNode?.childNodes !== undefined &&
              x.target.classList.contains(mobileStepperClasses.dot) &&
              !x.target.classList.contains(mobileStepperClasses.dotActive) &&
              !x.target.classList.contains(classes.inaccessibleStep)
            ) {
              props.navigateSection(
                Array.from(x.target.parentNode?.childNodes).indexOf(x.target),
                true
              );
              setToolTip("");
            }
          }}
          nextButton={
            <LoadingButton
              className={classes.navButton}
              variant="contained"
              onClick={(e) => {
                props.navigateSection(props.sectionNumber + 1, true);
              }}
              loading={props.submitButtonLoad}
            >
              {nextButton}
            </LoadingButton>
          }
          backButton={
            <Button
              className={classes.navButton}
              variant="contained"
              onClick={(e) => {
                props.navigateSection(
                  props.sectionNumber - 1,
                  !props.isNewResponse
                );
              }}
            >
              {previousButton}
            </Button>
          }
        />
      </Tooltip>
    </Box>
  );
};

export default QuestionnaireFooter;
