import { Container, Paper, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  StaffClient,
  PatientClient,
  PatientViewModel,
  Role,
  TenantClient,
  TenantOptionsViewModel,
  PatientTab,
  TenantViewModel,
} from "../../types/auto/types";
import { Configuration, DefaultCustomIdDisplayName } from "../Constants";
import { FetchOverride } from "../utils/Request";
import { theme as customTheme } from "../../Theme";
import ErrorComponent from "../components/elements/errors/ErrorComponent";
import ViewPatientDetails from "../components/patient/patient-details/ViewPatientDetails";
import ViewPatientQuestionnaire from "../components/patient/patient-details/ViewPatientQuestionnaire";
import PatientNavBar from "../components/patient/nav/PatientNavBar";
import ScheduleQuestionnaire from "../components/schedule/ScheduleQuestionnaire";
import ViewPatientTimeline from "../components/patient/patient-details/ViewPatientTimeline";
import Goals from "../components/goals/Goals";
import GraphsPage from "../components/patient/graphs/GraphsPage";
import { UserContext } from "../components/elements/stores/UserStore";
import { useMountedState, useUnmountPromise } from "react-use";
import ViewPatientNotes from "../components/patient/notes/ViewPatientNotes";
import ViewPatientMedications from "../components/patient/medication/ViewPatientMedications";
import PatientDashboard from "../components/patient/dashboard/PatientDashboard";
import ViewPatientDataset from "../components/patient/datasets/ViewPatientDataset";

const tenantClient = new TenantClient(Configuration.SERVER_ROOT, FetchOverride);

const useStyle = makeStyles((th: Theme) => ({
  patientFullName: {
    marginRight: "auto",
    padding: 10,
    alignSelf: "flex-end",
    paddingLeft: 20,
    paddingRight: 20,
  },
  errorContainer: {
    width: "100%",
    textAlign: "center",
  },
  errorText: {
    color: "red",
  },
  topContainer: {
    marginTop: 10,
    display: "flex",
    marginBottom: 10,
    borderRadius: "4px",
    borderWidth: "thin",
    borderColor: th.palette.grey[50],
    borderStyle: "solid",
    "@media print": { display: "none" },
  },
  mainContainer: {
    paddingRight: 0,
    paddingLeft: 0,
  },
}));
const ViewPatient = () => {
  const user = useContext(UserContext);
  const classes = useStyle(customTheme);
  const [searchParams] = useSearchParams();
  const patientIdNumerical = parseInt(searchParams.get("p") ?? "-1");
  const [errorNoPatient, setErrorNoPatient] = useState(false);
  const [errorUnauthorized, setErrorUnauthorized] = useState(false);
  const [serverError, setServerError] = useState(false);
  const isReadOnly =
    user.role === Role.ReadOnly || user.role === Role.ReadOnlyPIRedacted;
  var isStaff =
    user.role === Role.SuperAdmin ||
    user.role === Role.Admin ||
    user.role === Role.Clinician ||
    user.role === Role.ReadOnly ||
    user.role === Role.ReadOnlyPIRedacted;
  const [tab, setTab] = useState<PatientTab>(
    parseInt(searchParams.get("t") ?? (isStaff ? "0" : "1"))
  );
  const [patient, setPatient] = useState<PatientViewModel>();
  const [integration, setIntegration] = useState<boolean>(true);
  const [medicationSupported, setMedicationSupported] =
    useState<boolean>(false);
  const [notesSupported, setNotesSupported] = useState<boolean>(false);
  const [dashboardSupported, setDashboardSupported] = useState<boolean>(false);
  const [customPatientIdSupported, setCustomPatientIdSupported] =
    useState<boolean>(false);
  const [externalDataSupported, setExternalDataSupported] =
    useState<boolean>(false);
  const [nhsNumberRequired, setNhsNumberRequired] = useState<boolean>(false);
  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();
  const paperContainerRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLDivElement>(null);

  const [tenancyOptions, setTenancyOptions] = useState<
    TenantOptionsViewModel | undefined
  >(undefined);

  const reloadPatient = useCallback(async () => {
    await resolveWhileMounted(tenantClient.getTenant(undefined))
      .then((tenant: TenantViewModel) => {
        setServerError(false);
        const tenantOptions = tenant.tenantOptions;
        setTenancyOptions(tenantOptions);
        if (tenantOptions != null) {
          if (!isStaff && tenantOptions.patientTabOptions != null) {
            setTab(tenantOptions?.patientTabOptions[0]);
          }
          if (isStaff) {
            setIntegration(tenantOptions.integration ?? false);
          }
          setMedicationSupported(tenantOptions.allowMedication ?? false);
          setNotesSupported(tenantOptions.allowNotes ?? false);
          setDashboardSupported(tenantOptions.allowDashboard ?? false);
          setCustomPatientIdSupported(tenantOptions.customPatientId != null);
          setExternalDataSupported(tenantOptions.allowExternalData ?? false);
          setNhsNumberRequired(tenantOptions.nhsNumberRequired ?? false);
        }
      })
      .catch((e) => setServerError(true));

    if (user?.role === Role.Admin || user?.role === Role.SuperAdmin) {
      await resolveWhileMounted(
        new PatientClient(Configuration.SERVER_ROOT, FetchOverride).getPatient(
          patientIdNumerical
        )
      )
        .then((usr: PatientViewModel) => {
          setPatient(usr);
        })
        .catch((e) => {
          if (isMountedState()) {
            if (e.status === 400) {
              setErrorNoPatient(true);
            } else {
              setServerError(true);
            }
          }
        });
      return;
    } else if (user?.role === Role.Clinician || user?.role === Role.ReadOnly) {
      await resolveWhileMounted(
        new StaffClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).isClinicianLinkedToPatient(patientIdNumerical)
      ).then(async (isLinked) => {
        if (isLinked) {
          await resolveWhileMounted(
            new PatientClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getPatient(patientIdNumerical)
          )
            .then((usr: PatientViewModel) => {
              setPatient(usr);
            })
            .catch((e) => {
              if (e.status === 400) {
                setErrorNoPatient(true);
              } else {
                setServerError(true);
              }
            });
        } else {
          if (isMountedState()) setErrorUnauthorized(true);
        }
      });
    } else if (user?.role === Role.ReadOnlyPIRedacted) {
      await resolveWhileMounted(
        new StaffClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).isClinicianLinkedToPatient(patientIdNumerical)
      ).then(async (isLinked) => {
        if (isLinked) {
          await resolveWhileMounted(
            new PatientClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getPIRedactedPatient(patientIdNumerical)
          )
            .then((usr: PatientViewModel) => {
              setPatient(usr);
            })
            .catch((e) => {
              if (e.status === 400) {
                setErrorNoPatient(true);
              } else {
                setServerError(true);
              }
            });
        } else {
          if (isMountedState()) setErrorUnauthorized(true);
        }
      });
    } else {
      await resolveWhileMounted(
        new PatientClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).getCurrentPatient()
      )
        .then((usr: PatientViewModel) => {
          setPatient(usr);
        })
        .catch((e) => {
          if (e.status === 400) {
            setErrorNoPatient(true);
          } else {
            setServerError(true);
          }
        });
    }
  }, [
    isMountedState,
    resolveWhileMounted,
    patientIdNumerical,
    user?.role,
    isStaff,
  ]);

  useEffect(
    () => {
      const effect = async () => {
        if (isMountedState() && patient === undefined && user !== undefined) {
          await reloadPatient();
        }
      };
      effect();
    },
    // eslint-disable-next-line
    [
      patient,
      patientIdNumerical,
      setErrorNoPatient,
      setErrorUnauthorized,
      isMountedState,
      resolveWhileMounted,
      reloadPatient,
    ]
  );

  const tabIsEnabled = (tab: PatientTab) => {
    if (user === undefined) {
      return false;
    }
    if (isStaff) {
      return true;
    } else if (user.role === Role.Patient) {
      // case 1: unloaded or no options
      if (tenancyOptions === null || tenancyOptions === undefined) {
        return false;
      }
      // case 2: no configuratrion set
      if (tenancyOptions.patientTabOptions === undefined) {
        return true;
      }
      // case 3: configuration set
      return (
        tenancyOptions.patientTabOptions.find((x) => x === tab) !== undefined
      );
    }
    return false;
  };

  const getEnabledTabs = () => {
    if (isStaff) {
      return undefined;
    } else if (user.role === Role.Patient) {
      // case 1: unloaded or no options
      if (tenancyOptions === null || tenancyOptions === undefined) {
        return [];
      }
      // case 2: no configuration set
      if (tenancyOptions.patientTabOptions === undefined) {
        return undefined;
      }
      // case 3: configuration set
      return tenancyOptions.patientTabOptions;
    }
    return [];
  };

  const PaperWidth = () => {
    return paperContainerRef.current?.clientWidth ?? 0;
  };

  const NameWidth = () => {
    return nameRef.current?.clientWidth ?? 0;
  };

  const [firstNav, setFirstNav] = useState<boolean>(true);

  useEffect(() => {
    if (!firstNav && user.role !== Role.Patient) {
      window.history.replaceState(
        { reason: "update tab number" },
        "",
        window.location.href.replace(RegExp("\\&[a-z]\\=[0-9]+"), "") +
          "&t=" +
          tab.toString()
      );
    }
    setFirstNav(false);
  }, [tab, firstNav, user.role]);

  return (
    <>
      {!errorNoPatient && !errorUnauthorized && !serverError && (
        <Container className={classes.mainContainer} disableGutters>
          <div ref={paperContainerRef}>
            <Paper className={classes.topContainer} elevation={0}>
              <Typography className={classes.patientFullName} ref={nameRef}>
                {patient?.firstName} {patient?.lastName}
              </Typography>
              <PatientNavBar
                getContainerWidth={PaperWidth}
                getNameWidth={NameWidth}
                tab={tab}
                setTab={setTab}
                medicationSupported={medicationSupported}
                externalDatasetSupported={externalDataSupported}
                dashboardSupported={dashboardSupported}
                user={user}
                enabledTabs={getEnabledTabs()}
                notesSupported={notesSupported}
              />
            </Paper>
          </div>
          <Container className={classes.mainContainer}>
            {patient &&
              (tab === PatientTab.Details ? (
                isStaff ? (
                  <ViewPatientDetails
                    patient={patient}
                    integration={integration}
                    reloadPatient={reloadPatient}
                    setPatient={setPatient}
                    dashboardSupported={dashboardSupported}
                    user={user}
                    nhsNumberRequired={nhsNumberRequired}
                    customPatientId={customPatientIdSupported}
                    customPatientIdDisplayName={
                      tenancyOptions?.customPatientId?.displayName ??
                      DefaultCustomIdDisplayName
                    }
                    customPatientIdRegex={
                      tenancyOptions?.customPatientId?.regex ?? ""
                    }
                    customPatientIdMandatory={
                      tenancyOptions?.customPatientId?.mandatory ?? false
                    }
                  />
                ) : (
                  <></>
                )
              ) : tab === PatientTab.Dashboard &&
                tabIsEnabled(PatientTab.Dashboard) &&
                !isStaff &&
                dashboardSupported ? (
                <PatientDashboard patient={patient} />
              ) : tab === PatientTab.Schedule ? (
                isStaff ? (
                  <ScheduleQuestionnaire
                    patient={patient}
                    readonly={isReadOnly}
                  />
                ) : (
                  <></>
                )
              ) : tab === PatientTab.Goals && tabIsEnabled(PatientTab.Goals) ? (
                <Goals patient={patient} user={user} />
              ) : tab === PatientTab.Questionnaires ? (
                <ViewPatientQuestionnaire patient={patient} user={user} />
              ) : tab === PatientTab.Graphs &&
                tabIsEnabled(PatientTab.Graphs) ? (
                <GraphsPage
                  patient={patient}
                  user={user}
                  tenancyOptions={tenancyOptions}
                />
              ) : tab === PatientTab.Timeline &&
                tabIsEnabled(PatientTab.Timeline) ? (
                <ViewPatientTimeline
                  patient={patient}
                  user={user}
                  printingTemplateQuestionnaires={
                    tenancyOptions?.questionnairesWithPrintingTemplateEnabled ??
                    []
                  }
                />
              ) : tab === PatientTab.Notes &&
                tabIsEnabled(PatientTab.Notes) &&
                notesSupported ? (
                <ViewPatientNotes patient={patient} user={user} />
              ) : tab === PatientTab.Medication &&
                tabIsEnabled(PatientTab.Medication) &&
                medicationSupported ? (
                <ViewPatientMedications patient={patient} user={user} />
              ) : tab === PatientTab.Datasets &&
                tabIsEnabled(PatientTab.Datasets) ? (
                <ViewPatientDataset patient={patient} user={user} />
              ) : (
                <></>
              ))}
          </Container>
        </Container>
      )}
      {errorNoPatient && (
        <ErrorComponent errorText="No patient with this Id." />
      )}
      {errorUnauthorized && (
        <ErrorComponent errorText="You are not authorized to view this patient. View patient via search entering a valid reason or contact an admin to be linked to the patient." />
      )}
      {serverError && (
        <ErrorComponent errorText="An unexpected server error occurred, please try again later." />
      )}
    </>
  );
};

export default ViewPatient;
