import {
  Card,
  CardContent,
  Typography,
  Container,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  QuestionnaireClient,
  QuestionnaireVersionViewModel,
} from "../../../types/auto/types";
import {
  Publish,
  Delete,
  Edit,
  Preview,
  Print,
  FileDownload,
} from "@mui/icons-material";
import { CheckIsMobile } from "../../utils/MobileStatus";
import moment from "moment";
import { Configuration } from "../../Constants";
import QuestionnaireVersionDeleteModal from "./QuestionnaireVersionDeleteModal";
import QuestionnaireVersionPublishModal from "./QuestionnaireVersionPublishModal";
import QuestionnaireVersionOverwriteModal from "./QuestionnaireVersionOverwriteModal";
import QuestionnaireVersionOverwritePublishModal from "./QuestionnaireVersionOverwritePublishModal";
import React from "react";
import { FetchOverride } from "../../utils/Request";

interface Props {
  questionnaireVersion: QuestionnaireVersionViewModel;
  resetQuestionnaireVersions: () => void;
  editQuestionnaire: (qId: number, v: number) => void;
  printingTemplateEnabled: boolean;
  hasPublishedVersion: boolean;
  questionnaireName: string;
}

const QuestionnaireVersionCard = (props: Props): JSX.Element => {
  const previewQuestionnaire = () => {
    window.location.href =
      Configuration.SITEBASE +
      "/previewQuestionnaire?qId=" +
      props.questionnaireVersion.questionnaireId +
      "&v=" +
      props.questionnaireVersion.version;
  };

  const customPrintingTemplate = () => {
    window.location.href =
      Configuration.SITEBASE +
      "/printingTemplate?qId=" +
      props.questionnaireVersion.questionnaireId +
      "&v=" +
      props.questionnaireVersion.version;
  };

  const exportQuestionnaire = async () => {
    const exportFile = await new QuestionnaireClient(
      Configuration.SERVER_ROOT,
      FetchOverride
    ).exportQuestionnaireVersion(
      props.questionnaireVersion.questionnaireId,
      props.questionnaireVersion.version
    );

    const blob = new Blob([JSON.stringify(exportFile)], {
      type: "application/json",
    });
    let a = document.createElement("a");
    a.download = `${props.questionnaireName}_${props.questionnaireVersion.version}_export.json`;
    a.href = window.URL.createObjectURL(blob);
    a.click();
  };

  const [versionDeleteModalOpen, setVersionDeleteModalOpen] =
    React.useState(false);
  const [
    versionOverwritePublishModalOpen,
    setVersionOverwritePublishModalOpen,
  ] = React.useState(false);
  const [versionPublishModalOpen, setVersionPublishModalOpen] =
    React.useState(false);
  const [versionOverwriteModalOpen, setVersionOverwriteModalOpen] =
    React.useState(false);

  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    cardContent: {
      display: isMobile ? "block" : "flex",
      padding: "6px",
      paddingLeft: "12px",
      "&:last-child": {
        paddingBottom: 5,
      },
    },
    publishedContainer: {
      textAlign: "right",
      width: "100%",
      paddingTop: "5px",
    },
    buttonContainer: {
      display: "flex",
      padding: 0,
      width: isMobile ? "" : "fit-content",
      marginRight: isMobile ? "" : 0,
    },
    publishButton: {
      marginLeft: isMobile ? "" : "auto",
      width: isMobile ? "33%" : "",
    },
    editButton: {
      marginLeft: "10px",
      width: isMobile ? "33%" : "",
    },
    deleteButton: {
      backgroundColor: "red",
      "&:hover": {
        backgroundColor: "crimson",
      },
      marginLeft: "10px",
      width: isMobile ? "33%" : "",
    },
    previewButton: {
      marginLeft: "10px",
      width: isMobile ? "33%" : "",
    },
    printingTemplateButton: {
      marginLeft: "10px",
      width: isMobile ? "33%" : "",
    },
    exportButton: {
      marginLeft: "10px",
      width: isMobile ? "33%" : "",
    },
  });
  const classes = useStyle();
  return (
    <Card variant="outlined" elevation={0}>
      <CardContent className={classes.cardContent}>
        <Typography paddingTop={"5px"} fontSize={"20px"}>
          {props.questionnaireVersion.version}
        </Typography>
        <Button
          className={classes.previewButton}
          aria-label="Preview questionnaire version"
          variant="contained"
          onClick={previewQuestionnaire}
        >
          <Preview />
        </Button>
        {props.printingTemplateEnabled && (
          <Button
            className={classes.printingTemplateButton}
            aria-label="View printing template"
            variant="contained"
            onClick={() => customPrintingTemplate()}
          >
            <Print />
          </Button>
        )}
        <Button
          className={classes.exportButton}
          aria-label="Export questionnaire version"
          variant="contained"
          onClick={exportQuestionnaire}
        >
          <FileDownload />
        </Button>
        {props.questionnaireVersion.published != null && (
          <Typography className={classes.publishedContainer}>
            {`Published on ${moment(
              props.questionnaireVersion.published
            ).format("DD/MM/YYYY hh:mm A")}`}
          </Typography>
        )}
        {props.questionnaireVersion.published == null && (
          <Container className={classes.buttonContainer} disableGutters>
            <Button
              className={classes.publishButton}
              aria-label="Publish / Overwrite questionnaire version"
              variant="contained"
              onClick={() => {
                if (!props.hasPublishedVersion) {
                  setVersionPublishModalOpen(true);
                } else {
                  setVersionOverwritePublishModalOpen(true);
                }
              }}
            >
              <Publish />
            </Button>
            <Button
              className={classes.editButton}
              aria-label="Edit questionnaire version"
              variant="contained"
              onClick={() => {
                props.editQuestionnaire(
                  props.questionnaireVersion.questionnaireId,
                  props.questionnaireVersion.version
                );
              }}
            >
              <Edit />
            </Button>
            <Button
              className={classes.deleteButton}
              aria-label="Delete questionnaire version"
              variant="contained"
              onClick={() => {
                setVersionDeleteModalOpen(true);
              }}
            >
              <Delete />
            </Button>
          </Container>
        )}
      </CardContent>
      <QuestionnaireVersionDeleteModal
        questionnaireVersionToDelete={props.questionnaireVersion}
        open={versionDeleteModalOpen}
        closeModal={() => setVersionDeleteModalOpen(false)}
        refresh={() => props.resetQuestionnaireVersions()}
      />
      <QuestionnaireVersionOverwritePublishModal
        questionnaireVersionToEdit={props.questionnaireVersion}
        open={versionOverwritePublishModalOpen}
        closeModal={() => setVersionOverwritePublishModalOpen(false)}
        refresh={() => props.resetQuestionnaireVersions()}
        versionPublishModalOpen={versionPublishModalOpen}
        setVersionPublishModalOpenTrue={() => setVersionPublishModalOpen(true)}
        versionOverwriteModalOpen={versionOverwriteModalOpen}
        setVersionOverwriteModalOpenTrue={() =>
          setVersionOverwriteModalOpen(true)
        }
      />
      <QuestionnaireVersionPublishModal
        questionnaireVersionToEdit={props.questionnaireVersion}
        open={versionPublishModalOpen}
        closeModal={() => setVersionPublishModalOpen(false)}
        refresh={() => props.resetQuestionnaireVersions()}
      />
      <QuestionnaireVersionOverwriteModal
        questionnaireVersionToEdit={props.questionnaireVersion}
        open={versionOverwriteModalOpen}
        closeModal={() => setVersionOverwriteModalOpen(false)}
        refresh={() => props.resetQuestionnaireVersions()}
      />
    </Card>
  );
};

export default QuestionnaireVersionCard;
