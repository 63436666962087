import { Container, Divider, Slider, Theme, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { PatientViewModel } from "../../../../types/auto/types";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import {
  SentimentDissatisfied,
  SentimentVeryDissatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
} from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";

interface Props {
  patient?: PatientViewModel;
  values: number[];
  score: number;
  title: string;
  onChangeSeverityIndex?: (index: number, colour: string) => void;
}

interface Mark {
  value: number;
  label?: React.ReactNode;
}

interface GeneratedStyling {
  primaryColour: string;
  primarySentiment: React.ReactNode;
  sliderBackground: string;
}

const DashboardScoreDisplay = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    container: {},
    topContainer: {
      marginBottom: "10px",
      padding: "10px",
      paddingBottom: "20px",
      color: "white",
    },
    header: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      margin: "5px",
    },
    scoreContainer: {
      padding: "10px",
    },
  }));

  const classes = useStyle(theme);
  const [marks, setMarks] = useState<Mark[]>();
  const [generatedStyling, setGeneratedStyling] = useState<GeneratedStyling>();

  const getValueDirection = useCallback(() => {
    return props.values[0] > props.values[props.values.length - 1];
  }, [props.values]);
  const getValueMax = useCallback(() => {
    return getValueDirection()
      ? props.values[0]
      : props.values[props.values.length - 1];
  }, [getValueDirection, props.values]);

  const generateMarks = useCallback(() => {
    var allValues: Mark[] = [];

    var generateMain = () => {
      props.values.forEach((value) => {
        allValues.push({
          value: getValueDirection() ? getValueMax() - value : value,
          label: value,
        });
      });
    };

    if (getValueDirection()) {
      generateMain();
      allValues.push({
        value: getValueMax(),
        label: 0,
      });
    } else {
      allValues.push({
        value: 0,
        label: 0,
      });
      generateMain();
    }

    return allValues;
  }, [getValueDirection, getValueMax, props.values]);

  const generateStyling = useCallback(
    (allValues: Mark[]) => {
      const total = allValues?.length;
      if (total === undefined) {
        return;
      }

      var backgroundElements: string[] = [];
      var colours = ["darkgreen", "green", "orange", "red"];
      var sentiments = [
        <SentimentVerySatisfied sx={{ fontSize: "5rem" }} />,
        <SentimentSatisfiedAlt sx={{ fontSize: "5rem" }} />,
        <SentimentDissatisfied sx={{ fontSize: "5rem" }} />,
        <SentimentVeryDissatisfied sx={{ fontSize: "5rem" }} />,
      ];

      var primaryColour = colours[colours.length - 1];
      var primarySentiment = sentiments[sentiments.length - 1];
      if (getValueDirection()) {
        primaryColour = colours[0];
        primarySentiment = sentiments[0];
      }

      // create the linear gradients for the slider background
      for (let index = total - 2; index >= 0; index--) {
        var percentage = (100 / getValueMax()) * Number(allValues[index].value);
        var colour = colours[index + (colours.length - (allValues.length - 1))];
        var sentiment =
          sentiments[index + (colours.length - (allValues.length - 1))];
        backgroundElements.push(
          `linear-gradient(to right, transparent ${percentage}%, ${colour} ${percentage}%)`
        );

        // figure out what colour band we are currently in
        if (getValueDirection()) {
          if (props.score >= Number(allValues[index + 1].label)) {
            primaryColour = colour;
            primarySentiment = sentiment;
            if (props.onChangeSeverityIndex) {
              props.onChangeSeverityIndex(index, primaryColour);
            }
          }
        } else {
          if (Number(allValues[index + 1].value) > props.score) {
            primaryColour = colour;
            primarySentiment = sentiment;
            if (props.onChangeSeverityIndex) {
              props.onChangeSeverityIndex(index, primaryColour);
            }
          }
        }
      }

      var outputCss = "";
      for (var i = 0; i < backgroundElements.length - 1; i++) {
        outputCss += backgroundElements[i] + ", ";
      }
      outputCss += backgroundElements[backgroundElements.length - 1];

      setGeneratedStyling({
        primaryColour: primaryColour,
        primarySentiment: primarySentiment,
        sliderBackground: outputCss,
      });
    },
    [getValueDirection, getValueMax, props]
  );

  useEffect(() => {
    var allValues = generateMarks();
    generateStyling(allValues);
    setMarks(allValues);
  }, [generateMarks, generateStyling, props.score]);

  const backgroundStyling = () => {
    return {
      background: generatedStyling!.sliderBackground,
      borderRadius: "0%",
      "&.Mui-disabled": {
        ".MuiSlider-thumb": {
          opacity: 1.0,
          color: "white",
          borderRadius: "0%",
          rotate: "45deg",

          // 'magic numbers' to correct rotation misalignment
          marginLeft: "-0.64em",
          top: "1.18em",
        },
        color: `rgba(0, 0, 0, 0.0)`,
      },
    };
  };

  return (
    <>
      <Container className={classes.container}>
        <Container
          className={classes.topContainer}
          style={{
            backgroundColor:
              generatedStyling === undefined
                ? undefined
                : generatedStyling.primaryColour,
          }}
        >
          <Typography className={classes.header} variant="h5" component="h5">
            TODAY'S SCORE
          </Typography>

          <Grid container direction="row">
            <Grid xs>
              <Typography variant="h2" component="h2" align="center">
                {props.score}
              </Typography>
              <Typography variant="h5" component="h5" align="center">
                {props.title}
              </Typography>
            </Grid>
            <Grid
              xs={2}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Divider
                orientation="vertical"
                style={{
                  height: "100%",
                  width: "2px",
                  opacity: "100%",
                }}
              />
            </Grid>
            <Grid
              xs
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {generatedStyling === undefined ? (
                <></>
              ) : (
                generatedStyling.primarySentiment
              )}
            </Grid>
          </Grid>
        </Container>

        {props.values.length < 5 && props.values.length > 0 ? (
          <Slider
            disabled
            value={
              getValueDirection() ? getValueMax() - props.score : props.score
            }
            max={getValueMax()}
            min={0}
            marks={marks}
            aria-label="Disabled slider"
            sx={backgroundStyling()}
          />
        ) : (
          <Typography color="red">Invalid values for score display.</Typography>
        )}
      </Container>
    </>
  );
};

export default DashboardScoreDisplay;
