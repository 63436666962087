import { useEffect, useState } from "react";
import {
  QuestionnaireBuilderDataModel,
  ScoringDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import ScoringSelector from "./ScoringSelector";
import {
  QuestionnaireViewModel,
  QuestionType,
} from "../../../../types/auto/types";

interface Props {
  open: boolean;
  questionnaire: QuestionnaireBuilderDataModel;
  allQuestionnaires: QuestionnaireViewModel[];
  handleCancel: () => void;
  setQuestionnaireWIP: (scoring: ScoringDataModel[]) => void;
  keepAlive: () => void;
}

const QuestionnaireScoringModal = (props: Props): JSX.Element => {
  const [scoringWIP, setScoringWIP] = useState<ScoringDataModel[]>(
    props.questionnaire.scoring
  );
  const [allQuestionsArr, setAllQuestionsArr] = useState<number[]>([]);
  const [questionsDropdown] = useState<JSX.Element[]>(buildQuestionDropdown);

  function compareFn(a: number, b: number) {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
    return 0;
  }

  function buildQuestionDropdown() {
    var qDrop: JSX.Element[] = [
      <MenuItem value={-1} key={"question-placeholder"}>
        <em>Question</em>
      </MenuItem>,
    ];
    var allQuestions: number[] = [];
    for (let i = 0; i < props.questionnaire.sections.length; i++) {
      if (props.questionnaire.sections[i].questions.length === 0) {
        continue;
      } else {
        for (
          let x = 0;
          x < props.questionnaire.sections[i].questions.length;
          x++
        ) {
          if (
            props.questionnaire.sections[i].questions[x].questionType ===
            QuestionType.ReadOnly
          ) {
            continue;
          }

          const index = props.questionnaire.sections[i].questions[x].number;
          const text = "Question " + index;
          qDrop.push(
            <MenuItem value={index} key={"question-" + index}>
              {text}
            </MenuItem>
          );
          allQuestions.push(
            props.questionnaire.sections[i].questions[x].number
          );
        }
      }
    }
    setAllQuestionsArr(allQuestions);
    return qDrop;
  }

  function handleChange(
    index: number,
    scoreCategory: ScoringDataModel,
    arrayString: string
  ) {
    var deepCopy = structuredClone(scoringWIP);
    deepCopy[index] = structuredClone(scoreCategory);
    const sortedArr: number[] | undefined = deepCopy[index].questions;
    if (sortedArr != null) {
      sortedArr.sort(compareFn);
      if (sortedArr[0] === -1) {
        sortedArr.splice(0, 1);
      }
      deepCopy[index].questions = sortedArr;
    }
    if (arrayString !== "") {
      const newSubArr: number[][] = arrayString
        .replaceAll("[", "")
        .split("],")
        .map((subArr): number[] => {
          return subArr
            .replaceAll("]", "")
            .split(",")
            .map((num) => Number(num));
        });
      deepCopy[index].maxScoringSubscales = newSubArr;
    }
    props.setQuestionnaireWIP(deepCopy);
    props.keepAlive();
  }

  function handleDelete(index: number) {
    var deepCopy = structuredClone(scoringWIP);
    var updatedScores = deepCopy.filter((v, i) => i !== index);
    props.setQuestionnaireWIP(updatedScores);
    props.keepAlive();
  }

  useEffect(() => {
    setScoringWIP(props.questionnaire.scoring);
  }, [props.questionnaire.scoring]);

  return (
    <Dialog
      open={props.open}
      maxWidth="lg"
      fullWidth
      key={"scoring-main-modal"}
    >
      <DialogTitle key={"scoring-main-modal-title"}>
        Questionnaire Scoring
      </DialogTitle>
      <DialogContent key={"scoring-main-modal-content"}>
        <Box key={"scoring-main-modal-selector-container"}>
          <ScoringSelector
            questionnaire={props.questionnaire}
            allQuestionnaires={props.allQuestionnaires}
            scoring={scoringWIP}
            handleChange={(i, sc, aS) => handleChange(i, sc, aS)}
            handleDelete={(i) => handleDelete(i)}
            handleCancel={() => props.handleCancel()}
            allQuestionsArr={allQuestionsArr}
            questionsDropdown={questionsDropdown}
            key={"scoring-main-modal-selector"}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default QuestionnaireScoringModal;
