import { ExtendedLineViewModel } from "../../elements/graph/GraphLegend";

export const GetXAxisDomain = (
  fromDate: Date | undefined,
  toDate: Date | undefined,
  maxXPadding?: number
): number[][] => {
  const minDate = fromDate!.getTime();
  const maxDate = toDate!.getTime() + (maxXPadding || 0);

  const xAxisLabels = [] as number[][];

  // Create X-Axis Labels for all date intervals
  const dateIntervals = ["year", "month", "week", "day"];

  dateIntervals.forEach((interval) => {
    let value = new Date(minDate);
    value.setHours(0, 0, 0, 0);

    if (interval === "year") {
      value.setMonth(0);
      value.setDate(1);
    } else if (interval === "month") {
      value.setDate(1);
    }

    const xDomainArray = [value.getTime()] as number[];

    // Increment intervals based on time interval between current the x-axis zoom domain
    while (value.getTime() < maxDate) {
      switch (interval) {
        case "year":
          value.setFullYear(value.getFullYear() + 1);
          break;
        case "month":
          value.setMonth(value.getMonth() + 1);
          break;
        case "week":
          value.setDate(value.getDate() + 7);
          break;
        default:
          value.setDate(value.getDate() + 1);
          break;
      }

      xDomainArray.push(value.getTime());
    }

    xAxisLabels.push(xDomainArray);
  });

  return xAxisLabels;
};

export const GetDateInterval = (
  fromDate: Date | undefined,
  toDate: Date | undefined
): string => {
  const minDate = fromDate!.getTime();
  const maxDate = toDate!.getTime();

  const daysDifferent = Math.ceil((maxDate - minDate) / (1000 * 3600 * 24));

  if (daysDifferent > 365) {
    return "year";
  } else if (daysDifferent > 60) {
    return "month";
  } else if (daysDifferent > 10) {
    return "week";
  } else {
    return "day";
  }
};

export const GetMaxYValueForSummaryGraph = (
  lines: ExtendedLineViewModel[]
): number =>
  lines
    ? Math.max(...lines.map((x) => (x.data && !x.hidden ? x.maxScore : 0))) + 1
    : 0;
