import { Button, Container, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { NavigateFunction, To } from "react-router-dom";
import { theme as customTheme } from "../../../../Theme";
import {
  TemporaryAccessLogClient,
  TemporaryAccessLogViewModel,
} from "../../../../types/auto/types";
import {
  AllowListViewPatientReasonRegex,
  Configuration,
  HelpText,
} from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import ErrorComponent from "../../elements/errors/ErrorComponent";
import FormErrorText from "../../elements/errors/FormErrorText";

interface Props {
  patientId: number;
  pathToPatient: To;
  navigate: NavigateFunction;
}
const ViewPatientReason = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    reasonText: {
      fontWeight: "bold",
      textAlign: "center",
    },
    submitButton: {
      float: "right",
      "&:disabled": {
        backgroundColor: "darkgrey",
      },
    },
    inputText: {
      margin: 20,
      marginLeft: 0,
      marginRight: 0,
    },
  }));
  const classes = useStyle(customTheme);

  const [invalidCharacterUsed, setInvalidCharacterUsed] = useState(false);
  const [temporaryAccessReasonInput, setTemporaryAccessReasonInput] =
    useState("");
  const [error, setError] = useState(false);

  function handleChange(e: any) {
    var temporaryAccessReason = e.target.value;
    if (temporaryAccessReason.length > 0) {
      var regexp = new RegExp(AllowListViewPatientReasonRegex);
      var notInAllowList = regexp.test(temporaryAccessReason);
      if (notInAllowList) {
        setInvalidCharacterUsed(true);
        return;
      } else {
        setInvalidCharacterUsed(false);
      }
    }
    setTemporaryAccessReasonInput(temporaryAccessReason);
  }

  const handleCreateTemporaryAccessLog = (reasonInput: string) => {
    const newTemporaryAccessLog = new TemporaryAccessLogViewModel();
    newTemporaryAccessLog.patientId = props.patientId;
    newTemporaryAccessLog.reason = reasonInput;

    new TemporaryAccessLogClient(Configuration.SERVER_ROOT, FetchOverride)
      .insertTemporaryAccessLog(newTemporaryAccessLog)
      .then((insertedLog: boolean) => {
        if (insertedLog) {
          props.navigate(props.pathToPatient);
        } else {
          setError(true);
        }
      });
  };
  return (
    <>
      {!error && (
        <Container>
          <Typography className={classes.reasonText}>
            {
              HelpText.PATIENT_ACCESS
                .PATIENT_ACCESS_WITHOUT_RELATIONSHIP_WARNING
            }
          </Typography>
          <TextField
            label="Reason for viewing patient"
            placeholder="Please type your reason"
            multiline
            fullWidth
            className={classes.inputText}
            value={temporaryAccessReasonInput}
            onChange={handleChange}
          />
          <FormErrorText
            errorText="Viewing patient text has invalid characters i.e. < > +"
            isInvalid={invalidCharacterUsed}
          />
          <Button
            className={classes.submitButton}
            onClick={() =>
              handleCreateTemporaryAccessLog(temporaryAccessReasonInput)
            }
            disabled={temporaryAccessReasonInput.length === 0}
            aria-label="Submit reason"
            variant="contained"
          >
            Submit reason
          </Button>
        </Container>
      )}
      {error && (
        <ErrorComponent errorText="Temporary Access Log was not created." />
      )}
    </>
  );
};

export default ViewPatientReason;
