import { TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import { useMountedState } from "react-use";
import { theme } from "../../../../../Theme";
import { CheckIsMobile } from "../../../../utils/MobileStatus";
import { Frequency } from "../../../../../types/auto/types";

interface Props {
  timeOfDay: string | undefined;
  setTimeOfDay: (timeOfDay: string) => void;
  frequencyType: Frequency;
  frequencyAmount: number;
  setFrequencyAmount: (frequencyAmount: number) => void;
}

const AtTimeScheduling = (props: Props) => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    container: {
      display: isMobile ? "block" : "flex",
    },
    textContainer: {
      marginLeft: isMobile ? 0 : 10,
      marginRight: 10,
      marginTop: isMobile ? 10 : 15,
      marginBottom: isMobile ? 10 : 0,
    },
    timeTextField: {
      width: 200,
    },
    frequencyInput: {
      width: "fit-content",
    },
    timeError: {
      marginTop: "3px",
      marginRight: "14px",
      marginBottom: 0,
      marginLeft: "14px",
      color: "#d32f2f",
      fontWeight: 400,
      fontSize: "0.75rem",
      fontFamily: "sans-serif",
      letterSpacing: "0.03333em",
    },
    timePicker: {
      zIndex: 1000,
    },
  });
  const classes = useStyle(theme);
  const [date, setDate] = useState<Date | undefined>();

  const isMountedState = useMountedState();

  useEffect(() => {
    if (isMountedState() && props.timeOfDay) {
      const splitTime = props.timeOfDay.split(":");
      const newDate = new Date();
      newDate.setHours(parseInt(splitTime[0]));
      newDate.setMinutes(parseInt(splitTime[1]));
      newDate.setSeconds(parseInt(splitTime[2]));
      setDate(newDate);
    }
  }, [props, setDate, isMountedState]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={classes.container}>
        <Typography className={classes.textContainer}>at </Typography>
        <div>
          <TimePicker
            className={classes.timePicker}
            label="Time"
            value={date ?? ""}
            ampm={false}
            onChange={(outDate) => {
              if (outDate && typeof outDate !== "string") {
                setDate(outDate);
                props.setTimeOfDay(GetStringTimeOfDay(outDate));
              }
            }}
            renderInput={(params) => (
              <TextField className={classes.timeTextField} {...params} />
            )}
            PopperProps={{
              placement: "bottom",
              modifiers: [
                {
                  name: "flip",
                  options: {
                    fallbackPlacements: ["top", "right"],
                  },
                },
              ],
              disablePortal: true,
            }}
          />
          {(props.timeOfDay == null || props.timeOfDay === "") && (
            <p className={classes.timeError}>Please enter a time</p>
          )}
        </div>
      </div>
    </LocalizationProvider>
  );
};

export const GetStringTimeOfDay = (date: Date): string =>
  date.getHours() +
  ":" +
  date.getMinutes() +
  ":" +
  date.getSeconds() +
  "." +
  date.getMilliseconds();

export default AtTimeScheduling;
