import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Container,
  Modal,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
  CaseloadClient,
  CaseloadViewModel,
  CustomIdViewModel,
  ErrorType,
} from "../../../types/auto/types";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { Configuration, DefaultCustomIdDisplayName } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import { IsNameValid } from "../patient/create-patient/CreateEditPatientModalHelper";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  caseload: CaseloadViewModel | undefined;
  refresh: () => void;
  integration?: boolean;
  customCaseloadIdOptions?: CustomIdViewModel;
}
const CreateEditCaseloadModal = (props: Props): JSX.Element => {
  const [caseload, setCaseload] = React.useState(
    props.caseload ?? new CaseloadViewModel()
  );
  const editing = props.caseload !== undefined;
  const [nameError, setNameError] = React.useState(false);
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : "80%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    border: "none",
  };

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = useState<ErrorType | undefined>(undefined);

  const errorText = (() => {
    switch (error) {
      case ErrorType.InvalidModel:
        return "Invalid caseload details provided.";
      case ErrorType.MissingMandatoryCustomId:
        return `Missing ${
          props.customCaseloadIdOptions?.displayName ??
          DefaultCustomIdDisplayName
        }.`;
      case ErrorType.InvalidCustomId:
        return `Invalid ${
          props.customCaseloadIdOptions?.displayName ??
          DefaultCustomIdDisplayName
        }.`;
      case ErrorType.ExistingCustomId:
        return `A caseload with that ${
          props.customCaseloadIdOptions?.displayName ??
          DefaultCustomIdDisplayName
        } already exists. Please use a different ${
          props.customCaseloadIdOptions?.displayName ??
          DefaultCustomIdDisplayName
        }.`;
      default:
        return null;
    }
  })();

  const [customIdError, setCustomIdError] = useState(false);
  const isCustomIdValid = (customId: string): boolean => {
    if (customId == null || customId === "") {
      return !props.customCaseloadIdOptions?.mandatory;
    }

    var regex = props.customCaseloadIdOptions?.regex ?? "";

    const match = customId?.match(new RegExp(regex as string));

    return match !== null;
  };

  function onClose() {
    props.setOpen(false);
    setError(undefined);
    setLoading(false);
    setNameError(false);
    if (!editing) {
      setCaseload(new CaseloadViewModel());
    }
  }
  const useStyle = makeStyles((th: Theme) => ({
    textFieldContainer: {
      margin: 0,
      padding: 5,
      gap: 10,
      width: "100%",
      marginBottom: 15,
      display: "flex",
      flexDirection: "column",
    },
    actionButtonGroup: {
      marginTop: 20,
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      justifyContent: isMobile ? "center" : "end",
      gap: isMobile ? "4px" : "8px",
    },
    subHeader: {
      fontSize: 20,
      marginBottom: 10,
    },
    outerContainer: {
      backgroundColor: "white",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      paddingBottom: "10px",
    },
    error: {
      marginTop: 10,
    },
  }));
  const classes = useStyle();

  useEffect(() => {
    if (props.caseload !== undefined) {
      setCaseload({
        name: props.caseload.name,
        shortName: props.caseload.shortName,
        description: props.caseload.description,
        customCaseloadId: props.caseload.customCaseloadId,
        id: props.caseload.id,
        type: props.caseload.type,
        active: props.caseload.active,
        created: props.caseload.created,
        updated: props.caseload.updated,
      } as CaseloadViewModel);
    }
  }, [setCaseload, props.caseload, props.open]);

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h5" component="h5" className={classes.subHeader}>
          {props.caseload !== undefined ? "Edit " : "Create "}Caseload
        </Typography>
        <Container className={classes.textFieldContainer}>
          <TextField
            label="Name"
            required
            value={caseload.name ?? ""}
            disabled={props.integration}
            onChange={(e) => {
              setCaseload({
                ...caseload,
                name: e.target.value,
              } as CaseloadViewModel);
              setNameError(!IsNameValid(e.target.value));
            }}
            error={nameError}
            helperText={nameError && "Please provide a valid caseload name"}
          />
          <TextField
            label="Short name"
            value={caseload.shortName ?? ""}
            onChange={(e) => {
              setCaseload({
                ...caseload,
                shortName: e.target.value,
              } as CaseloadViewModel);
            }}
          />
          <TextField
            label="Description"
            multiline
            value={caseload.description ?? ""}
            onChange={(e) => {
              setCaseload({
                ...caseload,
                description: e.target.value,
              } as CaseloadViewModel);
            }}
          />
          {props.customCaseloadIdOptions && (
            <TextField
              label={
                props.customCaseloadIdOptions.displayName ??
                DefaultCustomIdDisplayName
              }
              value={caseload?.customCaseloadId ?? ""}
              onChange={(e) => {
                setCaseload({
                  ...caseload,
                  customCaseloadId: e.target.value,
                } as CaseloadViewModel);
                setCustomIdError(!isCustomIdValid(e.target.value));
              }}
              error={customIdError}
              helperText={
                customIdError &&
                `Please provide a valid ${
                  props.customCaseloadIdOptions.displayName ??
                  DefaultCustomIdDisplayName
                }.`
              }
              inputProps={{ maxLength: 50 }}
            />
          )}
        </Container>
        <div className={classes.actionButtonGroup}>
          <Button onClick={() => onClose()} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              if (customIdError) {
                return;
              }

              const isValidCaseloadName = IsNameValid(caseload.name);
              setNameError(!isValidCaseloadName);
              if (!isValidCaseloadName) {
                return;
              }
              setLoading(true);
              if (props.caseload === undefined) {
                new CaseloadClient(Configuration.SERVER_ROOT, FetchOverride)
                  .createTeamCaseload(caseload)
                  .then(() => {
                    onClose();
                    props.refresh();
                  })
                  .catch((e) => {
                    setError(e);
                    setLoading(false);
                  });
              } else {
                new CaseloadClient(Configuration.SERVER_ROOT, FetchOverride)
                  .editTeamCaseload(caseload)
                  .then(() => {
                    onClose();
                    props.refresh();
                  })
                  .catch((e) => {
                    setError(e);
                    setLoading(false);
                  });
              }
            }}
          >
            {props.caseload === undefined ? "Create Caseload" : "Save Changes"}
          </LoadingButton>
        </div>
        {error !== undefined && (
          <Alert severity="error" className={classes.error}>
            {errorText}
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default CreateEditCaseloadModal;
