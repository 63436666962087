import { createTheme } from "@mui/material/styles";

const primaryColour = "#005f8f";
const secondaryColour = "#0188cc";
const secondaryLight = "#87cefa";
const hoverColour = "#0aaef5";
const textColour = "#212529";
const grey = "#bdbdbd";
const defaultBackground = "#fafafa";

export const graphColours = [
  "rgb(255, 0, 41)",
  "rgb(55, 126, 184)",
  "rgb(102, 166, 30)",
  "rgb(152, 78, 163)",
  "rgb(0, 210, 213)",
  "rgb(255, 127, 0)",
  "rgb(127, 128, 205)",
  "rgb(179, 233, 0)",
  "rgb(247, 129, 191)",
  "rgb(141, 211, 199)",
  "rgb(190, 186, 218)",
  "rgb(251, 128, 114)",
  "rgb(128, 177, 211)",
  "rgb(253, 180, 98)",
  "rgb(252, 205, 229)",
  "rgb(188, 128, 189)",
  "rgb(255, 237, 111)",
  "rgb(196, 46, 96)",
  "rgb(175, 141, 0)",
  "rgb(166, 86, 40)",
];

/**
 * Builds the theme
 */
export const theme = createTheme({
  palette: {
    primary: { main: primaryColour },
    secondary: { main: secondaryColour, light: secondaryLight },
    text: { primary: textColour },
    background: { default: defaultBackground },
    grey: { 50: grey },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...((ownerState.variant === "contained" && {
            backgroundColor: secondaryColour,
            color: "#fff",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: hoverColour,
            },
          }) ||
            (ownerState.variant === "outlined" && {
              color: textColour,
              borderColor: textColour,
              textTransform: "capitalize",
            }) ||
            (ownerState.variant === "text" && {
              color: textColour,
              textTransform: "capitalize",
            })),
        }),
      },
    },
  },
});
