export const GroupBy = <ItemType,>(
  givenArray: ItemType[],
  getAttributeToGroupBy: (itemToSort: ItemType) => string | number | symbol
): { [key in string | number | symbol]: ItemType[] } =>
  givenArray.reduce(
    (group: { [Key: string | number | symbol]: ItemType[] }, item) => {
      const key = getAttributeToGroupBy(item);
      group[key] = group[key] ?? [];
      group[key].push(item);
      return group;
    },
    {}
  );
