export const replaceLast = (
  mainString: string | undefined,
  find: string,
  replace: string
): string => {
  if (mainString === undefined) {
    return "";
  }
  var lastIndex = mainString.lastIndexOf(find);

  if (lastIndex === -1) {
    return mainString;
  }

  var beginString = mainString.substring(0, lastIndex);
  var endString = mainString.substring(lastIndex + find.length);

  return beginString + replace + endString;
};
