import React, { useEffect, useState } from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryChart,
  VictoryClipContainer,
  VictoryScatter,
  VictoryTooltip,
  VictoryZoomContainer,
} from "victory";
import { SymptomsDataPointViewModel } from "../../../../../types/auto/types";
import { CheckIsTablet } from "../../../../utils/MobileStatus";
import { ToTitleCase } from "../../../../utils/TitleCase";
import { GetDateInterval, GetXAxisDomain } from "../ChartHelper";
import GraphTickLabel from "../GraphTickLabel";
import MobileZoomable from "./MobileZoomable";
import { ExtendedBubbleViewModel } from "./SymptomsChart";

interface Props {
  data: ExtendedBubbleViewModel;
  width: number;
  fromDate: Date | undefined;
  toDate: Date | undefined;
}

const BASE_GRAPH_HEIGHT = 100;
const BUBBLE_HEIGHT = 55;

const SymptomsGraphCore = (props: Props): JSX.Element => {
  const isMobile = CheckIsTablet();
  const [xAxisLabels, setXAxisLabels] = useState<number[][]>([[]]);
  const [xAxisValues, setXAxisValues] = useState<number[]>([]);
  const [dateInterval, setDateInterval] = useState<string>();
  const [chartHeight, setChartHeight] = useState(
    BASE_GRAPH_HEIGHT + BUBBLE_HEIGHT
  );
  const [allowZoom, setAllowZoom] = useState(true);
  const [zoomDomain, setZoomDomain] = useState<{
    x?: DomainTuple;
    y?: DomainTuple;
  }>();
  const [mobileSelectedDomain, setMobileSelectedDomain] = useState<{
    x?: DomainTuple;
    y?: DomainTuple;
  }>();

  useEffect(
    () => {
      const xAxisLabels = GetXAxisDomain(props.fromDate, props.toDate, 1);
      setXAxisLabels(xAxisLabels);

      const dateInterval = GetDateInterval(props.fromDate, props.toDate);
      setDateInterval(dateInterval);

      switch (dateInterval) {
        case "year":
          setXAxisValues(xAxisLabels[0]);
          break;
        case "month":
          setXAxisValues(xAxisLabels[1]);
          break;
        case "week":
          setXAxisValues(xAxisLabels[2]);
          break;
        default:
          setXAxisValues(xAxisLabels[3]);
          break;
      }

      // Set the height of the graph based on the number of bubbles provided
      const dataHeight =
        ([...new Set(props.data.data?.map((x) => x.y))]?.length || 0) *
          BUBBLE_HEIGHT +
        (props.data.versions && props.data.versions.length > 0
          ? BUBBLE_HEIGHT
          : 0);
      setChartHeight(BASE_GRAPH_HEIGHT + dataHeight);

      // Allow zooming if there are more than 3 data points
      setAllowZoom(
        ([...new Set(props.data.data?.map((x) => x.x?.toLocaleString("en-GB")))]
          ?.length || 0) > 3
      );
    },
    // Including xAxisLabels and dateInterval in dependency array causes infinite recursion
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.data, props.fromDate, props.toDate]
  );

  return (
    <>
      <VictoryChart
        domainPadding={{ x: 40, y: 40 }}
        padding={{ left: 120, top: 50, right: 50, bottom: 50 }}
        width={props.width}
        height={chartHeight}
        aria-label={"Symptoms Graph For " + props.data.chartName}
        containerComponent={
          <VictoryZoomContainer
            zoomDimension="x"
            containerId="chart"
            height={chartHeight}
            disable={true || !allowZoom}
            clipContainerComponent={<VictoryClipContainer />}
            minimumZoom={{ x: 0.1 }}
            onZoomDomainChange={(s) => {
              setMobileSelectedDomain(s);

              const newDateInterval = GetDateInterval(
                new Date(s.x[0]),
                new Date(s.x[1])
              );

              if (newDateInterval !== dateInterval) {
                setDateInterval(newDateInterval);
                switch (newDateInterval) {
                  case "year":
                    setXAxisValues(xAxisLabels[0]);
                    break;
                  case "month":
                    setXAxisValues(xAxisLabels[1]);
                    break;
                  case "week":
                    setXAxisValues(xAxisLabels[2]);
                    break;
                  default:
                    setXAxisValues(xAxisLabels[3]);
                    break;
                }
              }
            }}
            zoomDomain={zoomDomain}
            allowZoom={!isMobile}
          />
        }
      >
        <VictoryAxis
          dependentAxis
          style={{
            grid: { stroke: "lightgray", strokeWidth: 0.5 },
          }}
          crossAxis={false}
          tickLabelComponent={
            <GraphTickLabel
              chartTitle={props.data.chartName || ""}
              style={{
                textTransform: "capitalize",
                textAlign: "center",
              }}
            />
          }
        />
        <VictoryAxis
          style={{
            grid: { stroke: "lightgray", strokeWidth: 0.5 },
            tickLabels: { angle: -5, padding: 20 },
            axisLabel: { padding: 35 },
          }}
          label="Date"
          tickValues={xAxisValues}
          tickFormat={(t: number) => {
            switch (dateInterval) {
              case "year":
                return new Date(t).toLocaleDateString("en-GB", {
                  year: "numeric",
                });
              case "month":
                return new Date(t).toLocaleDateString("en-GB", {
                  year: "numeric",
                  month: "short",
                });
              default:
                return new Date(t).toLocaleDateString("en-GB");
            }
          }}
        />
        <VictoryScatter
          key={"versionScatter"}
          style={{
            data: {
              stroke: props.data.colour,
              strokeWidth: "2",
              fill: "rgba(255, 255, 255)",
            },
          }}
          size={5}
          symbol="plus"
          data={props.data.versions?.map((x) => ({
            x: x.x,
            y: "Version\nChange",
          }))}
          labels={() => [
            props.data.chartName || "",
            "Questionnaire Version Changed",
          ]}
          labelComponent={
            <VictoryTooltip
              pointerLength={20}
              flyoutPadding={{
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
              }}
              flyoutStyle={{
                fill: "rgba(65, 65, 65, 0.8)",
                strokeWidth: 1,
                overflowWrap: "break-word",
              }}
              style={{
                fill: "white",
                overflowWrap: "break-word",
              }}
              renderInPortal
            />
          }
        />
        <VictoryScatter
          style={{
            data: {
              stroke: (d) =>
                d.datum.score === 0
                  ? "rgba(0, 0, 0, 0)"
                  : d.datum.score < 0
                  ? "gray"
                  : props.data.colour,
              strokeWidth: "2",
              fill: (d) =>
                d.datum.score === 0
                  ? "rgba(0, 0, 0, 0)"
                  : d.datum.score < 0
                  ? "gray"
                  : props.data.colour,
              opacity: "80%",
            },
          }}
          bubbleProperty="size"
          size={4}
          data={props.data.data?.map(
            (x) =>
              ({
                ...x,
                y: x.y?.replace(" ", "\n"), //Replace spaces with new lines to prevent labels spreading off the screen
              } as SymptomsDataPointViewModel)
          )}
          labels={(data) => [
            ToTitleCase(data.datum.y || ""),
            data.datum.x === undefined || isNaN(data.datum.x)
              ? ""
              : (data.datum.x as Date).toLocaleDateString("en-GB"),

            "Score: " +
              (data.datum.score === undefined ||
              isNaN(data.datum.score) ||
              data.datum.totalScore === undefined ||
              isNaN(data.datum.totalScore)
                ? ""
                : data.datum.score.toString() +
                  "/" +
                  data.datum.totalScore.toString()),
          ]}
          labelComponent={
            <VictoryTooltip
              pointerLength={20}
              flyoutPadding={{
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
              }}
              flyoutStyle={{
                fill: "rgba(65, 65, 65, 0.8)",
                strokeWidth: 1,
                overflowWrap: "break-word",
              }}
              style={{
                fill: "white",
                overflowWrap: "break-word",
              }}
              renderInPortal
            />
          }
        />
      </VictoryChart>
      {isMobile && allowZoom && (
        <MobileZoomable
          width={props.width}
          mobileSelectedDomain={mobileSelectedDomain}
          setZoomDomain={setZoomDomain}
          xAxisValues={xAxisValues}
          dateInterval={dateInterval}
          xAxisLabels={xAxisLabels}
          setDateInterval={setDateInterval}
          setXAxisValues={setXAxisValues}
          fromDate={props.fromDate}
          toDate={props.toDate}
          data={props.data}
        />
      )}
    </>
  );
};

export default SymptomsGraphCore;
