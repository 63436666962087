import { UserCard } from "./UserCard";
import { AbstractUserCardType } from "./SearchTypes";

interface Props {
  users: AbstractUserCardType[];
  allowCaseloadDeletion?: boolean;
}

const UserSearchBody = (props: Props): JSX.Element => (
  <>
    {props.users.slice().map((user) => {
      return (
        <UserCard
          allowCaseloadDeletion={props.allowCaseloadDeletion}
          user={user}
          key={
            user.firstName ||
            "" + user.lastName ||
            "" + user.descriptions.flatMap((x) => x.value)
          }
        />
      );
    })}
  </>
);

export default UserSearchBody;
