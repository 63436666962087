import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  Container,
  Theme,
  Typography,
} from "@mui/material";
import {
  PatientViewModel,
  PatientTimelineDataViewModel,
  UserViewModel,
  DatasetClient,
  MeasurementViewModel,
} from "../../../../../types/auto/types";
import { makeStyles } from "@mui/styles";
import { CheckIsMobile } from "../../../../utils/MobileStatus";
import { theme } from "../../../../../Theme";
import ArticleIcon from "@mui/icons-material/Article";
import { useEffect, useState } from "react";
import { useMountedState, useUnmountPromise } from "react-use";
import { Configuration } from "../../../../Constants";
import { FetchOverride } from "../../../../utils/Request";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

interface Props {
  timelineEntree: PatientTimelineDataViewModel;
  index: number;
  patient: PatientViewModel;
  user: UserViewModel;
  currentTimelineLength: number;
  totalTimelineLength: number;
  totalTimelineLengthFiltered: number;
  setHiddenArrayIndex: () => void;
  isLastItem: boolean;
}
export const DatasetTimelineCard = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles((th: Theme) => ({
    headerAndActions: {
      paddingRight: "0",
      paddingLeft: "0",
    },
    headingText: {
      fontWeight: "bold",
    },
    cardContent: {
      padding: 8,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    card: {
      marginBottom: "10px",
      marginTop: props.index === 0 && isMobile ? 0 : "10px",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
    },
    patientMedicationNote: {
      whiteSpace: "pre-wrap",
      paddingLeft: "4px",
    },
    measurements: {
      paddingLeft: "4px",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
    cardActions: {
      cursor: "pointer",
      paddingLeft: 0,
    },
    collapse: {
      width: "100%",
    },
    expandButton: {
      marginLeft: "auto",
    },
    subTitle: {
      fontWeight: "bold",
    },
  }));
  const classes = useStyle(theme);
  const [loading, setLoading] = useState(false);
  const isMountedState = useMountedState();
  const resolveWhileMounted = useUnmountPromise();
  const [measurements, setMeasurements] = useState<MeasurementViewModel[]>([]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setLoading(true);
    const effect = async () => {
      if (isMountedState()) {
        await resolveWhileMounted(
          new DatasetClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getMeasurementsLinkedToDataset(props.timelineEntree.datasetId)
        ).then((measurements) => {
          setMeasurements(measurements);
          setLoading(false);
        });
      }
    };
    effect();
  }, [props, isMountedState, resolveWhileMounted]);

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Container className={classes.headerAndActions}>
          <Typography className={classes.headingText}>
            <ArticleIcon />
            {props.timelineEntree.datasetName}{" "}
          </Typography>
          <CardActions
            className={classes.cardActions}
            onClick={() => setExpanded((x) => !x)}
          >
            <Typography className={classes.subTitle}>Measurements</Typography>
            <div className={classes.expandButton}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </div>
          </CardActions>
          <Collapse
            className={classes.collapse}
            in={expanded}
            timeout="auto"
            unmountOnExit
          >
            <Container disableGutters>
              {loading && (
                <CircularProgress
                  className={classes.loadingSpinner}
                  aria-label="Loading"
                />
              )}
            </Container>
            {!loading &&
              measurements.map((measurement, key) => (
                <Typography key={key}>
                  <b>{measurement.name}</b>:{" "}
                  {props.timelineEntree.measurements?.find(
                    (x) => x.measurementId === measurement.id
                  ) !== undefined
                    ? props.timelineEntree.measurements?.find(
                        (x) => x.measurementId === measurement.id
                      )?.value
                    : "No value supplied"}
                </Typography>
              ))}
          </Collapse>
        </Container>
      </CardContent>
    </Card>
  );
};
