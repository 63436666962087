import { CircularProgress, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  EnumClient,
  GoalClient,
  GoalOwner,
  GoalViewModel,
  MonitoringStatus,
  PatientViewModel,
  Role,
  UserViewModel,
} from "../../../types/auto/types";
import { theme as customTheme } from "../../../Theme";
import { useEffect, useState } from "react";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import ActiveGoals from "./ActiveGoals";
import StaticGoalsList from "./StaticGoalsList";
import { useUnmountPromise } from "react-use";

interface Props {
  patient: PatientViewModel;
  user: UserViewModel;
}

const Goals = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    outerContainer: {
      backgroundColor: th.palette.background.default,
      flexBasis: "45%",
      marginTop: "1%",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
    },
    innerContainer: {
      paddingRight: 0,
      paddingLeft: 5,
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
    },
  }));
  const classes = useStyle(customTheme);
  const [goals, setGoals] = useState<GoalViewModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [refresh, setRefresh] = useState(false);
  const [goalOwnerDescriptions, setGoalOwnerDescriptions] = useState<{
    [key in keyof typeof GoalOwner]?: string;
  }>({});
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const isReadOnly =
    props.user.role === Role.ReadOnly ||
    props.user.role === Role.ReadOnlyPIRedacted;

  const resolveWhileMounted = useUnmountPromise();

  var isStaff =
    props.user.role === Role.Clinician ||
    props.user.role === Role.Admin ||
    props.user.role === Role.SuperAdmin ||
    props.user.role === Role.ReadOnly ||
    props.user.role === Role.ReadOnlyPIRedacted;

  const [monitoringStatusDescriptions, setMonitoringStatusDescriptions] =
    useState<{ [key in keyof typeof MonitoringStatus]?: string }>({});

  useEffect(() => {
    const effect = async () => {
      if (!dataLoaded) {
        setLoading(true);
        if (!isStaff) {
          await resolveWhileMounted(
            new GoalClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getGoalsForCurrentPatient()
          )
            .then((retrievedGoals) =>
              setGoals(
                retrievedGoals !== undefined && retrievedGoals !== null
                  ? retrievedGoals
                  : []
              )
            )
            .catch(() =>
              setError("Could not load goals. Please try again later")
            )
            .finally(() => {
              setLoading(false);
              setDataLoaded(true);
            });
        } else {
          await resolveWhileMounted(
            new GoalClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getGoalsForPatient(props.patient.id)
          )
            .then((retrievedGoals) =>
              setGoals(
                retrievedGoals !== undefined && retrievedGoals !== null
                  ? retrievedGoals
                  : []
              )
            )
            .catch(() =>
              setError("Could not load goals. Please try again later")
            )
            .finally(() => {
              setLoading(false);
              setDataLoaded(true);
            });
        }
        await resolveWhileMounted(
          new EnumClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getGoalOwnerDescriptions()
        ).then((x) => {
          setGoalOwnerDescriptions(x);
        });
        await resolveWhileMounted(
          new EnumClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).getMonitoringStatusDescriptions()
        ).then((x) => {
          setMonitoringStatusDescriptions(x);
        });
      }
    };
    effect();
  }, [props, setGoals, refresh, resolveWhileMounted, isStaff, dataLoaded]);

  return (
    <Container className={classes.innerContainer}>
      {loading && (
        <CircularProgress
          className={classes.loadingSpinner}
          aria-label="Loading"
        />
      )}
      {error && <Typography>{error}</Typography>}
      {!loading && error === undefined && (
        <>
          <ActiveGoals
            patient={props.patient}
            role={props.user.role}
            goals={goals.filter(
              (x) =>
                x.monitoringStatus !== MonitoringStatus.Archived &&
                x.monitoringStatus !==
                  MonitoringStatus.ArchivedDueToEndDateReached &&
                x.monitoringStatus !==
                  MonitoringStatus.ArchivedDueToMissedResponses &&
                x.monitoringStatus !==
                  MonitoringStatus.ArchivedDueToAnsweredResponses &&
                x.monitoringStatus !== MonitoringStatus.Completed
            )}
            refreshGoals={() => {
              setRefresh((x) => !x);
              setDataLoaded(false);
            }}
            goalOwnerDescriptions={goalOwnerDescriptions}
            monitoringStatusDescriptions={monitoringStatusDescriptions}
            readOnly={isReadOnly}
          />
          <StaticGoalsList
            name="Completed Goals"
            goals={goals.filter(
              (x) =>
                x.monitoringStatus ===
                  MonitoringStatus.ArchivedDueToEndDateReached ||
                x.monitoringStatus === MonitoringStatus.Completed
            )}
            patient={props.patient}
            refreshGoals={() => {
              setRefresh((x) => !x);
              setDataLoaded(false);
            }}
            role={props.user.role}
            goalOwnerDescriptions={goalOwnerDescriptions}
            monitoringStatusDescriptions={monitoringStatusDescriptions}
          />
          <StaticGoalsList
            name="Archived Goals"
            goals={goals.filter(
              (x) =>
                x.monitoringStatus === MonitoringStatus.Archived ||
                x.monitoringStatus ===
                  MonitoringStatus.ArchivedDueToMissedResponses ||
                x.monitoringStatus ===
                  MonitoringStatus.ArchivedDueToEndDateReached ||
                x.monitoringStatus ===
                  MonitoringStatus.ArchivedDueToAnsweredResponses
            )}
            patient={props.patient}
            refreshGoals={() => {
              setRefresh((x) => !x);
              setDataLoaded(false);
            }}
            role={props.user.role}
            goalOwnerDescriptions={goalOwnerDescriptions}
            monitoringStatusDescriptions={monitoringStatusDescriptions}
          />
        </>
      )}
    </Container>
  );
};
export default Goals;
