import { Configuration, HttpStatusCodes } from "../Constants";
import { StorageAccess } from "./LocalStorage";

/**
 * Interface for errors being returned upon API calls.
 */
export interface IErrorResult {
  statusCode: number;
  value: string;
}

/**
 * Interface for valid requests being made.
 */
export interface IRequestOptions {
  method: string;
  body: string;
  headers: {};
  credentials: RequestCredentials;
}

/**
 * Default request options to use.
 */
const requestOptions: IRequestOptions = {
  method: "",
  body: "",
  headers: {
    "Content-Type": "application/json",
  },
  credentials: "same-origin",
};

export const getCookie = (cname: string, cookie: string): string => {
  const name = cname + "=";
  const ca = decodeURIComponent(cookie).split(";");
  for (let c of ca) {
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
};

export const FetchOverride = {
  /**
   * Performs a fetch on the given parameters, first setting the credentials option to include. This is to ensure cookies persist across requests.
   * @param url The request info to pass through to fetch.
   * @param init The request options. If not undefined, then credentials will be set to include.
   */
  fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    const COOKIE_STORAGE_KEY = "cookie";
    const COOKIE_SET_HEADER_KEY = "set-cookie";

    if (init !== undefined) {
      init.credentials = "include";
      init.redirect = "manual";
    }

    if (StorageAccess.secureStorage.fetchAsync === undefined) {
      // local store isn't defined so we can just forget storing the cookies manually
      if (init !== undefined) {
        init.headers = {
          ...init.headers,
          WebAPICookie: getCookie(Configuration.COOKIE, document.cookie),
        };
      }
      return fetch(url, init);
    } else {
      return StorageAccess.secureStorage
        .fetchAsync(COOKIE_STORAGE_KEY)
        .then((cookie: string | null) => {
          if (init !== undefined && cookie !== undefined && cookie !== null) {
            init.headers = {
              ...init.headers,
              Cookie: cookie,
              WebAPICookie: getCookie(Configuration.COOKIE, cookie),
            };
          }

          return fetch(url, init).then((value: Response) => {
            if (
              value.headers.has(COOKIE_SET_HEADER_KEY) &&
              value.headers.get(COOKIE_SET_HEADER_KEY) !== null
            ) {
              StorageAccess.secureStorage.set(
                COOKIE_STORAGE_KEY,
                value.headers.get(COOKIE_SET_HEADER_KEY) ?? ""
              );
            }

            return value;
          });
        });
    }
  },
};

interface FetchDictionaryInterface {
  fetch: (
    url: RequestInfo,
    init?: RequestInit | undefined
  ) => Promise<Response>;
}

/**
 * Creates the fetch override while including the file to upload in the body
 * @param uploadData: The file's data to upload
 */
export const MakeUploadFetchOverride = (
  uploadData: string | ArrayBuffer | null
): FetchDictionaryInterface => {
  return {
    fetch: (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      // Additional request options for file uploads
      const requestOptions = {
        method: "POST",
        body: uploadData,
      } as RequestInit;

      return FetchOverride.fetch(url, { ...init, ...requestOptions });
    },
  };
};

/**
 * Wraps functions to allow for tests.
 */
export const wrapper = {
  reload: (): void => {
    window.location.reload();
  },
};

/**
 * Makes a call to the given url returning the appropriate object.
 * @param url The url to fetch/post data from/to. Should include any Ids etc.
 * @param request The request options.
 */
function call<T>(url: string, request: IRequestOptions): Promise<T> {
  url = Configuration.SERVER_ROOT + url.trim();
  return fetch(url, request).then(
    (response: Response) => {
      if (response.status === HttpStatusCodes.UNAUTHORIZED) {
        wrapper.reload();
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return response.json().then((obj: any) => {
          if (
            response.status === HttpStatusCodes.SUCCESSFUL ||
            response.status === HttpStatusCodes.CREATED
          ) {
            return obj;
          }

          return Promise.reject<IErrorResult>({
            value: "Invalid response",
            statusCode: response.status,
          });
        });
      } else if (response.status === HttpStatusCodes.NOT_FOUND) {
        return Promise.reject<IErrorResult>({
          value: "Not found",
          statusCode: response.status,
        });
      } else {
        return Promise.reject<IErrorResult>({
          value: "Expected a json response",
          statusCode: HttpStatusCodes.INTERNAL_SERVER_ERROR,
        });
      }
    },
    (err: string) =>
      Promise.reject<IErrorResult>({
        value: err,
        statusCode: HttpStatusCodes.INTERNAL_SERVER_ERROR,
      })
  );
}

const Request = {
  /**
   * Performs a GET on the given url.
   * @param url The relative Url to GET data from. e.g. /user/123.
   */
  get<T>(url: string): Promise<T> {
    return call<T>(url, { ...requestOptions, method: "GET" });
  },

  /**
   * Performs a POST on the given url.
   * @param url The relative Url to POST data to. e.g. /user/123
   * @param data The json object to post.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<T>(url: string, data: any): Promise<T> {
    return call<T>(url, {
      ...requestOptions,
      method: "POST",
      body: JSON.stringify(data),
    });
  },

  /**
   * Performs a PUT on the given url.
   * @param url The relative Url to PUT data. e.g. /user
   * @param data The json object to PUT.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put<T>(url: string, data: any): Promise<T> {
    return call<T>(url, {
      ...requestOptions,
      method: "PUT",
      body: JSON.stringify(data),
    });
  },

  /**
   * Performs a DELETE on the given url.
   * @param url The relative Url to DELETE. e.g. /user/123
   */
  delete<T>(url: string): Promise<T> {
    return call<T>(url, { ...requestOptions, method: "DELETE" });
  },
};

export default Request;
