import { Container } from "@mui/material";
import { PatientTimelineDataViewModel } from "../../../../types/auto/types";
import { ResponseAccordian } from "./ResponseAccordian";
import { ScoresAccordian } from "./ScoresAccordian";
import { makeStyles } from "@mui/styles";

interface Props {
  timelineEntree: PatientTimelineDataViewModel;
}

export const ResponseInformation = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    container: {
      padding: 0,
    },
  }));
  const classes = useStyle();
  return (
    <Container
      aria-label="Questionnaire response"
      className={classes.container}
      disableGutters
    >
      {props.timelineEntree.questionnaireScores !== undefined &&
      props.timelineEntree.questionnaireScores !== null &&
      props.timelineEntree.questionnaireScores.length > 0 ? (
        <ScoresAccordian
          questionnaireScores={props.timelineEntree.questionnaireScores}
          index={props.timelineEntree.responseId}
        />
      ) : (
        <></>
      )}
      <ResponseAccordian timelineEntree={props.timelineEntree} />
    </Container>
  );
};
