import { Box, Button, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { QuestionnaireVersionViewModel } from "../../../types/auto/types";
import { CheckIsMobile } from "../../utils/MobileStatus";

interface Props {
  questionnaireVersionToEdit: QuestionnaireVersionViewModel;
  open: boolean;
  closeModal: () => void;
  refresh: () => void;
  versionPublishModalOpen: boolean;
  setVersionPublishModalOpenTrue: () => void;
  versionOverwriteModalOpen: boolean;
  setVersionOverwriteModalOpenTrue: () => void;
}

const QuestionnaireVersionOverwritePublishModal = (
  props: Props
): JSX.Element => {
  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "100%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    borderRadius: 5,
    border: "none",
  };
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
    },
    actionButtonGroup: {
      textAlign: "right",
      display: "flex",
      gap: "5px",
      marginLeft: "auto",
      float: "right",
    },
    textField: {
      width: "100%",
      marginBottom: "10px",
    },
    subHeader: {
      marginBottom: 10,
    },
    overwriteButton: {
      backgroundColor: "red",
      "&:hover": {
        backgroundColor: "crimson",
      },
    },
  });
  const classes = useStyle();

  return (
    <Modal open={props.open}>
      <Box sx={{ ...modalStyle, width: isMobile ? "80%" : "40%" }}>
        <Typography variant="h5" component="h2" className={classes.header}>
          Publish or Overwrite Questionnaire Version?
        </Typography>
        <Typography className={classes.subHeader}>
          Would you like to publish a new questionnaire version or overwrite the
          previously published version?
        </Typography>
        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              props.closeModal();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.overwriteButton}
            onClick={() => {
              props.setVersionOverwriteModalOpenTrue();
              props.closeModal();
            }}
          >
            Overwrite
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              props.setVersionPublishModalOpenTrue();
              props.closeModal();
            }}
          >
            Publish
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default QuestionnaireVersionOverwritePublishModal;
