import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { Add, Delete, Edit } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DatasetClient, DatasetViewModel } from "../../../../types/auto/types";
import { makeStyles } from "@mui/styles";
import CreateEditDataSetModal from "./CreateEditDatasetModal";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import WarningDialog from "../../elements/dialog/WarningDialog";
import CreateEditMeasurementModal from "../measurements/CreateEditMeasurementModal";

interface Props {
  dataset: DatasetViewModel;
  linkedMeasurements: number;
  resetMeasurements: () => void;
  refresh: () => void;
}
const DatasetActions = (props: Props): JSX.Element => {
  const [anchorActionList, setAnchorActionList] =
    React.useState<null | HTMLElement>(null);
  const [openAddMeasurement, setOpenAddMeasurement] = React.useState(false);
  const [openEditDataset, setOpenEditDataset] = React.useState(false);
  const [openDeleteDataset, setOpenDeleteDataset] = React.useState(false);

  const useStyle = makeStyles(() => ({
    vert: {
      marginLeft: "auto",
      padding: "0",
      minWidth: "24px",
    },
  }));
  const classes = useStyle();
  const handleClose = () => {
    setAnchorActionList(null);
  };
  return (
    <>
      <Button
        onClick={(event) =>
          setAnchorActionList((val) =>
            val === null ? event.currentTarget : null
          )
        }
        aria-label="Open Dataset Action List"
        className={classes.vert}
      >
        <MoreVertIcon />
        <Menu
          open={anchorActionList !== null}
          anchorEl={anchorActionList}
          aria-label="Dataset Action List"
          onClose={handleClose}
        >
          <MenuItem
            aria-label="Add measurement"
            onClick={() => setOpenAddMeasurement(true)}
          >
            <Add />
            Add measurement
          </MenuItem>
          <MenuItem
            onClick={() => setOpenEditDataset(true)}
            aria-label="Edit dataset"
          >
            <Edit />
            Edit dataset
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenDeleteDataset(true);
            }}
            aria-label="Delete dataset"
          >
            <Delete />
            Delete dataset
          </MenuItem>
        </Menu>
      </Button>
      <WarningDialog
        title="Delete dataset"
        errorText="Error deleting dataset."
        body={`This will delete the selected dataset: ${
          props.dataset!.name ? props.dataset!.name : ""
        }. Are you sure this is intended?`}
        continueButtonText="Delete dataset"
        open={openDeleteDataset}
        onCancel={() => setOpenDeleteDataset(false)}
        setOpen={() => setOpenDeleteDataset(true)}
        onContinue={() =>
          new DatasetClient(Configuration.SERVER_ROOT, FetchOverride)
            .deleteDataset(props.dataset!)
            .then(() => {
              setOpenDeleteDataset(false);
              props.refresh();
            })
        }
        linkedObjects={props.linkedMeasurements}
        linkedErrorText={"Delete linked measurements before deleting dataset."}
      />
      <CreateEditDataSetModal
        open={openEditDataset}
        closeModal={() => setOpenEditDataset(false)}
        dataset={props.dataset}
        refresh={props.refresh}
      />
      <CreateEditMeasurementModal
        open={openAddMeasurement}
        resetMeasurements={props.resetMeasurements}
        datasetId={props.dataset.id}
        closeModal={() => setOpenAddMeasurement(false)}
      />
    </>
  );
};

export default DatasetActions;
