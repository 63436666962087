import React, { useState } from "react";
import { Alert, Box, Typography } from "@mui/material";
import QuestionBox from "./QuestionBox";
import {
  GoalViewModel,
  QuestionViewModel,
  QuestionnaireResponseViewModel,
  Role,
  SectionViewModel,
} from "../../../../types/auto/types";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import parse from "html-react-parser";

interface Props {
  section: SectionViewModel | undefined;
  responses: Array<QuestionnaireResponseViewModel>;
  setResponses: React.Dispatch<
    React.SetStateAction<Array<QuestionnaireResponseViewModel>>
  >;
  copyright: string | undefined;
  setRerender: () => void;
  patientId?: string;
  questionnaireId?: number;
  role?: Role;
  preview?: boolean;
  questions: Array<QuestionViewModel>;
  caseloadId?: number;
  episodeId?: string;
  sectionCompleteCheck: () => void;
  checkAccessibleSections: () => void;
  questionnaireResponseId?: number;
  setHasUnsubmittedGoals: (x: boolean) => void;
}

const QuestionnaireSection = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const [goalsFromQuestionnaire, setGoalsFromQuestionnaire] = useState<
    GoalViewModel[]
  >([]);

  return (
    <>
      <Typography
        variant={isMobile ? "h6" : "h5"}
        component="h1"
        gutterBottom
        sx={{ fontWeight: "bold", m: 2, ml: isMobile ? 0 : 2 }}
      >
        {props.section?.heading ?? null}
      </Typography>
      {props.section?.sectionTitle !== null && (
        <Alert
          key={"sectionTitle-" + props.section?.sectionTitle}
          severity="info"
        >
          {props.section?.sectionTitle}
        </Alert>
      )}
      {props.section?.questions?.map((question) => (
        <div key={"questionContainer-" + question.number}>
          <QuestionBox
            key={"question-" + question.number}
            question={question}
            setResponses={props.setResponses}
            responses={
              props.responses || new Array<QuestionnaireResponseViewModel>()
            }
            setRerender={props.setRerender}
            patientId={props.patientId}
            questionnaireId={props.questionnaireId}
            role={props.role}
            goalsFromQuestionnaire={goalsFromQuestionnaire}
            setGoalsFromQuestionnaire={setGoalsFromQuestionnaire}
            preview={props.preview}
            questions={props.questions}
            caseloadId={props.caseloadId}
            episodeId={props.episodeId}
            sectionCompleteCheck={props.sectionCompleteCheck}
            checkAccessibleSections={props.checkAccessibleSections}
            readOnly={false}
            questionnaireResponseId={props.questionnaireResponseId}
            setHasUnsubmittedGoals={(x) => props.setHasUnsubmittedGoals(x)}
          />
        </div>
      ))}
      {props.copyright ? (
        <Box
          sx={{
            m: isMobile ? 0 : 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" component="h1" gutterBottom>
            {parse(props.copyright ?? "")}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

export default QuestionnaireSection;
