import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import { useState, useEffect, useContext, useMemo, useCallback } from "react";
import HeaderBuilder from "./HeaderBuilder";
import BodyBuilder from "./BodyBuilder";
import FooterBuilder from "./FooterBuilder";
import {
  DefaultQuestionValuesViewModel,
  IdentityClient,
  QuestionnaireClient,
  QuestionnaireViewModel,
  UserViewModel,
} from "../../../../types/auto/types";
import CancelWarningModal from "./CancelWarningModal";
import parse from "html-react-parser";
import {
  ChangeType,
  convertDataModelToViewModel,
  generateQuestionnaireBuilderDataModel,
  QuestionnaireBuilderDataModel,
  ScoringDataModel,
  SectionDataModel,
  PatientDashboardOptionsModel,
  updateAnswerNumbersHelper,
  updateQuestionNumbersHelper,
} from "./QuestionnaireBuilderDataModelHelper";
import QuestionnaireSettings from "./QuestionnaireSettings";
import { useUnmountPromise } from "react-use";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import nameof from "../../../utils/NameOf";
import { UserContext } from "../../elements/stores/UserStore";
import { debounce } from "lodash";
import {
  expirationCookieExpired,
  getPath,
  isContextual,
  logout,
} from "../../../utils/timeout";

interface Props {
  questionnaire: QuestionnaireViewModel;
  allQuestionnaires: QuestionnaireViewModel[];
  cancelBuilder: () => void;
  enableDashboardOptions: boolean;
}

const QuestionnaireBuilder = (props: Props): JSX.Element => {
  const [loading] = useState<boolean>(false);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [section, setSection] = useState<number>(0);
  const [openCancelWarningModal, setOpenCancelWarningModal] =
    useState<boolean>(false);
  const [questionnaireWIP, setQuestionnaireWIP] =
    useState<QuestionnaireBuilderDataModel>(generateQuestionnaireWIP);
  const [questionNumberChange, setQuestionNumberChange] = useState<number>(0);
  const [questionNumberChangeType, setQuestionNumberChangeType] =
    useState<ChangeType>(ChangeType.Undefined);
  const [questionNumberRecalc, setQuestionNumberRecalc] =
    useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [sectionNumberChange, setSectionNumberChange] = useState<number>(0);
  const [sectionQuestionNumbersChange, setSectionQuestionNumbersChange] =
    useState<number[]>([]);
  const [sectionNumberChangeType, setSectionNumberChangeType] =
    useState<ChangeType>(ChangeType.Undefined);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [saveError, setSaveError] = useState<boolean>(false);
  const [saveAndCloseLoading, setSaveAndCloseLoading] = useState(false);
  const [previewCoverPage, setPreviewCoverPage] = useState<boolean>(false);
  const user = useContext(UserContext) as UserViewModel;

  // The number of milliseconds in-between grouped expiry cookie refresh requests.
  const debounceDelayMS = 2000;

  function generateQuestionnaireWIP() {
    var dmq = generateQuestionnaireBuilderDataModel(props.questionnaire);
    return dmq;
  }

  const useStyle = makeStyles({
    titles: {
      marginTop: "10px",
    },
    mainContainer: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      minHeight: "100%",
    },
    loadingSpinner: {
      margin: "auto",
      width: "100%",
      marginLeft: "46%",
      marginTop: 10,
    },
    footer: {
      minHeight: "60px",
    },
    body: {
      flex: 1,
    },
    settings: {
      minWidth: "216px",
    },
  });
  const classes = useStyle();

  function handleNavigate(
    currentSection: number,
    addSectionDirection: boolean | null
  ) {
    keepAlive();
    if (currentSection < 0) {
      setCancelLoading(true);
      setOpenCancelWarningModal(true);
    } else if (addSectionDirection !== null) {
      handleAddSection(currentSection, addSectionDirection);
    } else {
      setSection(currentSection);
    }
  }

  function handleAddSection(current: number, sectionAfter: boolean) {
    var localSections: SectionDataModel[] = [];
    var newQ: boolean = false;
    var currentSection = current;
    var newS: SectionDataModel = {
      heading: undefined,
      dependsOn: undefined,
      questions: [],
      sectionTitle: undefined,
    };
    if (questionnaireWIP.sections.length !== 0) {
      questionnaireWIP.sections.forEach((section, index) => {
        if (sectionAfter) {
          localSections.push(section);
          if (index === current) {
            localSections.push(newS);
            currentSection++;
          }
        } else {
          if (index === current) {
            localSections.push(newS);
          }
          localSections.push(section);
        }
      });
    } else {
      localSections = [...localSections, newS];
      newQ = true;
    }
    var localQuestionnaire = { ...questionnaireWIP, sections: localSections };
    setQuestionnaireWIP(localQuestionnaire);
    setSection(newQ ? 0 : currentSection);
    setSaveDisabled(false);
  }

  useEffect(
    () => {
      var updatedQuestions = updateQuestionNumbersHelper(
        questionnaireWIP,
        questionNumberChange,
        questionNumberChangeType,
        sectionNumberChange,
        sectionQuestionNumbersChange,
        sectionNumberChangeType
      );
      setQuestionNumberChangeType(ChangeType.Undefined);
      setSectionNumberChangeType(ChangeType.Undefined);
      if (firstLoad) {
        updatedQuestions = updateAnswerNumbersHelper(updatedQuestions);
        setFirstLoad(false);
      }
      setQuestionnaireWIP(updatedQuestions);
    },
    // eslint-disable-next-line
    [questionNumberChange, questionNumberRecalc]
  );

  function handleChange(
    field: string,
    value:
      | string
      | number
      | boolean
      | null
      | SectionDataModel[]
      | PatientDashboardOptionsModel
      | undefined
      | ScoringDataModel[]
      | DefaultQuestionValuesViewModel[]
  ) {
    setQuestionnaireWIP((questionnaireWIP) => ({
      ...questionnaireWIP,
      [field]: value,
    }));
    setSaveDisabled(false);
  }

  const resolveWhileMounted = useUnmountPromise();
  const questionnaireClient = new QuestionnaireClient(
    Configuration.SERVER_ROOT,
    FetchOverride
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleSave(close: boolean, logoutAfterSave: boolean = false) {
    var VM = convertDataModelToViewModel(questionnaireWIP);
    var JSONpayload: string = JSON.stringify(VM);
    await resolveWhileMounted(
      questionnaireClient.saveQuestionnaireVersion(JSONpayload)
    )
      .then((id) => {
        handleChange(nameof<QuestionnaireBuilderDataModel>("id"), id);
        setSaveLoading(false);
        setSaveDisabled(true);
        setSaveError(false);
        if (close) {
          props.cancelBuilder();
        }
      })
      .catch(() => {
        setSaveLoading(false);
        setSaveError(true);
      });
    if (logoutAfterSave) {
      logout(getPath(window.location.href), isContextual);
    }
  }

  const keepAlive = useMemo(
    () =>
      debounce(
        () => {
          new IdentityClient(
            Configuration.SERVER_ROOT,
            FetchOverride
          ).refreshCookie();
        },
        debounceDelayMS,
        { maxWait: debounceDelayMS * 5 }
      ),
    []
  );

  const [timeoutSwitch, setTimeoutSwitch] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const saveDraft = useCallback(() => {
    handleSave(false, true);
  }, [handleSave]);

  useEffect(() => {
    // Check one step before the cookie expires and twice as frequent as timeout component.
    // Then force logout on save to stop the cookie being refreshed
    const handler = setTimeout(() => {
      if (
        expirationCookieExpired(Configuration.TIMEOUTCHECK / 1000) &&
        !savingDraft
      ) {
        setSavingDraft(true);
        saveDraft();
      }
      setTimeoutSwitch(!timeoutSwitch);
    }, Configuration.TIMEOUTCHECK / 2);
    return () => clearTimeout(handler);
  }, [timeoutSwitch, savingDraft, saveDraft, user.role]);

  return (
    <Container className={classes.mainContainer} disableGutters>
      <Typography variant="h5" component="h2" className={classes.titles}>
        Questionnaire Builder
      </Typography>
      {saveError && (
        <Alert severity="error">
          There was an internal error and the questionnaire could not be saved.
          Please try again or contact support.
        </Alert>
      )}

      <Grid container>
        <Grid sm={12} md sx={{ pr: [0, 0, 4] }}>
          <HeaderBuilder
            questionnaire={questionnaireWIP}
            setQuestionnaireWIP={(f, v) => {
              handleChange(f, v);
            }}
            section={section}
            navigateSection={(current, direction) => {
              handleNavigate(current, direction);
            }}
            sections={questionnaireWIP?.sections}
            keepAlive={keepAlive}
          />
        </Grid>
        <Grid className={classes.settings} xs={12} md={2}>
          <QuestionnaireSettings
            questionnaire={questionnaireWIP}
            allQuestionnaires={props.allQuestionnaires}
            setQuestionnaireWIP={(f, v) => {
              handleChange(f, v);
            }}
            previewCoverPage={previewCoverPage}
            setPreviewCoverPage={setPreviewCoverPage}
            enableDashboardOptions={props.enableDashboardOptions}
            handleSave={() => handleSave(false)}
            handleSaveAndClose={() => handleSave(true)}
            saveLoading={saveLoading}
            saveAndCloseLoading={saveAndCloseLoading}
            saveDisabled={saveDisabled}
            setSaveLoading={() => setSaveLoading(true)}
            setSaveAndCloseLoading={() => setSaveAndCloseLoading(true)}
            keepAlive={keepAlive}
          />
        </Grid>
      </Grid>

      {previewCoverPage && (
        <Box>
          <Divider />
          {parse(questionnaireWIP.coverPage ?? "")}
        </Box>
      )}

      <Divider />

      <Box className={classes.body}>
        <BodyBuilder
          allQuestionnaires={props.allQuestionnaires}
          questionnaire={questionnaireWIP}
          section={section}
          setQuestionnaireWIP={(f, v) => {
            handleChange(f, v);
          }}
          setQuestionNumberChange={(qNum, type) => {
            setQuestionNumberChange(qNum);
            setQuestionNumberChangeType(type);
          }}
          setSectionNumberChange={(sn, sQNums, type) => {
            setSectionNumberChange(sn);
            setSectionQuestionNumbersChange(sQNums);
            setSectionNumberChangeType(type);
          }}
          setQuestionNumberRecalc={() => setQuestionNumberRecalc((x) => !x)}
          setSectionNumber={(sn) => {
            setSection(
              sn === questionnaireWIP.sections.length - 1
                ? sn - 1
                : sn - 1 === -1
                ? 0
                : sn
            );
          }}
          keepAlive={keepAlive}
        />
      </Box>

      <Divider></Divider>

      <Box className={classes.footer}>
        <FooterBuilder
          section={section}
          totalSections={questionnaireWIP.sections?.length ?? 0}
          sections={questionnaireWIP?.sections}
          navigateSection={(current, direction) => {
            handleNavigate(current, direction);
          }}
          cancelLoading={cancelLoading}
        />
      </Box>

      <Container>
        {loading && (
          <CircularProgress
            className={classes.loadingSpinner}
            aria-label="Loading"
          />
        )}
      </Container>
      <CancelWarningModal
        open={openCancelWarningModal}
        handleCancel={() => {
          props.cancelBuilder();
        }}
        handleStay={() => {
          setCancelLoading(false);
          setOpenCancelWarningModal(false);
          setSection(0);
        }}
      />
    </Container>
  );
};

export default QuestionnaireBuilder;
