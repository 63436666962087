import { Theme, Card, CardContent, Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  CaseloadViewModel,
  CustomIdViewModel,
} from "../../../types/auto/types";
import { theme as customTheme } from "../../../Theme";
import { CheckIsMobile, CheckIsSmallDevice } from "../../utils/MobileStatus";
import CaseloadActions from "./CaseloadActions";
import { DefaultCustomIdDisplayName } from "../../Constants";

interface Props {
  caseload: CaseloadViewModel;
  setRefresh: () => void;
  integration?: boolean;
  customCaseloadIdOptions?: CustomIdViewModel;
}

interface Preview {
  name: string;
  value: string;
}

const CaseloadCard = (props: Props) => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const useStyle = makeStyles((th: Theme) => ({
    caseloadCard: {
      display: isMobile ? "default" : "flex",
      flexWrap: "wrap",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      marginTop: "10px",
      marginRight: isMobile ? "auto" : "0.5%",
      width: isMobile ? "100%" : "49.5%",
      minWidth: isSmallDevice ? "180px" : "250px",
      height: "fit-content",
      "&:nth-child(even)": {
        marginRight: isMobile ? "auto" : "0",
        marginLeft: isMobile ? "auto" : "0.5%",
      },
    },
    cardContent: {
      width: "100%",
      padding: "2px !important",
    },
    NameContainer: {
      display: "flex",
      float: "left",
    },
    NameText: {
      fontSize: isMobile ? "1.3em" : "1.8em",
      paddingLeft: "1%",
      width: "100%",
    },
    ButtonContainer: {
      display: isMobile ? "grid" : "flex",
      position: isMobile ? "relative" : "static",
      top: "25%",
      padding: isMobile ? "2%" : "0%",
      marginLeft: "auto",
    },
    textMainContainer: {
      display: "block",
      width: "100%",
      paddingLeft: "1%",
      paddingRight: "1%",
      float: "left",
      paddingBottom: "10px",
    },
    textContainer: {
      display: "flex",
      float: "left",
    },
    textField: {
      fontWeight: "Bold",
      marginRight: "10px",
    },
    textFieldValue: {
      fontWeight: "normal",
    },
    button: {
      padding: "5px",
    },
    buttonContainer: {
      marginLeft: "auto",
      display: "flex",
    },
  }));
  const classes = useStyle({ theme: customTheme });

  const previews = [
    { name: "Short name", value: props.caseload.shortName },
    { name: "Description", value: props.caseload.description },
  ] as Preview[];

  if (props.customCaseloadIdOptions) {
    previews.push({
      name:
        props.customCaseloadIdOptions?.displayName ??
        DefaultCustomIdDisplayName,
      value: props.caseload.customCaseloadId,
    } as Preview);
  }

  return (
    <Card className={classes.caseloadCard} elevation={0}>
      <CardContent className={classes.cardContent}>
        <Container className={classes.NameContainer}>
          <Typography className={classes.NameText}>
            {props.caseload.name}
          </Typography>
          <Container className={classes.buttonContainer} disableGutters>
            <CaseloadActions
              caseload={props.caseload}
              refresh={props.setRefresh}
              integration={props.integration}
              customCaseloadIdOptions={props.customCaseloadIdOptions}
            />
          </Container>
        </Container>
        <Container className={classes.textMainContainer}>
          {previews.map((x, key) => (
            <Container className={classes.textContainer} key={key}>
              <Typography className={classes.textField}>{x.name}:</Typography>
              <Typography className={classes.textFieldValue}>
                {x.value}
              </Typography>
            </Container>
          ))}
        </Container>
      </CardContent>
    </Card>
  );
};
export default CaseloadCard;
