import { Box, Button, Container, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MuiColorInput } from "mui-color-input";
import { useEffect, useRef, useState } from "react";
import { useMountedState } from "react-use";
import { SimpleDrawingBoard, create } from "simple-drawing-board";

interface Props {
  onChange?: (e: string) => void;
}

export const DrawingBoard = (props: Props): JSX.Element => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sdbRef = useRef<SimpleDrawingBoard | null>(null);
  const [clear, setClear] = useState<boolean>(false);
  const [colour, setColour] = useState("#000");

  const backgroundColour = "#fff";

  const isMountedState = useMountedState();

  useEffect(() => {
    if (canvasRef.current) {
      if (isMountedState()) {
        const canvas = canvasRef.current;

        if (!sdbRef.current) {
          sdbRef.current = create(canvas);
          sdbRef.current.fill(backgroundColour);
          sdbRef.current.observer.on("drawEnd", () => {
            if (sdbRef.current != null) {
              const durl = sdbRef.current.toDataURL();
              if (props.onChange) {
                props.onChange(durl);
              }
            }
          });
        }

        if (clear) {
          sdbRef.current.clear();
          sdbRef.current.fill(backgroundColour);
          setClear(false);
        }
      }
    }
  }, [clear, isMountedState, props]);

  useEffect(() => {
    if (sdbRef.current) {
      sdbRef.current.setLineColor(colour);
    }
  }, [colour]);

  const useStyle = makeStyles((th: Theme) => ({
    actionContainer: {
      padding: 10,
      display: "flex",
      justifyContent: "flex-start",
    },
    action: {
      marginRight: 20,
    },
    canvas: {
      border: "1px solid black",
      borderColor: th.palette.grey[50],
      borderRadius: "4px",
    },
  }));
  const classes = useStyle();

  return (
    <Container disableGutters>
      <canvas
        className={classes.canvas}
        ref={canvasRef}
        width="500"
        height="300"
      ></canvas>
      <Box className={classes.actionContainer}>
        <Button
          className={classes.action}
          variant="contained"
          onClick={() => {
            setClear((x) => !x);
          }}
        >
          Clear
        </Button>
        <MuiColorInput
          className={classes.action}
          format="hex"
          value={colour}
          label="Pencil Colour"
          isAlphaHidden={true}
          onChange={(e) => {
            setColour(e);
          }}
        />
      </Box>
    </Container>
  );
};
