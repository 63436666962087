import { Card, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import {
  CheckIsLargeTablet,
  CheckIsMobile,
  CheckIsTablet,
} from "../../../utils/MobileStatus";

interface Props {
  title: string;
  body: string | JSX.Element;
  buttons: JSX.Element;
}

const QuestionnaireCard = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isTablet = CheckIsTablet();
  const isLargeTablet = CheckIsLargeTablet();
  const useStyle = makeStyles((th: Theme) => ({
    questionnaireCard: {
      width: isMobile ? "100%" : "49.5%",
      marginRight: isMobile ? "auto" : "0.5%",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      padding: 0,
      marginBottom: "10px",
      "&:nth-child(even)": {
        marginRight: isMobile ? "auto" : "0",
        marginLeft: isMobile ? "auto" : "0.5%",
      },
    },
    questionnaireHeader: {
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "bold",
      paddingBottom: "5%",
    },
    internalContainer: {
      padding: "3%",
    },
    questionnaireText: {
      textAlign: "center",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      verticalAlign: "bottom",
      flexDirection: isMobile || isTablet || isLargeTablet ? "column" : "row",
    },
  }));
  const classes = useStyle(theme);

  return (
    <Card className={classes.questionnaireCard} elevation={0}>
      <Container className={classes.internalContainer}>
        <Typography className={classes.questionnaireHeader}>
          {props.title}
        </Typography>
        {typeof props.body === "string" || props.body instanceof String ? (
          <Typography className={classes.questionnaireText}>
            {props.body}
          </Typography>
        ) : (
          props.body
        )}
        <Container className={classes.buttonContainer}>
          {props.buttons}
        </Container>
      </Container>
    </Card>
  );
};

export default QuestionnaireCard;
