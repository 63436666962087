import {
  Day,
  GoalOwner,
  MonitoringStatus,
  MonthPlace,
  PromptFrequency,
  PromptScheduleViewModel,
} from "../../../types/auto/types";
import { GetOrdinalSuffixForNumber } from "../../utils/NumberUtils";
import { replaceLast } from "../../utils/ReplaceLast";

const GetDailyFrequencyReport = (
  promptSchedule: PromptScheduleViewModel,
  startUpperCase: boolean
): string => {
  return (
    (startUpperCase ? "Every " : "every ") +
    (promptSchedule.frequencyAmount === 1
      ? " day"
      : promptSchedule.frequencyAmount + " days") +
    " at " +
    promptSchedule.timeOfDay?.substring(0, 5)
  );
};

const GetWeeklyFrequencyReport = (
  promptSchedule: PromptScheduleViewModel,
  startUpperCase: boolean
): string => {
  const weekdayString = replaceLast(
    promptSchedule.daysOfWeek?.map((x) => Day[x]).join(", "),
    ", ",
    " and "
  );

  return (
    (startUpperCase ? "Every " : "every ") +
    (promptSchedule.frequencyAmount === 1
      ? " week on " + weekdayString
      : promptSchedule.frequencyAmount + " weeks on " + weekdayString) +
    " at " +
    promptSchedule.timeOfDay?.substring(0, 5)
  );
};

const GetMonthlyFrequencyReport = (
  promptSchedule: PromptScheduleViewModel,
  startUpperCase: boolean
): string => {
  var startString = "";
  if (promptSchedule.dayOfMonth) {
    startString =
      (startUpperCase ? "On the " : "on the ") +
      GetOrdinalSuffixForNumber(promptSchedule.dayOfMonth) +
      " day of the month" +
      (promptSchedule.frequencyAmount === 1
        ? ""
        : " every " + promptSchedule.frequencyAmount + " months");
  } else if (
    promptSchedule.monthPlace !== undefined &&
    promptSchedule.daysOfWeek !== undefined &&
    promptSchedule.daysOfWeek.length > 0
  ) {
    startString =
      (startUpperCase ? "On the " : "on the ") +
      MonthPlace[promptSchedule.monthPlace].toLowerCase() +
      " " +
      Day[promptSchedule.daysOfWeek[0]] +
      " of the month" +
      (promptSchedule.frequencyAmount === 1
        ? ""
        : " every " + promptSchedule.frequencyAmount + " months");
  }

  return startString + " at " + promptSchedule.timeOfDay?.substring(0, 5);
};

export const GetReportingFrequencyString = (
  promptSchedule: PromptScheduleViewModel,
  removePrefix: boolean,
  startDate?: Date
): string => {
  var sentence = removePrefix ? "" : "You receive prompts ";
  if (promptSchedule.oneTimePrompt) {
    var tense =
      startDate == null || startDate > new Date()
        ? "Sending one time prompt on the "
        : "One time prompt sent on the ";
    sentence =
      tense +
      startDate?.toLocaleDateString("en-GB") +
      " at " +
      startDate?.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      }) +
      " due on the " +
      promptSchedule.oneTimePromptResponseDueDate?.toLocaleDateString("en-GB") +
      " at " +
      promptSchedule.oneTimePromptResponseDueDate?.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      });
  } else {
    switch (promptSchedule.frequency) {
      case PromptFrequency.Daily:
        sentence =
          sentence + GetDailyFrequencyReport(promptSchedule, removePrefix);
        break;
      case PromptFrequency.Weekly:
        sentence =
          sentence + GetWeeklyFrequencyReport(promptSchedule, removePrefix);
        break;
      case PromptFrequency.Monthly:
        sentence =
          sentence + GetMonthlyFrequencyReport(promptSchedule, removePrefix);
        break;
    }
  }

  return sentence;
};

export const GetMonitoringDescriptions = (
  descriptions: { [key in keyof typeof MonitoringStatus]?: string },
  monitoringStatus: MonitoringStatus
): string => {
  var keyValue = Object.entries(descriptions).filter(
    (key) => key[0] === MonitoringStatus[monitoringStatus]
  );

  if (keyValue.length > 0) {
    if (keyValue[0][1]) {
      return keyValue[0][1] as string;
    } else {
      return keyValue[0][0] as string;
    }
  } else {
    return monitoringStatus.toString();
  }
};

export const GetGoalOwnerDesciption = (
  descriptions: { [key in keyof typeof GoalOwner]?: string },
  goalOwner: GoalOwner
): string => {
  var keyValue = Object.entries(descriptions).filter(
    (key) => key[0] === GoalOwner[goalOwner]
  );

  if (keyValue.length > 0) {
    if (keyValue[0][1]) {
      return keyValue[0][1] as string;
    } else {
      return keyValue[0][0] as string;
    }
  } else {
    return goalOwner.toString();
  }
};
