import { Container, Theme, MenuItem, IconButton, Menu } from "@mui/material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../Theme";
import { KeyboardArrowDown, KeyboardArrowUp, Sort } from "@mui/icons-material";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { UserDescription } from "./SearchTypes";
import { SortDirectionType, SortType } from "../../../types/auto/types";

interface Props {
  sortBy: SortType;
  sortDirection: SortDirectionType;
  setSortBy: (orderBy: SortType) => void;
  setSortDirection: (direction: SortDirectionType) => void;
  floatRight?: boolean;
  sortableValues: UserDescription[];
}

const UserSort = (props: Props) => {
  const isMobile = CheckIsMobile();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const useStyle = makeStyles((th: Theme) => ({
    sortContainer: {
      display: "flex",
      boxShadow: "none",
      width: props.floatRight ? (isMobile ? "25%" : "10%") : undefined,
      float: "right",
      marginLeft: props.floatRight ? (isMobile ? "75%" : "90%") : "default",
      padding: props.floatRight ? "default" : 0,
    },
  }));
  const classes = useStyle(customTheme);

  return (
    <Container className={classes.sortContainer}>
      <IconButton
        color="primary"
        aria-label="select sort by"
        onClick={handleClick}
      >
        <Sort />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {props.sortableValues.map((x) => {
          return (
            <MenuItem
              role="menuitem"
              key={x.value}
              value={x.value}
              onClick={() => {
                props.setSortBy(x.type);
                handleClose();
              }}
            >
              {x.name}
            </MenuItem>
          );
        })}
      </Menu>

      <IconButton
        color="primary"
        aria-label="Change sort direction"
        onClick={() => {
          props.setSortDirection(
            props.sortDirection === SortDirectionType.Ascending
              ? SortDirectionType.Decending
              : SortDirectionType.Ascending
          );
        }}
      >
        {props.sortDirection === SortDirectionType.Ascending ? (
          <KeyboardArrowUp />
        ) : (
          <KeyboardArrowDown />
        )}
      </IconButton>
    </Container>
  );
};

export default UserSort;
