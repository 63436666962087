import { Box, Alert, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  AnswerDataModel,
  QuestionDataModel,
  SectionDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import BuilderQuestionBox from "./BuilderQuestionBox";
import { makeStyles } from "@mui/styles";
import QuestionDetails from "./QuestionDetails";
import QuestionActions from "./QuestionActions";
import {
  QuestionType,
  QuestionnaireViewModel,
} from "../../../../types/auto/types";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { AddCircle } from "@mui/icons-material";
import React from "react";

interface Props {
  questions: QuestionDataModel[];
  allQuestionnaires: QuestionnaireViewModel[];
  handleAddAnswer: (qNum: number) => void;
  preview: boolean;
  handleEditQuestion: (q: QuestionDataModel) => void;
  handleDuplicateQuestion: (q: QuestionDataModel) => void;
  handleDeleteQuestion: (qNum: number) => void;
  handleDeleteAnswer: (qNum: number, aNum: number) => void;
  handleEditAnswer: (answer: AnswerDataModel, qNum: number) => void;
  handleDependsOn: (q: QuestionDataModel) => void;
  handleAutofillFrom: (q: QuestionDataModel) => void;
  handleDefaultOverride: (q: QuestionDataModel) => void;
  handleToggleMulti: (q: QuestionDataModel, qType: QuestionType) => void;
  sectionNumber: number;
  handleToggleSkippable: (q: QuestionDataModel, qType: QuestionType) => void;
  handleToggleDay: (q: QuestionDataModel, qType: QuestionType) => void;
  sections: SectionDataModel[];
  addQuestion: (qNum: number) => void;
}

const SectionQuestions = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const questionBorderPadding = 15;
  const useStyle = makeStyles({
    mainContainer: {
      display: "flex",
      backgroundColor: "#EAE8E1",
      borderRadius: 20,
      padding: questionBorderPadding,
      width: "100%",
      flexWrap: "wrap",
      borderStyle: "solid",
      borderColor: "lightgrey",
      borderWidth: 2,
      gap: 10,
    },
    modifiedContainer: {
      display: "flex",
      backgroundColor: "#EAE8E1",
      borderRadius: 20,
      padding: 15,
      width: "100%",
      flexWrap: "wrap",
      borderStyle: "solid",
      borderWidth: 2,
      gap: 10,
    },
    titleContainer: {
      display: "flex",
      backgroundColor: "#EAE8E1",
      padding: 5,
      width: "100%",
    },
    previewContainer: {
      display: "flex",
      backgroundColor: "#EAE8E1",
      width: "100%",
      gap: 10,
      flexWrap: isMobile ? "wrap" : "nowrap",
    },
    addQuestionIconStyle: {
      marginRight: -questionBorderPadding,
    },
    fullWidth: {
      width: "100%",
    },
  });
  const classes = useStyle();

  const getFirstQuestionNumber = () => {
    if (props.questions.length > 0) {
      return props.questions[0].number;
    }
    return 1;
  };

  const returnRows: JSX.Element[] = props.questions.map((question, index) => {
    return (
      <React.Fragment
        key={"question-frag-" + question.number + "-index-" + index}
      >
        <Box
          className={
            props.preview &&
            (question.dependsOn ||
              question.autofillFrom ||
              question.defaultValueOverride)
              ? classes.modifiedContainer
              : classes.mainContainer
          }
          sx={{
            borderColor:
              question.dependsOn &&
              question.autofillFrom &&
              question.defaultValueOverride
                ? "#e88282 #4da9ff #fcb365"
                : question.dependsOn && question.autofillFrom
                ? "#e88282 #4da9ff"
                : question.dependsOn && question.defaultValueOverride
                ? "#e88282 #fcb365"
                : question.autofillFrom && question.defaultValueOverride
                ? "#4da9ff #fcb365"
                : question.dependsOn
                ? "#e88282"
                : question.autofillFrom
                ? "#4da9ff"
                : question.defaultValueOverride
                ? "#fcb365"
                : "lightgray",
          }}
          key={"question-container-" + question.number + "-index-" + index}
        >
          {!props.preview && (
            <Box
              className={classes.previewContainer}
              key={"question-details-container-" + question.number}
            >
              <QuestionDetails
                question={question}
                allQuestionnaires={props.allQuestionnaires}
                handleAddAnswer={(qNum) => props.handleAddAnswer(qNum)}
                handleEditQuestion={(q) => props.handleEditQuestion(q)}
                handleDuplicateQuestion={(q) =>
                  props.handleDuplicateQuestion(q)
                }
                handleDeleteQuestion={(qNum) =>
                  props.handleDeleteQuestion(qNum)
                }
                handleDependsOn={(q) => props.handleDependsOn(q)}
                handleAutofillFrom={(q) => props.handleAutofillFrom(q)}
                handleDefaultOverride={(q) => props.handleDefaultOverride(q)}
                handleDeleteAnswer={props.handleDeleteAnswer}
                handleEditAnswer={props.handleEditAnswer}
                handleToggleSkippable={props.handleToggleSkippable}
                handleToggleMulti={(q, qType) =>
                  props.handleToggleMulti(q, qType)
                }
                handleToggleDay={(q, qType) => props.handleToggleDay(q, qType)}
                key={"question-details-" + question.number}
                sections={props.sections}
              />
            </Box>
          )}
          {props.preview && (
            <Box
              className={classes.previewContainer}
              key={"question-preview-container-" + question.number}
            >
              {question.answers.length === 0 &&
              question.questionType !== QuestionType.Goals ? (
                <Alert
                  key={"question-preview-alert-" + question.number}
                  severity="info"
                >
                  Please add answers to preview this question.
                </Alert>
              ) : (
                <BuilderQuestionBox
                  question={question}
                  key={"question-box-" + question.number + "-" + index}
                />
              )}
              <QuestionActions
                question={question}
                handleEditQuestion={(q) => props.handleEditQuestion(q)}
                handleDuplicateQuestion={(q) =>
                  props.handleDuplicateQuestion(q)
                }
                handleDeleteQuestion={(qNum) =>
                  props.handleDeleteQuestion(qNum)
                }
                handleAddAnswer={(qNum) => props.handleAddAnswer(qNum)}
                handleDependsOn={(q) => props.handleDependsOn(q)}
                handleAutofillFrom={(q) => props.handleAutofillFrom(q)}
                handleDefaultOverride={(q) => props.handleDefaultOverride(q)}
                key={"preview-question-actions-" + question.number}
              />
            </Box>
          )}
        </Box>
        <Grid container className={classes.fullWidth} justifyContent="flex-end">
          <IconButton
            className={classes.addQuestionIconStyle}
            color="secondary"
            aria-label="Add Question"
            onClick={() => props.addQuestion(question.number + 1)} // We want to add a question after the current question
          >
            <AddCircle />
          </IconButton>
        </Grid>
      </React.Fragment>
    );
  });

  return (
    <Box
      display={"flex"}
      width={"100%"}
      flexWrap={"wrap"}
      key={"section-questions-main-container-" + props.sectionNumber}
    >
      {props.questions && props.questions.length > 0 && (
        <Grid container className={classes.fullWidth} justifyContent="flex-end">
          <IconButton
            className={classes.addQuestionIconStyle}
            color="secondary"
            aria-label="Add Question"
            onClick={() => props.addQuestion(getFirstQuestionNumber())}
          >
            <AddCircle />
          </IconButton>
        </Grid>
      )}
      {returnRows}
    </Box>
  );
};

export default SectionQuestions;
