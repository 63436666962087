import { Box, Button, Fade, Modal, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckIsMobile } from "../../utils/MobileStatus";
import { useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  QuestionnaireClient,
  QuestionnaireExportFileModel,
  QuestionnaireViewModel,
} from "../../../types/auto/types";
import { FetchOverride } from "../../utils/Request";
import { Configuration } from "../../Constants";
import Grid from "@mui/material/Unstable_Grid2";

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  onSave: () => void;
  questionnaireId?: number;
  questionnaireVersion?: number;
  nameValidation?: string;
}

const client = new QuestionnaireClient(
  Configuration.SERVER_ROOT,
  FetchOverride
);

const QuestionnaireImporter = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<QuestionnaireViewModel | null>(
    null
  );
  const [uploadError, setUploadError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);

  const processFile = (file: File) => {
    reset();

    if (file.type === "application/json") {
      const reader = new FileReader();

      reader.onload = async (e) => {
        if (e.target?.result) {
          try {
            setLoading(true);
            const json = JSON.parse(
              e.target.result as string
            ) as QuestionnaireExportFileModel;
            if (await validateImport(json)) {
              setFile(file);
              var questionnaireViewModel = JSON.parse(
                json.content
              ) as QuestionnaireViewModel;
              questionnaireViewModel.id = props.questionnaireId ?? -1;
              questionnaireViewModel.version = props.questionnaireVersion ?? 1;
              setFileContent(questionnaireViewModel);
            } else {
              setUploadError(
                "The file appears to be invalid or altered. Please try again with a valid file."
              );
            }
          } catch (error) {
            setUploadError("Please select a valid JSON file");
          } finally {
            setLoading(false);
          }
        }
      };

      reader.readAsText(file);
    } else {
      setUploadError("Please select a valid JSON file");
    }
  };

  // Handle file selection via input
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      processFile(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    // Prevent default behavior
    reset();
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      processFile(file);
    }
  };

  // Handle clicking the drop zone
  const handleClick = () => {
    reset();
    fileInputRef.current?.click(); // Trigger a click on the hidden file input
  };

  const validateImport = async (content: QuestionnaireExportFileModel) => {
    return await client.validateQuestionnaireVersionImport(content);
  };

  const saveQuestionnaire = async (
    questionnaire: QuestionnaireViewModel | null
  ) => {
    if (questionnaire) {
      var JSONpayload: string = JSON.stringify(questionnaire);
      await client.saveQuestionnaireVersion(JSONpayload);
      props.onSave();
    }
  };

  const reset = () => {
    setFile(null);
    setFileContent(null);
    setUploadError(null);
    if (fileInputRef.current != null) {
      fileInputRef.current!.value = "";
    }
  };

  // Remove all imported state when modal closes
  useEffect(() => {
    if (!props.open) {
      reset();
    }
  }, [props.open]);

  const isMobile = CheckIsMobile();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "95%" : "80%",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    border: "none",
    transition: "width 0.5s ease, height 0.5s ease",
  };

  const useStyle = makeStyles({
    dropzone: {
      padding: "10px",
      margin: "20px auto",
      width: "95%",
      height: "140px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderWidth: "2px",
      borderStyle: "dashed",
      borderColor: fileContent
        ? "green"
        : uploadError
        ? "red"
        : "cornflowerblue",
      borderRadius: "8px",
      background: "#eeeeee",
      cursor: "pointer",
      textAlign: "center",
      transition: "border-color 2s ease",
    },
    instruction: {
      fontStyle: "italic",
      margin: "0 0 10px",
    },
    uploadError: {
      padding: "10px 10px",
      fontWeight: "bold",
      fontSize: 15,
      color: "red",
      display: uploadError ? "block" : "none",
    },
    uploaded: {
      fontWeight: "bold",
      display: fileContent ? "block" : "none",
    },
    buttons: {
      marginTop: 32,
      display: "flex",
      justifyContent: "end",
    },
  });
  const classes = useStyle();
  return (
    <>
      <Modal open={props.open}>
        <Box sx={{ ...modalStyle }}>
          <h2>Upload Questionnaire</h2>
          <p>
            Please upload the JSON file of the questionnaire that you wish to
            upload.
          </p>
          <div>
            {/* Clickable and Draggable Drop Area */}
            <div
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={handleClick}
              className={classes.dropzone}
              role="button" // Button role for accessibility
              tabIndex={0} // Makes it focusable
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClick();
                }
              }}
            >
              <p className={classes.instruction}>
                Drag and drop the JSON file, or click to
                {fileContent || uploadError ? " re-upload" : " upload"}
              </p>

              {loading && <CircularProgress size={30} />}

              <Fade in={!!file} timeout={1500}>
                <p className={classes.uploaded}>{file?.name}</p>
              </Fade>

              <Fade in={!!uploadError} timeout={1500} key={uploadError}>
                <p className={classes.uploadError}>{uploadError}</p>
              </Fade>
            </div>

            {/* Hidden File Input */}
            <input
              ref={fileInputRef}
              type="file"
              accept="application/json"
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
          </div>

          {!!fileContent && (
            <Fade in={!!fileContent}>
              <Grid container spacing={0} paddingTop={2}>
                <Grid xs={12} md={2}>
                  <p className={classes.uploaded}>Name</p>
                </Grid>
                <Grid xs={12} md={10}>
                  <p>{fileContent?.name ?? ""}</p>
                </Grid>
                <Grid xs={12} md={2}>
                  <p className={classes.uploaded}>Title</p>
                </Grid>
                <Grid xs={12} md={10}>
                  <p>{fileContent?.title ?? ""}</p>
                </Grid>
                <Grid xs={12} md={2}>
                  <p className={classes.uploaded}>Description</p>
                </Grid>
                <Grid xs={12} md={10}>
                  <p>{fileContent?.description ?? ""}</p>
                </Grid>
              </Grid>
            </Fade>
          )}

          <div className={classes.buttons}>
            <Button onClick={() => props.setOpen(false)}>Cancel</Button>
            <LoadingButton
              variant="contained"
              onClick={() => {
                if (
                  props.nameValidation != null &&
                  fileContent?.name !== props.nameValidation
                ) {
                  setWarningModalOpen(true);
                } else {
                  saveQuestionnaire(fileContent);
                }
              }}
              disabled={!fileContent}
            >
              Import Questionnaire
            </LoadingButton>
          </div>
        </Box>
      </Modal>
      {warningModalOpen && (
        <Modal open={warningModalOpen}>
          <Box sx={{ ...modalStyle }}>
            <h2>Names do not match</h2>
            <p>
              The name of the questionnaire you are trying to upload does not
              match the name on the questionnaire card you selected. If you
              continue, you will overwrite the name with the name in the
              imported file. Do you want to continue?
            </p>

            <div className={classes.buttons}>
              <Button onClick={() => setWarningModalOpen(false)}>Cancel</Button>
              <LoadingButton
                variant="contained"
                onClick={() => {
                  setWarningModalOpen(false);
                  saveQuestionnaire(fileContent);
                }}
                disabled={!fileContent}
              >
                Continue
              </LoadingButton>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default QuestionnaireImporter;
