import { Container, Typography } from "@mui/material";
import {
  MonitoringStatus,
  PatientViewModel,
  Role,
  ScheduleClient,
  ScheduleViewModel,
} from "../../../types/auto/types";
import PromptableCard from "../elements/promptable-card/PromptableCard";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import PromptWarningDialog, {
  PromptActionWarningState,
} from "./dialog/PromptWarningDialog";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import { orderBy } from "lodash";

interface Props {
  schedules: ScheduleViewModel[];
  patient: PatientViewModel;
  refreshSchedules: () => void;
  monitoringStatusDescriptions: {
    [key in keyof typeof MonitoringStatus]?: string;
  };
  readonly: boolean;
}

const ExpiredSchedules = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    cardContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: 0,
      padding: 0,
    },
    title: { marginTop: 20 },
  }));
  const classes = useStyle();

  const [warningModalState, setWarningModalState] =
    useState<PromptActionWarningState>();
  const [scheduleToModify, setScheduleToModify] = useState<ScheduleViewModel>();
  const scheduleClient = new ScheduleClient(
    Configuration.SERVER_ROOT,
    FetchOverride
  );

  if (props.schedules.length === 0) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h5" component="h2" className={classes.title}>
        Archived Schedules
      </Typography>

      <Container className={classes.cardContainer}>
        <PromptWarningDialog
          open={warningModalState !== undefined}
          onClose={() => setWarningModalState(undefined)}
          patient={props.patient}
          state={warningModalState}
          refreshList={props.refreshSchedules}
          name={scheduleToModify?.questionnaire?.name || "questionnaire"}
          promptName="schedule"
          onResumeSingle={() =>
            scheduleClient.resumeSchedule(scheduleToModify?.id)
          }
          onResumeAll={() =>
            scheduleClient.resumeAllSchedulesForUser(props.patient?.id)
          }
          onSuspendSingle={() =>
            scheduleClient.suspendSchedule(scheduleToModify?.id)
          }
          onSuspendAll={() =>
            scheduleClient.suspendAllSchedulesForUser(props.patient?.id)
          }
          onArchiveSingle={() =>
            scheduleClient.archiveSchedule(scheduleToModify?.id)
          }
          onArchiveAll={() =>
            scheduleClient.archiveAllSchedulesForUser(props.patient?.id)
          }
          onUnarchive={() =>
            scheduleClient.unarchiveSchedule(scheduleToModify?.id)
          }
        />
        {orderBy(props.schedules, "created", "desc").map((x) => (
          <PromptableCard
            key={"archivedSchedules" + x.id}
            title={x.questionnaire?.name || ""}
            monitoringStatus={x.monitoringStatus}
            createdDate={x.created}
            lastResponse={x.lastResponseDate}
            prompt={x.promptSchedule}
            showPrompt
            onUnarchiving={() => {
              setWarningModalState(PromptActionWarningState.Unarchive);
              setScheduleToModify(x);
            }}
            role={Role.Clinician}
            monitoringStatusDescriptions={props.monitoringStatusDescriptions}
            readOnly={props.readonly}
            startDate={x.startDate}
          />
        ))}
      </Container>
    </>
  );
};
export default ExpiredSchedules;
