import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AdditionalPlots } from "./AdditionalPlotsBuilder";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import { useEffect, useState } from "react";

interface Props {
  open: boolean;
  formFields: JSX.Element[];
  handleClose: (dO?: AdditionalPlots) => void;
  handleUpdate: () => void;
  noQuestionnaireError: boolean;
  noCategoryError: boolean;
}

const AdditionalPlotsAddModal = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    additionalPlotsContainer: {
      margin: 0,
      paddingTop: 10,
      paddingRight: 0,
      paddingLeft: 0,
      display: "flex",
      width: "100%",
      alignContent: "start",
    },
  });
  const classes = useStyle(theme);

  const [noQuestionnaireError, setNoQuestionnaireError] =
    useState<boolean>(false);
  const [noCategoryError, setNoCategoryError] = useState<boolean>(false);

  useEffect(() => {
    setNoQuestionnaireError(false);
    setNoCategoryError(false);
  }, [props.formFields]);

  return (
    <Dialog open={props.open} maxWidth="lg" fullWidth>
      <DialogTitle>Add Dependency</DialogTitle>
      <DialogContent>
        <Container className={classes.additionalPlotsContainer}>
          {props.formFields}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.handleClose();
          }}
          variant="outlined"
        >
          Close
        </Button>
        <Button
          onClick={() => {
            if (props.noQuestionnaireError) {
              setNoQuestionnaireError(true);
            } else if (props.noCategoryError) {
              setNoCategoryError(true);
            } else {
              props.handleUpdate();
            }
          }}
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
      {(noQuestionnaireError || noCategoryError) && (
        <Alert severity="error">
          {"Please select a " +
            (noQuestionnaireError ? "Questionnaire." : "Category.")}
        </Alert>
      )}
    </Dialog>
  );
};

export default AdditionalPlotsAddModal;
