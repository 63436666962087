import React, { useState } from "react";
import { Tune, Print, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Tooltip,
  AccordionDetails,
} from "@mui/material";
import GraphSectionTitle from "./header/GraphSectionTitle";
import { makeStyles } from "@mui/styles";
import AccordionIconButton from "./AccordionIconButton";

interface Props {
  children: React.ReactNode;
  accordionIcon: React.ReactElement;
  accordionTitle: string;
  defaultExpanded?: boolean;
  showPrint?: boolean;
  printCallback?: () => void;
  showToggleLegend?: boolean;
  toggleLegendCallback?: () => void;
}

const AccordionContainer = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(!!props.defaultExpanded);

  const useStyle = makeStyles(() => ({
    accordionWrapper: {
      marginBottom: 20,
    },
    accordion: {
      margin: 0,
    },
    accordionSummaryWrapper: {
      display: "flex",
      alignItems: "center",
    },
    accordionSummary: {
      flexGrow: 1,
    },
    accordionButtons: {
      alignItems: "center",
    },
    accordionIcon: {
      alignSelf: "center",
    },
    buttonIcon: {
      marginRight: 10,
    },
  }));
  const classes = useStyle();

  return (
    <div className={classes.accordionWrapper}>
      <Accordion
        className={classes.accordion}
        TransitionProps={{ unmountOnExit: true }}
        disableGutters
        expanded={isExpanded}
        onChange={(_, expanded) => setIsExpanded(expanded)}
        sx={{ marginBottom: "16px" }}
      >
        <div className={classes.accordionSummaryWrapper}>
          <AccordionSummary className={classes.accordionSummary}>
            <Tooltip
              title={props.accordionTitle}
              className={classes.accordionIcon}
            >
              {props.accordionIcon}
            </Tooltip>

            <GraphSectionTitle title={props.accordionTitle} />
          </AccordionSummary>

          <div className={classes.accordionButtons}>
            {props.showToggleLegend && (
              <AccordionIconButton
                show={isExpanded}
                icon={<Tune />}
                label="toggle graph legend"
                tooltip="Toggle Legend"
                onClick={props.toggleLegendCallback}
                fade
              />
            )}
            {props.showPrint && (
              <AccordionIconButton
                show={isExpanded}
                icon={<Print />}
                label="print graph"
                tooltip="Print"
                onClick={props.printCallback}
                fade
              />
            )}
            <AccordionIconButton
              show
              icon={<ExpandMore />}
              label={"expand accordion"}
              onClick={() => setIsExpanded(!isExpanded)}
              style={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            />
          </div>
        </div>
        <AccordionDetails
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {props.children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionContainer;
