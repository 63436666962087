import {
  Button,
  Paper,
  Theme,
  Tooltip,
  TextField,
  IconButton,
  FormControlLabel,
  Switch,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import ReplayIcon from "@mui/icons-material/Replay";
import { AllowListPatientSearchRegex } from "../../Constants";
import { theme as customTheme } from "../../../Theme";
import FormErrorText from "../elements/errors/FormErrorText";
import { useMountedState } from "react-use";
import { useDebounce } from "../../utils/UseDebounce";

interface Props {
  filterCallback: (searchTerm: string, enableArchived: boolean) => void;
  searchTerm?: string;
  enableArchived?: boolean;
  clearSearch: () => void;
  helpText?: string;
}

const SimpleSearchFilters = (props: Props): JSX.Element => {
  const [searchText, setSearchText] = useState(props.searchTerm || "");
  const debouncedSearchFilter = useDebounce(searchText, 250);

  const [archive, setArchive] = useState(props.enableArchived || false);
  const [invalidCharacterUsed, setInvalidCharacterUsed] = useState(false);
  const [numInputs, setNumInputs] = useState(2);

  const useStyle = makeStyles((th: Theme) => ({
    outerPaper: {
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      paddingTop: "10px",
      paddingBottom: "10px",
      boxShadow: "none",
    },
    inputText: {
      width: "75%",
    },
    tooltip: {
      height: "40px",
      width: "40px",
    },
  }));

  const classes = useStyle(customTheme);

  const isMountedState = useMountedState();

  useEffect(() => {
    if (isMountedState()) {
      props.filterCallback(debouncedSearchFilter, archive);

      let numberOfInputs = 0;
      if (props.searchTerm !== undefined) {
        numberOfInputs++;
      }
      if (props.enableArchived !== undefined) {
        numberOfInputs++;
      }
      setNumInputs(numberOfInputs);
    }
  }, [props, debouncedSearchFilter, archive, isMountedState]);

  if (numInputs === 0) {
    return <></>;
  }

  return (
    <Paper component="form" className={classes.outerPaper}>
      <Grid container px={3} alignItems={"center"} spacing={1}>
        {props.searchTerm !== undefined && (
          <Grid xs={12} sm={(numInputs === 2 ? 9 : 8) / numInputs}>
            <Stack
              direction={"row"}
              alignItems={"end"}
              justifyContent={{ xs: "center", sm: "normal" }}
            >
              <TextField
                variant="standard"
                value={searchText}
                label="Search"
                onChange={(e: any) => {
                  var searchText = e.target.value;
                  var regexp = new RegExp(AllowListPatientSearchRegex);
                  if (regexp.test(searchText)) {
                    setInvalidCharacterUsed(true);
                    return;
                  } else {
                    setInvalidCharacterUsed(false);
                  }
                  setSearchText(searchText);
                }}
                fullWidth
                className={numInputs === 1 ? classes.inputText : ""}
              />
              {props.helpText && (
                <Tooltip
                  title={props.helpText ?? ""}
                  enterTouchDelay={0}
                  className={classes.tooltip}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Grid>
        )}
        {props.enableArchived !== undefined && (
          <Grid xs={12} sm={(numInputs === 2 ? 9 : 8) / numInputs}>
            <Stack
              direction={"row"}
              alignItems={"end"}
              justifyContent={numInputs === 1 ? "start" : "center"}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={archive}
                    onChange={() => setArchive((x) => !x)}
                    color="secondary"
                  />
                }
                label="Archived Caseloads"
              />
            </Stack>
          </Grid>
        )}
        <Grid xs={12} sm={numInputs === 2 ? 3 : 4}>
          <Stack direction="row" justifyContent={{ xs: "center", sm: "end" }}>
            <Button
              onClick={() => {
                setSearchText("");
                setArchive(false);
                props.clearSearch();
              }}
            >
              <ReplayIcon />
              Clear field{numInputs > 1 ? "s" : ""}
            </Button>
          </Stack>
        </Grid>
        {invalidCharacterUsed && (
          <Grid xs={12}>
            <FormErrorText
              errorText="Do not use invalid characters in search i.e. #,!,?"
              isInvalid={invalidCharacterUsed}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default SimpleSearchFilters;
