import React, { useState } from "react";
import { theme } from "../../../../Theme";
import { Button, Container, Menu, MenuItem, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CreateEditPatientNoteModel from "./CreateEditPatientNoteModal";
import { Add, Edit, Delete } from "@mui/icons-material";
import {
  PatientNoteViewModel,
  PatientViewModel,
} from "../../../../types/auto/types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DeletePatientNote } from "./DeletePatientNote";

interface Props {
  patient: PatientViewModel;
  style?: React.CSSProperties;
  onSave: () => void;
  patientNote?: PatientNoteViewModel;
}

const CreateEditPatientNote = (props: Props): JSX.Element => {
  const creating = !props.patientNote;
  const useStyle = makeStyles((th: Theme) => ({
    container: {
      padding: 0,
    },
    vert: {
      padding: "0",
      minWidth: "24px",
      width: "fit-content",
      marginLeft: "auto",
    },
  }));
  const classes = useStyle(theme);
  const [createEditPatientNoteOpen, setCreateEditPatientNoteOpen] =
    useState(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [anchorActionList, setAnchorActionList] =
    React.useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorActionList(null);
  };

  return (
    <>
      <CreateEditPatientNoteModel
        open={createEditPatientNoteOpen}
        closeModal={() => setCreateEditPatientNoteOpen(false)}
        patient={props.patient}
        setOpen={setCreateEditPatientNoteOpen}
        patientNote={props.patientNote}
        onSave={props.onSave}
      />
      <DeletePatientNote
        patientNote={props.patientNote!}
        openDeleteDialogue={openDeleteDialogue}
        setOpenDeleteDialogue={setOpenDeleteDialogue}
        onDelete={props.onSave}
      />
      {creating && (
        <Container className={classes.container} style={props.style}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setCreateEditPatientNoteOpen(true)}
          >
            Create note
          </Button>
        </Container>
      )}
      {!creating && (
        <Button
          onClick={(event) =>
            setAnchorActionList((val) =>
              val === null ? event.currentTarget : null
            )
          }
          aria-label="Open Action List"
          className={classes.vert}
        >
          <MoreVertIcon />
          <Menu
            open={anchorActionList !== null}
            anchorEl={anchorActionList}
            aria-label="Action List"
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => setCreateEditPatientNoteOpen(true)}
              aria-label="Edit response"
            >
              <Edit /> Edit patient note
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenDeleteDialogue(true);
              }}
            >
              <Delete /> Delete patient note
            </MenuItem>
          </Menu>
        </Button>
      )}
    </>
  );
};

export default CreateEditPatientNote;
