import React from "react";
import { CircularProgress, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  width: number;
  height: number;
  type: string;
}

const GraphLoading = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    container: {
      marginTop: 50,
      height: props.height,
      width: props.width,
      textAlign: "center",
      paddingTop: 175,
    },
  }));
  const classes = useStyle();

  return (
    <Container className={classes.container}>
      <CircularProgress
        id={"progressBar" + props.type}
        role="progressbar"
        aria-label={"Graph Loading " + props.type}
      />
    </Container>
  );
};

export default GraphLoading;
