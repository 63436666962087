import { Configuration } from "../Constants";
import { IdentityClient } from "../../types/auto/types";
import { FetchOverride } from "../utils/Request";
import { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useUnmountPromise } from "react-use";

const useStyle = makeStyles({
  loadingButtonContainer: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

const Login = (): React.ReactElement => {
  const navigate = useNavigate();
  const { redirectUrl } = useParams();
  var reformattedUrl = "";
  if (redirectUrl !== undefined) {
    reformattedUrl = redirectUrl.includes("search")
      ? redirectUrl?.replace(",", "?s=").replaceAll("_", "&")
      : redirectUrl.includes("preview")
      ? redirectUrl?.replace(",", "?qId=").replaceAll("_", "&")
      : redirectUrl?.replace(",", "?p=").replaceAll("_", "&");
  }

  const resolveWhileMounted = useUnmountPromise();

  useEffect(() => {
    const effect = async () => {
      const identityClient = new IdentityClient(
        Configuration.SERVER_ROOT,
        FetchOverride
      );

      await resolveWhileMounted(identityClient.isAuthed())
        .then((x) => {
          if (x.email === null) {
            window.location.href = identityClient.getAuthURL(
              redirectUrl ? reformattedUrl : ""
            );
          } else {
            navigate("/");
          }
        })
        .catch((error) => console.log(error));
    };
    effect();
  }, [navigate, redirectUrl, reformattedUrl, resolveWhileMounted]);

  const classes = useStyle();

  return (
    <Box className={classes.loadingButtonContainer}>
      <CircularProgress size={24} aria-label="Loading" />
    </Box>
  );
};

export default Login;
