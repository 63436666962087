import { Card, CardContent, Theme, Typography } from "@mui/material";
import { CheckIsMobile, CheckIsSmallDevice } from "../../../utils/MobileStatus";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Theme";
import {
  PatientNoteViewModel,
  PatientViewModel,
} from "../../../../types/auto/types";
import moment from "moment";
import { Container } from "@mui/system";
import CreateEditPatientNote from "./CreateEditPatientNote";

interface Props {
  patientNote: PatientNoteViewModel;
  patient: PatientViewModel;
  reload: () => void;
  readonly: boolean;
}

const PatientNote = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const useStyle = makeStyles((th: Theme) => ({
    headerAndActions: {
      display: "flex",
      paddingRight: "0",
      paddingLeft: "0",
    },
    card: {
      marginTop: "10px",
      width: isMobile ? "100%" : "49.5%",
      marginRight: isMobile ? "auto" : "0.5%",
      minWidth: isSmallDevice ? "180px" : "250px",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      height: "fit-content",
      "&:nth-child(even)": {
        marginRight: isMobile ? "auto" : "0",
        marginLeft: isMobile ? "auto" : "0.5%",
      },
    },
    staffInformation: {
      color: "gray",
      display: "inline",
      fontSize: 15,
    },
    patientNote: {
      whiteSpace: "pre-wrap",
    },
  }));
  const classes = useStyle(theme);
  return (
    <Card className={classes.card} elevation={0}>
      <CardContent>
        <Container className={classes.headerAndActions}>
          <Typography className={classes.staffInformation}>
            {props.patientNote.staffId && (
              <>Note made by: {props.patientNote.staffName} on </>
            )}
            {!props.patientNote.staffId && <>Note made by patient on </>}
            {moment(props.patientNote.created).format("DD-MM-YYYY hh:mm A")}
          </Typography>
          {!props.readonly && (
            <CreateEditPatientNote
              patient={props.patient}
              patientNote={props.patientNote}
              onSave={props.reload}
            />
          )}
        </Container>
        <Typography className={classes.patientNote}>
          {props.patientNote.text}
        </Typography>
        {props.patientNote.editedStaffId !== undefined &&
          props.patientNote.editedStaffId !== null && (
            <Typography className={classes.staffInformation}>
              Last edited by {props.patientNote.editedStaffName} on{" "}
              {moment(props.patientNote.updated).format("DD-MM-YYYY hh:mm A")}
            </Typography>
          )}
      </CardContent>
    </Card>
  );
};
export default PatientNote;
