import { Box, FormControl, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../../Theme";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InfoHint from "../../elements/form-elements/InfoHint";
import {
  OneOffPromptsDate,
  OneOffPromptsResponseDueDate,
} from "../../../HelpText";
import { PromptScheduleViewModel } from "../../../../types/auto/types";
import { useState } from "react";

interface Props {
  oneTimePrompt: PromptScheduleViewModel;
  startDate: Date;
  setSendTime: (startDate: Date) => void;
  setOneTimePrompt: (oneTimePrompt: PromptScheduleViewModel) => void;
  editing: boolean;
  tenantId: number;
  timeOfDay: string | undefined;
  daysBeforeReminders: number[];
}

const OneOffScheduling = (props: Props): JSX.Element => {
  const useStyle = makeStyles({
    form: { width: "100%", margin: "10px", marginLeft: 0 },
    datePicker: {
      width: "270px",
      paddingBottom: "10px",
      marginRight: "10px",
      zIndex: 100,
    },
    dateAndInfo: {
      display: "flex",
      alignItems: "center",
    },
    hint: { marginTop: 16 },
  });
  const classes = useStyle(customTheme);

  const [withinEditThreshold] = useState<boolean>(
    props.editing &&
      new Date() < new Date(props.startDate.valueOf() - 1000 * 60 * 31)
  );

  const remindersText = (): string => {
    var remindersText = "";
    var count = 0;
    if (props.daysBeforeReminders.length > 0) {
      remindersText = "Reminders will be sent ";
      props.daysBeforeReminders.forEach((x) => {
        if (count < props.daysBeforeReminders.length - 2) {
          remindersText += x.toString() + ", ";
        } else if (count === props.daysBeforeReminders.length - 2) {
          remindersText += x.toString() + " and ";
        } else {
          remindersText +=
            x.toString() + " days prior to the specified due date.";
        }
        count++;
      });
      return remindersText;
    } else {
      remindersText = "Reminders have not been set for this tenancy.";
    }
    return remindersText;
  };

  return (
    <FormControl className={classes.form}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box>
          <DateTimePicker
            label="Prompt Date"
            value={props.timeOfDay == null ? "" : props.startDate}
            onChange={(newValue: Date | null) => {
              if (newValue != null) {
                props.setSendTime(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={
                  props.startDate == null || props.timeOfDay == null
                    ? "Please enter a date to send the prompt on"
                    : isNaN(props.startDate.getTime())
                    ? "Please enter a valid date and time"
                    : undefined
                }
                error={
                  props.startDate == null ||
                  props.timeOfDay == null ||
                  isNaN(props.startDate.getTime())
                }
              />
            )}
            className={classes.datePicker}
            inputFormat="dd/MM/yyyy HH:mm"
            disabled={props.editing && !withinEditThreshold}
            disableMaskedInput
            disablePast={!props.editing}
            ampm={false}
            PopperProps={{
              placement: "bottom-start",
              modifiers: [
                {
                  name: "flip",
                  options: {
                    fallbackPlacements: ["top-start", "right"],
                  },
                },
              ],
              popperOptions: {
                strategy: "fixed",
              },
            }}
          />
          <InfoHint helpText={OneOffPromptsDate} className={classes.hint} />
        </Box>
        <Box>
          <DateTimePicker
            onChange={(newValue: Date | null) => {
              if (newValue != null) {
                props.oneTimePrompt.oneTimePromptResponseDueDate = newValue;
                props.setOneTimePrompt(props.oneTimePrompt);
              }
            }}
            label="Response Due Date"
            value={props.oneTimePrompt.oneTimePromptResponseDueDate ?? ""}
            className={classes.datePicker}
            disabled={props.editing && !withinEditThreshold}
            disableMaskedInput
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={
                  props.oneTimePrompt.oneTimePromptResponseDueDate === undefined
                    ? "Please enter a due date"
                    : isNaN(
                        props.oneTimePrompt.oneTimePromptResponseDueDate.getTime()
                      )
                    ? "Please enter a valid date and time"
                    : undefined
                }
                error={
                  props.oneTimePrompt.oneTimePromptResponseDueDate ===
                    undefined ||
                  isNaN(
                    props.oneTimePrompt.oneTimePromptResponseDueDate.getTime()
                  )
                }
              />
            )}
            disablePast={!props.editing}
            inputFormat="dd/MM/yyyy HH:mm"
            ampm={false}
            PopperProps={{
              placement: "bottom-start",
              modifiers: [
                {
                  name: "flip",
                  options: {
                    fallbackPlacements: ["top-start", "right"],
                  },
                },
              ],
              popperOptions: {
                strategy: "fixed",
              },
            }}
          />
          <InfoHint
            helpText={OneOffPromptsResponseDueDate}
            className={classes.hint}
          />
        </Box>
      </LocalizationProvider>
      <Typography>{remindersText()}</Typography>
    </FormControl>
  );
};

export default OneOffScheduling;
