import React, { useEffect, useState } from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryChart,
  VictoryZoomContainer,
} from "victory";
import GraphLoading from "../../../elements/graph/GraphLoading";
import GraphError from "../../../elements/graph/GraphError";
import { useMountedState } from "react-use";
import { GetDateInterval, GetXAxisDomain } from "../ChartHelper";
import GraphTickLabel from "../GraphTickLabel";
import { DataScatter, GroupedLine } from "./MedicationLineGraphCoreHelper";
import MobileZoomable from "./MobileZoomable";
import { CheckIsTablet } from "../../../../utils/MobileStatus";
import GraphMedicationLegend, {
  ExtendedMedicationViewModel,
} from "../../../elements/graph/GraphMedicationLegend";

interface Props {
  data: ExtendedMedicationViewModel[];
  setData?: React.Dispatch<React.SetStateAction<ExtendedMedicationViewModel[]>>;
  loading: boolean;
  error: boolean;
  width: number;
  height: number;
  fromDate: Date | undefined;
  toDate: Date | undefined;
  yDomain?: DomainTuple;
  hideLegend?: boolean;
}

const MedicationLineGraphCore = (props: Props): JSX.Element => {
  const isMobile = CheckIsTablet();
  const [xAxisLabels, setXAxisLabels] = useState<number[][]>([[]]);
  const [xAxisValues, setXAxisValues] = useState<number[]>([]);
  const [dateInterval, setDateInterval] = useState<string>();
  const isMountedState = useMountedState();
  const [zoomDomain, setZoomDomain] = useState<{
    x?: DomainTuple;
    y?: DomainTuple;
  }>();
  const [mobileSelectedDomain, setMobileSelectedDomain] = useState<{
    x?: DomainTuple;
    y?: DomainTuple;
  }>();
  const graphHeight =
    (props.data.length + 1) * 55 > 320 ? 340 : (props.data.length + 1) * 55;

  useEffect(
    () => {
      if (props.data && isMountedState()) {
        const xAxisLabels = GetXAxisDomain(props.fromDate, props.toDate, 1);
        setXAxisLabels(xAxisLabels);

        const dateInterval = GetDateInterval(props.fromDate, props.toDate);
        setDateInterval(dateInterval);

        switch (dateInterval) {
          case "year":
            setXAxisValues(xAxisLabels[0]);
            break;
          case "month":
            setXAxisValues(xAxisLabels[1]);
            break;
          case "week":
            setXAxisValues(xAxisLabels[2]);
            break;
          default:
            setXAxisValues(xAxisLabels[3]);
            break;
        }
      }
    },
    // Including xAxisLabels and dateInterval in dependency array causes infinite recursion
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.data,
      props.data.length,
      isMountedState,
      props.fromDate,
      props.toDate,
    ]
  );
  return (
    <>
      {props.loading ? (
        <GraphLoading
          width={props.width}
          height={graphHeight}
          type="medication"
        />
      ) : props.error ? (
        <GraphError width={props.width} height={graphHeight} />
      ) : (
        <>
          <VictoryChart
            domainPadding={{ x: 40, y: 40 }}
            padding={{ left: 120, top: 0, right: 50, bottom: 55 }}
            domain={{
              x:
                xAxisValues.length > 1
                  ? [xAxisValues[0], xAxisValues[xAxisValues.length - 1]]
                  : undefined,
            }}
            width={props.width}
            height={graphHeight}
            aria-label={"Medication Graph"}
            containerComponent={
              <VictoryZoomContainer
                zoomDimension="x"
                containerId="chart"
                height={graphHeight}
                minimumZoom={{ x: 1000 * 3600 * 24 * 6 }}
                onZoomDomainChange={(s) => {
                  setMobileSelectedDomain(s);

                  const newDateInterval = GetDateInterval(
                    new Date(s.x[0]),
                    new Date(s.x[1])
                  );

                  if (newDateInterval !== dateInterval) {
                    setDateInterval(newDateInterval);
                    switch (newDateInterval) {
                      case "year":
                        setXAxisValues(xAxisLabels[0]);
                        break;
                      case "month":
                        setXAxisValues(xAxisLabels[1]);
                        break;
                      case "week":
                        setXAxisValues(xAxisLabels[2]);
                        break;
                      default:
                        setXAxisValues(xAxisLabels[3]);
                        break;
                    }
                  }
                }}
                zoomDomain={zoomDomain}
                allowZoom={!isMobile}
              />
            }
          >
            <VictoryAxis
              dependentAxis
              style={{
                grid: { stroke: "lightgray", strokeWidth: 0.5 },
                tickLabels: { padding: 5 },
              }}
              crossAxis={false}
              tickLabelComponent={
                <GraphTickLabel
                  chartTitle={
                    props.data && props.data.length > 0
                      ? props.data[0].lineName || ""
                      : ""
                  }
                  style={{
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                />
              }
            />
            <VictoryAxis
              style={{
                grid: {
                  stroke: "lightgray",
                  strokeWidth: 0.5,
                },
                tickLabels: { angle: -5, padding: 20 },
                axisLabel: { padding: 35 },
              }}
              tickValues={xAxisValues}
              tickFormat={(t: number) => {
                switch (dateInterval) {
                  case "year":
                    return new Date(t).toLocaleDateString("en-GB", {
                      year: "numeric",
                    });
                  case "month":
                    return new Date(t).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "short",
                    });
                  default:
                    return new Date(t).toLocaleDateString("en-GB");
                }
              }}
            />
            {GroupedLine(props.data, "")}
            {DataScatter(props.data, "")}
          </VictoryChart>
          {/* Add a second chart on mobile to enable zooming, as the zoom container is bugged on mobile */}
          {isMobile && (
            <MobileZoomable
              width={props.width}
              mobileSelectedDomain={mobileSelectedDomain}
              setZoomDomain={setZoomDomain}
              xAxisValues={xAxisValues}
              dateInterval={dateInterval}
              xAxisLabels={xAxisLabels}
              setDateInterval={setDateInterval}
              setXAxisValues={setXAxisValues}
              fromDate={props.fromDate}
              toDate={props.toDate}
              data={props.data}
            />
          )}
          {props.setData && !props.hideLegend && (
            <GraphMedicationLegend data={props.data} setData={props.setData} />
          )}
        </>
      )}
    </>
  );
};

export default MedicationLineGraphCore;
