import {
  MenuList,
  IconButton,
  MenuItem,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Menu } from "@mui/icons-material";
import { theme } from "../../../../Theme";
import Drawer from "./NavigationDrawer";
import { useEffect, useState } from "react";
import {
  ContactDetailsViewModel,
  EnumClient,
  Role,
  TenantClient,
  TenantViewModel,
  UserClient,
  UserViewModel,
} from "../../../../types/auto/types";
import { FetchOverride } from "../../../utils/Request";
import { Configuration } from "../../../Constants";
import UserManagement from "../user/UserManagement";
import NavigationOptions from "./NavigationOptions";
import {
  CheckIsMobile,
  CheckIsSmallDevice,
  CheckIsSmallerThan,
} from "../../../utils/MobileStatus";
import { useMountedState, useUnmountPromise } from "react-use";
import ContactPreferenceModal from "../user/ContactPreferenceModal";
import ChangeTenancyModal from "../user/ChangeTenancyModal";
import HandleError from "../errors/HandleError";

const tenantClient = new TenantClient(Configuration.SERVER_ROOT, FetchOverride);

const NavigationBar = (): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const isSmallerThan1165 = CheckIsSmallerThan(1165);
  const isSmallerThan1090 = CheckIsSmallerThan(1090);
  const isSmallerThan1020 = CheckIsSmallerThan(1020);
  const isSmallerThan955 = CheckIsSmallerThan(955);
  const isSmallerThan905 = CheckIsSmallerThan(905);
  const isSmallerThan860 = CheckIsSmallerThan(860);
  const isSmallerThan735 = CheckIsSmallerThan(735);
  const isSmallerThan705 = CheckIsSmallerThan(705);
  const isSmallerThan680 = CheckIsSmallerThan(680);
  const [currentUser, setCurrentUser] = useState<UserViewModel>();
  const [anchorElNavDrawer, setAnchorElNavDrawer] =
    useState<null | HTMLElement>(null);

  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();

  const [roleDescriptions, setRoleDescriptions] = useState<{
    [key in keyof typeof Role]?: string;
  }>({});

  const [loadRole, setLoadRole] = useState(false);
  const [usingMedication, setUsingMedication] = useState(false);
  const [usingExternalData, setUsingExternalData] = useState(false);
  const [allowEditQuestionnaires, setAllowEditQuestionnaires] = useState(false);
  const [forceMobile, setForceMobile] = useState(false);
  const [optionGap, setOptionGap] = useState("3%");
  const [statusCode, setStatusCode] = useState<number>();
  const [navLoaded, setNavLoaded] = useState(false);

  useEffect(() => {
    if (isMountedState()) {
      const effect = async () => {
        setStatusCode(undefined);
        if (!currentUser) {
          setLoadRole(true);
          await resolveWhileMounted(
            new UserClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).getCurrentUser()
          ).then(async (user: UserViewModel) => {
            setCurrentUser(user);
            if (user.role !== Role.NoAccess) {
              await resolveWhileMounted(
                new EnumClient(
                  Configuration.SERVER_ROOT,
                  FetchOverride
                ).getRoleDescriptions()
              ).then((x) => {
                setRoleDescriptions(x);
                setLoadRole(false);
              });
            }
          });
        }
        if (currentUser) {
          setNavLoaded(false);
          if (
            currentUser.role !== Role.NoAccess &&
            currentUser.role !== Role.Patient
          ) {
            await resolveWhileMounted(
              tenantClient
                .getExternalDataEnabled()
                .then((externalDataEnabled) =>
                  setUsingExternalData(externalDataEnabled)
                )
                .catch((e) => setStatusCode(e.statusCode))
            );
            await resolveWhileMounted(
              tenantClient
                .getMedicationEnabled()
                .then((medEnabled) => setUsingMedication(medEnabled))
                .catch((e) => setStatusCode(e.statusCode))
            );
          }
          if (
            currentUser.role === Role.Admin ||
            currentUser.role === Role.SuperAdmin
          ) {
            await resolveWhileMounted(
              tenantClient
                .getEditQuestionnairesEnabled()
                .then((allowEditQuestionnaires) =>
                  setAllowEditQuestionnaires(allowEditQuestionnaires)
                )
                .catch((e) => setStatusCode(e.statusCode))
            );
          }
          setNavLoaded(true);
        }
      };
      effect().catch(() => {});
    }
  }, [currentUser, resolveWhileMounted, isMountedState]);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    if (
      currentUser?.role !== Role.Admin &&
      currentUser?.role !== Role.SuperAdmin
    ) {
      return;
    }

    const extraItemCount = [
      usingMedication,
      usingExternalData,
      allowEditQuestionnaires,
    ].filter(Boolean).length;

    const gap =
      extraItemCount === 3 ||
      (extraItemCount === 2 && isSmallerThan1090) ||
      (extraItemCount === 1 && isSmallerThan905) ||
      (extraItemCount === 0 && isSmallerThan705)
        ? "1%"
        : (extraItemCount === 2 && isSmallerThan1165) ||
          (extraItemCount === 1 && isSmallerThan955) ||
          (extraItemCount === 0 && isSmallerThan735)
        ? "2%"
        : "3%";

    const mobile =
      (extraItemCount === 3 && isSmallerThan1165) ||
      (extraItemCount === 2 && isSmallerThan1020) ||
      (extraItemCount === 1 && isSmallerThan860) ||
      (extraItemCount === 0 && isSmallerThan680);

    setForceMobile(mobile);
    setOptionGap(gap);
  }, [
    isMobile,
    currentUser,
    usingMedication,
    usingExternalData,
    allowEditQuestionnaires,
    isSmallerThan1165,
    isSmallerThan1090,
    isSmallerThan1020,
    isSmallerThan955,
    isSmallerThan905,
    isSmallerThan860,
    isSmallerThan735,
    isSmallerThan705,
    isSmallerThan680,
  ]);

  const useStyle = makeStyles((th: Theme) => ({
    buttonToolbar: {
      display: "flex",
      gap: optionGap,
      flexDirection: "row",
      backgroundColor: th.palette.secondary.main,
      color: "white",
      borderRadius: "4px",
      overflow: "hidden",
    },
    roleName: {
      fontWeight: "bolder",
      cursor: "default",
      pointerEvents: "none",
      fontSize: "22px",
      display: isSmallDevice ? "none" : "flex",
    },
    button: {
      display: "inline-block",
    },
  }));
  const classes = useStyle(theme);

  const handleClickNavDrawer = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorElNavDrawer !== null) {
      setAnchorElNavDrawer(null);
    } else {
      setAnchorElNavDrawer(event.currentTarget);
    }
  };
  const handleCloseNavDrawer = () => {
    setAnchorElNavDrawer(null);
  };

  const GetRoleDescription = (
    descriptions: { [key in keyof typeof Role]?: string },
    role?: Role
  ): string => {
    if (loadRole || role === undefined) {
      return "";
    }
    var keyValue = Object.entries(descriptions).filter(
      (key) => key[0] === Role[role as Role]
    );

    if (keyValue.length > 0) {
      if (keyValue[0][1]) {
        return keyValue[0][1] as string;
      } else {
        return keyValue[0][0] as string;
      }
    } else {
      return (role as Role).toString();
    }
  };
  const [contactDetails, setContactDetails] = useState(
    new ContactDetailsViewModel()
  );
  const [userTenancies, setUserTenancies] = useState<TenantViewModel[]>([]);
  const [currentTenancy, setCurrentTenancy] = useState<number>(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [contactPrefOpen, setContactPrefOpen] = useState(false);
  const [changeTenancyOpen, setChangeTenancyOpen] = useState(false);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      const effect = async () => {
        await new UserClient(Configuration.SERVER_ROOT, FetchOverride)
          .getContactDetails()
          .then((contactDetail) => {
            setContactDetails(contactDetail);
          })
          .catch((e) => setStatusCode(e.statusCode));

        await new UserClient(Configuration.SERVER_ROOT, FetchOverride)
          .getCurrentTenancy()
          .then((currentTenancy) => {
            setCurrentTenancy(currentTenancy);
          })
          .catch((e) => setStatusCode(e.statusCode));

        await tenantClient
          .getUserTenancies()
          .then((userTenancies) => {
            setUserTenancies(userTenancies);
          })
          .catch((e) => setStatusCode(e.statusCode));
      };
      effect();
    }
  }, [firstLoad]);

  return (
    <>
      {(isMobile || forceMobile) && currentUser && currentUser.role !== null ? (
        <MenuList
          className={classes.buttonToolbar}
          aria-label="Page Navigation"
          role="menu"
        >
          <MenuItem id="mainMenu" aria-label="main menu item" role="menuitem">
            <IconButton onClick={handleClickNavDrawer} aria-label="main menu">
              <Menu style={{ color: "white" }} />
              <Drawer
                open={anchorElNavDrawer !== null}
                handleCloseNavDrawer={handleCloseNavDrawer}
                userRole={currentUser?.role}
                anchorElNavDrawer={anchorElNavDrawer}
                usingMedication={usingMedication}
                usingExternalData={usingExternalData}
                allowEditQuestionnaires={allowEditQuestionnaires}
              />
            </IconButton>
          </MenuItem>
          <MenuItem
            className={classes.roleName}
            aria-label="user role"
            id="userRole"
            role="menuitem"
            sx={{ alignContent: "center", paddingLeft: 0 }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {GetRoleDescription(roleDescriptions, currentUser?.role)}
            </Typography>
          </MenuItem>
          <UserManagement
            user={currentUser}
            setContactPrefOpen={setContactPrefOpen}
            setTenancyChangeOpen={setChangeTenancyOpen}
            tenancyChangeEnabled={userTenancies && userTenancies.length > 1}
          />
        </MenuList>
      ) : (
        <MenuList
          className={classes.buttonToolbar}
          aria-label="Page Navigation"
          role="menu"
        >
          <MenuItem
            className={classes.roleName}
            aria-label="user role"
            id="mainMenu"
            role="menuitem"
            sx={{ alignContent: "center" }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {GetRoleDescription(roleDescriptions, currentUser?.role)}
            </Typography>
          </MenuItem>
          {navLoaded && (
            <NavigationOptions
              userRole={currentUser?.role}
              usingMedication={usingMedication}
              usingExternalData={usingExternalData}
              allowEditQuestionnaires={allowEditQuestionnaires}
            />
          )}
          <UserManagement
            user={currentUser}
            setContactPrefOpen={setContactPrefOpen}
            setTenancyChangeOpen={setChangeTenancyOpen}
            tenancyChangeEnabled={userTenancies && userTenancies.length > 1}
          />
        </MenuList>
      )}
      <ContactPreferenceModal
        open={contactPrefOpen}
        closeModal={() => setContactPrefOpen(false)}
        details={contactDetails}
      />
      <ChangeTenancyModal
        open={changeTenancyOpen}
        closeModal={() => setChangeTenancyOpen(false)}
        userTenancies={userTenancies}
        setCurrentTenancy={(tenancy: number) => setCurrentTenancy(tenancy)}
        currentTenancy={currentTenancy}
        userRole={currentUser?.role}
      />
      <HandleError statusCode={statusCode} />
    </>
  );
};

export default NavigationBar;
