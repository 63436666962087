import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useState } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { theme } from "../../../../Theme";

interface Props {
  setFile: React.Dispatch<React.SetStateAction<Blob | undefined>>;
  noFileError: boolean;
  setNoFileError: React.Dispatch<boolean>;
  invalidDataError: boolean;
}

const PatientUploadDropzone = ({
  setFile,
  noFileError,
  setNoFileError,
  invalidDataError,
}: Props): JSX.Element => {
  const [hovering, setHovering] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [incorrectFileTypeError, setIncorrectFileTypeError] = useState(false);
  const [fileName, setFileName] = useState<string>();

  const useStyle = makeStyles((theme: Theme) => ({
    dragDropContainer: {
      padding: 0,
      borderStyle: "dashed",
      borderColor:
        hovering || dragging
          ? theme.palette.grey[800]
          : incorrectFileTypeError || noFileError || invalidDataError
          ? theme.palette.error.main
          : theme.palette.grey[500],
      borderWidth: 2,
      borderRadius: 10,
      backgroundColor: theme.palette.grey[100],
      height: "150px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      verticalAlign: "center",
      alignItems: "center",
      pointer: "cursor",
      transition: "border-color 0.4s linear",
    },
    text: {
      margin: 0,
      color:
        hovering || dragging
          ? theme.palette.grey[900]
          : theme.palette.grey[700],
      transition: "color 0.4s linear",
    },
    errorText: {
      margin: 0,
      marginTop: 10,
      color: "red",
    },
    fileNameText: {
      margin: 0,
      marginTop: 10,
      color: theme.palette.grey[900],
    },
  }));
  const classes = useStyle(theme);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setIncorrectFileTypeError(false);
      setNoFileError(false);
      setDragging(false);
      setHovering(false);
      setFileName(undefined);

      acceptedFiles.forEach((file) => {
        setFileName(file.name);
        setFile(file);
      });
    },
    [setFile, setNoFileError]
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[], event: DropEvent) => {
      setIncorrectFileTypeError(true);
      setNoFileError(false);
      setDragging(false);
      setHovering(false);
      setFileName(undefined);
    },
    [setNoFileError]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: "text/csv",
    maxFiles: 1,
  });

  return (
    <div
      {...getRootProps()}
      className={classes.dragDropContainer}
      onDragEnter={() => setDragging(true)}
      onDragLeave={() => setDragging(false)}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <input {...getInputProps()} />
      <p className={classes.text}>
        {dragging
          ? "Drop file here"
          : "Drag and drop a csv file, or click to upload"}
      </p>
      {(incorrectFileTypeError || noFileError) && (
        <p className={classes.errorText}>Please upload a csv file</p>
      )}
      {fileName && <p className={classes.fileNameText}>{fileName}</p>}
    </div>
  );
};

export default PatientUploadDropzone;
