import { Card, CardContent, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Gender,
  PatientClient,
  PreferredContact,
  PatientViewModel,
  UserViewModel,
  ErrorType,
  Role,
} from "../../../../types/auto/types";
import { theme as customTheme, theme } from "../../../../Theme";
import moment from "moment";
import {
  CheckIsLargeDevice,
  CheckIsMobile,
  CheckIsSmallDevice,
} from "../../../utils/MobileStatus";
import React, { useCallback, useEffect, useState } from "react";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import AvatarLogo from "../../elements/nav/AvatarLogo";
import ContactPreferences, {
  generateErrorText,
  isSelectedValid,
} from "../../elements/contact-preferences/ContactPreferences";
import CreateEditPatient from "../create-patient/CreateEditPatient";
import { useCommonStyle } from "../../../utils/CommonStyles";
import { makeStyles } from "@mui/styles";
import { isNullOrEmptyString } from "../../../HelperFunctions";

interface Props {
  patient: PatientViewModel;
  integration: boolean;
  reloadPatient: () => void;
  dashboardSupported: boolean;
  nhsNumberRequired: boolean;
  setPatient: React.Dispatch<
    React.SetStateAction<PatientViewModel | undefined>
  >;
  user: UserViewModel;
  customPatientId: boolean;
  customPatientIdDisplayName: string;
  customPatientIdRegex: string;
  customPatientIdMandatory: boolean;
}

const ViewPatientDetails = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const isLargeDevice = CheckIsLargeDevice();
  const [prefError, setPrefError] = useState<ErrorType | undefined>(
    isSelectedValid(
      props.patient.mobileNumber,
      props.patient.contactEmail,
      props.patient.preferredContact
    )
      ? undefined
      : ErrorType.NullContact
  );

  const useStyle = makeStyles(() => ({
    error: {
      color: "red",
      textAlign: "center",
    },
    redacted: {
      color: "transparent",
      backgroundColor: "black",
      borderRadius: "10px",
      userSelect: "none",
    },
  }));
  const classes = useStyle();

  const useCommonStyles = useCommonStyle(
    customTheme,
    isSmallDevice,
    isMobile,
    isLargeDevice
  );
  const commonStyles = useCommonStyles(customTheme);

  const redact = props.user.role === Role.ReadOnlyPIRedacted;

  const isReadOnly =
    props.user.role === Role.ReadOnly ||
    props.user.role === Role.ReadOnlyPIRedacted;

  const birthDateFormatted = props.patient.dateOfBirth
    ? moment(props.patient.dateOfBirth).format("DD-MMM-YYYY")
    : "Not Provided";
  const genderIndex =
    props.patient.gender !== undefined ? props.patient.gender : -1;

  const [loading, setLoading] = useState(false);

  const getPreference = useCallback(() => {
    return props.patient.preferredContact === PreferredContact.Email
      ? [PreferredContact.Email]
      : props.patient.preferredContact === PreferredContact.Mobile
      ? [PreferredContact.Mobile]
      : [PreferredContact.Email, PreferredContact.Mobile];
  }, [props.patient.preferredContact]);

  const [messagingPreferences, setMessagingPreferences] = useState<
    PreferredContact[]
  >(getPreference());

  const handlePreferenceChange = (
    event: React.MouseEvent<HTMLElement>,
    newMessagingPreferences: PreferredContact[]
  ) => {
    if (newMessagingPreferences === messagingPreferences) {
      return;
    }

    if (newMessagingPreferences.length) {
      const patientPreference =
        newMessagingPreferences.length === 2
          ? PreferredContact.Both
          : newMessagingPreferences[0];

      setLoading(true);
      new PatientClient(Configuration.SERVER_ROOT, FetchOverride)
        .updateMessagingPreferences(props.patient.id, patientPreference)
        .then(() => {
          props.reloadPatient();
          setMessagingPreferences(getPreference());
          setLoading(false);
        })
        .catch((errorType) => {
          setPrefError(errorType);
        });
    }
  };

  const patientUsername =
    props.patient.firstName + " " + props.patient.lastName;

  useEffect(() => {
    setMessagingPreferences(getPreference());
    setPrefError(
      isSelectedValid(
        props.patient.mobileNumber,
        props.patient.contactEmail,
        props.patient.preferredContact
      )
        ? undefined
        : ErrorType.NullContact
    );
  }, [props.user, props.patient, getPreference]);

  const withCustomId = [
    {
      name: "First Name",
      value: props.patient.firstName
        ? props.patient.firstName
        : redact
        ? "Redacted"
        : "Not Provided",
    },
    {
      name: "Last Name",
      value: props.patient.lastName
        ? props.patient.lastName
        : redact
        ? "Redacted"
        : "Not Provided",
    },
    {
      name: "True Colours ID",
      value: redact ? "Redacted" : props.patient.id,
    },
    {
      name: "NHS Number",
      value: props.patient.nhsNumber
        ? props.patient.nhsNumber
        : redact
        ? "Redacted"
        : "Not Provided",
    },
    { name: " Date of Birth", value: birthDateFormatted },
    {
      name: "Gender",
      value: genderIndex !== -1 ? Gender[genderIndex] : "Not Provided",
    },
    {
      name: props.customPatientIdDisplayName,
      value: props.patient.customPatientId,
    },
  ] as {
    name: string;
    value: string;
  }[];

  const withoutCustomId = [
    {
      name: "First Name",
      value: props.patient.firstName
        ? props.patient.firstName
        : redact
        ? "Redacted"
        : "Not Provided",
    },
    {
      name: "Last Name",
      value: props.patient.lastName
        ? props.patient.lastName
        : redact
        ? "Redacted"
        : "Not Provided",
    },
    {
      name: "True Colours ID",
      value: redact ? "Redacted" : props.patient.id,
    },
    {
      name: "NHS Number",
      value: props.patient.nhsNumber
        ? props.patient.nhsNumber
        : redact
        ? "Redacted"
        : "Not Provided",
    },
    { name: " Date of Birth", value: birthDateFormatted },
    {
      name: "Gender",
      value: genderIndex !== -1 ? Gender[genderIndex] : "Not Provided",
    },
  ] as {
    name: string;
    value: string;
  }[];

  return (
    <Grid container spacing={2}>
      {!props.integration && !isReadOnly && (
        <Grid xs={12}>
          <CreateEditPatient
            patient={props.patient}
            onSave={() => {
              props.reloadPatient();
              setMessagingPreferences(getPreference());
            }}
            user={props.user}
            dashboardSupported={props.dashboardSupported}
            nhsNumberRequired={props.nhsNumberRequired}
            customPatientId={props.customPatientId}
            customPatientIdDisplayName={props.customPatientIdDisplayName}
            customPatientIdRegex={props.customPatientIdRegex}
            customPatientIdMandatory={props.customPatientIdMandatory}
          />
        </Grid>
      )}
      <Grid xs={12} md={6}>
        <Card className={commonStyles.card} elevation={0}>
          <CardContent
            className={commonStyles.cardContent}
            sx={{ ":last-child": { paddingBottom: 1 } }}
          >
            {!redact && (
              <Container className={commonStyles.avatarContainer}>
                <AvatarLogo
                  username={patientUsername}
                  backgroundColour={theme.palette.secondary.main}
                  colour="white"
                  size={56}
                />
              </Container>
            )}
            {(props.customPatientId ? withCustomId : withoutCustomId).map(
              (x, key) => (
                <Container className={commonStyles.textContainer} key={key}>
                  <Typography className={commonStyles.textField}>
                    {x.name}:
                  </Typography>
                  <Typography className={commonStyles.textValue}>
                    {x.value}
                  </Typography>
                </Container>
              )
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} md={6}>
        <Card className={commonStyles.card} elevation={0}>
          <CardContent
            className={commonStyles.cardContent}
            sx={{ ":last-child": { paddingBottom: 1 } }}
          >
            <Typography className={commonStyles.contactDetails}>
              Contact details
            </Typography>
            {(
              [
                {
                  name: "Mobile Number",
                  value: props.patient.mobileNumber
                    ? props.patient.mobileNumber
                    : redact
                    ? "Redacted"
                    : "Not Provided",
                },
                {
                  name: "Email",
                  value: props.patient.contactEmail
                    ? props.patient.contactEmail
                    : redact
                    ? "Redacted"
                    : "Not Provided",
                },
              ] as {
                name: string;
                value: string;
              }[]
            ).map((x, key) => (
              <Container
                className={commonStyles.textContainer2}
                sx={{ paddingBottom: redact ? "3px" : null }}
                key={key}
              >
                <Typography className={commonStyles.textField2}>
                  {x.name}:
                </Typography>
                <Typography className={commonStyles.textValue2}>
                  {x.value}
                </Typography>
              </Container>
            ))}
          </CardContent>
          {!redact && (
            <>
              <Typography className={commonStyles.cardTitle}>
                Contact preference
              </Typography>
              <ContactPreferences
                messagingPreferences={messagingPreferences}
                onChange={handlePreferenceChange}
                fromPatientForm={false}
                hasMobile={!isNullOrEmptyString(props.patient.mobileNumber)}
                hasEmail={!isNullOrEmptyString(props.patient.contactEmail)}
                loading={loading}
                disable={isReadOnly}
              />
              {prefError != null && (
                <Typography className={classes.error}>
                  {generateErrorText(prefError)}
                </Typography>
              )}
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};
export default ViewPatientDetails;
