import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

interface Props {
  open: boolean;
  handleContinue: () => void;
  handleStay: () => void;
}

function QuestionnaireScoringWarningModal(props: Props) {
  return (
    <Dialog
      open={props.open}
      aria-labelledby="scoring-warning-dialog-title"
      aria-describedby="scoring-warning-dialog-description"
      key={"socring-warning-dialog"}
    >
      <DialogTitle
        id="scoring-warning-dialog-title"
        key={"socring-warning-dialog-title"}
      >
        {"Questionnaire Scoring"}
      </DialogTitle>
      <DialogContent key={"socring-warning-dialog-content"}>
        <DialogContentText
          id="scoring-warning-dialog-description"
          key={"socring-warning-dialog-content-text"}
        >
          Questionnaire Scoring should be done at the end of the questionnaire
          building process so that subsequent edits don't interfere with the
          assigned logic. Do you want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions key={"socring-warning-dialog-actions"}>
        <Button
          onClick={() => props.handleStay()}
          variant="outlined"
          key={"socring-warning-no-button"}
        >
          No
        </Button>
        <Button
          onClick={() => props.handleContinue()}
          variant="contained"
          key={"socring-warning-dialog-yes-button"}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QuestionnaireScoringWarningModal;
