import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  QuestionnaireBuilderDataModel,
  ScoringDataModel,
} from "./QuestionnaireBuilderDataModelHelper";
import AdditionalPlotsSelector from "./AdditionalPlotsSelector";
import { AdditionalPlots } from "./AdditionalPlotsBuilder";
import { useEffect, useState } from "react";
import {
  AdditionalPlotViewModel,
  QuestionnaireViewModel,
} from "../../../../types/auto/types";

interface Props {
  open: boolean;
  questionnaire: QuestionnaireBuilderDataModel;
  allQuestionnaires: QuestionnaireViewModel[];
  category: ScoringDataModel;
  additionalPlots: AdditionalPlots;
  handleClose: () => void;
  handleSubmit: (additionalPlots: AdditionalPlots | null) => void;
}

const AdditionalPlotsEditModal = (props: Props): JSX.Element => {
  const [additionalPlotsWIP, setAdditionalPlotsWIP] = useState<AdditionalPlots>(
    props.additionalPlots
  );
  const [singleAdditionalPlots, setSingleAdditionalPlots] =
    useState<AdditionalPlots>(undefined);
  const [noQuestionnaireError, setNoQuestionnaireError] =
    useState<boolean>(true);
  const [noCategoryError, setNoCategoryError] = useState<boolean>(true);

  function handleLocalChange(e: any, isQ: boolean) {
    let newAp: AdditionalPlotViewModel = new AdditionalPlotViewModel();
    if (isQ) {
      if (e.target.value === "") {
        newAp.questionnaireId = -1;
      } else {
        newAp.questionnaireId = e.target.value;
      }
      setSingleAdditionalPlots(newAp);
      setNoCategoryError(true);
      if (e.target.value === -1 || e.target.value === "") {
        setNoQuestionnaireError(true);
      } else {
        setNoQuestionnaireError(false);
      }
    } else {
      if (singleAdditionalPlots !== undefined) {
        newAp.questionnaireId = singleAdditionalPlots.questionnaireId;
        const { subscaleIndex, questionnaireVersion } = JSON.parse(
          e.target.value
        );
        newAp.questionnaireVersion = questionnaireVersion;
        newAp.subscaleIndex = subscaleIndex;
        setSingleAdditionalPlots(newAp);
        if (e.target.value.length === 0) {
          setNoCategoryError(true);
        } else {
          setNoCategoryError(false);
        }
      }
    }
  }

  function handleChange() {
    props.handleSubmit(singleAdditionalPlots);
    setNoQuestionnaireError(true);
    setNoCategoryError(true);
  }

  function handleDelete() {
    var updatedDep: AdditionalPlots;
    props.handleSubmit(updatedDep);
  }

  useEffect(() => {
    setAdditionalPlotsWIP(props.additionalPlots);
  }, [props.additionalPlots]);

  return (
    <Dialog open={props.open} maxWidth="lg" fullWidth>
      <DialogTitle>
        {"Category " + props.category.name + " Additional Plots"}
      </DialogTitle>
      <DialogContent>
        <Box>
          <AdditionalPlotsSelector
            questionnaire={props.questionnaire}
            additionalPlots={additionalPlotsWIP}
            singleAdditionalPlots={singleAdditionalPlots}
            allQuestionnaires={props.allQuestionnaires}
            setSingleAdditionalPlots={(dO) => setSingleAdditionalPlots(dO)}
            handleChange={() => handleChange()}
            handleLocalChange={(e, isQ) => handleLocalChange(e, isQ)}
            handleDelete={() => handleDelete()}
            handleClose={() => props.handleClose()}
            noQuestionnaireError={noQuestionnaireError}
            noCategoryError={noCategoryError}
            resetErrors={(to) => {
              setNoQuestionnaireError(to);
              setNoCategoryError(to);
            }}
          ></AdditionalPlotsSelector>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AdditionalPlotsEditModal;
