import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const ContextualTimeout = (): JSX.Element => {
  const useStyle = makeStyles(() => ({
    contextualContainer: {
      marginTop: "40%",
      textAlign: "center",
      width: "100%",
    },
  }));
  const classes = useStyle();

  return (
    <Container className={classes.contextualContainer} disableGutters>
      <Typography variant="h4" component="h2">
        Session Timeout
      </Typography>
      <Typography variant="h6" component="h2">
        Please exit and re-enter the patient record on Rio to restart the
        session
      </Typography>
    </Container>
  );
};

export default ContextualTimeout;
