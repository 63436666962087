import {
  AnswerViewModel,
  QuestionType,
  QuestionViewModel,
  QuestionnaireResponseViewModel,
  TableQuestionType,
} from "../../../../types/auto/types";
import { QuestionBoxProps } from "./QuestionBox";

export const handleChange = (e: any, props: QuestionBoxProps) => {
  var value = e.target.value;
  var key = props.responses.findIndex(
    (r) => r.number === props.question.number
  );
  if (key < 0) {
    props.responses.push(
      new QuestionnaireResponseViewModel({
        number: props.question.number,
        answer: value,
        isDefaultValue: false,
      })
    );
    props.setResponses(props.responses);
  } else {
    if (props.responses[key].answer === value) {
      props.responses.splice(key, 1);
    } else {
      props.responses[key].answer = value;
    }
    props.setResponses(props.responses);
  }
  props.setRerender();
  if (props.sectionCompleteCheck) {
    props.sectionCompleteCheck();
  }
  if (props.checkAccessibleSections) {
    props.checkAccessibleSections();
  }
};

export const handleTextboxChange = (value: string, props: QuestionBoxProps) => {
  var key = props.responses.findIndex(
    (r) => r.number === props.question.number
  );
  if (key < 0) {
    props.responses.push(
      new QuestionnaireResponseViewModel({
        number: props.question.number,
        answer: value,
        isDefaultValue: false,
      })
    );
    props.setResponses(props.responses);
  } else {
    value.length > 0
      ? (props.responses[key].answer = value)
      : props.responses.splice(key, 1);
    props.setResponses(props.responses);
  }
  props.setRerender();
  if (props.sectionCompleteCheck) {
    props.sectionCompleteCheck();
  }
  if (props.checkAccessibleSections) {
    props.checkAccessibleSections();
  }
};

export const handleCheckBoxChange = (
  e: any,
  props: QuestionBoxProps,
  answer: AnswerViewModel
) => {
  var value = answer.number != null ? answer.number.toString() : "-1";
  var key = props.responses.findIndex(
    (r) => r.number === props.question.number
  );
  const checkedCount = props.question.answers?.filter(
    (answer) => answer.checked
  ).length;
  if (checkedCount === props.question.maxAnswers) {
    //if max number is checked you can only uncheck
    if (answer.checked === true) {
      answer.checked = !answer.checked;
    }
  } else {
    if (answer.checked) {
      answer.checked = !answer.checked;
    } else {
      answer.checked = true;
    }
  }
  if (key < 0) {
    props.responses.push(
      new QuestionnaireResponseViewModel({
        number: props.question.number,
        answer: value,
        isDefaultValue: false,
      })
    );
    props.setResponses(props.responses);
  } else {
    var valueArray = props.responses[key].answer?.split(",");
    var index = valueArray?.indexOf(value);
    if (index! >= 0) {
      //if the value check exisits in the array delete it
      valueArray?.splice(Number(index), 1);
    } else if (valueArray?.length !== props.question.maxAnswers) {
      //if the value array is not full push it
      valueArray?.push(value);
    } else if (props.question.maxAnswers === 1) {
      //if the array is full and the max answers is one
      valueArray = [];
      valueArray?.push(value);
    }
    if (valueArray?.toString() === "") {
      props.responses.splice(key, 1);
    } else {
      props.responses[key].answer = valueArray?.toString();
    }
    props.setResponses(props.responses);
  }
  props.setRerender();
  if (props.sectionCompleteCheck) {
    props.sectionCompleteCheck();
  }
  if (props.checkAccessibleSections) {
    props.checkAccessibleSections();
  }
};

export const handleCheckBoxSingleChange = (e: any, props: QuestionBoxProps) => {
  var key = props.responses.findIndex(
    (r) => r.number === props.question.number
  );
  if (key < 0) {
    props.responses.push(
      new QuestionnaireResponseViewModel({
        number: props.question.number,
        answer: e.target.checked ? "true" : "false",
        isDefaultValue: false,
      })
    );
  } else {
    props.responses[key].answer = e.target.checked ? "true" : "false";
  }
  props.setResponses(props.responses);
  props.setRerender();
  if (props.sectionCompleteCheck) {
    props.sectionCompleteCheck();
  }
  if (props.checkAccessibleSections) {
    props.checkAccessibleSections();
  }
};

export const handleMultiChange = (e: any, props: QuestionBoxProps) => {
  var value = e.target.value;
  var key = props.responses.findIndex(
    (r) => r.number === props.question.number
  );
  if (key < 0) {
    props.responses.push(
      new QuestionnaireResponseViewModel({
        number: props.question.number,
        answer: value,
        isDefaultValue: false,
      })
    );
    props.setResponses(props.responses);
  } else {
    var valueArray = props.responses[key].answer?.split(",");
    var index = valueArray?.indexOf(value);
    if (index! >= 0) {
      valueArray?.splice(Number(index), 1);
    } else if (valueArray?.length !== props.question.maxAnswers) {
      valueArray?.push(value);
    }
    if (valueArray?.toString() === "") {
      props.responses.splice(key, 1);
    } else {
      props.responses[key].answer = valueArray?.toString();
    }
    props.setResponses(props.responses);
  }
  props.setRerender();
  if (props.sectionCompleteCheck) {
    props.sectionCompleteCheck();
  }
  if (props.checkAccessibleSections) {
    props.checkAccessibleSections();
  }
};

export const handleSliderChange = (
  props: QuestionBoxProps,
  value: number,
  readOnly: boolean
) => {
  if (readOnly) {
    return;
  }
  props.setResponses((responses) => {
    var key = responses.findIndex((r) => r.number === props.question.number);
    if (key < 0) {
      responses.push(
        new QuestionnaireResponseViewModel({
          number: props.question.number,
          answer: value.toString(),
          isDefaultValue: false,
        })
      );
    } else {
      if (!(responses[key].answer === value.toString())) {
        responses[key].answer = value.toString();
      }
    }
    return responses;
  });
  props.setRerender();
  if (props.sectionCompleteCheck) {
    props.sectionCompleteCheck();
  }
  if (props.checkAccessibleSections) {
    props.checkAccessibleSections();
  }
};

export const handleNumberChange = (
  props: QuestionBoxProps,
  value: number,
  answer: AnswerViewModel
) => {
  if (Number.isNaN(value)) {
    return;
  }

  if (answer.rangeMin && answer.rangeMin > value) {
    value = answer.rangeMin;
  }
  if (answer.rangeMax && answer.rangeMax < value) {
    value = answer.rangeMax;
  }

  var key = props.responses.findIndex(
    (r) => r.number === props.question.number
  );
  if (key < 0) {
    props.responses.push(
      new QuestionnaireResponseViewModel({
        number: props.question.number,
        answer: value.toString(),
        isDefaultValue: false,
      })
    );
    props.setResponses(props.responses);
  } else {
    if (props.responses[key].answer === value.toString()) {
      props.responses.splice(key, 1);
    } else {
      props.responses[key].answer = value.toString();
    }
    props.setResponses(props.responses);
  }
  props.setRerender();
  if (props.sectionCompleteCheck) {
    props.sectionCompleteCheck();
  }
  if (props.checkAccessibleSections) {
    props.checkAccessibleSections();
  }
};

export const handleDateChange = (
  value: Date | null,
  props: QuestionBoxProps,
  disableFuture: boolean
) => {
  var key = props.responses.findIndex(
    (r) => r.number === props.question.number
  );
  if (value != null) {
    value = new Date(value);
    if (!isNaN(value.getDate())) {
      if (
        (disableFuture && new Date() < value) ||
        (props.question.questionType === QuestionType.DateRange &&
          (value < props.question.minimumDate! ||
            value > props.question.maximumDate!))
      ) {
        if (key !== -1) {
          props.responses.splice(key, 1);
          props.setResponses(props.responses);
        }
      } else if (key < 0) {
        props.responses.push(
          new QuestionnaireResponseViewModel({
            number: props.question.number,
            answer: value.toLocaleDateString("en-GB", {
              day: "numeric",
              month: "long",
              year: "numeric",
            }),
            isDefaultValue: false,
          })
        );
        props.setResponses(props.responses);
      } else {
        props.responses[key].answer = value.toLocaleDateString("en-GB", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
        props.setResponses(props.responses);
      }
    } else {
      if (key !== -1) {
        props.responses.splice(key, 1);
        props.setResponses(props.responses);
      }
    }
    props.setRerender();
    if (props.sectionCompleteCheck) {
      props.sectionCompleteCheck();
    }
    if (props.checkAccessibleSections) {
      props.checkAccessibleSections();
    }
  } else {
    if (key !== -1) {
      props.responses.splice(key, 1);
      props.setResponses(props.responses);
      props.setRerender();
      if (props.sectionCompleteCheck) {
        props.sectionCompleteCheck();
      }
      if (props.checkAccessibleSections) {
        props.checkAccessibleSections();
      }
    }
  }
};

export const handleSketchChange = (
  value: string | null,
  props: QuestionBoxProps
) => {
  if (value != null) {
    var key = props.responses.findIndex(
      (r) => r.number === props.question.number
    );
    if (key < 0) {
      props.responses.push(
        new QuestionnaireResponseViewModel({
          number: props.question.number,
          answer: value,
          isDefaultValue: false,
        })
      );
      props.setResponses(props.responses);
    } else {
      props.responses[key].answer = value;
      props.setResponses(props.responses);
    }
  }
};

export const handleTableChange = (
  row: number,
  totalRows: number,
  question: string,
  e: any,
  props: QuestionBoxProps,
  type?: TableQuestionType
) => {
  var value;
  if (type === TableQuestionType.Date) {
    value = new Date(e).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    if (isNaN(new Date(value).getDate())) {
      value = "";
    }
  } else if (type === TableQuestionType.Checkbox) {
    value = e.target.checked;
  } else {
    value = e.target.value;
    // stripped for json convert safety
    value = value.replaceAll(`"`, "");
  }

  var key = props.responses.findIndex(
    (r) => r.number === props.question.number
  );
  type TableData = { [key: string]: string | number | boolean };
  if (key < 0) {
    var tableData: TableData[] = [];

    for (var index = 0; index < totalRows; index++) {
      var tableRow: TableData = { row: row };
      tableRow["row"] = index;
      for (
        var answerIndex = 0;
        answerIndex < (props.question.answers?.length ?? 0);
        answerIndex++
      ) {
        var tableType = props.question.answers?.at(answerIndex)?.tableType;
        switch (tableType) {
          case TableQuestionType.Dropdown:
            tableRow[props.question.answers?.at(answerIndex)!.text ?? ""] =
              "None";
            break;
          case TableQuestionType.Number:
            tableRow[props.question.answers?.at(answerIndex)!.text ?? ""] = 0;
            break;
          case TableQuestionType.Textbox:
            tableRow[props.question.answers?.at(answerIndex)!.text ?? ""] = "";
            break;
          case TableQuestionType.Checkbox:
            tableRow[props.question.answers?.at(answerIndex)!.text ?? ""] =
              false;
            break;
          case TableQuestionType.Date:
            tableRow[props.question.answers?.at(answerIndex)!.text ?? ""] = "";
            break;
        }
      }

      tableData.push(tableRow);
    }

    tableData[tableData.findIndex((x) => x.row === row)][question] = value;

    var tableValueString = JSON.stringify(tableData);
    props.responses.push(
      new QuestionnaireResponseViewModel({
        number: props.question.number,
        answer: tableValueString,
        isDefaultValue: false,
      })
    );
    props.setResponses(props.responses);
  } else {
    var tableDataUpdate: TableData[] = JSON.parse(
      props.responses[key].answer ?? ""
    );

    if (type === TableQuestionType.Checkbox) {
      var data =
        tableDataUpdate[tableDataUpdate.findIndex((x) => x.row === row)];
      var dataIndex = 0;
      for (const [key] of Object.entries(data)) {
        if (key === "row") {
          continue;
        }
        dataIndex += 1;
        if (dataIndex === 1) {
          continue;
        }
        data[key] = false;
      }
    }

    tableDataUpdate[tableDataUpdate.findIndex((x) => x.row === row)][question] =
      value;
    props.responses[key].answer = JSON.stringify(tableDataUpdate);
    props.setResponses(props.responses);
  }
  props.setRerender();
  if (props.sectionCompleteCheck) {
    props.sectionCompleteCheck();
  }
  if (props.checkAccessibleSections) {
    props.checkAccessibleSections();
  }
};

export const getValue = (
  questionNumber: Number,
  responses: Array<QuestionnaireResponseViewModel>
) => {
  var response = responses.find((x) => x.number === questionNumber);
  return response?.answer;
};

export const getValueResponse = (
  questionNumber: Number,
  responses: Array<QuestionnaireResponseViewModel>
) => {
  var response = responses.find((x) => x.number === questionNumber);
  return response;
};

export const getTableResponse = (
  response: QuestionnaireResponseViewModel,
  row: number,
  question: string
) => {
  type TableData = { [key: string]: string | number | boolean };
  if (response?.answer !== undefined) {
    var tableDataUpdate: TableData[] = JSON.parse(response.answer ?? "");
    return tableDataUpdate[tableDataUpdate.findIndex((x) => x.row === row)][
      question
    ];
  }
  return undefined;
};

export const isDependsValid = (
  question: QuestionViewModel,
  responses: QuestionnaireResponseViewModel[],
  questions: QuestionViewModel[],
  readOnly: boolean
) => {
  if (readOnly && responses.length === 0) {
    return true;
  }

  if (question.dependsOn !== undefined) {
    return isDependsValidInternal(question.dependsOn, responses, questions);
  }

  return true;
};

export const getQuestionTypeInternal = (
  questions: QuestionViewModel[] = [],
  questionNumber: number
) => {
  let foundQuestion = questions.find((x) => x.number === questionNumber);
  if (foundQuestion) {
    return foundQuestion.questionType;
  }

  return -1;
};

type DependsType = {
  [key: string]: number[];
};

export const isDependsValidInternal = (
  dependsOn: DependsType,
  responses: QuestionnaireResponseViewModel[],
  questions: QuestionViewModel[]
) => {
  var satisfied: boolean;

  // Check that each answer condition is satisfied by the actual response
  for (let k in dependsOn) {
    satisfied = false;
    var response = getValue(Number(k), responses);

    if (
      response === undefined &&
      getQuestionTypeInternal(questions, Number(k)) !== QuestionType.Textbox
    ) {
      return false;
    }

    var splitResponse = response?.split(",") || [""];

    if (
      getQuestionTypeInternal(questions, Number(k)) === QuestionType.DropDown
    ) {
      splitResponse = [response ?? ""];
    }

    for (let i = 0; i < splitResponse.length; i++) {
      if (!satisfied) {
        satisfied = dependsOn[k].includes(Number(splitResponse[i]));
      }
    }

    if (
      getQuestionTypeInternal(questions, Number(k)) === QuestionType.DropDown
    ) {
      const foundQuestion = questions.find(
        (question) => question.number === Number(k)
      );
      if (
        foundQuestion &&
        foundQuestion.answers &&
        foundQuestion.answers.length > 0
      ) {
        let splitResponseCopy = splitResponse.slice();

        satisfied = foundQuestion.answers.some(
          (answer) =>
            splitResponseCopy.includes(answer.text || "") &&
            dependsOn[k].includes(answer.number || 0)
        );
      }
    }

    if (
      getQuestionTypeInternal(questions, Number(k)) ===
      QuestionType.CheckBoxSingle
    ) {
      //if set to 0, display if unticked
      if (dependsOn[k].find((x) => x.valueOf) === 0) {
        if (responses.find((x) => x.number === Number(k))?.answer === "true") {
          satisfied = false;
        } else {
          satisfied = true;
        }
        //if set to 1, display if ticked
      } else if (dependsOn[k].find((x) => x.valueOf) === 1) {
        if (responses.find((x) => x.number === Number(k))?.answer === "false") {
          satisfied = false;
        } else {
          satisfied = true;
        }
      }
    }

    if (
      getQuestionTypeInternal(questions, Number(k)) === QuestionType.Textbox
    ) {
      // If dependsOn answer is 0, satisfied is true if response is empty
      if (dependsOn[k].find((x) => x.valueOf) === 0) {
        if (responses.find((x) => x.number === Number(k))?.answer == null) {
          satisfied = true;
        } else {
          satisfied =
            responses.find((x) => x.number === Number(k))?.answer?.length ===
              0 || responses.find((x) => x.number === Number(k))?.answer === "";
        }
        //If dependsOn answer is 1, satisfied is true if response is not empty
      } else if (dependsOn[k].find((x) => x.valueOf) === 1) {
        if (responses.find((x) => x.number === Number(k))?.answer == null) {
          satisfied = false;
        } else {
          satisfied =
            responses.find((x) => x.number === Number(k))?.answer?.length !==
              0 || responses.find((x) => x.number === Number(k))?.answer !== "";
        }
      }
    }
    if (!satisfied) {
      return false;
    }
  }

  return true;
};
