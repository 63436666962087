import { useUnmountPromise } from "react-use";
import { CaseloadClient, Role } from "../../../../types/auto/types";
import WarningDialog from "../../elements/dialog/WarningDialog";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";

interface Props {
  open: boolean;
  closeModal: () => void;
  refresh: () => void;
  id: number | undefined;
  caseloadId: number;
  role?: Role;
}

const RemoveUserFromCaseloadModal = (props: Props): JSX.Element => {
  const resolveWhileMounted = useUnmountPromise();

  return (
    <WarningDialog
      title={
        props.role === Role.Patient
          ? "Remove patient from caseload"
          : props.role === Role.Clinician
          ? "Remove clinician from caseload"
          : "Remove read-only user from caseload"
      }
      errorText={
        props.role === Role.Patient
          ? "Error removing patient caseload link."
          : "Error removing clinician caseload link."
      }
      body={`This will remove the user from the caseload. Are you sure this is intended?`}
      continueButtonText="Remove"
      open={props.open && props.id !== undefined}
      onCancel={() => props.closeModal()}
      onContinue={async () => {
        if (props.role === Role.Patient) {
          await resolveWhileMounted(
            new CaseloadClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).removePatientFromCaseload(props.caseloadId, props.id)
          ).then((success: boolean) => {
            if (success) {
              props.refresh();
            }
            props.closeModal();
          });
        } else if (
          props.role === Role.Clinician ||
          props.role === Role.ReadOnly
        ) {
          await resolveWhileMounted(
            new CaseloadClient(
              Configuration.SERVER_ROOT,
              FetchOverride
            ).removeClinicianFromCaseload(props.caseloadId, props.id)
          ).then((success: boolean) => {
            if (success) {
              props.refresh();
            }
            props.closeModal();
          });
        }
      }}
    />
  );
};

export default RemoveUserFromCaseloadModal;
