import { Fade, IconButton, SxProps, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  show: boolean;
  icon: React.ReactElement;
  label: string;
  tooltip?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
  fade?: boolean;
}

const AccordionIconButton = (props: Props) => {
  const useStyle = makeStyles(() => ({
    buttonIcon: {
      marginRight: 10,
    },
  }));
  const classes = useStyle();

  const content = (
    <IconButton
      color="primary"
      aria-label={props.label}
      className={classes.buttonIcon}
      onClick={props.onClick}
      style={props.style}
      sx={props.sx}
    >
      {props.icon}
    </IconButton>
  );

  return props.fade ? (
    <Tooltip title={props.tooltip}>
      <Fade in={props.show} timeout={500}>
        {content}
      </Fade>
    </Tooltip>
  ) : (
    content
  );
};

export default AccordionIconButton;
