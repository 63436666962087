import { Typography, Theme, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  GoalCategory,
  GoalClient,
  GoalOwner,
  GoalViewModel,
  MonitoringStatus,
  PatientViewModel,
  QuestionnaireClient,
  Role,
} from "../../../types/auto/types";
import { useEffect, useState } from "react";
import { Configuration } from "../../Constants";
import { FetchOverride } from "../../utils/Request";
import CreateEditGoalModal from "./CreateEditGoalModal";
import { theme } from "../../../Theme";
import PromptableCard from "../elements/promptable-card/PromptableCard";
import ActivePromptableActionButtons from "../schedule/ActivePromptableActionButtons";
import PromptWarningDialog, {
  PromptActionWarningState,
} from "../schedule/dialog/PromptWarningDialog";
import RateGoalModal from "./RateGoalModal";
import { orderBy } from "lodash";
import { useUnmountPromise } from "react-use";

interface Props {
  patient: PatientViewModel;
  role?: Role;
  goals: GoalViewModel[];
  refreshGoals: () => void;
  goalOwnerDescriptions: {
    [key in keyof typeof GoalOwner]?: string;
  };
  monitoringStatusDescriptions: {
    [key in keyof typeof MonitoringStatus]?: string;
  };
  questionnaireId?: number;
  goalCategory?: GoalCategory;
  caseloadId?: number;
  episodeId?: string;
  readOnly: boolean;
  questionnaireResponseId?: number;
}

const ActiveGoals = (props: Props): JSX.Element => {
  const [goalToModify, setGoalToModify] = useState<GoalViewModel>();
  const [createEditGoalOpen, setCreateEditGoalOpen] = useState(false);
  const [warningModalState, setWarningModalState] =
    useState<PromptActionWarningState>();
  const [rateGoalOpen, setRateGoalOpen] = useState(false);
  const [namesMap, setNamesMap] = useState<Map<number, string>>();
  const goalClient = new GoalClient(Configuration.SERVER_ROOT, FetchOverride);

  var isStaff =
    props.role === Role.Clinician ||
    props.role === Role.Admin ||
    props.role === Role.SuperAdmin;

  const useStyle = makeStyles((th: Theme) => ({
    button: {
      margin: 3,
      marginTop: 15,
    },
    continueButton: {
      backgroundColor: th.palette.secondary.main,
      color: "white",
      margin: 5,
      float: "right",
      "&:disabled": {
        backgroundColor: theme.palette.grey,
      },
      "&:hover": {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    cardContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: 0,
      padding: 0,
    },
  }));
  const classes = useStyle(theme);

  const resolveWhileMounted = useUnmountPromise();

  const questionnaireClient = new QuestionnaireClient(
    Configuration.SERVER_ROOT,
    FetchOverride
  );

  useEffect(
    () => {
      const effect = async () => {
        if (namesMap === undefined) {
          var goalNameMap = new Map();
          props.goals.forEach((g) => {
            if (g.questionnaireId) {
              if (!goalNameMap.has(g.questionnaireId)) {
                goalNameMap.set(g.questionnaireId, "");
              }
            }
          });
          if (
            props.questionnaireId != null &&
            !goalNameMap.has(props.questionnaireId)
          ) {
            goalNameMap.set(props.questionnaireId, "");
          }
          for (const elements of goalNameMap) {
            await resolveWhileMounted(
              questionnaireClient.getLatestQuestionnaireVersion(
                elements[0],
                true
              )
            ).then((questionnaire) => {
              goalNameMap.set(questionnaire.id, questionnaire.name);
            });
          }
          setNamesMap(goalNameMap);
        }
      };
      effect();
    },
    // eslint-disable-next-line
    [props.questionnaireId, props.goals]
  );

  return (
    <>
      <PromptWarningDialog
        open={warningModalState !== undefined}
        onClose={() => setWarningModalState(undefined)}
        patient={props.patient}
        state={warningModalState}
        refreshList={props.refreshGoals}
        name={goalToModify?.question || "goal"}
        promptName="goal"
        onResumeSingle={() => goalClient.resumeGoal(goalToModify?.id)}
        onResumeAll={() => goalClient.resumeAllGoalsForUser(props.patient?.id)}
        onSuspendSingle={() => goalClient.suspendGoal(goalToModify?.id)}
        onSuspendAll={() =>
          goalClient.suspendAllGoalsForUser(props.patient?.id)
        }
        onArchiveSingle={() => goalClient.archiveGoal(goalToModify?.id)}
        onArchiveAll={() =>
          goalClient.archiveAllGoalsForUser(props.patient?.id)
        }
        onCompleteSingle={() => goalClient.completeGoal(goalToModify?.id)}
        onCompleteAll={() =>
          isStaff
            ? goalClient.completeAllGoalsForUser(props.patient?.id)
            : goalClient.completeAllGoalsForCurrentUser()
        }
      />
      <CreateEditGoalModal
        patient={props.patient}
        goal={goalToModify}
        open={createEditGoalOpen}
        role={props.role}
        closeModal={() => {
          setCreateEditGoalOpen(false);
          setGoalToModify(undefined);
          props.refreshGoals();
        }}
        questionnaireId={props.questionnaireId}
        goalCategory={props.goalCategory}
        caseloadId={props.caseloadId}
        episodeId={props.episodeId}
        questionnaireResponseId={props.questionnaireResponseId}
      />
      <RateGoalModal
        patient={props.patient}
        goalQuestion={goalToModify?.question ?? ""}
        goalId={goalToModify?.id ?? 0}
        open={rateGoalOpen}
        closeModal={() => {
          setRateGoalOpen(false);
          setGoalToModify(undefined);
          props.refreshGoals();
        }}
      />
      {props.questionnaireId == null && (
        <Typography variant="h5" component="h2">
          Active Goals
        </Typography>
      )}
      {!props.readOnly && (
        <ActivePromptableActionButtons
          createTitle="Create Goal"
          onCreateClick={() => {
            setGoalToModify(undefined);
            setCreateEditGoalOpen(true);
          }}
          showSuspendAll={
            isStaff &&
            props.goals.length > 0 &&
            props.goals.some(
              (x) => x.monitoringStatus !== MonitoringStatus.Suspended
            ) &&
            props.questionnaireId == null
          }
          onSuspendClick={() =>
            setWarningModalState(PromptActionWarningState.SuspendAll)
          }
          showResumeAll={
            isStaff &&
            props.goals.length > 0 &&
            props.goals.some(
              (x) => x.monitoringStatus === MonitoringStatus.Suspended
            ) &&
            props.questionnaireId == null
          }
          onResumeClick={() =>
            setWarningModalState(PromptActionWarningState.ResumeAll)
          }
          showArchiveAll={
            isStaff && props.goals.length > 0 && props.questionnaireId == null
          }
          onArchiveClick={() =>
            setWarningModalState(PromptActionWarningState.ArchiveAll)
          }
          showCompleteAll={
            props.goals.length > 0 && props.questionnaireId == null
          }
          onCompleteClick={() =>
            setWarningModalState(PromptActionWarningState.CompleteAll)
          }
        />
      )}
      {props.goals.length === 0 ? (
        <Typography>
          There are no active goals. Click the above button to add a new goal.
        </Typography>
      ) : (
        <Container className={classes.cardContainer}>
          {orderBy(props.goals, "created", "desc").map((x) => (
            <PromptableCard
              key={"activeGoals" + x.id}
              title={x.question || ""}
              monitoringStatus={x.monitoringStatus}
              createdDate={x.created}
              lastResponse={x.lastResponse}
              prompt={x.promptSchedule}
              showPrompt
              onEdit={() => {
                setGoalToModify(x);
                setCreateEditGoalOpen(true);
              }}
              onRate={() => {
                setGoalToModify(x);
                setRateGoalOpen(true);
              }}
              onSuspendResume={(suspend) => {
                setGoalToModify(x);
                setWarningModalState(
                  suspend
                    ? PromptActionWarningState.SuspendSingle
                    : PromptActionWarningState.ResumeSingle
                );
              }}
              onComplete={() => {
                setGoalToModify(x);
                setWarningModalState(PromptActionWarningState.CompleteSingle);
              }}
              onArchived={() => {
                setGoalToModify(x);
                setWarningModalState(PromptActionWarningState.ArchiveSingle);
              }}
              lastResponseValue={x.lastResponseValue}
              owner={x.owner}
              role={props.role}
              goalOwnerDescriptions={props.goalOwnerDescriptions}
              monitoringStatusDescriptions={props.monitoringStatusDescriptions}
              goalCategory={x.goalCategory}
              goalQuestionnaireName={
                x.questionnaireId != null
                  ? namesMap?.get(x.questionnaireId)
                  : undefined
              }
              readOnly={props.readOnly}
            />
          ))}
        </Container>
      )}
    </>
  );
};
export default ActiveGoals;
