import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { SectionObjectType } from "./SectionBuilderQuestionAnswerHelper";

interface Props {
  open: boolean;
  handleCancel: () => void;
  handleDelete: (objType: SectionObjectType) => void;
  objectType: SectionObjectType;
}

const DeleteWarningModal = (props: Props): JSX.Element => {
  return (
    <Dialog
      open={props.open}
      aria-labelledby="delete-warning-dialog-title"
      aria-describedby="delete-warning-dialog-description"
    >
      <DialogTitle id="delete-warning-dialog-title">
        {"Delete " + SectionObjectType[props.objectType] + "?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-warning-dialog-description">
          {"Are you sure you want to delete this " +
            SectionObjectType[props.objectType] +
            "?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.handleCancel();
          }}
          variant="outlined"
          aria-label="Back button"
        >
          Back
        </Button>
        <Button
          onClick={() => props.handleDelete(props.objectType)}
          variant="contained"
          aria-label="Yes button"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteWarningModal;
