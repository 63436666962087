import { makeStyles } from "@mui/styles";
import { Error400 } from "./Error400";
import { Error401 } from "./Error401";
import { Error500 } from "./Error500";

interface Props {
  statusCode: number | undefined;
}

const HandleError = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    wide: {
      width: "100%",
    },
  }));

  const classes = useStyle();
  const errorType = () => {
    switch (props.statusCode) {
      case 400:
        return <Error400 />;
      case 401:
        return <Error401 />;
      default:
        return <Error500 />;
    }
  };

  return (
    <>
      {props.statusCode === undefined ? null : (
        <div className={classes.wide}>{errorType()}</div>
      )}
    </>
  );
};

export default HandleError;
