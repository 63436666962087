import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface DataModel {
  [key: string]: any;
}

interface Props {
  open: boolean;
  edit: boolean;
  formFields: JSX.Element[];
  dataModel: DataModel;
  setModalOpen: (o: boolean) => void;
  handleCancel: () => void;
  handleAddRow: () => void;
  handleEditAnswerString: () => void;
  objectName: string;
}

const MultiSelectorTableEditModal = (props: Props): JSX.Element => {
  return (
    <Dialog open={props.open}>
      <DialogTitle>
        {(props.edit ? "Edit " : "Add ") + props.objectName}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexWrap={"wrap"} gap={2} padding={2}>
          {props.formFields}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.handleCancel();
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (props.edit) {
              props.handleEditAnswerString();
              props.setModalOpen(false);
            } else {
              props.handleAddRow();
              props.setModalOpen(false);
            }
          }}
          variant="contained"
        >
          {(props.edit ? "Edit " : "Add ") + props.objectName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultiSelectorTableEditModal;
