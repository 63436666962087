import parse from "html-react-parser";
interface IProp {
  className?: string;
  footer: string;
}

const Footer = (props: IProp): JSX.Element => (
  <footer className={props.className}>{parse(props.footer)}</footer>
);

export default Footer;
