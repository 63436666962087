import Menu from "@mui/material/Menu";

interface Props {
  anchor: HTMLElement | null;
  onClose: () => void;
  navItems: JSX.Element[];
}

const PatientNavDrawer = (props: Props): JSX.Element => {
  return (
    <Menu
      open={props.anchor != null}
      onClose={props.onClose}
      anchorEl={props.anchor}
    >
      {props.navItems}
    </Menu>
  );
};

export default PatientNavDrawer;
