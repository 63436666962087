import { Alert } from "@mui/material";

interface Props {
  errorText: string;
  isInvalid: boolean;
}

export const FormErrorText = (props: Props): JSX.Element => {
  return (
    <Alert
      className="validationError"
      severity="error"
      hidden={!props.isInvalid}
    >
      {props.errorText}
    </Alert>
  );
};

export default FormErrorText;
