import React from "react";
import {
  PatientNoteClient,
  PatientNoteViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import WarningDialog from "../../elements/dialog/WarningDialog";
import { Error400 } from "../../elements/errors/Error400";

interface Props {
  patientNote: PatientNoteViewModel;
  openDeleteDialogue: boolean;
  setOpenDeleteDialogue: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: () => void;
}
export const DeletePatientNote = (props: Props): JSX.Element => {
  const [errorDeletingPatientNote, setErrorDeletingPatientNote] =
    React.useState(false);

  function onClose() {
    props.setOpenDeleteDialogue(false);
    setErrorDeletingPatientNote(false);
  }
  return (
    <>
      <WarningDialog
        errorText="Error deleting patient note"
        title="Delete patient note"
        body={`This will delete the selected patient note with content: ${
          props.patientNote ? props.patientNote.text : ""
        }. Are you sure this is intended?`}
        continueButtonText="Delete patient note"
        open={props.openDeleteDialogue}
        onCancel={() => onClose()}
        setOpen={() => props.setOpenDeleteDialogue}
        onContinue={() =>
          new PatientNoteClient(Configuration.SERVER_ROOT, FetchOverride)
            .deletePatientNote(props.patientNote)
            .then(() => {
              onClose();
              props.onDelete();
            })
            .catch(() => {
              setErrorDeletingPatientNote(true);
              props.setOpenDeleteDialogue(false);
            })
        }
      />
      {errorDeletingPatientNote && <Error400 />}
    </>
  );
};
