import { FormControl } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../../Theme";
import { Dispatch, SetStateAction, useEffect } from "react";
import MultiSelectChip from "../../elements/form-elements/MultiSelectChip";
import {
  CaseloadType,
  CaseloadViewModel,
  PatientViewModel,
  PromptScheduleViewModel,
  QuestionnaireViewModel,
  ScheduleType,
  ScheduleViewModel,
} from "../../../../types/auto/types";
import CaseloadDropdown from "../../elements/caseload-dropdown/CaseloadDropdown";
import { useMountedState } from "react-use";

interface Props {
  allCaseloads: CaseloadViewModel[];
  patient: PatientViewModel;
  caseloadId: number | undefined;
  itemIndex: number | undefined;
  caseloadError?: boolean;
  setCaseloadId: (
    caseloadId: number | undefined,
    episodeId: string | undefined,
    itemIndex: number | undefined
  ) => void;
  selectedQuestionnaires: QuestionnaireViewModel[];
  selectedQuestionnairesError?: boolean;
  setSelectedQuestionnaires: Dispatch<SetStateAction<QuestionnaireViewModel[]>>;
  allQuestionnaires: QuestionnaireViewModel[];
  caseloadType: CaseloadType;
  setPromptSchedule: React.Dispatch<
    React.SetStateAction<PromptScheduleViewModel | undefined>
  > | null;
  schedule: ScheduleViewModel | undefined;
}

const CaseloadQuestionnaireDropdowns = (props: Props) => {
  const authedQuestionnaireError =
    props.schedule != null &&
    props.schedule.scheduleType !== ScheduleType.Open &&
    props.schedule.scheduleType != null &&
    props.selectedQuestionnaires.filter((x) => x.authenticatedOnly).length > 0;
  const useStyle = makeStyles({
    formControl: { marginBottom: 20, width: "100%" },
    receivePrompts: { display: "flex" },
    multiSelect: { width: "100%" },
    hint: { marginTop: 10 },
  });
  const classes = useStyle(customTheme);

  const isMountedState = useMountedState();

  useEffect(() => {
    if (
      isMountedState() &&
      props.caseloadId === undefined &&
      props.selectedQuestionnaires.length > 0
    ) {
      props.setSelectedQuestionnaires([]);
    }
  }, [props, isMountedState]);

  useEffect(() => {
    if (
      isMountedState() &&
      props.caseloadId === undefined &&
      props.selectedQuestionnaires.length > 0
    ) {
      props.setSelectedQuestionnaires([]);
    }
  }, [props, isMountedState]);

  return (
    <>
      <CaseloadDropdown
        allCaseloads={props.allCaseloads}
        patient={props.patient}
        itemIndex={props.itemIndex}
        setCaseloadId={props.setCaseloadId}
        error={props.caseloadError}
      />
      <FormControl className={classes.formControl}>
        <div className={classes.receivePrompts}>
          <MultiSelectChip
            disabled={props.caseloadId === undefined}
            label="Questionnaires"
            options={props.allQuestionnaires}
            selectedOptions={props.selectedQuestionnaires}
            setSelectedOptions={props.setSelectedQuestionnaires}
            className={classes.multiSelect}
            error={
              authedQuestionnaireError || props.selectedQuestionnairesError
            }
            errorText={
              authedQuestionnaireError
                ? "Authenticated questionnaires can only be selected for open schedules"
                : "Please include at least one questionnaire"
            }
          />
        </div>
      </FormControl>
    </>
  );
};

export default CaseloadQuestionnaireDropdowns;
