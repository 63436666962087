import React from "react";
import {
  CardActions,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Email, PhoneAndroid } from "@mui/icons-material";
import { ErrorType, PreferredContact } from "../../../../types/auto/types";
import { theme } from "../../../../Theme";
import { makeStyles } from "@mui/styles";
import { isNullOrEmptyString } from "../../../HelperFunctions";

interface Props {
  messagingPreferences: PreferredContact[];
  onChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => void;
  fromPatientForm: boolean;
  hasMobile: boolean;
  hasEmail: boolean;
  loading: boolean;
  disable?: boolean;
}

const ContactPreferences = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    updateContactPref: {
      justifyContent: props.fromPatientForm ? undefined : "center",
      display: "flex",
      alignItems: "center",
      paddingLeft: props.fromPatientForm ? 0 : undefined,
    },
    contactPreference: {
      "&.Mui-selected": {
        color: "white",
        background: props.disable ? "grey" : th.palette.secondary.main,
        "&:hover": {
          backgroundColor: "deepskyblue",
        },
      },
    },
  }));
  const classes = useStyle(theme);

  return (
    <CardActions className={classes.updateContactPref}>
      <ToggleButtonGroup
        value={props.messagingPreferences}
        onChange={props.onChange}
        aria-label="messaging-preferences"
        disabled={props.disable}
      >
        <ToggleButton
          value={PreferredContact.Email}
          aria-label="email"
          className={classes.contactPreference}
          disabled={!props.hasEmail || props.loading}
        >
          <Email />
        </ToggleButton>
        <ToggleButton
          value={PreferredContact.Mobile}
          aria-label="mobile"
          className={classes.contactPreference}
          disabled={!props.hasMobile || props.loading}
        >
          <PhoneAndroid />
        </ToggleButton>
      </ToggleButtonGroup>
    </CardActions>
  );
};

export default ContactPreferences;

export function generateErrorText(errorType: ErrorType): string {
  if (errorType === ErrorType.NullContact) {
    return "No details provided for the chosen contact preference.";
  } else {
    return "System error please contact support team";
  }
}

export function isSelectedValid(
  mobileNumber: string | undefined,
  contactEmail: string | undefined,
  prefferedContact?: PreferredContact
): boolean {
  if (prefferedContact === PreferredContact.Email) {
    if (!isNullOrEmptyString(contactEmail)) {
      return true;
    } else {
      return false;
    }
  } else if (prefferedContact === PreferredContact.Mobile) {
    if (!isNullOrEmptyString(mobileNumber)) {
      return true;
    } else {
      return false;
    }
  } else {
    if (
      !isNullOrEmptyString(mobileNumber) &&
      !isNullOrEmptyString(contactEmail)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
