/* eslint-disable prettier/prettier */
import {
  Container,
  Box,
  Theme,
  Button,
  Alert,
  Collapse,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  CaseloadViewModel,
  CaseloadClient,
  Role,
  StaffClient,
  UserClient,
  TenantClient,
  SortDirectionType,
  SortType,
  TenantViewModel,
  TenantOptionsViewModel,
} from "../../types/auto/types";
import { theme } from "../../Theme";
import { Configuration, DefaultCustomIdDisplayName } from "../Constants";
import { FetchOverride } from "../utils/Request";
import UserSearchFilters from "../components/search/UserSearchFilters";
import {
  createSearchParams,
  To,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useMountedState, useUnmountPromise } from "react-use";
import { UserContext } from "../components/elements/stores/UserStore";
import {
  AdminsToAbstractUserCardType,
  StaffSortValues,
  CliniciansToAbstractUserCardType,
  PatientSortValues,
  PatientPIRedactedSortValues,
  PatientsToAbstractUserCardType,
  ReadOnlyToAbstractUserCardType,
  ReadOnlyPIRedactedToAbstractUserCardType,
  PIRedactedPatientsToAbstractUserCardType,
} from "../components/search/UserSearchHelper";
import UserSearchResults from "../components/search/UserSearchResults";
import CustomDialog from "../components/elements/dialog/CustomDialog";
import ViewPatientReason from "../components/patient/dialog/ViewPatientReason";
import CreateEditPatient from "../components/patient/create-patient/CreateEditPatient";
import CreateEditStaff from "../components/staff/create-edit-staff/CreateEditStaff";
import { CheckIsMobile } from "../utils/MobileStatus";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadCaseloadModal from "../components/caseload/DownloadCaseloadModal";
import BatchUploadMedicationModal from "../components/medication/BatchUploadMedicationModal";
import { PlaylistAdd } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import AddPatientsToCaseload from "../components/patient/admin-actions/AddPatientsToCaseload";
import AddCliniciansToCaseload from "../components/staff/caseloads/AddCliniciansToCaseload";
import RemoveUserFromCaseloadModal from "../components/patient/admin-actions/RemoveUserFromCaseloadModal";
import AddReadOnlyToCaseload from "../components/staff/caseloads/AddReadOnlyToCaseload";
import AddReadOnlyPIRedactedToCaseload from "../components/staff/caseloads/AddReadOnlyPIRedactedToCaseload";
import HandleError from "../components/elements/errors/HandleError";

const caseloadClient = new CaseloadClient(
  Configuration.SERVER_ROOT,
  FetchOverride
);

const tenantClient = new TenantClient(Configuration.SERVER_ROOT, FetchOverride);

const UserSearchPage = () => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles((th: Theme) => ({
    mainContainer: {
      padding: 0,
    },
    box: {
      marginTop: 10,
    },
    buttonContainer: {
      display: isMobile ? "block" : "flex",
      padding: 0,
      margin: 0,
      marginTop: isMobile ? undefined : "10px",
      flex: "0 0 max-content",
    },
    downloadResponsesButton: {
      whiteSpace: "nowrap",
      textAlign: "center",
      width: isMobile ? "100%" : "fit-content",
      marginTop: "10px",
      marginLeft: "auto",
    },
    downloadResponsesContainer: {
      display: "flex",
      alignSelf: "flex-end",
      padding: 0,
      marginLeft: isMobile ? 0 : 10,
    },
    medicationContainer: {
      padding: 0,
      marginTop: isMobile ? 0 : 10,
      display: isMobile ? "block" : "flex",
      marginLeft: isMobile ? 0 : 10,
      alignSelf: "flex-start",
    },
    button: {
      width: isMobile ? "100%" : "default",
      marginTop: isMobile ? "10px" : 0,
    },
    batchSuccessAlert: {
      marginTop: "20px",
    },
    noRoleIcon: {
      height: "2em",
      width: "2em",
    },
  }));
  const classes = useStyle(theme);

  const [statusCode, setStatusCode] = useState<number>();
  const [filteredUsers, setFilteredUsers] = useState<any[]>();
  const [filteredUserType, setFilteredUserType] = useState(Role.Patient);
  const [filteredCaseloadId, setFilteredCaseloadId] = useState(0);
  const [caseloads, setCaseloads] = useState<CaseloadViewModel[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchCriteria = searchParams.get("s");
  const searchDate = searchParams.get("d");
  const searchCaseload = searchParams.get("c");
  const role = searchParams.get("r");
  const page = searchParams.get("p");
  const [totalPages, setTotalPages] = useState<number>(0);

  const [sortDirection, setSortDirection] = useState<SortDirectionType>(
    SortDirectionType.Ascending
  );
  const [sortBy, setSortBy] = useState<SortType>(SortType.FirstName);

  const [openPatientReasonDialogue, setOpenPatientReasonDialogue] =
    useState(false);
  const [openDownloadCaseloadModal, setDownloadCaseloadModal] = useState(false);
  const [batchMediciationUploadModalOpen, setBatchMediciationUploadModalOpen] =
    useState(false);

  const [openRemoveUserFromCaseloadModal, setOpenRemoveUserFromCaseloadModal] =
    useState(false);
  const [removeUserRole, setRemoveUserRole] = useState<Role>();
  const [removeUserId, setRemoveUserId] = useState<number | undefined>(
    undefined
  );

  const [displayBatchSuccessAlert, setDisplayBatchSuccessAlert] =
    useState(false);
  const [batchSuccessAlert, setBatchSuccessAlert] = useState<string>("");

  const [selectedPatientId, setSelectedPatientId] = useState(0);
  const [pathToPatient, setPathToPatient] = useState<To>("");
  const [integration, setIntegration] = useState(true);
  const [refreshList, setRefreshList] = useState(false);
  const [usingMedication, setUsingMedication] = useState(false);
  const [usingExternalDatasets, setUsingExternalDatasets] = useState(false);
  const user = useContext(UserContext);
  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();
  const [firstLoadSearch, setFirstLoadSearch] = useState(true);
  const [sortOverride, setSortOverride] = useState(false);
  const [firstLoadCaseload, setFirstLoadCaseload] = useState(true);
  const [nhsNumberRequired, setNhsNumberRequired] = useState(false);
  const [customPatientId, setCustomPatientId] = useState(false);
  const [userTenancies, setUserTenancies] = useState<TenantViewModel[]>([]);
  const [userTenanciesLoaded, setUserTenanciesLoaded] = useState(false);

  const isReadOnly =
    user.role === Role.ReadOnly || user.role === Role.ReadOnlyPIRedacted;

  const [tenancyOptions, setTenancyOptions] = useState<
    TenantOptionsViewModel | undefined
  >(undefined);

  const [filteredCaseload, setFilteredCaseload] = useState<
    CaseloadViewModel | undefined
  >(undefined);
  useEffect(() => {
    setFilteredCaseload(caseloads.find((c) => c.id === filteredCaseloadId));
  }, [caseloads, filteredCaseloadId]);

  useEffect(() => {
    const effect = async () => {
      if (user.role !== Role.NoAccess) {
        setStatusCode(undefined);
        await resolveWhileMounted(tenantClient.getIsTenantIntegrated())
          .then((x) => setIntegration(x))
          .catch((e) => setStatusCode(e.status));

        await resolveWhileMounted(tenantClient.getMedicationEnabled())
          .then((x) => setUsingMedication(x))
          .catch((e) => setStatusCode(e.status));

        await resolveWhileMounted(tenantClient.getCustomPatientIdEnabled())
          .then((x) => setCustomPatientId(x))
          .catch((e) => setStatusCode(e.status));

        await resolveWhileMounted(tenantClient.getTenant(undefined))
          .then((tenant: TenantViewModel) =>
            setTenancyOptions(tenant.tenantOptions)
          )
          .catch((e) => setStatusCode(e.status));

        await resolveWhileMounted(tenantClient.getCustomPatientIdEnabled())
          .then((x) => setCustomPatientId(x))
          .catch((e) => setStatusCode(e.status));

        await resolveWhileMounted(tenantClient.getExternalDataEnabled())
          .then((x) => setUsingExternalDatasets(x))
          .catch((e) => setStatusCode(e.status));

        await resolveWhileMounted(tenantClient.getNHSNumberRequired())
          .then((x) => setNhsNumberRequired(x))
          .catch((e) => setStatusCode(e.status));
      }
    };
    effect();
  }, [resolveWhileMounted, user.role]);

  useEffect(() => {
    const effect = async () => {
      if (isMountedState()) {
        setStatusCode(undefined);
        await resolveWhileMounted(tenantClient.getUserTenancies())
          .then((x) => {
            setUserTenancies(x);
            setUserTenanciesLoaded(true);
          })
          .catch((e) => setStatusCode(e.status));
      }
    };
    effect();
  }, [isMountedState, resolveWhileMounted]);

  useEffect(() => {
    if (isMountedState() && firstLoadCaseload) {
      const effect = async () => {
        if (user === undefined) {
          return;
        }
        if (user.role !== Role.NoAccess) {
          setStatusCode(undefined);
          if (
            user.role === Role.Clinician ||
            user.role === Role.ReadOnly ||
            user.role === Role.ReadOnlyPIRedacted
          ) {
            await resolveWhileMounted(caseloadClient.getCaseloadsForClinician())
              .then((caseloadData) => setCaseloads(caseloadData))
              .catch((e) => setStatusCode(e.status));
          } else {
            await resolveWhileMounted(
              caseloadClient.getCaseloadsForAdministrator(false)
            )
              .then((caseloads) => {
                setCaseloads(caseloads);
              })
              .catch((e) => setStatusCode(e.status));
          }
        }
      };
      effect();
      setFirstLoadCaseload(false);
    }
  }, [
    setCaseloads,
    user,
    resolveWhileMounted,
    isMountedState,
    firstLoadCaseload,
  ]);

  useEffect(() => {
    if (
      (searchCriteria || searchDate || searchCaseload) &&
      (firstLoadSearch || sortOverride)
    ) {
      setSortOverride(false);
      filteringOfPatients(
        searchCriteria || "",
        searchDate || "",
        Number(searchCaseload),
        role ? (parseInt(role) as Role) : Role.Patient,
        page ? parseInt(page) : 1,
        sortBy,
        sortDirection
      );
    }
    setFirstLoadSearch(false);
    if (Number(searchCaseload) === 0) {
      setTotalPages(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchCriteria,
    searchDate,
    searchCaseload,
    refreshList,
    sortBy,
    sortDirection,
  ]);

  function filteringOfPatients(
    searchTerm: string,
    searchDate: string,
    caseload: number | null,
    role: Role,
    page: number | null,
    sort: SortType | null,
    direction: SortDirectionType | null
  ) {
    if (isMountedState()) {
      const effect = async () => {
        setLoading(true);
        var userClient = new UserClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        );
        await resolveWhileMounted(
          userClient.searchUsers(
            searchTerm,
            searchDate,
            caseload,
            role,
            sort === null ? sortBy : sort,
            direction === null ? sortDirection : sortDirection,
            user.id,
            page
          )
        )
          .then((users) => {
            navigate({
              pathname: "/search",
              search: createSearchParams({
                s: searchTerm,
                d: role === Role.Patient ? searchDate : "",
                c: caseload?.toString() || "",
                r: role.toString(),
                p: page?.toString() || "1",
              }).toString(),
            });
            setFilteredCaseloadId(caseload ?? 0);
            if (users === null) {
              setFilteredUsers([]);
              setTotalPages(0);
            } else {
              setFilteredUsers(users.item1);
              setTotalPages(users.item2);
            }
            setFilteredUserType(role);
            setLoading(false);
            setStatusCode(undefined);
          })
          .catch((e) => {
            if (isMountedState()) {
              setLoading(false);
              setStatusCode(e.status);
            }
          });
      };
      effect();
    }
  }

  const handleClickPatient = async (
    path: To,
    patientId: number | undefined
  ) => {
    if (user?.role !== Role.Admin && user?.role !== Role.SuperAdmin) {
      await resolveWhileMounted(
        new StaffClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).isClinicianLinkedToPatient(patientId)
      ).then((isLinked: boolean) => {
        if (!isLinked) {
          setSelectedPatientId(patientId || 0);
          setPathToPatient(path);
          setOpenPatientReasonDialogue(true);
        } else {
          navigate(path);
        }
      });
    } else {
      navigate(path);
    }
  };

  const handleRemovePatientFromCaseload = async (
    patientId: number | undefined
  ) => {
    setRemoveUserRole(Role.Patient);
    setRemoveUserId(patientId);
    setOpenRemoveUserFromCaseloadModal(true);
  };

  const handleRemoveClinicianFromCaseload = async (
    clinicianId: number | undefined
  ) => {
    setRemoveUserRole(Role.Clinician);
    setRemoveUserId(clinicianId);
    setOpenRemoveUserFromCaseloadModal(true);
  };

  const handleRemoveReadOnlyFromCaseload = async (
    readOnlyId: number | undefined
  ) => {
    setRemoveUserRole(Role.ReadOnly);
    setRemoveUserId(readOnlyId);
    setOpenRemoveUserFromCaseloadModal(true);
  };

  const handleRemoveReadOnlyPIRedactedFromCaseload = async (
    readOnlyPIRedactedId: number | undefined
  ) => {
    setRemoveUserRole(Role.ReadOnlyPIRedacted);
    setRemoveUserId(readOnlyPIRedactedId);
    setOpenRemoveUserFromCaseloadModal(true);
  };

  // Note: userTenancies will always be 1 or more since currenttenancy populates the list if no tenancy records are found.
  if (user.role === Role.NoAccess) {
    return (
      <Container className={classes.mainContainer}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
          sx={{ marginTop: "5em" }}
        >
          <Grid>
            <Stack direction="column" alignItems="center" gap={1}>
              <PersonOffIcon className={classes.noRoleIcon} />
              {user.patientLoginDisabled === true ? (
                <Typography variant="h5" align="center">
                  The tenancy does not support patient logins. Please change
                  your tenancy by clicking the avatar above or contact the
                  account support team.
                </Typography>
              ) : (
                <>
                  <>
                    {userTenanciesLoaded && userTenancies.length <= 1 && (
                      <Typography variant="h5" align="center">
                        No tenancies linked to this user. Please contact the
                        account support team.
                      </Typography>
                    )}
                  </>
                  <>
                    {userTenanciesLoaded && userTenancies.length > 1 && (
                      <Typography variant="h5" align="center">
                        Please select a tenancy by clicking the avatar above, if
                        you think there is something missing, contact the
                        account support team.
                      </Typography>
                    )}
                  </>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <>
      <Container className={classes.mainContainer}>
        <Box className={classes.box}>
          <UserSearchFilters
            filteringOfPatients={filteringOfPatients}
            caseloads={caseloads}
            criteriaValue={searchCriteria || ""}
            dateValue={searchDate || ""}
            caseloadValue={Number(searchCaseload)}
            roleFilterValue={role ? (parseInt(role) as Role) : null}
            clearSearch={() => {
              setFilteredUsers(undefined);
              setFilteredCaseloadId(0);
            }}
          />
          <HandleError statusCode={statusCode} />
        </Box>
        <Container className={classes.buttonContainer} disableGutters>
          {!integration &&
            (role === null || (parseInt(role) as Role) === Role.Patient ? (
              filteredCaseloadId === 0 && !isReadOnly ? (
                <CreateEditPatient
                  allCaseloads={caseloads}
                  style={{
                    justifyContent: usingMedication
                      ? "space-evenly"
                      : "flex-start",
                  }}
                  onSave={() => {
                    setFirstLoadSearch(true);
                    setRefreshList((x) => !x);
                  }}
                  onSuccess={(successAlert) => {
                    setDisplayBatchSuccessAlert(true);
                    setBatchSuccessAlert(successAlert);
                  }}
                  nhsNumberRequired={nhsNumberRequired}
                  customPatientId={customPatientId}
                  customPatientIdDisplayName={
                    tenancyOptions?.customPatientId?.displayName ??
                    DefaultCustomIdDisplayName
                  }
                  customPatientIdRegex={
                    tenancyOptions?.customPatientId?.regex ?? ""
                  }
                  customPatientIdMandatory={
                    tenancyOptions?.customPatientId?.mandatory ?? false
                  }
                />
              ) : (
                (user?.role === Role.Admin ||
                  user?.role === Role.SuperAdmin) && (
                  <AddPatientsToCaseload
                    caseloadId={filteredCaseloadId}
                    refresh={() => {
                      setFirstLoadSearch(true);
                      setRefreshList((x) => !x);
                    }}
                  />
                )
              )
            ) : (parseInt(role) as Role) === Role.Clinician ? (
              filteredCaseloadId === 0 ? (
                <CreateEditStaff
                  style={{
                    justifyContent: usingMedication
                      ? "space-evenly"
                      : "flex-start",
                  }}
                  onSave={() => {
                    setFirstLoadSearch(true);
                    setRefreshList((x) => !x);
                  }}
                  role={Role.Clinician}
                />
              ) : (
                (user?.role === Role.Admin ||
                  user?.role === Role.SuperAdmin) && (
                  <AddCliniciansToCaseload
                    caseloadId={filteredCaseloadId}
                    refresh={() => {
                      setFirstLoadSearch(true);
                      setRefreshList((x) => !x);
                    }}
                  />
                )
              )
            ) : (parseInt(role) as Role) === Role.ReadOnly ? (
              filteredCaseloadId === 0 ? (
                <CreateEditStaff
                  style={{
                    justifyContent: usingMedication
                      ? "space-evenly"
                      : "flex-start",
                  }}
                  onSave={() => {
                    setFirstLoadSearch(true);
                    setRefreshList((x) => !x);
                  }}
                  role={Role.ReadOnly}
                />
              ) : (
                (user?.role === Role.Admin ||
                  user?.role === Role.SuperAdmin) && (
                  <AddReadOnlyToCaseload
                    caseloadId={filteredCaseloadId}
                    refresh={() => {
                      setFirstLoadSearch(true);
                      setRefreshList((x) => !x);
                    }}
                  />
                )
              )
            ) : (parseInt(role) as Role) === Role.ReadOnlyPIRedacted ? (
              filteredCaseloadId === 0 ? (
                <CreateEditStaff
                  style={{
                    justifyContent: usingMedication
                      ? "space-evenly"
                      : "flex-start",
                  }}
                  onSave={() => {
                    setFirstLoadSearch(true);
                    setRefreshList((x) => !x);
                  }}
                  role={Role.ReadOnlyPIRedacted}
                />
              ) : (
                (user?.role === Role.Admin ||
                  user?.role === Role.SuperAdmin) && (
                  <AddReadOnlyPIRedactedToCaseload
                    caseloadId={filteredCaseloadId}
                    refresh={() => {
                      setFirstLoadSearch(true);
                      setRefreshList((x) => !x);
                    }}
                  />
                )
              )
            ) : (
              <></>
            ))}

          {role !== null && (parseInt(role) as Role) === Role.Admin && (
            <CreateEditStaff
              style={{
                justifyContent: usingMedication ? "space-evenly" : "flex-start",
              }}
              onSave={() => {
                setFirstLoadSearch(true);
                setRefreshList((x) => !x);
              }}
              role={Role.Admin}
            />
          )}

          {usingMedication &&
            filteredCaseloadId === 0 &&
            user?.role !== Role.ReadOnly &&
            user?.role !== Role.ReadOnlyPIRedacted &&
            (role === null || (parseInt(role) as Role) === Role.Patient) && (
              <Container className={classes.medicationContainer}>
                <BatchUploadMedicationModal
                  open={batchMediciationUploadModalOpen}
                  closeModal={() => setBatchMediciationUploadModalOpen(false)}
                  onSuccess={(successAlert) => {
                    setDisplayBatchSuccessAlert(true);
                    setBatchSuccessAlert(successAlert);
                  }}
                />
                <Button
                  variant="contained"
                  startIcon={<PlaylistAdd />}
                  onClick={() => setBatchMediciationUploadModalOpen(true)}
                  className={classes.button}
                >
                  Batch Upload Medication
                </Button>
              </Container>
            )}

          <Container
            className={classes.downloadResponsesContainer}
            disableGutters
          >
            <Button
              disabled={
                filteredCaseloadId === 0 ||
                filteredCaseload == null ||
                (parseInt(role ?? "1") as Role) !== Role.Patient
              }
              className={classes.downloadResponsesButton}
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={() => {
                setDownloadCaseloadModal(true);
              }}
            >
              Download caseload data
            </Button>
          </Container>
        </Container>

        <Collapse in={displayBatchSuccessAlert}>
          <Alert
            className={classes.batchSuccessAlert}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setDisplayBatchSuccessAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Success: {batchSuccessAlert}
          </Alert>
        </Collapse>

        <Box>
          <UserSearchResults
            allowCaseloadDeletion={
              filteredCaseloadId !== 0 &&
              (user?.role === Role.Admin || user?.role === Role.SuperAdmin) &&
              !integration
            }
            loading={loading}
            sortBy={sortBy}
            sortDirection={sortDirection}
            sortableValues={
              filteredUserType === Role.Patient
                ? user.role === Role.ReadOnlyPIRedacted
                  ? PatientPIRedactedSortValues
                  : PatientSortValues
                : StaffSortValues
            }
            users={
              filteredUserType === Role.Patient
                ? user.role === Role.ReadOnlyPIRedacted
                  ? PIRedactedPatientsToAbstractUserCardType(
                      filteredUsers,
                      (patient) =>
                        handleClickPatient(
                          "/viewPatient?p=" + String(patient.id),
                          patient.id
                        ),
                      (patient) => handleRemovePatientFromCaseload(patient.id)
                    )
                  : PatientsToAbstractUserCardType(
                      filteredUsers,
                      (patient) =>
                        handleClickPatient(
                          "/viewPatient?p=" + String(patient.id),
                          patient.id
                        ),
                      (patient) => handleRemovePatientFromCaseload(patient.id)
                    )
                : filteredUserType === Role.Clinician
                ? CliniciansToAbstractUserCardType(
                    filteredUsers,
                    (clinician) =>
                      handleClickPatient(
                        "/viewStaff/" + String(clinician.id),
                        clinician.id
                      ),
                    (clinician) =>
                      handleRemoveClinicianFromCaseload(clinician.id)
                  )
                : filteredUserType === Role.ReadOnly
                ? ReadOnlyToAbstractUserCardType(
                    filteredUsers,
                    (readOnly) =>
                      handleClickPatient(
                        "/viewStaff/" + String(readOnly.id),
                        readOnly.id
                      ),
                    (readOnly) => handleRemoveReadOnlyFromCaseload(readOnly.id)
                  )
                : filteredUserType === Role.ReadOnlyPIRedacted
                ? ReadOnlyPIRedactedToAbstractUserCardType(
                    filteredUsers,
                    (readOnlyPIRedacted) =>
                      handleClickPatient(
                        "/viewStaff/" + String(readOnlyPIRedacted.id),
                        readOnlyPIRedacted.id
                      ),
                    (readOnlyPIRedacted) =>
                      handleRemoveReadOnlyPIRedactedFromCaseload(
                        readOnlyPIRedacted.id
                      )
                  )
                : AdminsToAbstractUserCardType(filteredUsers, (admin) =>
                    handleClickPatient(
                      "/viewStaff/" + String(admin.id),
                      admin.id
                    )
                  )
            }
            page={page ? parseInt(page) : 1}
            totalPages={totalPages}
            onSortChange={(sort?: SortType, direction?: SortDirectionType) => {
              if (sort !== undefined) {
                setSortBy(sort);
              }
              if (direction !== undefined) {
                setSortDirection(direction);
              }
              setSortOverride(true);
            }}
            onPageChange={(newPage: number) => {
              filteringOfPatients(
                searchCriteria || "",
                searchDate || "",
                Number(searchCaseload),
                role ? (parseInt(role) as Role) : Role.Patient,
                newPage,
                sortBy,
                sortDirection
              );
            }}
          />
        </Box>
      </Container>

      {filteredCaseload && (
        <DownloadCaseloadModal
          open={openDownloadCaseloadModal}
          caseload={filteredCaseload}
          closeModal={() => setDownloadCaseloadModal(false)}
          enableExternalDatasets={usingExternalDatasets}
        />
      )}

      <RemoveUserFromCaseloadModal
        open={openRemoveUserFromCaseloadModal}
        id={removeUserId}
        role={removeUserRole}
        caseloadId={filteredCaseloadId}
        closeModal={() => {
          setOpenRemoveUserFromCaseloadModal(false);
        }}
        refresh={() => {
          setFirstLoadSearch(true);
          setRefreshList((x) => !x);
        }}
      />

      <CustomDialog
        open={openPatientReasonDialogue}
        onClose={() => setOpenPatientReasonDialogue(false)}
        title="Reason for viewing patient with no relationship"
      >
        <ViewPatientReason
          patientId={selectedPatientId}
          pathToPatient={pathToPatient}
          navigate={navigate}
        />
      </CustomDialog>
    </>
  );
};

export default UserSearchPage;
