import {
  CaseloadViewModel,
  ErrorType,
  Gender,
  PatientClient,
  PreferredContact,
  PatientViewModel,
  ContactDetailsViewModel,
} from "../../../../types/auto/types";
import { PhoneNumberUtil } from "google-libphonenumber";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";

export const IsNameValid = (name: string | undefined): boolean =>
  name !== undefined && /\S/.test(name);

export const IsGenderValid = (gender: Gender | undefined): boolean =>
  gender !== undefined;

export const IsBirthDateValid = (birthDate: Date | undefined): boolean =>
  birthDate !== undefined &&
  birthDate !== null &&
  birthDate < new Date() &&
  birthDate.getFullYear() > 1900;

export const IsCustomPatientIdValid = (
  customPatientId: string | undefined,
  regexExpression: string | undefined,
  customPatientIdMandatory: boolean
): boolean => {
  if (
    customPatientId === undefined ||
    customPatientId === null ||
    customPatientId.toString() === ""
  ) {
    return !customPatientIdMandatory;
  }
  return customPatientId?.match(new RegExp(regexExpression as string)) !== null;
};

export const IsNHSNumberValid = (
  nhsNumber: number | undefined,
  nhsNumberRequired: boolean
): boolean => {
  if (
    nhsNumber === undefined ||
    nhsNumber === null ||
    nhsNumber.toString() === ""
  ) {
    return !nhsNumberRequired;
  }

  // NHS number must be 10 digits long
  if (Math.floor(Math.log10(nhsNumber) + 1) !== 10) {
    return false;
  }

  // Multiply each digit by 10 minus their index and sum the result - ignoring the check digit (the last number)
  const summedWeightedNumber = Math.floor(nhsNumber / 10)
    .toString()
    .split("")
    .map((digit, i) => (10 - i) * parseInt(digit))
    .reduce((prev, current) => prev + current);

  // Mod the result and subtract the remainder from 11
  const calculatedCheckDigit = 11 - (summedWeightedNumber % 11);

  // If the calculated and the actual check digit are not equal, the NHS number is invalid
  if (calculatedCheckDigit !== nhsNumber % 10) {
    return false;
  }

  return true;
};

export const IsEmailValid = (email: string | undefined): boolean =>
  email !== undefined &&
  email !== null &&
  email !== "" &&
  email.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/g) !== null;

export const IsMobileNumberValid = (
  number: string | undefined,
  isRequired: boolean
) => {
  // If there is no number, it is valid unless it it required
  if (number === undefined || number === null || number === "") {
    return !isRequired;
  }

  try {
    const numberUtil = PhoneNumberUtil.getInstance();
    const numberValue = numberUtil.parse(number);
    return numberUtil.isValidNumber(numberValue);
  } catch {
    // If the parser can't parse the number, it is not valid
    return false;
  }
};

export const CreateEditPatientCall = (
  isCreate: boolean,
  patient: PatientViewModel,
  contact: ContactDetailsViewModel,
  selectedCaseloads: CaseloadViewModel[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setFirstNameError: React.Dispatch<React.SetStateAction<boolean>>,
  setLastNameError: React.Dispatch<React.SetStateAction<boolean>>,
  setBirthDateError: React.Dispatch<React.SetStateAction<boolean>>,
  setGenderError: React.Dispatch<React.SetStateAction<boolean>>,
  setNhsNumberError: React.Dispatch<React.SetStateAction<boolean>>,
  setEmailError: React.Dispatch<React.SetStateAction<boolean>>,
  setMobileNumberError: React.Dispatch<React.SetStateAction<boolean>>,
  closeModal: () => void,
  setError: React.Dispatch<React.SetStateAction<ErrorType | undefined>>,
  nhsNumberRequired: boolean,
  customPatientId: boolean,
  customPatientIdRegex: string,
  setCustomPatientIdError: React.Dispatch<React.SetStateAction<boolean>>,
  customPatientIdMandatory: boolean
) => {
  setLoading(true);

  patient.contactEmail = contact.contactEmail;
  patient.mobileNumber = contact.mobileNumber;
  patient.preferredContact = contact.preferredContact;

  // Validate the inputs
  const isFirstNameValid = IsNameValid(patient.firstName);
  const isLastNameValid = IsNameValid(patient.lastName);
  const isBirthDateValid = IsBirthDateValid(patient.dateOfBirth);
  const isGenderValid = IsGenderValid(patient.gender);
  const isNhsNumberValid = IsNHSNumberValid(
    patient.nhsNumber,
    nhsNumberRequired
  );
  const isEmailValid = IsEmailValid(patient.contactEmail);
  const isMobileNumberValid = IsMobileNumberValid(
    patient.mobileNumber,
    patient.preferredContact === PreferredContact.Mobile ||
      patient.preferredContact === PreferredContact.Both
  );
  const isCustomPatientIdValid = customPatientId
    ? IsCustomPatientIdValid(
        patient.customPatientId,
        customPatientIdRegex,
        customPatientIdMandatory
      )
    : true;

  setFirstNameError(!isFirstNameValid);
  setLastNameError(!isLastNameValid);
  setBirthDateError(!isBirthDateValid);
  setGenderError(!isGenderValid);
  setNhsNumberError(!isNhsNumberValid);
  setEmailError(!isEmailValid);
  setMobileNumberError(!isMobileNumberValid);
  setCustomPatientIdError(!isCustomPatientIdValid);

  if (
    !(
      isFirstNameValid &&
      isLastNameValid &&
      isBirthDateValid &&
      isGenderValid &&
      isNhsNumberValid &&
      isEmailValid &&
      isMobileNumberValid &&
      isCustomPatientIdValid
    )
  ) {
    setLoading(false);
    return;
  }

  if (isCreate) {
    new PatientClient(Configuration.SERVER_ROOT, FetchOverride)
      .createPatient(
        patient,
        selectedCaseloads.map((x) => x.id)
      )
      .then(() => {
        setError(undefined);
        closeModal();
      })
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  } else {
    new PatientClient(Configuration.SERVER_ROOT, FetchOverride)
      .updateDetails(patient)
      .then(() => {
        setError(undefined);
        closeModal();
      })
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  }
};
