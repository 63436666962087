import React from "react";
import { VictoryLabel, VictoryLabelProps } from "victory";

interface Props extends VictoryLabelProps {
  chartTitle: string;
}

const GraphTickLabel = (props: Props): JSX.Element => (
  <VictoryLabel
    {...{
      ...props,
      id: "Symptoms Graph Label X Axis " + props.chartTitle + props.text,
    }}
  />
);

export default GraphTickLabel;
