import React, { useEffect, useState } from "react";
import {
  DomainTuple,
  VictoryAxis,
  VictoryChart,
  VictoryZoomContainer,
} from "victory";
import GraphLegend, {
  ExtendedLineViewModel,
} from "../../../elements/graph/GraphLegend";
import GraphLoading from "../../../elements/graph/GraphLoading";
import GraphError from "../../../elements/graph/GraphError";
import { useMountedState, useUnmountPromise } from "react-use";
import {
  GetDateInterval,
  GetMaxYValueForSummaryGraph,
  GetXAxisDomain,
} from "../ChartHelper";
import GraphTickLabel from "../GraphTickLabel";
import {
  CreateBackgroundVictory,
  CreateReverseBackgroundVictory,
  DataScatter,
  NoResponseScatter,
  GroupedLine,
  VersionChangeScatter,
  updateNoResponseYValues,
  AdditionalPlotsScatter,
  InvalidResponseScatter,
  updateInvalidResponseYValues,
} from "./LineGraphCoreHelper";
import MobileZoomable from "./MobileZoomable";
import { CheckIsTablet } from "../../../../utils/MobileStatus";
import {
  GraphTenantOptionsModel,
  TenantOptionsViewModel,
} from "../../../../../types/auto/types";
import GraphNoData from "../../../elements/graph/GraphNoData";

interface Props {
  data: ExtendedLineViewModel[];
  fromDate: Date | undefined;
  toDate: Date | undefined;
  loading: boolean;
  error: boolean;
  width: number;
  height: number;
  yDomain?: DomainTuple;
  fromGoal?: boolean;
  tenancyOptions: TenantOptionsViewModel | undefined;
  hideLegend?: boolean;
}

const LineGraphCore = (props: Props): JSX.Element => {
  const isMobile = CheckIsTablet();
  const [maxYValue, setMaxYValue] = useState(10);
  const [data, setData] = useState<ExtendedLineViewModel[]>(props.data);
  const [xAxisLabels, setXAxisLabels] = useState<number[][]>([[]]);
  const [xAxisValues, setXAxisValues] = useState<number[]>([]);
  const [dateInterval, setDateInterval] = useState<string>();
  const isMountedState = useMountedState();
  const resolveWhileMounted = useUnmountPromise();
  const [zoomDomain, setZoomDomain] = useState<{
    x?: DomainTuple;
    y?: DomainTuple;
  }>();
  const [mobileSelectedDomain, setMobileSelectedDomain] = useState<{
    x?: DomainTuple;
    y?: DomainTuple;
  }>();

  useEffect(
    () => {
      const effect = async () => {
        // Get the max y value and min and max x values for the axis
        if (data && isMountedState()) {
          setMaxYValue(GetMaxYValueForSummaryGraph(data));

          const xAxisLabels = GetXAxisDomain(props.fromDate, props.toDate, 1);
          setXAxisLabels(xAxisLabels);

          const dateInterval = GetDateInterval(props.fromDate, props.toDate);
          setDateInterval(dateInterval);

          switch (dateInterval) {
            case "year":
              setXAxisValues(xAxisLabels[0]);
              break;
            case "month":
              setXAxisValues(xAxisLabels[1]);
              break;
            case "week":
              setXAxisValues(xAxisLabels[2]);
              break;
            default:
              setXAxisValues(xAxisLabels[3]);
              break;
          }
        }
      };
      effect();
    },
    // Including xAxisLabels and dateInterval in dependency array causes infinite recursion
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      data.length,
      isMountedState,
      resolveWhileMounted,
      props.fromDate,
      props.toDate,
    ]
  );

  function getGraphTenantOptions() {
    var options: GraphTenantOptionsModel | undefined = undefined;
    if (props.tenancyOptions?.graphTenancyOptions != null && data.length > 0) {
      if (data[0].plotSubscalesLineId) {
        options = props.tenancyOptions.graphTenancyOptions.filter(
          (x) => x.questionnaireId === data[0].plotSubscalesLineId
        )[0];
        return options;
      } else if (data.length === 1) {
        options = props.tenancyOptions.graphTenancyOptions.filter(
          (x) => x.questionnaireId === data[0].lineId
        )[0];
        return options;
      }
    }
    return undefined;
  }

  const graphTenancyOptions = getGraphTenantOptions();

  return (
    <>
      {props.loading ? (
        <GraphLoading width={props.width} height={props.height} type="line" />
      ) : props.error ? (
        <GraphError width={props.width} height={props.height} />
      ) : props.data.length === 0 ? (
        <GraphNoData width={props.width} height={props.height} />
      ) : (
        <>
          <VictoryChart
            domainPadding={{ x: 40, y: 40 }}
            padding={{ left: 120, top: 0, right: 50, bottom: 55 }}
            domain={{
              x:
                xAxisValues.length > 1
                  ? [xAxisValues[0], xAxisValues[xAxisValues.length - 1]]
                  : undefined,
              y: props.yDomain || [0, maxYValue],
            }}
            width={props.width}
            height={props.height}
            aria-label={
              "Summary Graph" + data && data.length === 1
                ? data[0].lineName
                : "all"
            }
            containerComponent={
              <VictoryZoomContainer
                zoomDimension="x"
                containerId="chart"
                height={props.height}
                minimumZoom={{ x: 1000 * 3600 * 24 * 6 }}
                onZoomDomainChange={(s) => {
                  setMobileSelectedDomain(s);

                  const newDateInterval = GetDateInterval(
                    new Date(s.x[0]),
                    new Date(s.x[1])
                  );

                  if (newDateInterval !== dateInterval) {
                    setDateInterval(newDateInterval);
                    switch (newDateInterval) {
                      case "year":
                        setXAxisValues(xAxisLabels[0]);
                        break;
                      case "month":
                        setXAxisValues(xAxisLabels[1]);
                        break;
                      case "week":
                        setXAxisValues(xAxisLabels[2]);
                        break;
                      default:
                        setXAxisValues(xAxisLabels[3]);
                        break;
                    }
                  }
                }}
                zoomDomain={zoomDomain}
                allowZoom={!isMobile}
              />
            }
          >
            <VictoryAxis
              dependentAxis
              style={{
                grid: { stroke: "lightgray", strokeWidth: 0.5 },
                axisLabel: { padding: 50 },
              }}
              label="Score"
              crossAxis={false}
              tickLabelComponent={
                <GraphTickLabel
                  chartTitle={
                    data && data.length > 0 ? data[0].lineName || "" : ""
                  }
                />
              }
            />
            <VictoryAxis
              style={{
                grid: { stroke: "lightgray", strokeWidth: 0.5 },
                tickLabels: { angle: -5, padding: 20 },
                axisLabel: { padding: 35 },
              }}
              tickValues={xAxisValues}
              tickFormat={(t: number) => {
                switch (dateInterval) {
                  case "year":
                    return new Date(t).toLocaleDateString("en-GB", {
                      year: "numeric",
                    });
                  case "month":
                    return new Date(t).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "short",
                    });
                  default:
                    return new Date(t).toLocaleDateString("en-GB");
                }
              }}
            />
            {graphTenancyOptions !== undefined &&
              !props.fromGoal &&
              graphTenancyOptions.borderlineScore <
                graphTenancyOptions.clinicalScore &&
              CreateBackgroundVictory(
                graphTenancyOptions?.stableScore ?? 0,
                graphTenancyOptions?.borderlineScore ?? 0,
                graphTenancyOptions?.clinicalScore ?? 0,
                props.tenancyOptions?.graphBackgroundOpacity ?? 0.2,
                maxYValue
              )}
            {graphTenancyOptions !== undefined &&
              !props.fromGoal &&
              graphTenancyOptions.borderlineScore >
                graphTenancyOptions.clinicalScore &&
              CreateReverseBackgroundVictory(
                graphTenancyOptions?.stableScore ?? 0,
                graphTenancyOptions?.borderlineScore ?? 0,
                graphTenancyOptions?.clinicalScore ?? 0,
                props.tenancyOptions?.graphBackgroundOpacity ?? 0.2,
                maxYValue
              )}
            {GroupedLine(props.data, "")}
            {DataScatter(props.data, "")}
            {VersionChangeScatter(props.data, "")}
            {NoResponseScatter(
              updateNoResponseYValues(props.data, maxYValue),
              ""
            )}
            {AdditionalPlotsScatter(props.data, "")}
            {InvalidResponseScatter(
              updateInvalidResponseYValues(props.data, maxYValue),
              ""
            )}
          </VictoryChart>
          {/* Add a second chart on mobile to enable zooming, as the zoom container is bugged on mobile */}
          {isMobile && (
            <MobileZoomable
              width={props.width}
              mobileSelectedDomain={mobileSelectedDomain}
              setZoomDomain={setZoomDomain}
              xAxisValues={xAxisValues}
              dateInterval={dateInterval}
              xAxisLabels={xAxisLabels}
              setDateInterval={setDateInterval}
              setXAxisValues={setXAxisValues}
              fromDate={props.fromDate}
              toDate={props.toDate}
              data={data}
              yDomain={props.yDomain || [0, maxYValue]}
            />
          )}
          {data.length > 1 && !props.hideLegend && (
            <GraphLegend data={data} setData={setData} />
          )}
        </>
      )}
    </>
  );
};

export default LineGraphCore;
