import { Alert } from "@mui/material";

interface Props {
  message: string;
  Show: boolean;
}

export const FormErrorText = (props: Props): JSX.Element => {
  return (
    <Alert className="validationPass" severity="success" hidden={!props.Show}>
      {props.message}
    </Alert>
  );
};

export default FormErrorText;
