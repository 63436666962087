import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { useMountedState } from "react-use";
import { theme } from "../../../../../Theme";
import { Day, MonthPlace } from "../../../../../types/auto/types";
import { CheckIsMobile } from "../../../../utils/MobileStatus";
interface Props {
  dayOfMonth: number | undefined;
  setDayOfMonth: (dayOfMonth: number | undefined) => void;
  monthPlace: MonthPlace | undefined;
  setMonthPlace: (monthPlace: MonthPlace | undefined) => void;
  dayOfWeek: Day | undefined;
  setDayOfWeek: (dayOfWeek: Day | undefined) => void;
  timeOfDay: string | undefined;
  setTimeOfDay: (timeOfDay: string) => void;
  frequencyAmount: number;
  setFrequencyAmount: (frequencyAmount: number) => void;
}

const defaultDayOfMonth = 1;
const minDayOfMonthValue = 1;
const maxDayOfMonthValue = 31;
const defaultWeekPlace = MonthPlace.First;
const defaultDayOfWeek = Day.Monday;
const onDayValue = "onDay";
const onTheValue = "onThe";

const MonthlyPromptScheduling = (props: Props) => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    container: {
      display: isMobile ? "grid" : "flex",
      width: "100%",
    },
    firstOptionContainer: {
      display: isMobile ? "grid" : "flex",
      marginBottom: 10,
    },
    secondOptionContainer: {
      display: isMobile ? "grid" : "flex",
      marginBottom: 10,
      marginLeft: isMobile ? 0 : 20,
    },
    dayOfMonth: {
      display: isMobile ? "grid" : "flex",
      width: "100%",
    },
    monthContainer: {
      display: "flex",
    },
  });
  const classes = useStyle(theme);
  const [radioValue, setRadioValue] = useState(
    props.dayOfMonth === undefined || props.dayOfMonth === null
      ? onTheValue
      : onDayValue
  );
  const [dayOfMonth, setDayOfMonth] = useState(
    props.dayOfMonth === undefined || props.dayOfMonth === null
      ? defaultDayOfMonth
      : props.dayOfMonth
  );
  const [monthPlace, setMonthPlace] = useState(
    props.monthPlace === undefined || props.monthPlace === null
      ? defaultWeekPlace
      : props.monthPlace
  );
  const [dayOfWeek, setDayOfWeek] = useState(
    props.dayOfWeek === undefined || props.dayOfWeek == null
      ? defaultDayOfWeek
      : props.dayOfWeek
  );

  const isMountedState = useMountedState();

  // If the props change, update the values stored in state
  useEffect(() => {
    if (isMountedState()) {
      if (
        props.dayOfMonth !== undefined &&
        props.dayOfMonth !== null &&
        props.dayOfMonth !== dayOfMonth
      ) {
        setDayOfMonth(props.dayOfMonth);
        setRadioValue(onDayValue);
      }
      if (
        props.monthPlace !== undefined &&
        props.monthPlace !== null &&
        props.monthPlace !== monthPlace
      ) {
        setMonthPlace(props.monthPlace);
        setRadioValue(onTheValue);
      }
      if (
        props.dayOfWeek !== undefined &&
        props.dayOfWeek !== null &&
        props.dayOfWeek !== dayOfWeek
      ) {
        setDayOfWeek(props.dayOfWeek);
        setRadioValue(onTheValue);
      }
    }
  }, [
    props.dayOfMonth,
    props.monthPlace,
    props.dayOfWeek,
    isMountedState,
    setDayOfMonth,
    setMonthPlace,
    setDayOfWeek,
    props,
    dayOfMonth,
    monthPlace,
    dayOfWeek,
  ]);

  return (
    <FormControl sx={{ paddingTop: "10px" }}>
      <RadioGroup
        defaultValue="onDay"
        value={radioValue}
        onChange={(event) => setRadioValue(event.target.value)}
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <div className={classes.firstOptionContainer}>
          <FormControlLabel value="onDay" control={<Radio />} label="On Day" />
          <TextField
            label="Day of Month"
            type="number"
            className={classes.dayOfMonth}
            InputProps={{
              inputProps: {
                min: minDayOfMonthValue,
                max: maxDayOfMonthValue,
              },
            }}
            value={dayOfMonth === 0 ? "" : dayOfMonth}
            onFocus={(event) => {
              event.target.select();
            }}
            onChange={(event) => {
              let val = Number(event.target.value);

              // Impose min / max value constraints
              val =
                val < minDayOfMonthValue
                  ? minDayOfMonthValue
                  : val > maxDayOfMonthValue
                  ? maxDayOfMonthValue
                  : val;
              setDayOfMonth(val);
              props.setDayOfMonth(val);
              props.setDayOfWeek(undefined);
              props.setMonthPlace(undefined);
            }}
            disabled={radioValue !== onDayValue}
          />
        </div>

        <div className={classes.secondOptionContainer}>
          <FormControlLabel
            value="onThe"
            control={
              <Radio
                onFocus={() => {
                  if (
                    props.dayOfWeek !== null &&
                    props.dayOfWeek !== undefined
                  ) {
                    props.setDayOfWeek(props.dayOfWeek);
                  } else {
                    props.setDayOfWeek(Day.Monday);
                  }
                  if (
                    props.monthPlace !== null &&
                    props.monthPlace !== undefined
                  ) {
                    props.setMonthPlace(props.monthPlace);
                  } else {
                    props.setMonthPlace(MonthPlace.First);
                  }
                }}
              />
            }
            label="On The"
          />
          <FormControl>
            <Select
              value={monthPlace}
              onChange={(event) => {
                props.setMonthPlace(event.target.value as MonthPlace);
                props.setDayOfMonth(undefined);
                setMonthPlace(event.target.value as MonthPlace);
              }}
              disabled={radioValue === onDayValue}
            >
              {Object.keys(MonthPlace)
                .filter((x) => isNaN(Number(x)))
                .map((val) => {
                  return (
                    <MenuItem
                      key={val}
                      value={MonthPlace[val as keyof typeof MonthPlace]}
                      role="menuitem"
                    >
                      {val}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl>
            <Select
              value={dayOfWeek}
              onChange={(event) => {
                setDayOfWeek(event.target.value as Day);
                props.setDayOfWeek(event.target.value as Day);
                props.setDayOfMonth(undefined);
              }}
              disabled={radioValue === onDayValue}
            >
              {Object.keys(Day)
                .filter((x) => isNaN(Number(x)))
                .map((val) => {
                  return (
                    <MenuItem
                      key={val}
                      value={Day[val as keyof typeof Day]}
                      role="menuitem"
                    >
                      {val}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default MonthlyPromptScheduling;
